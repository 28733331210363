import PublicHeader from 'component/PublicHeader';
import React from 'react';
import { Link } from 'react-router-dom';

const ZoomConfigInfo = () => {
	return (
		<>
			<PublicHeader />
			<div className="p-8">
				<div className="my-2">
					<div
						style={{
							fontFamily: 'Paytone One',
							fontSize: 32,
						}}
						className="w-full"
					>
						Configuration for Zoom Live Meeting Captions
					</div>
				</div>
				<div>
					<div>
						<p className="m-2">
							This is the Zoom configuration page, where I will guide you
							through the necessary steps to set up your Zoom account and enable
							live captioning during your Zoom meetings. To enable live
							captioning,{' '}
							<strong>
								you must be the host of the meeting and have a Zoom Pro account
							</strong>
							. With live captioning, you can choose the language you prefer for
							real-time transcription of the audio in your meetings. Allow me to
							walk you through the process and make sure you have all the
							information you need to get starte
						</p>
						<div>
							<div
								style={{
									fontFamily: 'Paytone One',
									fontSize: 28,
								}}
								className="w-full"
							>
								Prerequisites
							</div>
							<ol className="m-2">
								<li>
									<strong>1.</strong> Zoom Pro Account
								</li>
								<li>
									<strong>2.</strong> Must Be Zoom Host
								</li>
							</ol>
						</div>
						<div>
							<div
								style={{
									fontFamily: 'Paytone One',
									fontSize: 22,
								}}
								className="w-full"
							>
								Step 1
							</div>
							<p className="m-2">
								To begin with, please log in to your Zoom account on the web and
								navigate to the settings page by clicking on your profile. Once
								you are in the settings, look for the "In Meeting (Advanced)"
								section and locate the manual caption option. Enable this option
								and make sure to check the box that says "Allow use of caption
								API Token to integrate with 3rd-party Closed Captioning
								services". This will ensure that you can use live captioning
								during your Zoom meetings.
							</p>
							<div className="m-5">
								<img
									src={
										'https://react-vimeo-hosting.s3.us-east-2.amazonaws.com/step-1.png'
									}
									alt="step 1"
									style={{ width: '1000px' }}
									className="mx-auto"
								/>{' '}
							</div>
						</div>
						<div>
							<div
								style={{
									fontFamily: 'Paytone One',
									fontSize: 22,
								}}
								className="w-full"
							>
								Step 2
							</div>
							<p className="m-2">
								Moving on to the next step, in the meeting advanced settings,
								you will find the option "Allow livestreaming of meetings". This
								option is only available for users with a Zoom Pro account, so
								make sure that you have this subscription. Once you have enabled
								the livestreaming option, ensure that you also check the "Custom
								Live Streaming Service" box. In the provided box labeled
								"Provide specific instructions that will enable meeting hosts in
								your account to configure live streaming", you can enter the
								following value to make it easier for you to start live
								streaming: "rtmp://stream.letz.chat/live". Simply copy and paste
								this value to use it in the future. With these steps completed,
								you have successfully finished the second step.
							</p>
							<div className="m-5">
								<img
									src={
										'https://react-vimeo-hosting.s3.us-east-2.amazonaws.com/step-2.jpeg'
									}
									alt="step 2"
									style={{ width: '1000px' }}
									className="mx-auto"
								/>
							</div>
							<p className="m-5">
								Great job! You have now configured your captioning tool to
								render live translated captions during Zoom meetings. With this
								tool in place, you can now provide more accessibility and
								engagement for all meeting attendees, even if you are not the
								host of the Zoom meeting. We hope that this guide has been
								helpful in enhancing your meeting experience and we thank you
								for using our captioning tool.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="p-8">
				<div className="my-2">
					<div
						style={{
							fontFamily: 'Paytone One',
							fontSize: 32,
						}}
						className="w-full"
					>
						How to Start Zoom Live Meeting Captions
					</div>
				</div>
				<div>
					<div>
						<p className="m-2">
							Certainly! In this page, we will guide you through the steps to
							start live captions during a Zoom meeting. Live captions provide
							real-time transcription of audio in the meeting, making it easier
							for attendees to follow along and participate. With our
							easy-to-follow instructions, you'll be able to start live captions
							in no time.
						</p>
						<div>
							<div
								style={{
									fontFamily: 'Paytone One',
									fontSize: 28,
								}}
								className="w-full"
							>
								Prerequisites
							</div>
							<ol className="m-2">
								<li>
									<strong>1.</strong> Zoom Pro Account
								</li>
								<li>
									<strong>2.</strong> Must Be Zoom Host
								</li>
							</ol>
						</div>
						<div>
							<div
								style={{
									fontFamily: 'Paytone One',
									fontSize: 22,
								}}
								className="w-full"
							>
								Step 1 : Start the Live Stream on Custom Service
							</div>
							<div>
								<p className="m-2">
									Follow these instructions to start the live captions during
									your Zoom meeting:
								</p>
								<ol className="m-2">
									<li className="m-2">
										<p>
											Start the live stream on the custom service provided by
											our captioning tool. This service uses only your audio to
											transcribe captions in various languages.
										</p>
									</li>
									<li className="m-2">
										<p>
											Once your Zoom meeting has started, click on the three-dot
											"More" button at the bottom of the meeting window.
										</p>
									</li>
									<li className="m-2">
										<p>
											Select the "Live on Custom Live Streaming Service" option.
											This will take you to a new page where you will be
											prompted to enter three values:
										</p>
										<ol type="a" className="m-2">
											<li>Streaming URL</li>
											<li>Streaming key</li>
											<li>Live streaming page URL</li>
										</ol>
										<p>
											These values are available on your dashboard. You can{' '}
											<Link to={'/zoom'} className="text-blue-400">
												Click here
											</Link>{' '}
											to access them.
										</p>
									</li>
									<li className="m-2">
										<p>
											Make sure not to select the option "Custom streaming
											service requires authentication to broadcast."
										</p>
									</li>
									<li className="m-2">
										<p>
											Click on "Go Live." Zoom will ask for permission to access
											your meeting. Click "Got It."
										</p>
									</li>
									<li className="m-2">
										<p>
											Congratulations, you have successfully completed Step 1
											and started live streaming your Zoom meeting on our
											captioning tool's custom service. Our tool will now
											transcribe captions in real-time based on the audio from
											your meeting.
										</p>
									</li>
								</ol>
							</div>
							<div className="m-5">
								<img
									src={
										'https://react-vimeo-hosting.s3.us-east-2.amazonaws.com/live-stream.jpeg'
									}
									alt="Live Streaming"
									style={{ width: '1000px' }}
									className="mx-auto"
								/>{' '}
							</div>
							<div className="m-5">
								<img
									src={
										'https://react-vimeo-hosting.s3.us-east-2.amazonaws.com/live-page.jpeg'
									}
									alt="Live Streaming"
									style={{ width: '1000px' }}
									className="mx-auto"
								/>{' '}
							</div>
						</div>
						<div>
							<div
								style={{
									fontFamily: 'Paytone One',
									fontSize: 22,
								}}
								className="w-full"
							>
								Step 2: Get the Caption API Token for the Live Meeting
							</div>
							<div className="m-2">
								<p className="m-2">
									Follow these instructions to get the caption API token for the
									live meeting:
								</p>
								<ol className="m-2">
									<li className="m-2">
										<p>
											During the Zoom meeting, click on the "Show Caption"
											button in the meeting controls. This will open a modal
											window.
										</p>
									</li>
									<li className="m-2">
										<p>
											In the caption window, click on the "Save" button. It
											doesn't matter which language you select, as our captions
											are not language-dependent. You can define the source
											language in our dashboard panel when you start the
											meeting.
										</p>
									</li>
									<li className="m-2">
										<p>
											Click on the small up arrow in the top right corner of the
											caption button. This will open a menu.
										</p>
									</li>
									<li className="m-2">
										<p>
											Click on the "Setup Manually" caption button and then
											select "On."
										</p>
									</li>
									<li className="m-2">
										<p>
											Click the "Copy the API Token" button. You have now
											successfully obtained the API token for the captions.
										</p>
									</li>
									<li className="m-2">
										<p>
											Paste the API token into our captioning tool's form to
											start captioning the meeting.
										</p>
									</li>
								</ol>
							</div>
							<div className="m-5">
								<img
									src={
										'https://react-vimeo-hosting.s3.us-east-2.amazonaws.com/caption-token-image.jpeg'
									}
									alt="captionTokenImage"
									style={{ width: '1000px' }}
									className="mx-auto"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ZoomConfigInfo;
