export const awsTranscribeLanguages = [
	{ code: 'en-IE', name: 'English (Ireland)' },
	{ code: 'ar-AE', name: 'Arabic (United Arab Emirates)' },
	{ code: 'te-IN', name: 'Telugu (India)' },
	{ code: 'zh-TW', name: 'Chinese (Taiwan)' },
	{ code: 'en-US', name: 'English (United States)' },
	{ code: 'ta-IN', name: 'Tamil (India)' },
	{ code: 'en-AB', name: 'English (Unknown region)' }, // 'AB' is not a standard region code
	{ code: 'en-IN', name: 'English (India)' },
	{ code: 'zh-CN', name: 'Chinese (China)' },
	{ code: 'ar-SA', name: 'Arabic (Saudi Arabia)' },
	{ code: 'en-ZA', name: 'English (South Africa)' },
	{ code: 'gd-GB', name: 'Scottish Gaelic (United Kingdom)' },
	{ code: 'th-TH', name: 'Thai (Thailand)' },
	{ code: 'tr-TR', name: 'Turkish (Turkey)' },
	{ code: 'ru-RU', name: 'Russian (Russia)' },
	{ code: 'pt-PT', name: 'Portuguese (Portugal)' },
	{ code: 'nl-NL', name: 'Dutch (Netherlands)' },
	{ code: 'it-IT', name: 'Italian (Italy)' },
	{ code: 'id-ID', name: 'Indonesian (Indonesia)' },
	{ code: 'fr-FR', name: 'French (France)' },
	{ code: 'es-ES', name: 'Spanish (Spain)' },
	{ code: 'de-DE', name: 'German (Germany)' },
	{ code: 'ga-IE', name: 'Irish (Ireland)' },
	{ code: 'af-ZA', name: 'Afrikaans (South Africa)' },
	{ code: 'en-NZ', name: 'English (New Zealand)' },
	{ code: 'ko-KR', name: 'Korean (South Korea)' },
	{ code: 'hi-IN', name: 'Hindi (India)' },
	{ code: 'de-CH', name: 'German (Switzerland)' },
	{ code: 'vi-VN', name: 'Vietnamese (Vietnam)' },
	{ code: 'cy-GB', name: 'Welsh (United Kingdom)' },
	{ code: 'ms-MY', name: 'Malay (Malaysia)' },
	{ code: 'he-IL', name: 'Hebrew (Israel)' },
	{ code: 'da-DK', name: 'Danish (Denmark)' },
	{ code: 'en-AU', name: 'English (Australia)' },
	{ code: 'pt-BR', name: 'Portuguese (Brazil)' },
	{ code: 'en-WL', name: 'English (Unknown region)' }, // 'WL' is not a standard region code
	{ code: 'fa-IR', name: 'Persian (Iran)' },
	{ code: 'sv-SE', name: 'Swedish (Sweden)' },
	{ code: 'ja-JP', name: 'Japanese (Japan)' },
	{ code: 'es-US', name: 'Spanish (United States)' },
	{ code: 'fr-CA', name: 'French (Canada)' },
	{ code: 'en-GB', name: 'English (United Kingdom)' },
];

const languages = [
	{ code: 'af', name: 'Afrikaans' },
	{ code: 'sq', name: 'Albanian' },
	{ code: 'am', name: 'Amharic' },
	{ code: 'ar', name: 'Arabic' },
	{ code: 'hy', name: 'Armenian' },
	{ code: 'az', name: 'Azerbaijani' },
	{ code: 'bn', name: 'Bengali' },
	{ code: 'bs', name: 'Bosnian' },
	{ code: 'bg', name: 'Bulgarian' },
	{ code: 'ca', name: 'Catalan' },
	{ code: 'zh', name: 'Chinese' },
	{ code: 'hr', name: 'Croatian' },
	{ code: 'cs', name: 'Czech' },
	{ code: 'da', name: 'Danish' },
	{ code: 'fa', name: 'Dari' },
	{ code: 'nl', name: 'Dutch' },
	{ code: 'en', name: 'English' },
	{ code: 'et', name: 'Estonian' },
	{ code: 'fa', name: 'Farsi' },
	{ code: 'tl', name: 'Filipino' },
	{ code: 'fi', name: 'Finnish' },
	{ code: 'fr', name: 'French' },
	{ code: 'ka', name: 'Georgian' },
	{ code: 'de', name: 'German' },
	{ code: 'el', name: 'Greek' },
	{ code: 'gu', name: 'Gujarati' },
	{ code: 'ht', name: 'Haitian' },
	{ code: 'ha', name: 'Hausa' },
	{ code: 'he', name: 'Hebrew' },
	{ code: 'hi', name: 'Hindi' },
	{ code: 'hu', name: 'Hungarian' },
	{ code: 'is', name: 'Icelandic' },
	{ code: 'id', name: 'Indonesian' },
	{ code: 'ga', name: 'Irish' },
	{ code: 'it', name: 'Italian' },
	{ code: 'ja', name: 'Japanese' },
	{ code: 'kn', name: 'Kannada' },
	{ code: 'kk', name: 'Kazakh' },
	{ code: 'ko', name: 'Korean' },
	{ code: 'lv', name: 'Latvian' },
	{ code: 'lt', name: 'Lithuanian' },
	{ code: 'mk', name: 'Macedonian' },
	{ code: 'ms', name: 'Malay' },
	{ code: 'ml', name: 'Malayalam' },
	{ code: 'mt', name: 'Maltese' },
	{ code: 'mr', name: 'Marathi' },
	{ code: 'mn', name: 'Mongolian' },
	{ code: 'no', name: 'Norwegian' },
	{ code: 'ps', name: 'Pashto' },
	{ code: 'pl', name: 'Polish' },
	{ code: 'pt', name: 'Portuguese' },
	{ code: 'pa', name: 'Punjabi' },
	{ code: 'ro', name: 'Romanian' },
	{ code: 'ru', name: 'Russian' },
	{ code: 'sr', name: 'Serbian' },
	{ code: 'si', name: 'Sinhala' },
	{ code: 'sk', name: 'Slovak' },
	{ code: 'sl', name: 'Slovenian' },
	{ code: 'so', name: 'Somali' },
	{ code: 'es', name: 'Spanish' },
	{ code: 'sw', name: 'Swahili' },
	{ code: 'sv', name: 'Swedish' },
	{ code: 'ta', name: 'Tamil' },
	{ code: 'te', name: 'Telugu' },
	{ code: 'th', name: 'Thai' },
	{ code: 'tr', name: 'Turkish' },
	{ code: 'uk', name: 'Ukrainian' },
	{ code: 'ur', name: 'Urdu' },
	{ code: 'uz', name: 'Uzbek' },
	{ code: 'vi', name: 'Vietnamese' },
	{ code: 'cy', name: 'Welsh' },
	{ code: 'es-MX', name: 'Spanish(Mexico)' },
	{ code: 'pt-PT', name: 'Portuguese(Portugal)' },
	{ code: 'zh-TW', name: 'Chinese(Traditional)' },
	{ code: 'fa-AF', name: 'Dari' },
	{ code: 'fr-CA', name: 'French(Canada)' },
];

export const talkEasyLanguages = [
	{ code: 'af', name: 'Afrikaans' },
	{ code: 'ar', name: 'Arabic' },
	{ code: 'bn', name: 'Bengali' },
	{ code: 'bg', name: 'Bulgarian' },
	{ code: 'ca', name: 'Catalan' },
	{ code: 'zh', name: 'Chinese' },
	{ code: 'cs', name: 'Czech' },
	{ code: 'da', name: 'Danish' },
	{ code: 'nl', name: 'Dutch' },
	{ code: 'en', name: 'English' },
	{ code: 'tl', name: 'Filipino' },
	{ code: 'fi', name: 'Finnish' },
	{ code: 'de', name: 'German' },
	{ code: 'el', name: 'Greek' },
	{ code: 'gu', name: 'Gujarati' },
	{ code: 'he', name: 'Hebrew' },
	{ code: 'hi', name: 'Hindi' },
	{ code: 'hu', name: 'Hungarian' },
	{ code: 'is', name: 'Icelandic' },
	{ code: 'id', name: 'Indonesian' },
	{ code: 'it', name: 'Italian' },
	{ code: 'ja', name: 'Japanese' },
	{ code: 'kn', name: 'Kannada' },
	{ code: 'ko', name: 'Korean' },
	{ code: 'lt', name: 'Lithuanian' },
	{ code: 'ms', name: 'Malay' },
	{ code: 'ml', name: 'Malayalam' },
	{ code: 'mr', name: 'Marathi' },
	{ code: 'no', name: 'Norwegian' },
	{ code: 'pl', name: 'Polish' },
	{ code: 'pt', name: 'Portuguese' },
	{ code: 'pa', name: 'Punjabi' },
	{ code: 'ro', name: 'Romanian' },
	{ code: 'ru', name: 'Russian' },
	{ code: 'sr', name: 'Serbian' },
	{ code: 'sk', name: 'Slovak' },
	{ code: 'es', name: 'Spanish' },
	{ code: 'sv', name: 'Swedish' },
	{ code: 'ta', name: 'Tamil' },
	{ code: 'te', name: 'Telugu' },
	{ code: 'th', name: 'Thai' },
	{ code: 'tr', name: 'Turkish' },
	{ code: 'uk', name: 'Ukrainian' },
	{ code: 'vi', name: 'Vietnamese' },
	{ code: 'pt-PT', name: 'Portuguese(Portugal)' },
	{ code: 'fr-CA', name: 'French(Canada)' },
];

export const googleLiveVoiceToTextLanguages = [
	{ name: 'Afrikaans (South Africa)', code: 'af-ZA' },
	{ name: 'Albanian (Albania)', code: 'sq-AL' },
	{ name: 'Amharic (Ethiopia)', code: 'am-ET' },
	{ name: 'Arabic (Algeria)', code: 'ar-DZ' },
	{ name: 'Arabic (Bahrain)', code: 'ar-BH' },
	{ name: 'Arabic (Egypt)', code: 'ar-EG' },
	{ name: 'Arabic (Iraq)', code: 'ar-IQ' },
	{ name: 'Arabic (Israel)', code: 'ar-IL' },
	{ name: 'Arabic (Jordan)', code: 'ar-JO' },
	{ name: 'Arabic (Kuwait)', code: 'ar-KW' },
	{ name: 'Arabic (Lebanon)', code: 'ar-LB' },
	{ name: 'Arabic (Mauritania)', code: 'ar-MR' },
	{ name: 'Arabic (Morocco)', code: 'ar-MA' },
	{ name: 'Arabic (Oman)', code: 'ar-OM' },
	{ name: 'Arabic (Qatar)', code: 'ar-QA' },
	{ name: 'Arabic (Saudi Arabia)', code: 'ar-SA' },
	{ name: 'Arabic (State of Palestine)', code: 'ar-PS' },
	{ name: 'Arabic (Tunisia)', code: 'ar-TN' },
	{ name: 'Arabic (United Arab Emirates)', code: 'ar-AE' },
	{ name: 'Arabic (Yemen)', code: 'ar-YE' },
	{ name: 'Armenian (Armenia)', code: 'hy-AM' },
	{ name: 'Azerbaijani (Azerbaijan)', code: 'az-AZ' },
	{ name: 'Basque (Spain)', code: 'eu-ES' },
	{ name: 'Bengali (Bangladesh)', code: 'bn-BD' },
	{ name: 'Bengali (India)', code: 'bn-IN' },
	{ name: 'Bosnian (Bosnia and Herzegovina)', code: 'bs-BA' },
	{ name: 'Bulgarian (Bulgaria)', code: 'bg-BG' },
	{ name: 'Burmese (Myanmar)', code: 'my-MM' },
	{ name: 'Catalan (Spain)', code: 'ca-ES' },
	{ name: 'Chinese, Cantonese (Traditional Hong Kong)', code: 'yue-Hant-HK' },
	{ name: 'Chinese, Mandarin (Simplified, China)', code: 'zh (cmn-Hans-CN)' },
	{
		name: 'Chinese, Mandarin (Traditional, Taiwan)',
		code: 'zh-TW (cmn-Hant-TW)',
	},
	{ name: 'Croatian (Croatia)', code: 'hr-HR' },
	{ name: 'Czech (Czech Republic)', code: 'cs-CZ' },
	{ name: 'Danish (Denmark)', code: 'da-DK' },
	{ name: 'Dutch (Belgium)', code: 'nl-BE' },
	{ name: 'Dutch (Netherlands)', code: 'nl-NL' },
	{ name: 'English (Australia)', code: 'en-AU' },
	{ name: 'English (Canada)', code: 'en-CA' },
	{ name: 'English (Ghana)', code: 'en-GH' },
	{ name: 'English (Hong Kong)', code: 'en-HK' },
	{ name: 'English (India)', code: 'en-IN' },
	{ name: 'English (Ireland)', code: 'en-IE' },
	{ name: 'English (Kenya)', code: 'en-KE' },
	{ name: 'English (New Zealand)', code: 'en-NZ' },
	{ name: 'English (Nigeria)', code: 'en-NG' },
	{ name: 'English (Pakistan)', code: 'en-PK' },
	{ name: 'English (Philippines)', code: 'en-PH' },
	{ name: 'English (Singapore)', code: 'en-SG' },
	{ name: 'English (South Africa)', code: 'en-ZA' },
	{ name: 'English (Tanzania)', code: 'en-TZ' },
	{ name: 'English (United Kingdom)', code: 'en-GB' },
	{ name: 'English (United States)', code: 'en-US' },
	{ name: 'Estonian (Estonia)', code: 'et-EE' },
	{ name: 'Filipino (Philippines)', code: 'fil-PH' },
	{ name: 'Finnish (Finland)', code: 'fi-FI' },
	{ name: 'French (Belgium)', code: 'fr-BE' },
	{ name: 'French (Canada)', code: 'fr-CA' },
	{ name: 'French (France)', code: 'fr-FR' },
	{ name: 'French (Switzerland)', code: 'fr-CH' },
	{ name: 'Galician (Spain)', code: 'gl-ES' },
	{ name: 'Georgian (Georgia)', code: 'ka-GE' },
	{ name: 'German (Austria)', code: 'de-AT' },
	{ name: 'German (Germany)', code: 'de-DE' },
	{ name: 'German (Switzerland)', code: 'de-CH' },
	{ name: 'Greek (Greece)', code: 'el-GR' },
	{ name: 'Gujarati (India)', code: 'gu-IN' },
	{ name: 'Hebrew (Israel)', code: 'iw-IL' },
	{ name: 'Hindi (India)', code: 'hi-IN' },
	{ name: 'Hungarian (Hungary)', code: 'hu-HU' },
	{ name: 'Icelandic (Iceland)', code: 'is-IS' },
	{ name: 'Indonesian (Indonesia)', code: 'id-ID' },
	{ name: 'Italian (Italy)', code: 'it-IT' },
	{ name: 'Italian (Switzerland)', code: 'it-CH' },
	{ name: 'Japanese (Japan)', code: 'ja-JP' },
	{ name: 'Javanese (Indonesia)', code: 'jv-ID' },
	{ name: 'Kannada (India)', code: 'kn-IN' },
	{ name: 'Kazakh (Kazakhstan)', code: 'kk-KZ' },
	{ name: 'Khmer (Cambodia)', code: 'km-KH' },
	{ name: 'Korean (South Korea)', code: 'ko-KR' },
	{ name: 'Lao (Laos)', code: 'lo-LA' },
	{ name: 'Latvian (Latvia)', code: 'lv-LV' },
	{ name: 'Lithuanian (Lithuania)', code: 'lt-LT' },
	{ name: 'Macedonian (North Macedonia)', code: 'mk-MK' },
	{ name: 'Malay (Malaysia)', code: 'ms-MY' },
	{ name: 'Malayalam (India)', code: 'ml-IN' },
	{ name: 'Marathi (India)', code: 'mr-IN' },
	{ name: 'Mongolian (Mongolia)', code: 'mn-MN' },
	{ name: 'Nepali (Nepal)', code: 'ne-NP' },
	{ name: 'Norwegian Bokmål (Norway)', code: 'no-NO' },
	{ name: 'Persian (Iran)', code: 'fa-IR' },
	{ name: 'Polish (Poland)', code: 'pl-PL' },
	{ name: 'Portuguese (Brazil)', code: 'pt-BR' },
	{ name: 'Portuguese (Portugal)', code: 'pt-PT' },
	{ name: 'Punjabi (Gurmukhi India)', code: 'pa-Guru-IN' },
	{ name: 'Romanian (Romania)', code: 'ro-RO' },
	{ name: 'Russian (Russia)', code: 'ru-RU' },
	{ name: 'Kinyarwanda (Rwanda)', code: 'rw-RW' },
	{ name: 'Serbian (Serbia)', code: 'sr-RS' },
	{ name: 'Sinhala (Sri Lanka)', code: 'si-LK' },
	{ name: 'Slovak (Slovakia)', code: 'sk-SK' },
	{ name: 'Slovenian (Slovenia)', code: 'sl-SI' },
	{ name: 'Swati (South Africa)', code: 'ss-latn-za' },
	{ name: 'Southern Sotho (South Africa)', code: 'st-ZA' },
	{ name: 'Spanish (Argentina)', code: 'es-AR' },
	{ name: 'Spanish (Bolivia)', code: 'es-BO' },
	{ name: 'Spanish (Chile)', code: 'es-CL' },
	{ name: 'Spanish (Colombia)', code: 'es-CO' },
	{ name: 'Spanish (Costa Rica)', code: 'es-CR' },
	{ name: 'Spanish (Dominican Republic)', code: 'es-DO' },
	{ name: 'Spanish (Ecuador)', code: 'es-EC' },
	{ name: 'Spanish (El Salvador)', code: 'es-SV' },
	{ name: 'Spanish (Guatemala)', code: 'es-GT' },
	{ name: 'Spanish (Honduras)', code: 'es-HN' },
	{ name: 'Spanish (Mexico)', code: 'es-MX' },
	{ name: 'Spanish (Nicaragua)', code: 'es-NI' },
	{ name: 'Spanish (Panama)', code: 'es-PA' },
	{ name: 'Spanish (Paraguay)', code: 'es-PY' },
	{ name: 'Spanish (Peru)', code: 'es-PE' },
	{ name: 'Spanish (Puerto Rico)', code: 'es-PR' },
	{ name: 'Spanish (Spain)', code: 'es-ES' },
	{ name: 'Spanish (United States)', code: 'es-US' },
	{ name: 'Spanish (Uruguay)', code: 'es-UY' },
	{ name: 'Spanish (Venezuela)', code: 'es-VE' },
	{ name: 'Sundanese (Indonesia)', code: 'su-ID' },
	{ name: 'Swahili (Kenya)', code: 'sw-KE' },
	{ name: 'Swahili (Tanzania)', code: 'sw-TZ' },
	{ name: 'Swedish (Sweden)', code: 'sv-SE' },
	{ name: 'Tamil (India)', code: 'ta-IN' },
	{ name: 'Tamil (Malaysia)', code: 'ta-MY' },
	{ name: 'Tamil (Singapore)', code: 'ta-SG' },
	{ name: 'Tamil (Sri Lanka)', code: 'ta-LK' },
	{ name: 'Telugu (India)', code: 'te-IN' },
	{ name: 'Thai (Thailand)', code: 'th-TH' },
	{ name: 'Setswana (South Africa)', code: 'tn-latn-za' },
	{ name: 'Turkish (Turkey)', code: 'tr-TR' },
	{ name: 'Tsonga (South Africa)', code: 'ts-ZA' },
	{ name: 'Ukrainian (Ukraine)', code: 'uk-UA' },
	{ name: 'Urdu (India)', code: 'ur-IN' },
	{ name: 'Urdu (Pakistan)', code: 'ur-PK' },
	{ name: 'Uzbek (Uzbekistan)', code: 'uz-UZ' },
	{ name: 'Venda (South Africa)', code: 've-ZA' },
	{ name: 'Vietnamese (Vietnam)', code: 'vi-VN' },
	{ name: 'isiXhosa (South Africa)', code: 'xh-ZA' },
	{ name: 'Zulu (South Africa)', code: 'zu-ZA' },
	{
		name: 'Haitian Creole',
		code: 'ht-HT',
	},
];

export const uniteLanguages = [
	{
		name: 'Afrikaans',
		code: 'af-ZA',
	},
	{
		name: 'Amharic',
		code: 'am-ET',
	},
	{
		name: 'Arabic (Algeria)',
		code: 'ar-DZ',
	},
	{
		name: 'Arabic (Bahrain)',
		code: 'ar-BH',
	},
	{
		name: 'Arabic (Egypt)',
		code: 'ar-EG',
	},
	{
		name: 'Arabic (Iraq)',
		code: 'ar-IQ',
	},
	{
		name: 'Arabic (Israel)',
		code: 'ar-IL',
	},
	{
		name: 'Arabic (Jordan)',
		code: 'ar-JO',
	},
	{
		name: 'Arabic (Kuwait)',
		code: 'ar-KW',
	},
	{
		name: 'Arabic (Lebanon)',
		code: 'ar-LB',
	},
	{
		name: 'Arabic (Mauritania)',
		code: 'ar-MR',
	},
	{
		name: 'Arabic (Morocco)',
		code: 'ar-MA',
	},
	{
		name: 'Arabic (Oman)',
		code: 'ar-OM',
	},
	{
		name: 'Arabic (Qatar)',
		code: 'ar-QA',
	},
	{
		name: 'Arabic (Saudi Arabia)',
		code: 'ar-SA',
	},
	{
		name: 'Arabic (Palestine)',
		code: 'ar-PS',
	},
	{
		name: 'Arabic (Syria)',
		code: 'ar-SY',
	},
	{
		name: 'Arabic (Tunisia)',
		code: 'ar-TN',
	},
	{
		name: 'Arabic (United Arab Emirates)',
		code: 'ar-AE',
	},
	{
		name: 'Arabic (Yemen)',
		code: 'ar-YE',
	},
	{
		name: 'Basque',
		code: 'eu-ES',
	},
	{
		name: 'Bengali (Bangladesh)',
		code: 'bn-BD',
	},
	{
		name: 'Bengali (India)',
		code: 'bn-IN',
	},
	{
		name: 'Bulgarian',
		code: 'bg-BG',
	},
	{
		name: 'Catalan',
		code: 'ca-ES',
	},
	{
		name: 'Czech',
		code: 'cs-CZ',
	},
	{
		name: 'Danish',
		code: 'da-DK',
	},
	{
		name: 'Dutch (Belgium)',
		code: 'nl-BE',
	},
	{
		name: 'Dutch (Netherlands)',
		code: 'nl-NL',
	},
	{
		name: 'English (Australia)',
		code: 'en-AU',
	},
	{
		name: 'English (Canada)',
		code: 'en-CA',
	},
	{
		name: 'English (Ghana)',
		code: 'en-GH',
	},
	{
		name: 'English (Hong Kong)',
		code: 'en-HK',
	},
	{
		name: 'English (India)',
		code: 'en-IN',
	},
	{
		name: 'English (Ireland)',
		code: 'en-IE',
	},
	{
		name: 'English (Kenya)',
		code: 'en-KE',
	},
	{
		name: 'English (New Zealand)',
		code: 'en-NZ',
	},
	{
		name: 'English (Nigeria)',
		code: 'en-NG',
	},
	{
		name: 'English (Pakistan)',
		code: 'en-PK',
	},
	{
		name: 'English (Philippines)',
		code: 'en-PH',
	},
	{
		name: 'English (Singapore)',
		code: 'en-SG',
	},
	{
		name: 'English (South Africa)',
		code: 'en-ZA',
	},
	{
		name: 'English (Tanzania)',
		code: 'en-TZ',
	},
	{
		name: 'English (United Kingdom)',
		code: 'en-GB',
	},
	{
		name: 'English (United States)',
		code: 'en-US',
	},
	{
		name: 'Filipino',
		code: 'fil-PH',
	},
	{
		name: 'Finnish',
		code: 'fi-FI',
	},
	{
		name: 'French (Belgium)',
		code: 'fr-BE',
	},
	{
		name: 'French (Canada)',
		code: 'fr-CA',
	},
	{
		name: 'French (France)',
		code: 'fr-FR',
	},
	{
		name: 'French (Switzerland)',
		code: 'fr-CH',
	},
	{
		name: 'Galician',
		code: 'gl-ES',
	},
	{
		name: 'German (Austria)',
		code: 'de-AT',
	},
	{
		name: 'German (Germany)',
		code: 'de-DE',
	},
	{
		name: 'German (Switzerland)',
		code: 'de-CH',
	},
	{
		name: 'Greek',
		code: 'el-GR',
	},
	{
		name: 'Gujarati',
		code: 'gu-IN',
	},
	{
		name: 'Hindi',
		code: 'hi-IN',
	},
	{
		name: 'Haitian Creole',
		code: 'ht-HT',
	},
	{
		name: 'Hungarian',
		code: 'hu-HU',
	},
	{
		name: 'Icelandic',
		code: 'is-IS',
	},
	{
		name: 'Indonesian',
		code: 'id-ID',
	},
	{
		name: 'Italian (Italy)',
		code: 'it-IT',
	},
	{
		name: 'Italian (Switzerland)',
		code: 'it-CH',
	},
	{
		name: 'Japanese',
		code: 'ja-JP',
	},
	{
		name: 'Kannada',
		code: 'kn-IN',
	},
	{
		name: 'Korean',
		code: 'ko-KR',
	},
	{
		name: 'Latvian',
		code: 'lv-LV',
	},
	{
		name: 'Lithuanian',
		code: 'lt-LT',
	},
	{
		name: 'Malay',
		code: 'ms-MY',
	},
	{
		name: 'Malayalam',
		code: 'ml-IN',
	},
	{
		name: 'Marathi',
		code: 'mr-IN',
	},
	{
		name: 'Polish',
		code: 'pl-PL',
	},
	{
		name: 'Portuguese (Brazil)',
		code: 'pt-BR',
	},
	{
		name: 'Portuguese (Portugal)',
		code: 'pt-PT',
	},
	{
		name: 'Punjabi (Gurmukhi)',
		code: 'pa-Guru-IN',
	},
	{
		name: 'Romanian',
		code: 'ro-RO',
	},
	{
		name: 'Russian',
		code: 'ru-RU',
	},
	{
		name: 'Serbian',
		code: 'sr-RS',
	},
	{
		name: 'Slovak',
		code: 'sk-SK',
	},
	{
		name: 'Spanish (Argentina)',
		code: 'es-AR',
	},
	{
		name: 'Spanish (Bolivia)',
		code: 'es-BO',
	},
	{
		name: 'Spanish (Chile)',
		code: 'es-CL',
	},
	{
		name: 'Spanish (Colombia)',
		code: 'es-CO',
	},
	{
		name: 'Spanish (Costa Rica)',
		code: 'es-CR',
	},
	{
		name: 'Spanish (Dominican Republic)',
		code: 'es-DO',
	},
	{
		name: 'Spanish (Ecuador)',
		code: 'es-EC',
	},
	{
		name: 'Spanish (El Salvador)',
		code: 'es-SV',
	},
	{
		name: 'Spanish (Guatemala)',
		code: 'es-GT',
	},
	{
		name: 'Spanish (Honduras)',
		code: 'es-HN',
	},
	{
		name: 'Spanish (Mexico)',
		code: 'es-MX',
	},
	{
		name: 'Spanish (Nicaragua)',
		code: 'es-NI',
	},
	{
		name: 'Spanish (Panama)',
		code: 'es-PA',
	},
	{
		name: 'Spanish (Paraguay)',
		code: 'es-PY',
	},
	{
		name: 'Spanish (Peru)',
		code: 'es-PE',
	},
	{
		name: 'Spanish (Puerto Rico)',
		code: 'es-PR',
	},
	{
		name: 'Spanish (Spain)',
		code: 'es-ES',
	},
	{
		name: 'Spanish (United States)',
		code: 'es-US',
	},
	{
		name: 'Spanish (Uruguay)',
		code: 'es-UY',
	},
	{
		name: 'Spanish (Venezuela)',
		code: 'es-VE',
	},
	{
		name: 'Swedish',
		code: 'sv-SE',
	},
	{
		name: 'Tamil (India)',
		code: 'ta-IN',
	},
	{
		name: 'Tamil (Malaysia)',
		code: 'ta-MY',
	},
	{
		name: 'Tamil (Singapore)',
		code: 'ta-SG',
	},
	{
		name: 'Tamil (Sri Lanka)',
		code: 'ta-LK',
	},
	{
		name: 'Telugu',
		code: 'te-IN',
	},
	{
		name: 'Thai',
		code: 'th-TH',
	},
	{
		name: 'Turkish',
		code: 'tr-TR',
	},
	{
		name: 'Ukrainian',
		code: 'uk-UA',
	},
	{
		name: 'Urdu (India)',
		code: 'ur-IN',
	},
	{
		name: 'Urdu (Pakistan)',
		code: 'ur-PK',
	},
	{
		name: 'Vietnamese',
		code: 'vi-VN',
	},
];

export const liveLanguages = [
	{ code: 'zh-CN', name: 'Chinese, Simplified' },
	{ code: 'en-AU', name: 'English, Australian' },
	{ code: 'en-GB', name: 'English, British' },
	{ code: 'en-US', name: 'English, US' },
	{ code: 'fr-FR', name: 'French' },
	{ code: 'fr-CA', name: 'French, Canadian' },
	{ code: 'de-DE', name: 'German' },
	{ code: 'hi-IN', name: 'Hindi, Indian' },
	{ code: 'it-IT', name: 'Italian' },
	{ code: 'ja-JP', name: 'Japanese' },
	{ code: 'ko-KR', name: 'Korean' },
	{ code: 'pt-BR', name: 'Portuguese, Brazilian' },
	{ code: 'es-US', name: 'Spanish, US' },
	{ code: 'th-TH', name: 'Thai' },
];

export const voiceCloneLanguages = [
	{ code: 'ar-XA', languageName: 'Arabic' },
	{ code: 'bn-IN', languageName: 'Bengali' },
	{ code: 'cmn-CN', languageName: 'Mandarin Chinese' },
	{ code: 'cmn-TW', languageName: 'Traditional Chinese' },
	{ code: 'cs-CZ', languageName: 'Czech' },
	{ code: 'da-DK', languageName: 'Danish' },
	{ code: 'de-DE', languageName: 'German' },
	{ code: 'el-GR', languageName: 'Greek' },
	{ code: 'en-AU', languageName: 'English (Australia)' },
	{ code: 'en-GB', languageName: 'English (United Kingdom)' },
	{ code: 'en-IN', languageName: 'English (India)' },
	{ code: 'en-US', languageName: 'English (United States)' },
	{ code: 'es-ES', languageName: 'Spanish (Spain)' },
	{ code: 'es-US', languageName: 'Spanish (United States)' },
	{ code: 'fi-FI', languageName: 'Finnish' },
	{ code: 'fil-PH', languageName: 'Filipino' },
	{ code: 'fr-CA', languageName: 'French (Canada)' },
	{ code: 'fr-FR', languageName: 'French (France)' },
	{ code: 'gu-IN', languageName: 'Gujarati' },
	{ code: 'he-IL', languageName: 'Hebrew' },
	{ code: 'hi-IN', languageName: 'Hindi' },
	{ code: 'hu-HU', languageName: 'Hungarian' },
	{ code: 'id-ID', languageName: 'Indonesian' },
	{ code: 'it-IT', languageName: 'Italian' },
	{ code: 'ja-JP', languageName: 'Japanese' },
	{ code: 'kn-IN', languageName: 'Kannada' },
	{ code: 'ko-KR', languageName: 'Korean' },
	{ code: 'ml-IN', languageName: 'Malayalam' },
	{ code: 'mr-IN', languageName: 'Marathi' },
	{ code: 'ms-MY', languageName: 'Malay' },
	{ code: 'nb-NO', languageName: 'Norwegian Bokmål' },
	{ code: 'nl-BE', languageName: 'Dutch (Belgium)' },
	{ code: 'nl-NL', languageName: 'Dutch (Netherlands)' },
	{ code: 'pa-IN', languageName: 'Punjabi' },
	{ code: 'pl-PL', languageName: 'Polish' },
	{ code: 'pt-BR', languageName: 'Portuguese (Brazil)' },
	{ code: 'pt-PT', languageName: 'Portuguese (Portugal)' },
	{ code: 'ro-RO', languageName: 'Romanian' },
	{ code: 'ru-RU', languageName: 'Russian' },
	{ code: 'sk-SK', languageName: 'Slovak' },
	{ code: 'sv-SE', languageName: 'Swedish' },
	{ code: 'ta-IN', languageName: 'Tamil' },
	{ code: 'tr-TR', languageName: 'Turkish' },
	{ code: 'uk-UA', languageName: 'Ukrainian' },
	{ code: 'vi-VN', languageName: 'Vietnamese' },
	{ code: 'eu-ES', languageName: 'Basque' },
	{ code: 'gl-ES', languageName: 'Galician' },
	{ code: 'yue-HK', languageName: 'Cantonese' },
	{ code: 'af-ZA', languageName: 'Afrikaans' },
	{ code: 'bg-BG', languageName: 'Bulgarian' },
	{ code: 'is-IS', languageName: 'Icelandic' },
	{ code: 'lt-LT', languageName: 'Lithuanian' },
	{ code: 'lv-LV', languageName: 'Latvian' },
	{ code: 'sr-RS', languageName: 'Serbian' },
	{ code: 'th-TH', languageName: 'Thai' },
	{ code: 'te-IN', languageName: 'Telugu' },
	{ code: 'ca-ES', languageName: 'Catalan' },
];

export default languages;
