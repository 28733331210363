import React, { useState } from 'react';
import {
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement,
	useStripe,
	useElements,
} from '@stripe/react-stripe-js';
import Loader from './loader';
import { createNotification } from 'utils/create-notification';

// Customize the CardElement styles
const CARD_ELEMENT_OPTIONS = {
	style: {
		base: {
			color: '#333',
			fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
			fontSmoothing: 'antialiased',
			fontSize: '16px',
			'::placeholder': {
				color: '#aab7c4',
			},
		},
		invalid: {
			color: '#fa755a',
			iconColor: '#fa755a',
		},
	},
};

// eslint-disable-next-line react/prop-types
function CheckoutForm({ amount, handlePayment }) {
	const [loading, setLoading] = useState(false);
	const stripe = useStripe();
	const elements = useElements();

	const handleSubmit = async (event) => {
		setLoading(true);
		event.preventDefault();

		if (!stripe || !elements) {
			return;
		}

		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: 'card',
			card: elements.getElement(CardNumberElement),
		});

		if (error) {
			setLoading(false);
			console.log(error);
			const customError = error?.message
				? error?.message
				: 'Problem in payment.';
			createNotification('error', 'Error', customError);
		} else {
			await handlePayment(paymentMethod?.id);
			setLoading(false);
		}
	};

	return (
		<>
			{loading && <Loader />}
			<form onSubmit={handleSubmit}>
				<label>
					Card number
					<CardNumberElement options={CARD_ELEMENT_OPTIONS} />
				</label>
				<label>
					Expiration date
					<CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
				</label>
				<label>
					CVC
					<CardCvcElement options={CARD_ELEMENT_OPTIONS} />
				</label>
				<button
					type="submit"
					disabled={!stripe}
					className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
				>
					Subscribe for ${amount}/month
				</button>
			</form>
		</>
	);
}

export default CheckoutForm;
