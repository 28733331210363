import { useState, useEffect, useCallback } from 'react';

const useDebouncedQueue = (func, delay = 300) => {
	const [queue, setQueue] = useState([]);

	// The function to add requests to the queue, wrapped in a debounce function
	const enqueue = useCallback((...args) => {
		setQueue((prevQueue) => [...prevQueue, args]);
	}, []);

	// Debounce function
	const debounce = (func, timeout = delay) => {
		let timer;
		return (...args) => {
			clearTimeout(timer);
			timer = setTimeout(() => {
				func.apply(this, args);
			}, timeout);
		};
	};

	const debouncedEnqueue = debounce(enqueue);

	// useEffect to process the queue
	useEffect(() => {
		if (queue.length > 0) {
			const args = queue[0]; // Get the first item in the queue
			func(...args);

			// Remove the first item from the queue after processing
			setQueue((prevQueue) => prevQueue.slice(1));
		}
	}, [queue, func]); // Dependencies on queue and func

	return debouncedEnqueue;
};

export default useDebouncedQueue;
