import { useEffect, useRef } from 'react';

// Custom hook to skip the effect on the first render
function useSkipFirstRender(effect, deps) {
	const isFirstRender = useRef(true);

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false; // Mark as not the first render anymore
			return;
		}

		// Call the provided effect if it's not the first render
		return effect();
		// Ensure the effect and its dependencies are correctly handled
	}, deps); // Pass the dependencies of the effect
}

export default useSkipFirstRender;
