import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Container } from '@mui/material';
import Moderator from './Moderator';
import CustomSelect from 'component/CustomSelect';
import { googleLiveVoiceToTextLanguages } from 'constants/languages';
import Modal from 'component/Modal';
import { createNotification } from 'utils/create-notification';
import { useSelector } from 'react-redux';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ApiService } from 'services/api-services';
import { useNavigate } from 'react-router-dom';
import Loader from 'component/loader';

let deviceLanguage = navigator.language || navigator.userLanguage;
deviceLanguage = googleLiveVoiceToTextLanguages.find(
	(el) => el.code === deviceLanguage
);
if (!deviceLanguage || deviceLanguage === 'undefined') {
	console.log('device language is not getting setting to en-US');
	deviceLanguage = { name: 'English (United States)', code: 'en-US' };
}
const PodcastTranslation = () => {
	const profileInformation = useSelector(
		(state) => state?.user?.profileInformation
	);

	const navigate = useNavigate();
	const nameInputRef = useRef();
	const emailInputRef = useRef();
	const [inputFieldState, setInputFieldState] = useState('');
	const [emailFieldState, setEmailFieldState] = useState('');
	const [isFormSubmit, setIsFormSubmit] = useState(false);
	const [isAddSpeaker, setIsAddSpeaker] = useState(false);
	const [qrCodeLink, setQrCodeLink] = useState('www.google.com');
	const [qrCode, setQrCode] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [urlId, setUrlId] = useState();

	useEffect(() => {
		if (profileInformation) {
			const isSubscriptionActive =
				profileInformation?.billingInfo?.subscriptions?.find((el) => {
					return (
						el?.status === 'active' && el?.itemName === 'podcast-translation'
					);
				});
			const permission =
				profileInformation?.servicesPayment?.find(
					(el) => el === 'podcast-translation'
				) || isSubscriptionActive;

			if (!permission) {
				navigate('/upgrade');
			}
		}
	}, [profileInformation]);

	const { control } = useForm({
		defaultValues: {
			sourceLanguage: {
				value: deviceLanguage?.code,
				label: deviceLanguage?.name,
			},
		},
	});

	const inputChangeHandler = async (e) => {
		setInputFieldState(e.target.value);
	};
	const emailChangeHandler = async (e) => {
		setEmailFieldState(e.target.value);
	};

	const onSubmit = async (e) => {
		e?.preventDefault();
		setIsLoading(true);

		if (inputFieldState) {
			const res = await ApiService.post(
				'/api/podcast-translation/add-presenter',
				{
					name: inputFieldState,
					...(emailFieldState && { email: emailFieldState }),
				}
			);
			if (res?.status === 200) {
				console.log(res.data, 'res.data');
				setQrCodeLink(res?.data?.url);
				setQrCode(res?.data?.qrCodeBase64);
				setIsFormSubmit(true);

				setUrlId(
					res?.data?.url.split('/')[res?.data?.url.split('/')?.length - 1]
				);
			}
		} else {
			createNotification('error', 'Error', 'Please Enter Presenter Name');
		}
		setIsLoading(false);
	};

	useEffect(() => {
		if (profileInformation) {
			if (!profileInformation?.services?.includes('podcast-translation')) {
				return navigate('/');
			}
		}
	}, [profileInformation]);

	return (
		<>
			<Box className="relative bg-[#f8f8f8] text-trans">
				<Container className="mt-5">
					<div className="bg-white">
						<div className="flex flex-col rounded-xl">
							<div className="flex flex-col justify-center items-center border-t-2 z-0">
								<div className="flex flex-col gap-8 justify-center items-center lgm:w-[70%] lg:w-[90%] sm:w-[90%] xs:w-[90%] py-8">
									<div className="flex md:flex-row xs:flex-col justify-center items-center gap-4">
										<h1 className="text-3xl font-bold">Moderator</h1>
									</div>
									<div className="xs:w-[100%]">
										<Moderator
											setIsAddSpeaker={setIsAddSpeaker}
											isAddSpeaker={isAddSpeaker}
											setIsFormSubmit={setIsFormSubmit}
											setQrCodeLink={setQrCodeLink}
											setQrCode={setQrCode}
											setUrlId={setUrlId}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Container>
			</Box>
			<Modal
				open={isAddSpeaker}
				title={isFormSubmit ? 'Download QR code' : 'Add Speaker'}
				handleClose={() => {
					setIsFormSubmit(false);
					setIsAddSpeaker(false);
					setInputFieldState('');
					setEmailFieldState('');
				}}
			>
				<div>
					{isFormSubmit ? (
						<div className="bg-[#E5F3FF] py-4 flex flex-col justify-center items-center">
							<img
								id="base64Image"
								src={`data:image/png;base64,${qrCode}`}
								alt="Base64"
							/>
							<a
								href={`data:image/png;base64,${qrCode}`}
								download="letzchat-unite-qr.png"
							>
								<p className="sm:text-xl xs:text-md text-center text-custom-blue mt-2">
									Download QR Code
								</p>
							</a>

							<div className="flex items-center justify-center my-2">
								<a
									style={{
										textAlign: 'center',
										overflowWrap: 'break-word',
										maxWidth: 'calc(70% - 1rem)', // adjust this as needed
										overflow: 'hidden',
									}}
									target="_blank"
									rel="noreferrer"
									className={`sm:text-xl xs:text-md mr-2 text-center text-custom-blue whitespace-normal cursor-pointer`}
									href={qrCodeLink}
								>
									{qrCodeLink}
								</a>
								<ContentCopyIcon
									style={{ cursor: 'pointer' }}
									onClick={async () =>
										await navigator.clipboard.writeText(qrCodeLink)
									}
								/>
							</div>

							<p className="sm:text-xl xs:text-md text-center">
								Scan to access Presenter Page
							</p>
						</div>
					) : (
						<form
							onSubmit={onSubmit}
							className="flex flex-col justify-center items-center gap-4"
						>
							<input
								value={inputFieldState}
								onChange={inputChangeHandler}
								type="text"
								name="ask a question"
								placeholder="Enter Presenter Name"
								className="p-4 pr-10 border border-custom-blue rounded-lg sm:w-96 xs:w-60 relative"
								ref={nameInputRef}
							/>
							<input
								value={emailFieldState}
								onChange={emailChangeHandler}
								type="email"
								name="ask a question"
								placeholder="Enter Presenter Email"
								className="p-4 pr-10 border border-custom-blue rounded-lg sm:w-96 xs:w-60 relative"
								ref={emailInputRef}
							/>
							<button
								type="submit"
								className="bg-custom-blue text-white rounded-lg py-4 px-12"
							>
								Submit
							</button>
						</form>
					)}
					{isLoading && <Loader />}
				</div>
			</Modal>
		</>
	);
};

export default PodcastTranslation;
