import {
	Avatar,
	Box,
	Container,
	IconButton,
	Menu,
	MenuItem,
	Tooltip,
} from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Loader from './loader';
import curveImg from 'assets/curve.png';
import logo from 'assets/logo.png';
import userProfile from 'assets/user-profile.jpeg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { logout } from 'store/user';

// eslint-disable-next-line react/prop-types
const PublicHeader = ({ styles }) => {
	return (
		<>
			<Box className="bg-[#f8f8f8]">
				<Box className="bg-white pt-3 pb-3 z-30">
					<Container
						maxWidth="lg"
						className="d-flex justify-content-between align-items-center profiles-user-image"
					>
						<div>
							<Link to="/">
								<img
									alt="company logo"
									src={logo}
									width="180"
									height="180"
									className="d-inline-block align-top pt-3 pb-3"
								/>
							</Link>
						</div>
					</Container>
				</Box>
			</Box>
		</>
	);
};

export default PublicHeader;
