import { ApiService } from './api-services';

export default class ProfileService {
	static baseUrl = ['/api/auth'];

	static async updateProfilePicture(data) {
		const res = await ApiService.put(
			`${ProfileService.baseUrl[0]}/update-profile-picture`,
			data
		);
		return res;
	}

	static async updateLogoPicture(data) {
		const res = await ApiService.put(
			`${ProfileService.baseUrl[0]}/update-logo-picture`,
			data
		);
		return res;
	}

	static async deleteProfilePicture() {
		const res = await ApiService.delete(
			`${ProfileService.baseUrl[0]}/delete-profile-picture`
		);
		return res;
	}

	static async deleteLogoPicture() {
		const res = await ApiService.delete(
			`${ProfileService.baseUrl[0]}/delete-logo-picture`
		);
		return res;
	}

	static async getAllProfileInformation() {
		const res = await ApiService.get(
			`${ProfileService.baseUrl[0]}/get-profile`
		);
		return res;
	}

	static async updateProfileInformation(data) {
		const res = await ApiService.post(
			`${ProfileService.baseUrl[0]}/update-profile`,
			data
		);
		return res;
	}
}
