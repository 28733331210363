import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
// import './speaker.css';

const Speaker = () => {
	const [isSpeaking, setIsSpeaking] = useState(false);

	useEffect(() => {
		const interval = setInterval(() => {
			setIsSpeaking((prevState) => !prevState);
		}, 400);

		return () => clearInterval(interval);
	}, []);

	const soundWaveVariants = {
		hidden: { opacity: 0 },
		visible: { opacity: 1, scale: 1.2 },
	};

	const soundWaveTransition = {
		duration: 0.5,
		loop: Infinity,
		ease: 'easeInOut',
	};

	return (
		<div className="speaker-container">
			{/* <svg
				xmlns="http://www.w3.org/2000/svg"
				id="speaker"
				x="0"
				y="0"
				version="1.1"
				viewBox="0 0 29 29"
				xmlSpace="preserve"
			>
				<path d="M3.5 10h4l5.875-4.7A1 1 0 0 1 15 6.081V22.92a1 1 0 0 1-1.625.781L7.5 19h-4a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1z" />
			</svg> */}

			{isSpeaking && (
				<motion.svg
					xmlns="http://www.w3.org/2000/svg"
					id="waves"
					x="0"
					y="0"
					version="1.1"
					viewBox="0 0 29 29"
					xmlSpace="preserve"
					initial="hidden"
					animate="visible"
					variants={soundWaveVariants}
					transition={soundWaveTransition}
				>
					<path
						fill="none"
						stroke="#6db7f2"
						strokeMiterlimit="10"
						strokeWidth="2"
						d="M20.303 9.197c1.406 1.406 2.197 3.315 2.197 5.303s-.791 3.897-2.197 5.303M23.132 6.368a11.504 11.504 0 010 16.264M17.475 12.025a3.502 3.502 0 010 4.95"
					/>
				</motion.svg>
			)}
		</div>
	);
};

export default Speaker;
