import React, { useEffect, useState } from 'react';
import { TextField, Button, Box, Container, IconButton } from '@mui/material';
import { AddCircle, Delete } from '@mui/icons-material';
import Modal from 'component/Modal';
import { useForm } from 'react-hook-form';
import styles from './createInvoice.module.scss';
import Select from 'react-select';
import { createNotification } from 'utils/create-notification';
import api from 'utils/axios';
import Loader from 'component/loader';

const options = [
	{ value: 'website_translation', label: 'Website Translation', price: 299 },
	{ value: 'image_translation', label: 'Image Translation', price: 5 },
];
const today = new Date();
const quantityOptions = [{ value: '1', label: '1' }];

function createData(title, qty, rate, amount) {
	return { title, qty, rate, amount };
}

export default function CreateInvoice() {
	const [open, setOpen] = useState(false);
	const [rows, setRows] = useState([]);
	const [selectedItem, setSelectedItem] = useState(options[0]);
	const [selectedQuantity, setSelectedQuantity] = useState(quantityOptions[0]);
	const [list, setList] = useState([]);
	const [total, setTotal] = useState('');
	const [loading, setLoading] = useState('');

	const handleAdd = (event) => {
		event.preventDefault();

		if (!list?.find((el) => el?.item?.value === selectedItem?.value)) {
			setList((prevList) => [
				...prevList,
				{ item: selectedItem, quantity: selectedQuantity },
			]);
			setRows((prev) => {
				return [
					...prev,
					createData(
						selectedItem.label,
						selectedQuantity.label,
						selectedItem.price,
						selectedItem.price
					),
				];
			});
		}
		if (list?.find((el) => el?.item?.value === selectedItem?.value)) {
			createNotification(
				'info',
				'Duplicate Item',
				'Cannot Add Duplicate Products.'
			);
		}

		setOpen(false);
	};

	useEffect(() => {
		setTotal(() => {
			return list.reduce((acc, el) => {
				return acc + el?.item?.price || 0;
			}, 0);
		});
	}, [list]);

	const handlekk = () => {
		setOpen(true);
	};

	const { register, handleSubmit, setValue } = useForm();

	const onSubmit = async (data) => {
		if (list.length < 1) {
			return createNotification('error', 'Error', 'Please Add Items');
		}
		data.created = data.invoiceDate;
		data.totalAmount = total;
		data.items = list.map((el) => {
			// 		value: string;
			// label: string;
			// price: number;
			return {
				title: el?.item?.value,
				qty: el?.quantity?.value,
				rate: el?.item?.price,
				amount: el?.item?.price,
			};
		});
		delete data.invoiceDate;
		try {
			setLoading(true);
			const response = await api.post('payment/create-invoice', data);
			console.log(response);
			createNotification(
				'success',
				'Invoice Created',
				'Invoice Successfully Sent To ' + data.name + ' , ' + data.email
			);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			createNotification(
				'error',
				'Invoice Failed',
				error?.response?.data?.error || error?.response?.data?.msg
			);
			console.error(error);
		}
	};

	const deleteItemHandler = async (rowName) => {
		setRows((prev) => {
			return prev.filter((el) => el.title !== rowName);
		});
		setList((prev) => {
			return prev?.filter((el) => el.item.label !== rowName);
		});
	};

	const getLatestInvoiceNumber = async () => {
		try {
			setLoading(true);
			const { data } = await api.get('payment/get-invoice-number');
			setValue('invoiceNumber', data?.newInvoiceNumber);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log('Error in getting invoice Number', error);
			createNotification('error', 'Error', 'Failed in getting Invoice Number');
		}
	};

	useEffect(() => {
		getLatestInvoiceNumber();
	}, []);

	return (
		<>
			{loading && <Loader />}
			<div>
				<Modal
					open={open}
					handleClose={() => {
						setOpen(false);
					}}
					title="Add Items"
					className={styles.ModelContainer}
				>
					<form onSubmit={handleAdd}>
						<div className="mb-5 select-width">
							<label htmlFor="item-select">Products</label>
							<Select
								id="item-select"
								className="basic-single xs:w-4/12 xz:w-6/12 xm:w-7/12 sm:w-full"
								classNamePrefix="select"
								name="color"
								options={options}
								onChange={setSelectedItem}
								value={selectedItem}
							/>
						</div>
						<div className="select-width mb-10">
							<label htmlFor="quantity-select">Quantity</label>
							<Select
								id="quantity-select"
								className="basic-single xs:w-4/12 xz:w-6/12 xm:w-7/12 sm:w-full"
								classNamePrefix="select"
								name="color"
								options={quantityOptions}
								onChange={setSelectedQuantity}
								value={selectedQuantity}
							/>
						</div>
						<div>
							<Button
								type="submit"
								variant="contained"
								className="skyblue__button"
							>
								Submit
							</Button>
						</div>
					</form>
				</Modal>
			</div>
			<Box>
				<Container className="pt-5">
					<form onSubmit={handleSubmit(onSubmit)} className="pt-5">
						<div className="grid xm:grid-cols-2 xs:grid-cols-1 mb-3 gap-2">
							<div className="col-span-1">
								<TextField
									{...register('name')}
									type="text"
									variant="outlined"
									fullWidth
									required
									placeholder="Name"
									className="input-invoice"
								/>
							</div>
							<div className="col-span-1 flex items-center justify-around gap-5">
								<label className="label__invoice">Invoice#</label>
								<TextField
									{...register('invoiceNumber')}
									variant="outlined"
									fullWidth
									type="text"
									disabled
									placeholder="INV-00001"
								/>
							</div>
						</div>
						<div className="grid xm:grid-cols-2 xs:grid-cols-1 mb-3 gap-2">
							<div className="col-span-1">
								<TextField
									{...register('address')}
									variant="outlined"
									fullWidth
									required
									type="text"
									placeholder="Address Line"
								/>
							</div>
							<div className="col-span-1 flex items-center justify-around gap-5">
								<label className="label__invoice">Invoice Date</label>
								<TextField
									variant="outlined"
									fullWidth
									defaultValue={`${today.getFullYear()}-${(today.getMonth() + 1)
										.toString()
										.padStart(2, '0')}-${today
										.getDate()
										.toString()
										.padStart(2, '0')}`}
									type="date"
									{...register('invoiceDate')}
								/>
							</div>
						</div>
						<div className="grid xm:grid-cols-2 xs:grid-cols-1 mb-3 gap-2">
							<div className="col-span-1">
								<TextField
									{...register('email')}
									type="email"
									variant="outlined"
									fullWidth
									required
									placeholder="Email Address"
								/>
							</div>
							<div className="col-span-1 flex items-center justify-around gap-5">
								<label className="label__invoice">Due Date</label>
								<TextField
									{...register('dueDate')}
									variant="outlined"
									fullWidth
									type="Date"
									required
									placeholder="Due Date"
								/>
							</div>
						</div>
						<div>
							<ul aria-label="customized table" className="w-full pt-14">
								<li>
									<ul
										className="grid grid-cols-5 gap-4 thead__table rounded-xl mb-5"
										style={{ gridTemplateColumns: '8fr 3fr 3fr 3fr 3fr' }}
									>
										<li className="text-left">Description</li>
										<li className="text-left">QTY</li>
										<li className="text-left">Rate</li>
										<li className="text-left">Amount</li>
										<li className="text-left"></li>
									</ul>
								</li>
								<li>
									{rows.map((row) => (
										<ul
											key={row.title}
											className="grid grid-cols-5 gap-4 border-2 border-grey-200 p-5 mb-3 rounded-xl"
											style={{ gridTemplateColumns: '8fr 3fr 3fr 3fr 3fr' }}
										>
											<li
												scope="row"
												style={{
													overflow: 'hidden',
													textOverflow: 'ellipsis',
													whiteSpace: 'nowrap',
												}}
											>
												{row.title}
											</li>
											<li>{row.qty}</li>
											<li>${row.rate}</li>
											<li>${row.amount}</li>
											<li>
												<Delete
													color="action"
													onClick={() => deleteItemHandler(row.title)}
												/>
											</li>
										</ul>
									))}
								</li>
							</ul>
						</div>

						<div className="flex justify-between items-center pt-8">
							<div className="flex items-center" onClick={handlekk}>
								<div>
									<IconButton color="primary" aria-label="Add">
										<AddCircle className="add-item-btn" />
									</IconButton>
								</div>
								<div>
									<p>
										<span className="add-items-text cursor-pointer">
											Add Line Items
										</span>
									</p>
								</div>
							</div>
						</div>

						<div className="flex items-center justify-end gap-10 mt-5 mb-10">
							<label className="total-title">Total</label>
							<TextField
								variant="outlined"
								fullWidth
								disabled
								value={`$${total}`}
								placeholder="$660.00"
								className="input-number"
							/>
						</div>
						<div className="mb-10">
							<TextField
								{...register('notes')}
								id="my-textarea"
								label="Notes"
								multiline
								fullWidth
								rows={8}
							/>
						</div>
						<div className="flex justify-center items-center">
							<Button
								type="submit"
								variant="contained"
								className="save-invoice"
							>
								Save Invoice
							</Button>
						</div>
					</form>
				</Container>
			</Box>
		</>
	);
}
