import React, { useRef } from 'react';
import { Box, Button, Container, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { login, loginWithGoogle, loginWithMicrosoft } from 'store/user';
import Loader from 'component/loader';
import rightCurve from 'assets/right-curve.png';
import googleIcon from 'assets/GOOGLE.png';
import leftCurve from 'assets/left-curve.png';
import leftImage from 'assets/left-img.png';
import logo from 'assets/logo.png';
import { GoogleLogin } from '@react-oauth/google';
import { createNotification } from 'utils/create-notification';

import { useMsalAuthentication, useMsal } from '@azure/msal-react';
import * as msal from '@azure/msal-browser';
import { SignInButton } from 'component/Microsoft-Login/SignInButton';
import { useNavigate } from 'react-router-dom';
import AuthService from 'services/auth-services';

const schema = yup.object().shape({
	email: yup
		.string()
		.email('Invalid email format')
		.required('Email is required'),
});

const ForgotPassword = () => {
	//for testing
	const [loading, setLoading] = React.useState(false);
	const [forgotPassword, setForgotPassword] = React.useState(false);
	const hiddenElementRef = useRef(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const onSubmit = async (data) => {
		setLoading(true);
		try {
			const response = await AuthService.changePassword(data);
			if (response.data) {
				setForgotPassword(true);
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	return (
		<>
			{loading && <Loader />}
			<Box className="h-screen">
				<Box>
					<img
						src={rightCurve}
						className="fixed top-0 right-0"
						alt="right-curve"
					/>
				</Box>
				<Box>
					<img
						src={leftCurve}
						className="fixed bottom-0 left-0 z-0"
						alt="left-curve"
						width={500}
					/>
				</Box>
				<Container>
					<div className="flex items-center justify-center h-screen">
						<div className="grid md:grid-cols-2 sm:grid-cols-1">
							<div className="left-wrapper">
								{' '}
								<img src={leftImage} className="relative" alt="left-img" />{' '}
							</div>

							<div className="right-wrapper flex flex-col justify-center items-center">
								<div className="mb-5 logo-img cursor-pointer">
									<img
										src={logo}
										className="mb-5 relative"
										width={250}
										alt="logo-img"
										onClick={() => navigate('/')}
									/>
								</div>

								{!forgotPassword ? (
									<form
										onSubmit={handleSubmit(onSubmit)}
										className="form-signin"
									>
										<div className="mb-5">
											<p className="mb-5 text-md">Forgot Password </p>
											<TextField
												{...register('email')}
												id="email"
												className="style-input"
												name="email"
												label="Email"
												variant="outlined"
												size="medium"
												error={!!errors.email}
												helperText={errors.email?.message}
												sx={{
													'& .MuiOutlinedInput-root': {
														'& fieldset': {
															borderColor: 'rgba(126, 139, 182, 0.3);',
															borderRadius: '10px',
															'&:hover fieldset': {
																borderColor: '#6DB7F2',
															},
															'&.Mui-focused fieldset': {
																borderColor: '#6DB7F2',
															},
														},
													},
												}}
											/>
										</div>
										<div className="mb-3">
											<Button
												variant="contained"
												type="submit"
												className="submit-button"
											>
												Submit
											</Button>
										</div>
										<div className="mb-3">
											<Button
												variant="contained"
												type="submit"
												className="submit-button"
												onClick={() => navigate('/login')}
											>
												Back to Login
											</Button>
										</div>
									</form>
								) : (
									<Container className="font-[600] text-3xl">
										Password reset request successful! We have sent you an email
										to reset your password. Please check your inbox (and spam
										folder if you do not see the email) and follow the
										instructions to reset your password. Thank you!
									</Container>
								)}
							</div>
						</div>
					</div>
				</Container>
			</Box>
		</>
	);
};

export default ForgotPassword;
