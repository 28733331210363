import React, { useEffect, useRef, useState } from 'react';
import ReactSwitch from 'react-switch';
import moment from 'moment';
import { useSelector } from 'react-redux';
import api from 'utils/axios';
import AddIcon from '@mui/icons-material/Add';
import Socket from '../../../services/SocketManager';
import { googleLiveVoiceToTextLanguages } from 'constants/languages';
import Loader from 'component/loader';
import style from './scrollbar.module.scss';
import Modal from 'component/Modal';

function getLanguageNameByCode(code) {
	const language = googleLiveVoiceToTextLanguages.find(
		(lang) => lang.code === code
	);
	return language ? language.name : null;
}

const Moderator = ({
	_,
	setIsAddSpeaker,
	isAddSpeaker,
	setIsFormSubmit,
	setQrCodeLink,
	setQrCode,
	setUrlId,
}) => {
	const [loading, setLoading] = useState(false);
	const [presenters, setPresenters] = useState([]);
	const [liveTranscript, setLiveTranscript] = useState([]);
	const [deletePresenterOpen, setDeletePresenterOpen] = useState(false);

	const liveTranscriptScroling = useRef(null);

	const [finalTranscript, setFinalTranscript] = useState([]);
	const [dbMessages, setDbMessages] = useState([]);
	const [roomId, setRoomId] = useState(null);
	const [publicAccessData, setPublicAccessData] = useState({});
	const [autoSend, setAutoSend] = useState(false);

	const roomRef = useRef(null);

	const profileInformation = useSelector(
		(state) => state?.user?.profileInformation
	);

	useEffect(() => {
		if (liveTranscriptScroling.current) {
			liveTranscriptScroling.current.scrollTop =
				liveTranscriptScroling.current.scrollHeight;
		}
	}, [liveTranscript]);

	useEffect(() => {
		if (profileInformation?._id) {
			roomRef.current = profileInformation?._id.toString().slice(2, 9);
			api
				.get('podcast-translation/get-moderator-transcript/' + roomRef.current)
				.then((res) => {
					setLoading(false);
					const transcript = res?.data?.sort((a, b) => {
						return new Date(b.time) - new Date(a.time);
					});
					setDbMessages(transcript);
				})
				.catch((err) => {
					console.log(err, 'err in api call of get-moderator-transcript');
					setLoading(false);
				});
			Socket.emit(
				'register_moderator',
				profileInformation?._id,
				roomRef.current
			);
		}
	}, [profileInformation]);

	const downloadTranscriptHandler = async () => {
		try {
			setLoading(true);
			const response = await api.get(
				'podcast-translation/download-moderator-transcript/' + roomRef.current
			);

			const blob = new Blob([response.data], {
				type: response.headers['content-type'],
			});

			// Create a temporary URL to download the file
			const url = window.URL.createObjectURL(blob);

			// Create an anchor element and programmatically click it to trigger the download
			const link = document.createElement('a');
			link.href = url;
			link.download = `transcript-${
				profileInformation?.unite?.url?.split('/')[5]
			}`; // Set the desired file name and extension
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);

			// Release the temporary URL
			window.URL.revokeObjectURL(url);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.error('An error occurred in downloadTranscriptHandler:', error);
		}
	};

	const deleteTranscriptHandler = async () => {
		try {
			setLoading(true);
			await api.delete(
				'podcast-translation/delete-podcast-messages/' + roomRef.current
			);
			setDbMessages([]);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.error('An error occurred in deleteTranscriptHandler:', error);
		}
	};

	const submitMessage = (index, message) => {
		let messageToAll = undefined;
		setLiveTranscript((prev) =>
			prev.map((item, i) => (i === index ? { ...item, submitted: true } : item))
		);
		if (!message) {
			messageToAll = liveTranscript[index];
			setFinalTranscript((prev) => [liveTranscript[index], ...prev]);
		} else {
			messageToAll = message;
			setFinalTranscript((prev) => [message, ...prev]);
		}
		Socket.emit('send_message_moderator_to_presenter', { messageToAll });
	};

	const cancelMessage = (index) => {
		setLiveTranscript((prev) =>
			prev.map((item, i) => (i === index ? { ...item, canceled: true } : item))
		);
	};

	const handleReceiveMessage = (data) => {
		let submitted = false;
		if (autoSend) {
			submitted = true;
		}
		const newMessage = { ...data, submitted, canceled: false };
		setLiveTranscript((prev) => {
			const newTranscript = [...prev, newMessage];

			if (autoSend) {
				submitMessage(newTranscript.length - 1, newMessage);
			}

			return newTranscript;
		});
	};

	const showModelFunc = (data) => {
		setQrCodeLink(data?.url);
		setQrCode(data?.qrCode);
		setUrlId(data?.url.split('/')[data?.url.split('/')?.length - 1]);
		setIsAddSpeaker(true);
		setIsFormSubmit(true);
	};

	const getPublicUrlInfo = async () => {
		try {
			setLoading(true);
			const response = await api.get('podcast-translation/get-public-url');
			setPublicAccessData(response?.data);
			console.log(response?.data);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log('error in getPublicUrlInfo', error);
		}
	};

	useEffect(() => {
		Socket.on('receive_message_moderator_from_presenter', handleReceiveMessage);
		return () => {
			Socket.off(
				'receive_message_moderator_from_presenter',
				handleReceiveMessage
			);
		};
	}, [autoSend]);

	useEffect(() => {
		setLoading(true);
		api
			.get(`podcast-translation/get-all-presenters`)
			.then((res) => {
				setRoomId(res?.data?.podcastTranslations[0]?.roomId);
				setPresenters(res?.data?.podcastTranslations);
				getPublicUrlInfo();
			})
			.catch((err) => {
				setLoading(false);
				console.log(err, 'err in api call of get-all-presenters');
			});
	}, [isAddSpeaker]);

	useEffect(() => {
		Socket.on('language_to_moderator_Data', (data) => {
			setPresenters((prev) => {
				return prev.map((el) => {
					if (el._id === data?.presenter?._id) {
						return {
							...el,
							language: data.sourceLanguage,
						};
					}
					return el;
				});
			});
		});

		Socket.on('online', (data) => {
			console.log(data, 'online Data');
			setPresenters((prev) => {
				console.log(data, 'data online');
				return prev.map((el) => {
					if (el._id === data) {
						return {
							...el,
							online: true,
						};
					}
					return el;
				});
			});
		});

		Socket.on('offline', (data) => {
			console.log(data, 'offline Data');
			setPresenters((prev) => {
				return prev.map((el) => {
					if (el._id === data) {
						return {
							...el,
							online: false,
						};
					}
					return el;
				});
			});
		});
	}, []);

	const deletAllSpeakersHandler = () => {
		setLoading(true);
		setDeletePresenterOpen(false);
		api
			.delete(`podcast-translation/delete-all-presenters`)
			.then((res) => {
				setLoading(false);
				setPresenters([]);
			})
			.catch((err) => {
				setLoading(false);
				console.log(err, 'err in api call of delete-all-speakers');
			});
	};

	return (
		<>
			<Modal
				open={deletePresenterOpen}
				handleClose={() => {
					setDeletePresenterOpen(false);
				}}
				title="Are you sure you want to delete All Presenters?"
			>
				<div className="flex justify-center mt-4">
					<button
						onClick={deletAllSpeakersHandler}
						className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded mr-2"
					>
						YES
					</button>
					<button
						onClick={() => setDeletePresenterOpen(false)}
						className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded"
					>
						NO
					</button>
				</div>
			</Modal>

			{loading && <Loader />}
			<div className="flex flex-col gap-8">
				<div className="flex justify-center gap-8">
					<div className="px-3 py-16 bg-[#E5F3FF] xs:w-[100%] rounded-3xl flex flex-col  justify-center gap-3 z-10">
						<div className="flex xm:flex-row xs:flex-col xs:gap-4 xm:gap-2 items-center justify-end">
							<div className="flex items-center gap-5 px-8">
								<span className="text-custom-blue font-bold">Auto Send</span>
								<ReactSwitch
									className=""
									checked={autoSend}
									onChange={() => setAutoSend(!autoSend)}
								/>
							</div>
						</div>
						<div className="flex flex-col justify-center items-center ">
							<h1 className="font-bold text-2xl mb-6 "> Live Transcript</h1>
							<div
								className=" flex flex-col max-h-48 w-3/4 "
								id={style.scrollbar}
								ref={liveTranscriptScroling}
							>
								{liveTranscript?.map((el, i) => (
									<div
										className="flex items-center justify-between  mb-3  "
										key={i}
									>
										<span
											className=""
											style={{
												textDecoration: el?.canceled ? 'line-through' : 'none',
											}}
										>
											<strong>{el?.presenter?.name}</strong>: {el?.text}
										</span>

										<div className="items-end">
											<button
												disabled={el?.submitted || el?.canceled}
												className={`py-1 px-2 ml-3 ${
													el?.submitted ? 'bg-green-400' : 'bg-[#6d87f2]'
												}  rounded-3xl text-xs font-bold text-white cursor-pointer`}
												onClick={() => submitMessage(i)}
											>
												{el.submitted ? 'Submitted' : 'Submit'}
											</button>
											<button
												disabled={el?.canceled || el?.submitted}
												className={`py-1 px-2 ml-3 ${
													el?.canceled ? 'bg-[#777]' : 'bg-red-600'
												} rounded-3xl text-xs font-bold text-white cursor-pointer`}
												onClick={() => cancelMessage(i)}
											>
												{el?.canceled ? 'Canceled' : 'Cancel'}
											</button>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
				<div className="flex lgz:items-start xs:items-center justify-between xxl:flex-row xs:flex-col gap-3 px-2 ">
					<div
						className={`flex flex-2 flex-col border-2 rounded-lg border-custom-blue p-6 xxl:min-w-[39%] xs:w-[100%] min-h-[248px]`}
					>
						<div className="flex justify-center items-center gap-4">
							<p
								onClick={downloadTranscriptHandler}
								className="text-xl flex items-center justify-center text-center xs:mb-3 md:mb-0 cursor-pointer hover:text-custom-blue"
							>
								<button className="bg-custom-blue text-white px-4 py-1 rounded hover:text-blue-600 ">
									Download Transcript
								</button>
							</p>
							<p
								onClick={deleteTranscriptHandler}
								className="text-xl flex items-center justify-center text-center xs:mb-3 md:mb-0 cursor-pointer hover:text-custom-blue"
							>
								<button className="bg-red-400 text-white px-4 py-1 rounded hover:text-blue-600 ">
									Delete Transcript
								</button>
							</p>
						</div>

						<p className="text-2xl 3 flex items-center justify-center mb-3 mt-11">
							Transcript
						</p>
						<div className="gap-1 flex flex-col h-48" id={style.scrollbar}>
							{finalTranscript?.map((el) => {
								return (
									<>
										<p className="text-md flex xxl:flex-row xs:flex-col justify-between items-center text-custom-blue mb-2 ">
											<p className="mr-4 text-center min-w-[50%] font-bold	 whitespace-normal break-words">
												{el?.presenter?.name}
											</p>
											<p className="mr-4 text-center min-w-[50%] whitespace-normal break-words">
												{el?.text}
											</p>
											<p className="text-center min-w-[50%] whitespace-normal font-bold">
												{moment(el?.time).format('M/D/YY h:mm:ssA')}
											</p>
										</p>
										<hr />
									</>
								);
							})}
							{dbMessages?.map((el) => {
								return (
									<>
										<div className="text-md flex xxl:flex-row xs:flex-col justify-between items-center text-custom-blue mb-0.5 ">
											<div className="mr-4 text-center min-w-[50%] font-bold whitespace-normal break-words">
												{el?.presenterName}
											</div>

											<div className="mr-4 text-center min-w-[50%] whitespace-normal break-words">
												{el?.text}
											</div>
											<div className="text-center min-w-[50%] whitespace-normal font-bold">
												{moment(el?.time).format('M/D/YY h:mm:ssA')}
											</div>
										</div>
										<hr />
									</>
								);
							})}
						</div>
					</div>

					<div
						className={`flex flex-1 flex-col  bg-[#E5F3FF]  border-2  border-black p-8 xxl:min-w-[39%] xs:w-[100%] min-h-[248px]
						`}
					>
						<p className="text-xl flex items-center justify-center text-center xs:mb-3 md:mb-0 ">
							Participants
						</p>
						<div className="flex items-center   justify-center">
							<AddIcon style={{ color: '#000' }} />
							<p
								className="text-md mb-1 text-center mt-2 cursor-pointer  "
								onClick={() => setIsAddSpeaker(true)}
							>
								Add Speaker
							</p>
						</div>
						<div className="flex items-center   justify-center">
							<p
								className="text-md mb-2 text-center cursor-pointer text-red-500"
								onClick={() => setDeletePresenterOpen(true)}
							>
								Delete All Speakers
							</p>
						</div>
						<div>
							{presenters?.map((el) => (
								<p key={el?.name}>
									<b
										onClick={() => showModelFunc(el)}
										style={{ cursor: 'pointer' }}
										className="hover:text-blue-500"
									>
										{el?.name}:{' '}
									</b>
									<i>{getLanguageNameByCode(el?.language)}</i> link to dedicated
									page{' '}
									<span
										className={`inline-block h-3 w-3 rounded-full ${
											el?.online ? 'bg-green-500' : 'bg-gray-300'
										} shadow-md`}
									></span>
								</p>
							))}
						</div>
					</div>
					<div
						className={`flex flex-1 flex-col  bg-[#E5F3FF]  border-2  border-black p-8 xxl:min-w-[39%] xs:w-[100%] min-h-[248px]
						`}
					>
						<img
							id="base64Image"
							src={`data:image/png;base64,${publicAccessData?.qrCodeBase64}`}
							alt="Base64"
							width={'150px'}
							className="m-auto"
						/>
						<a
							href={`data:image/png;base64,${publicAccessData?.qrCodeBase64}`}
							download="letzchat-unite-qr.png"
						>
							<p className="sm:text-xl xs:text-md text-center text-custom-blue">
								Download QR Code
							</p>
						</a>
						<a
							href={publicAccessData?.url}
							target="_blank"
							rel="noopener noreferrer"
						>
							<p
								style={{
									textAlign: 'center',
									overflowWrap: 'break-word',
									maxWidth: 'calc(100% - 2rem)', // adjust this as needed
									overflow: 'hidden',
								}}
								className={`sm:text-xl xs:text-md text-center text-custom-blue whitespace-normal`}
							>
								{publicAccessData?.url}
							</p>
						</a>
						<p className="sm:text-xl xs:text-md text-center">
							Scan to access Listener Page
						</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default Moderator;
