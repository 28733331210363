import React, { useState, useEffect } from 'react';
import './Loader.css';

// eslint-disable-next-line react/prop-types
const Loader = ({ loading }) => {
	const [percentage, setPercentage] = useState(0);

	useEffect(() => {
		if (loading) {
			let timer = setTimeout(function run() {
				setPercentage((oldPercentage) => {
					if (oldPercentage >= 100) {
						clearTimeout(timer);
						return 100;
					}
					return oldPercentage + 1;
				});
				timer = setTimeout(run, percentage * 300);
			}, percentage * 300);
			return () => clearTimeout(timer);
		} else {
			setPercentage(0);
		}
	}, [loading, percentage]);

	return (
		<>
			{loading && (
				<div className="loader-container">
					<div className="circular-loader"></div>
					<div className="percentage">{percentage}%</div>
				</div>
			)}
		</>
	);
};

export default Loader;
