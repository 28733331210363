import {
	Box,
	Container,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import languages, { liveLanguages } from 'constants/languages';
import React, { useEffect, useMemo, useState } from 'react';
import api from 'utils/axios';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import Loader from 'component/loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { createNotification } from 'utils/create-notification';

const ZoomSubtitling = () => {
	const [loading, setLoading] = useState(false);
	const [jobStatus, setJobStatus] = useState(false);
	const { profileInformation } = useSelector((state) => state.user);
	const navigate = useNavigate();

	const getStatusOfJob = async () => {
		const url = `zoom/status`;
		const response = await api.get(url);
		if (response.data.message === 'Running') {
			setJobStatus(true);
		} else {
			setJobStatus(false);
		}
	};

	useEffect(() => {
		getStatusOfJob();
	}, []);

	const validationSchema = useMemo(() => {
		return yup.object({
			sourceLanguage: yup.string().required(),
			targetLanguage: yup.string().required(),
			captionApiToken: yup.string().required(),
		});
	}, []);

	const user = useSelector((state) => state?.user?.profileInformation);

	const {
		register,
		formState: { errors },
		handleSubmit,
		control,
	} = useForm({
		resolver: yupResolver(validationSchema),
	});

	const onSubmit = async (data) => {
		console.log(data);
		try {
			setLoading(true);
			await api.post(`zoom`, {
				...data,
			});
			setLoading(false);
			setJobStatus(true);
		} catch (error) {
			createNotification('error', 'Error', error?.response?.data?.error);
			console.log(error);
			setLoading(false);
		}
	};

	useEffect(() => {
		if (profileInformation) {
			if (!profileInformation?.services?.includes('zoom-subtitles')) {
				return navigate('/');
			}
		}
	}, [profileInformation]);

	useEffect(() => {
		if (profileInformation) {
			const isSubscriptionActive =
				profileInformation?.billingInfo?.subscriptions?.find((el) => {
					return el?.status === 'active' && el?.itemName === 'zoom-subtitles';
				});
			const permission =
				profileInformation?.servicesPayment?.find(
					(el) => el === 'zoom-subtitles'
				) || isSubscriptionActive;

			if (!permission) {
				navigate('/upgrade');
			}
		}
	}, [profileInformation]);

	return (
		<>
			{loading && <Loader />}
			<div>
				<Box className="bg-[#f8f8f8] text-trans">
					<Container className="mt-5">
						<div className="flex xs:flex-col lg:flex-row justify-between lg:px-32 xs:px-4 gap-5">
							<div className="bg-white lg:w-[60%] xs:w-full p-10 rounded-2xl z-10 h-full">
								<h1 className="text-2xl w-80">
									Real-time Subtitle Generation & Translation
								</h1>
								<hr className="border border-x-2 mt-6 -mx-10" />
								<p className="text-lg mt-6">
									Our system is designed to make Zoom meetings more accessible
									and inclusive by automatically generating, translating, and
									rendering subtitles in real-time. With this system, you no
									longer need to worry about manually creating subtitles for
									your meetings, which can be time-consuming and prone to
									errors. Instead, our system automatically generates subtitles
									as the meeting progresses, and can even translate them into
									different languages for participants who prefer to read
									subtitles in their native language.
									<br />
									<br />
									The subtitles are rendered directly on your screen, making it
									easy for everyone to follow along and participate in the
									meeting, regardless of their hearing ability or language
									proficiency. Our system is user- friendly and easy to set up,
									so you can focus on your meeting without worrying about
									technical details. We believe that everyone should have equal
									access to information and communication, and our system is
									designed to help achieve this goal.
								</p>
								<h3 className="text-custom-blue mt-8 text-xl font-[600] mb-4">
									Prerequisites
								</h3>

								<div>
									<ol>
										<li>1. Zoom pro account</li>
										<li className="my-1">2. Must be zoom host</li>
										<li className="w-[64%]">
											3. Setup You Zoom For Get The Streaming of your meeting to
											transcribe it.{' '}
											<Link
												to="/zoom-subtitling/config-info"
												className="text-custom-blue cursor-pointer"
											>
												Click Here To Steup
											</Link>
										</li>
									</ol>
								</div>

								<h3 className="text-custom-blue mt-8 text-xl font-[600] mb-4">
									Your info for Streaming
								</h3>
								<div className="flex flex-col gap-2">
									<p>
										Streaming URL:{' '}
										<span className="text-custom-blue">
											rtmp://stream.letz.chat/live
										</span>
									</p>
									<p>Streaming key: {user && user?.zoomStreamToken}</p>
									<p>
										Live streaming page URL:{' '}
										<span className="text-custom-blue">
											{' '}
											https://www.video.letz.chat/zoom
										</span>
									</p>
								</div>
							</div>
							<div className="bg-white lg:w-[40%] xs:w-full p-10 rounded-2xl z-10 h-full">
								<form onSubmit={handleSubmit(onSubmit)}>
									<h1 className="text-2xl">
										Letz Start. Please Make Sure You Have Read All Steps Before
										Starting.{' '}
										<Link
											to="/zoom-subtitling/config-info"
											className="text-custom-blue font-[500]"
										>
											Click Here
										</Link>{' '}
										to Read configuration and read about how to get Caption API
										Token.
									</h1>
									<div className="mt-10 flex relative">
										<FormControl fullWidth>
											<InputLabel id="demo-simple-select-label">
												Source language
											</InputLabel>
											<Controller
												name="sourceLanguage"
												control={control}
												defaultValue=""
												render={({ field }) => (
													<Select
														{...field}
														label="Source Language"
														error={errors?.sourceLanguage}
													>
														{liveLanguages.map((option) => (
															<MenuItem key={option.code} value={option.code}>
																{option.name}
															</MenuItem>
														))}
													</Select>
												)}
											/>
										</FormControl>
										<PlayArrowIcon
											style={{
												color: '#6db7f2',
											}}
											fontSize="large"
											className="absolute right-1 bottom-3 cursor-pointer"
										/>
									</div>
									<div className="mt-5 flex relative">
										<FormControl fullWidth>
											<InputLabel id="demo-simple-select-label">
												Target language
											</InputLabel>
											<Controller
												name="targetLanguage"
												control={control}
												defaultValue=""
												render={({ field }) => (
													<Select
														{...field}
														label="Target Language"
														error={errors?.targetLanguage}
													>
														{languages.map((option) => (
															<MenuItem key={option.code} value={option.code}>
																{option.name}
															</MenuItem>
														))}
													</Select>
												)}
											/>
										</FormControl>
										<PlayArrowIcon
											style={{
												color: '#6db7f2',
											}}
											fontSize="large"
											className="absolute right-1 bottom-3"
										/>
									</div>
									<div className="mt-5 flex relative">
										<Controller
											name="captionApiToken"
											control={control}
											defaultValue=""
											render={({ field }) => (
												<TextField
													error={errors?.captionApiToken}
													{...field}
													label="Caption Api Token"
													className="w-full"
													id="caption-api-token"
												/>
											)}
										/>

										<PlayArrowIcon
											style={{
												color: '#6db7f2',
											}}
											fontSize="large"
											className="absolute right-1 bottom-3 "
										/>
									</div>
									<div className="mt-4">
										<button
											disabled={loading || jobStatus}
											// disabled={true}
											type="submit"
											className={`text-white ${
												loading || jobStatus ? 'bg-gray-500' : 'bg-custom-blue'
											} w-full h-14 rounded-lg`}
										>
											{jobStatus ? (
												'Running! to stop end live streaming.'
											) : loading ? (
												<FontAwesomeIcon
													icon={faSpinner}
													className="animate-spin"
												/>
											) : (
												'Start Transcription'
											)}
										</button>
									</div>
								</form>
							</div>
						</div>
					</Container>
				</Box>
			</div>
		</>
	);
};

export default ZoomSubtitling;
