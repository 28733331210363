import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Select from 'react-select';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Modal from './Modal';
import axios from 'axios';
import api from 'utils/axios';
import Loader from './loader';
import { createNotification } from 'utils/create-notification';
function FeedbackModule({ open, handleClose }) {
	const { control, register, handleSubmit } = useForm();
	const [selectedFile, setSelectedFile] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleFileInputChange = async (event) => {
		setSelectedFile(event.target.files[0]);
		event.target.value = '';
	};

	const VisuallyHiddenInput = styled('input')({
		clip: 'rect(0 0 0 0)',
		clipPath: 'inset(50%)',
		height: 1,
		overflow: 'hidden',
		position: 'absolute',
		bottom: 0,
		left: 0,
		whiteSpace: 'nowrap',
		width: 1,
	});
	const options = [
		{ label: 'Suggestion', value: 'suggestion' },
		{ label: 'Feedback', value: 'feedback' },
		{ label: 'Issue', value: 'issue' },
		{ label: 'Feature Recommendation', value: 'featurerecommendation' },
	];

	// const onSubmit = async (data) => {
	// 	console.log(data);
	// 	try {
	// 		const response = await axios.post(
	// 			`${process.env.REACT_APP_BASE_URL}/api/feedback`,
	// 			data
	// 		);
	// 		console.log(response.data);
	// 	} catch (error) {
	// 		console.error(error);
	// 	}
	// };
	const onSubmit = async (data) => {
		setLoading(true);
		console.log(data);
		try {
			const formData = new FormData();
			formData.append('subject', data.subject);
			formData.append('message', data.message);
			console.log(selectedFile);
			formData.append('file', selectedFile);
			formData.append('type', data.type.value);
			const response = await api.post(
				`${process.env.REACT_APP_BASE_URL}/api/feedback`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
					responseType: 'json',
				}
			);
			setLoading(false);

			handleClose();
			createNotification('success', 'Success', 'Your Feedback is submitted.');
		} catch (error) {
			console.error(error);
			setLoading(false);
		}
	};

	return (
		<>
			<div>
				<Modal
					title="Feedback"
					open={open}
					bodyTopMargin={1}
					handleClose={handleClose}
				>
					{loading && <Loader />}{' '}
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="w-6/12 mx-auto flex flex-col gap-8">
							<div>
								<h3 className="text-lg font-semibold mb-4">Subject:</h3>
								<TextField
									id="subject"
									className="w-full"
									name="subject"
									placeholder="Subject"
									variant="outlined"
									size="medium"
									{...register('subject', { required: 'Subject is required' })}
									sx={{
										'& .MuiOutlinedInput-root': {
											'& fieldset': {
												borderColor: 'rgba(126, 139, 182, 0.3);',
												borderRadius: '10px',
												'&:hover fieldset': {
													borderColor: '#6DB7F2',
												},
												'&.Mui-focused fieldset': {
													borderColor: '#6DB7F2',
												},
											},
										},
									}}
								/>
							</div>
							<div className="">
								<h3 className="text-lg font-semibold mb-4">Type</h3>
								<Controller
									name="type"
									control={control}
									rules={{ required: 'Type is required' }}
									render={({ field }) => (
										<Select
											{...field}
											options={options}
											placeholder="Select your Type"
											onChange={(value) => field.onChange(value)}
										/>
									)}
								/>
							</div>
							<div className="">
								<h3 className="text-lg font-semibold mb-4">Message:</h3>
								<TextField
									type="message"
									className="w-full text-lg"
									multiline
									rows={12}
									{...register('message', { required: 'Message is required' })}
									placeholder="Enter your message here....."
									size="large"
								/>
							</div>
							<div>
								<h3 className="text-lg font-semibold mb-4">Attach a File </h3>
								<Button
									className="mx-auto"
									component="label"
									role={undefined}
									variant="contained"
									tabIndex={-1}
									startIcon={<CloudUploadIcon />}
								>
									{selectedFile ? selectedFile.name : 'Upload File'}
									<VisuallyHiddenInput
										type="file"
										disabled={selectedFile}
										onChange={handleFileInputChange}
									/>
								</Button>
							</div>
							<div className="">
								<button
									type="submit"
									className="bg-custom-blue text-white py-3 px-7 rounded-lg"
								>
									Send
								</button>
							</div>
						</div>
					</form>
				</Modal>
			</div>
		</>
	);
}

export default FeedbackModule;
