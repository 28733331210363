import { Box, Container, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import UnitBlue from 'assets/unityIconBlue.svg';
import UnitWhite from 'assets/unityIconWhite.svg';
import Subtitles from 'assets/subtitles.svg';
import AnalyticsIcon from 'assets/analytics.svg';
import SubtitlesBlue from 'assets/subtitlesBlue.svg';
import style from './translation.modules.scss';

import {
	Article,
	Image,
	PeopleAlt,
	Person2,
	Person2Rounded,
	Public,
	TranslateRounded,
} from '@mui/icons-material';
import KeyboardVoiceOutlinedIcon from '@mui/icons-material/KeyboardVoiceOutlined';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import './translation.modules.scss';
import { useDispatch, useSelector } from 'react-redux';
import User, { getProfileInfoAction } from 'store/user';
import Header from 'component/Header';

const Translation = () => {
	const [isAdmin, setIsAdmin] = useState(true);
	const [UnitIcon, setUnitIcon] = useState(true);
	const [podcastIcon, setPodcastIcon] = useState(false);
	const [zoomSubtitling, setZoomSubtitling] = useState(true);
	const dispatch = useDispatch();
	const { profileInformation } = useSelector((state) => state.user);

	const location = useLocation();

	useEffect(() => {
		// Compare the current location with the to prop of the NavLink
		setUnitIcon(location.pathname === '/unite');
		setPodcastIcon(location?.pathname === '/podcast-translation');
	}, [location]);
	useEffect(() => {
		// Compare the current location with the to prop of the NavLink
		setZoomSubtitling(location.pathname === '/zoom-subtitling');
	}, [location]);

	useEffect(() => {
		const dd = dispatch(getProfileInfoAction());
		console.log();
	}, []);

	const changeZoomIcon = () => {
		setZoomSubtitling(!zoomSubtitling);
	};

	return (
		<>
			<Header />
			<Box className="bg-gray-100 mb-1 translation-tabs">
				<Container>
					<div className="pt-10 flex lgm:justify-start xs:justify-center gap-2 items-center lgm:flex-row xs:flex-col">
						{profileInformation?.services?.find((el) => el === 'text') && (
							<div className="text-[#6db7f2] hover:text-[#fff] mb-10">
								<NavLink
									to="/"
									exact
									className="border-2 border-[#6db7f2] py-4 lgm:px-6 xs:px-16  rounded-lg text-[#6db7f2] hover:bg-[#6db7f2] hover:text-[#fff] focus:bg-[#6db7f2] focus:text-[#fff]"
								>
									<TranslateRounded
										fontSize="medium"
										style={{ marginRight: '2px' }}
									/>{' '}
									Text
								</NavLink>
							</div>
						)}
						{profileInformation?.services?.find((el) => el === 'image') && (
							<div className=" text-[#6db7f2] hover:text-[#fff] mb-10  ">
								<NavLink
									to="/image-translation"
									className="border-2 border-[#6db7f2] py-4 lgm:px-6 xs:px-14 rounded-lg text-[#6db7f2] hover:bg-[#6db7f2] hover:text-[#fff] active:bg-[#6db7f2] focus:bg-[#6db7f2] focus:text-[#fff]"
								>
									<Image
										fontSize="medium"
										style={{ marginRight: '2px', marginBottom: '2px' }}
									/>
									Images
								</NavLink>
							</div>
						)}
						{profileInformation?.services?.find((el) => el === 'document') && (
							<div className=" text-[#6db7f2] hover:text-[#fff] mb-10  ">
								<NavLink
									to="/doc-translation"
									className=" border-2 border-[#6db7f2]  py-4 lgm:px-6 xs:px-10  rounded-lg text-[#6db7f2] hover:bg-[#6db7f2] hover:text-[#fff] active:bg-[#6db7f2]  focus:bg-[#6db7f2] focus:text-[#fff] "
								>
									<Article
										fontSize="medium"
										style={{ marginRight: '2px', marginBottom: '2px' }}
									/>
									Documents
								</NavLink>
							</div>
						)}
						{profileInformation?.services?.find((el) => el === 'webpage') && (
							<div className="text-[#6db7f2] hover:text-[#fff] mb-10 z-20">
								<NavLink
									to="/web-translation"
									className="border-2 border-[#6db7f2] py-4 lgm:px-6 xs:px-12 rounded-lg text-[#6db7f2] hover:bg-[#6db7f2] hover:text-[#fff] active:bg-[#6db7f2]  focus:bg-[#6db7f2] focus:text-[#fff] "
								>
									<Public
										fontSize="medium"
										style={{ marginRight: '2px', marginBottom: '2px' }}
									/>
									Websites
								</NavLink>
							</div>
						)}
						{profileInformation?.services?.find(
							(el) => el === 'voice-dubbing'
						) && (
							<div className=" text-[#6db7f2] hover:text-[#fff] xs:mb-6 lgm:mb-10 z-20">
								<NavLink
									to="/voice-dubbing"
									className="border-2 border-[#6db7f2] py-4 lgm:px-6 xs:px-7 rounded-lg text-[#6db7f2] hover:bg-[#6db7f2] hover:text-[#fff] active:bg-[#6db7f2] focus:bg-[#6db7f2] focus:text-[#fff] "
								>
									<KeyboardVoiceOutlinedIcon
										fontSize="medium"
										style={{ marginRight: '2px', marginBottom: '2px' }}
									/>
									Voice Dubbing
								</NavLink>
							</div>
						)}
						{profileInformation?.services?.find((el) => el === 'unite') && (
							<div className=" text-[#6db7f2] hover:text-[#fff] xs:mb-4 lgm:mb-10 z-20">
								<NavLink
									to="/unite"
									className="border-2 border-[#6db7f2] py-4 lgm:px-6 xs:px-14 flex rounded-lg text-[#6db7f2] hover:bg-[#6db7f2] hover:text-[#fff] active:bg-[#6db7f2] focus:bg-[#6db7f2] focus:text-[#fff] "
								>
									<img
										className="mr-2 text-[#6db7f2]"
										src={UnitIcon ? UnitWhite : UnitBlue}
										alt="unit icon"
									/>
									Unite
								</NavLink>
							</div>
						)}
						{profileInformation?.services?.find(
							(el) => el === 'zoom-subtitles'
						) && (
							<div className=" text-[#6db7f2] hover:text-[#fff] xs:mb-5 lgm:mb-10 flex justify-center items-center z-20">
								<NavLink
									to="/zoom-subtitling"
									onClick={changeZoomIcon}
									className="border-2 border-[#6db7f2] py-4 lgm:px-6 xs:px-5 flex rounded-lg text-[#6db7f2] hover:bg-[#6db7f2] hover:text-[#fff] active:bg-[#6db7f2] focus:bg-[#6db7f2] focus:text-[#fff] "
								>
									<img
										className="mr-2 text-[#6db7f2]"
										src={zoomSubtitling ? Subtitles : SubtitlesBlue}
										alt="zoom subtitling icon"
									/>
									Zoom Subtitling
								</NavLink>
							</div>
						)}

						{profileInformation?.services?.find(
							(el) => el === 'podcast-translation'
						) && (
							<div className=" text-[#6db7f2] hover:text-[#fff] xs:mb-4 lgm:mb-10 z-20">
								<NavLink
									to="/podcast-translation"
									className="border-2 border-[#6db7f2] py-4 lgm:px-6 xs:px-14 flex rounded-lg text-[#6db7f2] hover:bg-[#6db7f2] hover:text-[#fff] active:bg-[#6db7f2] focus:bg-[#6db7f2] focus:text-[#fff] "
								>
									<img
										className="mr-2 text-[#6db7f2]"
										src={podcastIcon ? UnitWhite : UnitBlue}
										alt="unit icon"
									/>
									Podcast translation
								</NavLink>
							</div>
						)}
						<div className=" text-[#6db7f2] hover:text-[#fff] xs:mb-5 lgm:mb-10 flex justify-center items-center z-20">
							<NavLink
								to="/website-analytics"
								className="border-2 border-[#6db7f2] py-4 lgm:px-6 xs:px-5 flex rounded-lg text-[#6db7f2] hover:bg-[#6db7f2] hover:text-[#fff] active:bg-[#6db7f2] focus:bg-[#6db7f2] focus:text-[#fff] "
							>
								<img
									className="mr-2 text-[#6db7f2]"
									src={AnalyticsIcon}
									alt="website analytics icon"
								/>
								Website Analytics
							</NavLink>
						</div>
					</div>
				</Container>
			</Box>
		</>
	);
};

export default Translation;
