import React from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'config/microsoft/authConfig';
import logo from './microsoft.png';
import { Button } from '@mui/material';

// eslint-disable-next-line react/prop-types
export const SignInButton = ({ signInWithMicrosoftHandler }) => {
	const { instance } = useMsal();

	const handleLogin = (loginType) => {
		if (loginType === 'popup') {
			instance
				.loginPopup(loginRequest)
				.then(async (res) => {
					await signInWithMicrosoftHandler(res);
				})
				.catch((e) => {
					console.log('Error in microsoft login', e);
				});
		}
	};
	return (
		<div>
			<Button
				className="microsoft-link-button font-[600] rounded-lg flex justify-between items-center"
				type="button"
				onClick={() => handleLogin('popup')}
			>
				<span>
					<img className="w-5 h-5 flex flex-start mr-1" src={logo} alt="" />
				</span>
				<span className="mr-28">Sign in with Microsoft</span>
			</Button>
		</div>
	);
};
