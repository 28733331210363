import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Grid, TextField } from '@mui/material';
import styles from './index.module.scss';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { ExpandMore, Repeat } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faArrowRight,
	faUnlockKeyhole,
} from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import LanguageSelectorMain from '../../../component/LanguageSelectorMain';
import InfiniteLoader from '../../../component/InfiniteLoader/InfiniteLoader';
import Loader from '../../../component/InfiniteLoader/InfiniteLoader';
import api from '../../../utils/axios';
import stripe from 'assets/stripe.png';
import Modal from 'component/Modal';
import CheckoutForm from 'component/Checkout';
import { useSelector } from 'react-redux';
import { ApiService } from 'services/api-services';
import { useNavigate } from 'react-router-dom';

const overlayStyles = {
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	backgroundColor: 'rgba(0, 0, 0, 0.8)',
	zIndex: 999,
};

const WebsiteTranslation = () => {
	const navigate = useNavigate();

	const [showOverlay, setShowOverlay] = useState(true);
	const [isLoading, setIsLoading] = React.useState(false);
	const [websiteUrl, setWebsiteUrl] = React.useState();
	const [value, setValue] = React.useState('1');
	const [open, setOpen] = useState(false);
	const [rightvalue, setRightValue] = React.useState('2');
	const [selectedLang, seSelectedLang] = React.useState({
		sourceLang: 'English',
		targetLang: 'English',
	});
	const [languageCode, setLanguageCode] = React.useState({
		sourceLangCode: 'auto',
		targetLangCode: 'en',
	});
	const { control, watch, getValues, setValue: RfSetValue } = useForm();

	const { profileInformation } = useSelector((state) => state?.user);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const righthandleChange = (event, newValue) => {
		setRightValue(newValue);
	};

	const handleLangCode = (val) => {
		val?.type === 'sourceLanguage' &&
			setLanguageCode({ ...languageCode, sourceLangCode: val?.code });
		val?.type === 'targetLanguage' &&
			setLanguageCode({ ...languageCode, targetLangCode: val?.code });
	};

	const handleWebsiteUrlChange = (e) => {
		const websiteUrlVar = e.target.value;
		setWebsiteUrl(websiteUrlVar);
	};

	console.log(websiteUrl);

	useEffect(() => {
		let targetLang = getValues('targetLanguage');
		let sourceLang = getValues('sourceLanguage');
		if (targetLang?.value || sourceLang?.value) {
			seSelectedLang({
				sourceLang: sourceLang?.label,
				targetLang: targetLang?.label,
			});
			setLanguageCode({
				sourceLangCode: sourceLang ? sourceLang?.value : 'auto',
				targetLangCode: targetLang ? targetLang?.value : 'en',
			});
			if (targetLang?.label && targetLang?.label != selectedLang?.targetLang)
				setRightValue('2');
			if (sourceLang?.label && sourceLang?.label != selectedLang?.sourceLang)
				setValue('2');
		}
	}, [watch()?.sourceLanguage, watch()?.targetLanguage]);
	console.log('----->', languageCode);

	const webTranslationAnylyticsHandler = async () => {
		setIsLoading(true);
		try {
			const response = await api.post('/translation/webpage/analytics', {
				url: websiteUrl,
			});

			window.location.href = response.data.url;
			setTimeout(() => {
				setIsLoading(false);
			}, 3000);
		} catch (error) {
			setIsLoading(false);
			console.log('Error in text Translation', error);
		}
	};

	const webTranslationHandler = async () => {
		const sourceLanguage = languageCode?.sourceLangCode;
		const targetLanguage = languageCode?.targetLangCode;
		setIsLoading(true);
		try {
			const response = await api.post(
				'/translation/webpage',
				{
					sourceLanguage,
					targetLanguage,
					url: websiteUrl,
				},
				{
					responseType: 'arraybuffer', // Set the response type to 'arraybuffer' for handling binary data
					headers: {
						'Content-Type': 'application/json', // Set the content type to 'application/json' for sending JSON data
					},
				}
			);

			// Create a Blob with the received data
			const blob = new Blob([response.data], { type: 'application/zip' });

			// Create an anchor element and initiate the download
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute(
				'download',
				`translated-Webpage-${Math.random() * 12389}.zip`
			); // Set the desired filename
			document.body.appendChild(link);
			link.click();

			// Clean up after the download
			link.parentNode.removeChild(link);
			window.URL.revokeObjectURL(url);

			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			console.log('Error in text Translation', error);
		}
	};

	const handlePayment = async (paymentMethodId) => {
		const response = await ApiService.post('api/payment/webpage-subscription', {
			paymentMethod: paymentMethodId,
		});
		if (!response?.response?.data?.error) {
			setOpen(false);
			setShowOverlay(false);
		}
	};

	useEffect(() => {
		if (profileInformation?.webpageSubscriptionPayment) {
			setShowOverlay(false);
		}
		if (profileInformation?.servicesPayment?.find((el) => el === 'webpage')) {
			setShowOverlay(false);
		}
		const isSubscriptionActive =
			profileInformation?.billingInfo?.subscriptions?.find((el) => {
				return el?.status === 'active' && el?.itemName === 'webpage';
			});
		if (isSubscriptionActive) {
			setShowOverlay(false);
		}
	}, [profileInformation]);

	const handleLanguageSwitch = () => {
		let targetLang = getValues('targetLanguage');
		let sourceLang = getValues('sourceLanguage');
		sourceLang && RfSetValue('targetLanguage', sourceLang);
		targetLang && RfSetValue('sourceLanguage', targetLang);

		if (!targetLang && !sourceLang) {
			languageCode?.targetLangCode === 'es' && setValue('3');
			languageCode?.targetLangCode === 'en' && setValue('2');
			languageCode?.targetLangCode === 'fr' && setValue('4');

			languageCode?.sourceLangCode === 'es' && setRightValue('3');
			languageCode?.sourceLangCode === 'en' && setRightValue('2');
			languageCode?.sourceLangCode === 'fr' && setRightValue('4');
		}
		setLanguageCode({
			sourceLangCode: languageCode?.targetLangCode,
			targetLangCode: languageCode?.sourceLangCode,
		});
	};

	return (
		<>
			<Loader loading={isLoading} />
			<Box>
				<Container className={`mt-5 ${showOverlay ? 'overlay-box' : ''}`}>
					<LanguageSelectorMain
						handleChange={handleChange}
						control={control}
						righthandleChange={righthandleChange}
						value={value}
						setValue={setValue}
						rightvalue={rightvalue}
						setRightValue={setRightValue}
						selectedLang={selectedLang}
						setLanguageCode={handleLangCode}
						getValues={getValues}
						handleLanguageSwitch={handleLanguageSwitch}
					/>
					<Box className="relative bg-white h-60 rounded-bl-lg rounded-br-lg shadow-lg box-height">
						{showOverlay && (
							<div
								style={overlayStyles}
								className="flex justify-center items-center flex-wrap overlay-text  lg:gap-10 md:gap-10 sm:gap-0  "
							>
								<div className="secure-img">
									<FontAwesomeIcon
										icon={faUnlockKeyhole}
										className="secure-icon"
									/>
								</div>
								<div>
									<h2
										className="text-white text-6xl font-bold mb-2 subcribe-secure hover:text-custom-blue"
										onClick={() => navigate('/upgrade')}
										style={{ cursor: 'pointer' }}
									>
										Subscribe to gain access
									</h2>
									<p className="text-white text-4xl plans-secure">
										The Performance Plan is available at a monthly rate of
										$299.00.
									</p>
								</div>
							</div>
						)}

						<div className="flex justify-center items-center lm:flex-row flex-col pt-20 lm:gap-0 gap-4">
							<TextField
								required
								id="outlined-password-input"
								label="Website Link"
								type="text"
								className="text-website "
								value={websiteUrl}
								onChange={handleWebsiteUrlChange}
							/>
							<button
								onClick={webTranslationHandler}
								className="w-10 h-10 bg-[#6db7f2] hover:bg-blue-400 text-white font-bold py-2 px-4 rounded-full ml-5 arrow-btn"
							>
								<FontAwesomeIcon icon={faArrowRight} className="-ml-1" />
							</button>
							<button
								disabled={showOverlay}
								onClick={webTranslationAnylyticsHandler}
								className="w-60 bg-[#6db7f2] hover:bg-blue-400 text-white font-bold py-4 rounded-lg  md:ml-5  sm:ml-0"
							>
								Get Translation Analytics
							</button>
						</div>
					</Box>
					<Box>
						el{' '}
						<div className="text-center pt-10">
							<p className="text-lg text-[#00000099]">
								Secure payment transctions are facilitated through
							</p>
							<div className="flex justify-center items-center">
								<img src={stripe} alt="stripe" width={150} />
							</div>
						</div>
					</Box>
					<div>
						<Modal
							open={open}
							handleClose={() => setOpen(false)}
							bodyTopMargin={1}
							className={styles.ModelContainer}
						>
							<div className="w-96 bg-white rounded p-6 shadow-xl">
								<h2 className="text-2xl font-bold mb-6 text-gray-800">
									Checkout
								</h2>
								<p className="mb-6 text-gray-600">
									Subscribe to our Website translation service for only $250.00
									per month. Unlimited translations, no hidden costs.
								</p>
								<CheckoutForm amount={250.0} handlePayment={handlePayment} />
							</div>
						</Modal>
					</div>
				</Container>
			</Box>
		</>
	);
};

export default WebsiteTranslation;
