import { useState, useEffect, useRef } from 'react';
import SocketManager from '../services/SocketManager';

const getMediaStream = (deviceId) => {
	console.log('deviceId', { deviceId: deviceId || 'default' });
	return navigator.mediaDevices.getUserMedia({
		audio: {
			deviceId: deviceId || 'default', // Use selected device ID or 'default'
			sampleRate: 16000,
			sampleSize: 16,
			channelCount: 1,
		},
		video: false,
	});
};

const useGoogleTranscriptionWithSingleConnection = (
	language,
	silenceDuration,
	inputDeviceId
) => {
	const [isStreamLoading, setIsStreamLoading] = useState(false);

	const [connection, setConnection] = useState();
	const [isRecording, setIsRecording] = useState(false);
	const [recorder, setRecorder] = useState();
	const [dataSpeech, setDataSpeech] = useState();
	const [updatedMessage, setUpdatedMessage] = useState();

	const recognitionHistory = useRef([]);
	const currentRecognition = useRef('');
	const currentPhrase = useRef('');
	const socketRef = useRef();
	const processorRef = useRef();
	const audioContextRef = useRef();
	const audioInputRef = useRef();
	const stopOnSilenceRef = useRef(false);
	const currentRecognitionRef = useRef();
	const socketConnection = useRef();

	const debounceTimeoutRef = useRef(null);

	const debounce = (func, delay) => {
		return function (...args) {
			clearTimeout(debounceTimeoutRef.current);
			debounceTimeoutRef.current = setTimeout(
				() => func.apply(this, args),
				delay
			);
		};
	};

	const updateRecognitionHistory = () => {
		if (currentRecognition.current.trim() !== '') {
			const message = {
				text: currentRecognition.current,
				time: new Date(),
			};
			setUpdatedMessage(currentRecognition.current);
			recognitionHistory.current = [message, ...recognitionHistory.current];
			currentRecognition.current = '';
		}
	};

	const debouncedUpdateRecognitionHistory = debounce(
		updateRecognitionHistory,
		300
	); // Adjust the delay as needed

	const speechRecognized = (data) => {
		setDataSpeech(data);
		if (data.isFinal) {
			currentPhrase.current = '';
			currentRecognition.current += `${data.text} `;
		} else {
			currentPhrase.current = data.text;
		}
	};

	useEffect(() => {
		currentRecognitionRef.current = `${currentRecognition.current} ${currentPhrase.current}`;
	}, [currentRecognition.current, currentPhrase.current]);

	useEffect(() => {
		// eslint-disable-next-line no-undef
		socketRef.current = SocketManager;

		socketRef.current.emit('set_silence_duration', silenceDuration);

		socketRef.current.on('silence_threshold_exceeded', () => {
			debouncedUpdateRecognitionHistory();
			stopOnSilenceRef.current = true;
		});

		// socketRef.current.on('silence_threshold_exceeded', () => {
		// 	if (
		// 		currentRecognitionRef.current &&
		// 		currentRecognitionRef.current !== ' '
		// 	) {
		// 		const message = {
		// 			text: currentRecognitionRef.current,
		// 			time: new Date(),
		// 		};

		// 		recognitionHistory.current = [message, ...recognitionHistory.current];

		// 		currentRecognition.current = '';
		// 		currentPhrase.current = '';
		// 		currentRecognitionRef.current = '';
		// 	}
		// 	stopOnSilenceRef.current = true;
		// });

		socketRef.current.on('connect', () => {
			socketConnection.current = socketRef.current;
		});

		return () => {
			socketRef?.current?.disconnect();
		};
	}, []);

	const setSilenceDuration = (duration) => {
		socketRef.current.emit('set_silence_duration', duration);
	};

	const connect = () => {
		currentPhrase.current = '';
		currentRecognition.current = '';
		recognitionHistory.current = [];

		setIsStreamLoading(true);
		setTimeout(() => {
			setIsStreamLoading(false);
		}, 2000);

		setConnection(socketConnection.current);

		socketRef.current.emit('startGoogleCloudStream', language);

		socketRef.current.on('receive_audio_text', (data) => {
			speechRecognized(data);
		});

		socketRef.current.on('disconnect', () => {
			console.log('disconnected', socketRef.current.id);
		});
	};

	const disconnect = () => {
		if (!connection) return;
		connection?.emit('endGoogleCloudStream');
		processorRef.current?.disconnect();
		audioInputRef.current?.disconnect();
		audioContextRef.current?.close();
		setConnection(undefined);
		setRecorder(undefined);
		setIsRecording(false);
		socketRef.current.off('receive_audio_text');
	};

	useEffect(() => {
		(async () => {
			if (connection) {
				if (isRecording) {
					return;
				}

				const stream = await getMediaStream(inputDeviceId);

				audioContextRef.current = new window.AudioContext();

				await audioContextRef.current.audioWorklet.addModule(
					'/src/worklets/recorderWorkletProcessor.js'
				);

				audioContextRef.current.resume();

				audioInputRef.current =
					audioContextRef.current.createMediaStreamSource(stream);

				processorRef.current = new AudioWorkletNode(
					audioContextRef.current,
					'recorder.worklet'
				);

				processorRef.current.connect(audioContextRef.current.destination);
				audioContextRef.current.resume();

				audioInputRef.current.connect(processorRef.current);

				processorRef.current.port.onmessage = () => {
					const audioData = event.data;
					if (typeof connection.emit === 'function')
						connection?.emit('send_audio_data', { audio: audioData });
				};
				setIsRecording(true);
			} else {
				console.log('No connection');
			}
		})();
		return () => {
			if (isRecording) {
				processorRef.current?.disconnect();
				audioInputRef.current?.disconnect();
				if (audioContextRef.current?.state !== 'closed') {
					audioContextRef.current?.close();
				}
			}
		};
	}, [connection, isRecording, recorder]);

	return {
		isStreamLoading,
		connect,
		disconnect,
		currentPhrase,
		currentRecognition,
		recognitionHistory,
		isRecording,
		setSilenceDuration,
		dataSpeech,
		stopOnSilenceRef,
		currentRecognitionRef,
		updatedMessage,
	};
};

export default useGoogleTranscriptionWithSingleConnection;
