import { Box, Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Presenter from './Presenter';
import Listener from './Listener';
import CustomSelect from 'component/CustomSelect';
import { uniteLanguages } from 'constants/languages';
import { useForm } from 'react-hook-form';
import ListenTranscript from './ListenTranscript';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

let deviceLanguage = navigator.language || navigator.userLanguage;
deviceLanguage = uniteLanguages.find((el) => el.code === deviceLanguage);
if (!deviceLanguage || deviceLanguage === 'undefined') {
	console.log('device language is not getting setting to en-US');
	deviceLanguage = { name: 'English (United States)', code: 'en-US' };
}
const Unite = () => {
	const { profileInformation } = useSelector((state) => state.user);
	const navigate = useNavigate();
	const [content, setContent] = useState('presenter');

	const changeContent = (res) => {
		setContent(res);
	};

	const { control, watch, setValue } = useForm({
		defaultValues: {
			sourceLanguage: {
				value: deviceLanguage?.code,
				label: deviceLanguage?.name,
			},
		},
	});

	useEffect(() => {
		if (profileInformation) {
			if (!profileInformation?.services?.includes('unite')) {
				navigate('/');
			}
		}
	}, [profileInformation]);

	useEffect(() => {
		if (profileInformation) {
			const isSubscriptionActive =
				profileInformation?.billingInfo?.subscriptions?.find((el) => {
					return el?.status === 'active' && el?.itemName === 'unite';
				});
			const permission =
				profileInformation?.servicesPayment?.find((el) => el === 'unite') ||
				isSubscriptionActive;

			if (!permission) {
				navigate('/upgrade');
			}
		}
	}, [profileInformation]);

	return (
		<>
			<Box className="relative bg-[#f8f8f8] text-trans">
				<Container className="mt-5">
					<div className="bg-white">
						<div className="flex flex-col rounded-xl">
							<CustomSelect
								name="sourceLanguage"
								control={control}
								options={uniteLanguages.map((country) => ({
									label: country.name,
									value: country.code,
								}))}
								placeholder={'Select Language'}
								defaultValue={{
									value: deviceLanguage?.code,
									label: deviceLanguage?.name,
								}}
								className="border-b-0 rounded-0 xs:w-[60%] md:w-[20%] z-30"
							/>
							<div className="flex flex-col justify-center items-center border-t-2 z-0">
								<div className="flex flex-col gap-8 justify-center items-center lgm:w-[70%] lg:w-[90%] sm:w-[90%] xs:w-[90%] py-8">
									<div className="flex md:flex-row xs:flex-col justify-center items-center gap-4">
										<button
											type="button"
											onClick={() => changeContent('presenter')}
											className={`xs:w-[200px] lg:w-[300px] lgm:w-[350px] h-16 rounded text-[#6db7f2] text-xl font-bold hover:bg-[#6db7f2] hover:text-[#fff] focus:bg-[#6db7f2] focus:text-[#fff] border-2 ${
												content === 'presenter'
													? 'border-custom-blue'
													: 'border-grey-200'
											} ${
												content === 'presenter' ? 'bg-custom-blue' : 'bg-white'
											} ${
												content === 'presenter'
													? 'text-white'
													: 'text-custom-blue'
											} `}
										>
											Presenter
										</button>
										<button
											type="button"
											onClick={() => changeContent('listener')}
											className={`xs:w-[200px] lg:w-[300px] lgm:w-[350px] h-16 rounded text-[#6db7f2] text-xl font-bold hover:bg-[#6db7f2] hover:text-[#fff] active:bg-[#6db7f2] focus:bg-[#6db7f2] focus:text-[#fff] border-2 ${
												content === 'listener'
													? 'border-custom-blue'
													: 'border-gray-200'
											} ${
												content === 'listener' ? 'bg-custom-blue' : 'bg-white'
											} ${
												content === 'listener'
													? 'text-white'
													: 'text-custom-blue'
											}`}
										>
											Listener
										</button>
										<button
											type="button"
											onClick={() => changeContent('listenTranscript')}
											className={`xs:w-[200px] lg:w-[300px] lgm:w-[350px] h-16 rounded text-[#6db7f2] text-xl font-bold hover:bg-[#6db7f2] hover:text-[#fff] active:bg-[#6db7f2] focus:bg-[#6db7f2] focus:text-[#fff] border-2 ${
												content === 'listenTranscript'
													? 'border-custom-blue'
													: 'border-gray-200'
											} ${
												content === 'listenTranscript'
													? 'bg-custom-blue'
													: 'bg-white'
											} ${
												content === 'listenTranscript'
													? 'text-white'
													: 'text-custom-blue'
											}`}
										>
											Listen Transcript
										</button>
									</div>
									<div className="xs:w-[100%]">
										{(content === 'presenter' && (
											<Presenter
												sourceLanguage={watch('sourceLanguage')}
												deviceLanguage={deviceLanguage}
											/>
										)) ||
											(content == 'listener' && <Listener />) ||
											(content == 'listenTranscript' && <ListenTranscript />)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</Container>
			</Box>
		</>
	);
};

export default Unite;
