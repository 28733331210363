/* eslint-disable no-undef */
import _axios from 'axios';
import { createNotification } from 'utils/create-notification';

export class ApiService {
	static createAxios = () => {
		const token =
			(localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')))
				?.token || '';
		return _axios.create({
			baseURL:
				process.env.REACT_APP_API_IS_DEV === 'true'
					? process.env.REACT_APP_API_BASE_URL_DEV
					: process.env.REACT_APP_BASE_URL,
			headers: {
				authorization: token,
			},
			withCredentials: true,
		});
	};
	static async get(url, config) {
		const axios = ApiService.createAxios();
		try {
			const res = await axios.get(url, config);
			return res;
		} catch (error) {
			ApiService.handleError(error);
			return error;
		}
	}
	static async post(url, body, config) {
		const axios = ApiService.createAxios();
		try {
			const res = await axios.post(url, body, config);
			if (!res.config.url?.includes('login')) ApiService.handleSuccess(res);
			return res;
		} catch (error) {
			console.log('here');
			ApiService.handleError(error);
			return error;
		}
	}
	static async put(url, body, config) {
		const axios = ApiService.createAxios();
		try {
			const res = await axios.put(url, body, config);
			ApiService.handleSuccess(res);
			return res;
		} catch (error) {
			ApiService.handleError(error);
			return error;
		}
	}
	static async delete(url, config) {
		const axios = ApiService.createAxios();
		try {
			const res = await axios.delete(url, config);

			ApiService.handleSuccess(res);
			return res;
		} catch (error) {
			ApiService.handleError(error);
			return error;
		}
	}
	static handleError(error) {
		if (error?.response?.data?.msg === 'Session Expired!') {
			localStorage.removeItem('user_id');
			localStorage.removeItem('token');
			window.location.reload();
		} else {
			console.log('handleError in api services');
			if (error?.response?.data?.error) {
				typeof error?.response?.data?.error === 'object'
					? createNotification(
							'error',
							'Error',
							error?.response?.data?.error[0]
					  )
					: createNotification('error', 'Error', error?.response?.data?.error);
			} else {
				createNotification('error', 'Error', error?.response?.data?.message);
			}
		}
	}
	static handleSuccess(res) {
		if (res.config.url !== 'notifications/') {
			// createNotification(
			// 	'success',
			// 	'Success',
			// 	res?.data?.msg || 'Successfully'
			// );
		}
	}
}
