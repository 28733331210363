import React, { useEffect, useRef } from 'react';
import { Box, Button, Container, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { login, loginWithGoogle, loginWithMicrosoft } from 'store/user';
import Loader from 'component/loader';
import rightCurve from 'assets/right-curve.png';
import googleIcon from 'assets/GOOGLE.png';
import leftCurve from 'assets/left-curve.png';
import leftImage from 'assets/left-img.png';
import logo from 'assets/logo.png';
import { GoogleLogin } from '@react-oauth/google';
import { createNotification } from 'utils/create-notification';

import { useMsalAuthentication, useMsal } from '@azure/msal-react';
import * as msal from '@azure/msal-browser';
import { SignInButton } from 'component/Microsoft-Login/SignInButton';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import api from 'utils/axios';
import Footer from 'component/footer';

const schema = yup.object().shape({
	email: yup
		.string()
		.email('Invalid email format')
		.required('Email is required'),
	password: yup
		.string()
		.required('Password is required')
		.min(8, 'Minimum 8 Characters'),
});

const Login = () => {
	const [loading, setLoading] = React.useState(false);
	const hiddenElementRef = useRef(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	console.log('hello', searchParams);
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const onSubmit = async (data) => {
		setLoading(true);
		await dispatch(login(data));
		setLoading(false);
	};

	const signupWithEmail = () => {
		navigate('/signup');
	};

	const handleGoogleLogin = async (response) => {
		const token = response.credential;
		setLoading(true);
		await dispatch(loginWithGoogle({ token }));
		setLoading(false);
	};

	const handleGoogleLoginError = (error) => {
		setLoading(true);
		console.log('Error when clicking on continue with google', error);
		createNotification(
			'error',
			'Google Login Error',
			'Issue in continue with google'
		);
		setLoading(false);
	};

	const handleLogin = () => {
		hiddenElementRef.current.click();
	};

	const signInWithMicrosoftHandler = async (data) => {
		console.log('Microsoft Data', data);
		const fullName = data?.account?.name;
		const microsoftAccountId = data?.account?.homeAccountId;
		const email = data?.account?.username;
		console.log(name, microsoftAccountId, email);

		setLoading(true);
		await dispatch(loginWithMicrosoft({ fullName, email, microsoftAccountId }));
		setLoading(false);
	};

	useEffect(() => {
		if (searchParams.get('utm_source')) {
			api
				.get(`/auth/utm-source?utm_source=${searchParams.get('utm_source')}`, {
					withCredentials: true,
				})
				.then((res) => console.log(res))
				.catch((error) => {
					console.log(error);
				});
		}
	}, []);

	function getAllCookies() {
		return document.cookie.split(';').reduce((cookies, cookie) => {
			const [name, value] = cookie.split('=').map((c) => c.trim());
			cookies[name] = value;
			return cookies;
		}, {});
	}

	useEffect(() => {
		const cookies = getAllCookies();
		console.log('hamza', cookies); // Logs an object containing all cookies
	}, []);

	return (
		<>
			{loading && <Loader />}
			<Box className="">
				<Box>
					<img
						src={rightCurve}
						className="fixed top-0 right-0 -z-10"
						alt="right-curve"
					/>
				</Box>
				<Box>
					<img
						src={leftCurve}
						className="fixed bottom-0 left-0"
						alt="left-curve"
						width={500}
					/>
				</Box>
				<Container>
					<div className="flex items-center justify-center mt-6 pb-6">
						<div className="grid md:grid-cols-2 xs:grid-cols-1">
							<div className="">
								<img
									src={leftImage}
									className="w-full h-full z-50"
									alt="left-img"
								/>
							</div>

							<div className="right-wrapper flex flex-col justify-center items-center">
								<div className="mb-5 logo-img">
									<img
										src={logo}
										className="mb-5 relative"
										width={250}
										alt="logo-img"
									/>
								</div>
								<form
									onSubmit={handleSubmit(onSubmit)}
									className="grid grid-cols-1"
								>
									<div className="mb-5">
										<p className="mb-5 text-md">Sign In </p>
										<TextField
											{...register('email')}
											id="email"
											className="style-input"
											name="email"
											label="Email"
											variant="outlined"
											size="medium"
											error={!!errors.email}
											helperText={errors.email?.message}
											sx={{
												'& .MuiOutlinedInput-root': {
													'& fieldset': {
														borderColor: 'rgba(126, 139, 182, 0.3);',
														borderRadius: '10px',
														'&:hover fieldset': {
															borderColor: '#6DB7F2',
														},
														'&.Mui-focused fieldset': {
															borderColor: '#6DB7F2',
														},
													},
												},
											}}
										/>
									</div>
									<div className="mb-5">
										<TextField
											{...register('password')}
											id="password"
											className="style-input"
											name="password"
											label="Password"
											type="password"
											size="medium"
											error={!!errors.password}
											helperText={errors.password?.message}
											sx={{
												'& .MuiOutlinedInput-root': {
													'& fieldset': {
														borderColor: 'rgba(126, 139, 182, 0.3);',
														borderRadius: '10px',
														'&:hover fieldset': {
															borderColor: 'green',
														},
														'&.Mui-focused fieldset': {
															borderColor: 'green',
														},
													},
												},
											}}
										/>
									</div>
									<div
										className="flex flex-end mb-5 text-[#6DB7F2] text-md cursor-pointer"
										onClick={() => navigate('/forgot-password')}
									>
										<p>Forgot Password?</p>
									</div>

									<div className="mb-3">
										<Button
											variant="contained"
											type="submit"
											className="submit-button"
										>
											Login
										</Button>
									</div>
									<div className="mb-8">
										<Button
											variant="contained"
											type="button"
											className="submit-button"
											onClick={signupWithEmail}
										>
											Signup
										</Button>
									</div>

									<Button className="google-link-btn" onClick={handleLogin}>
										<GoogleLogin
											onSuccess={handleGoogleLogin}
											onError={handleGoogleLoginError}
										/>
									</Button>
									<SignInButton
										signInWithMicrosoftHandler={signInWithMicrosoftHandler}
									/>
								</form>
							</div>
						</div>
					</div>
				</Container>
			</Box>
		</>
	);
};

export default Login;
