import {
	Box,
	Container,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	ThemeProvider,
	createTheme,
} from '@mui/material';
import stripe from 'assets/stripe.png';

import React, { useEffect, useState } from 'react';
import api from 'utils/axios';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useSelector } from 'react-redux';
import Loader from 'component/loader';

const theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 320,
			md: 600,
			lg: 960,
			xl: 1280,
		},
	},
});

const VoiceDubbingHistory = () => {
	const [loading, setLoading] = useState(true);
	const [dubbingDetail, setDubbingDetail] = useState([]);
	const { profileInformation } = useSelector((state) => state.user);

	useEffect(() => {
		if (profileInformation?._id) {
			api
				.get(`/history/voice-dubbing/${profileInformation?._id}`)
				.then((res) => {
					setLoading(true);
					const filterDetails = res?.data
						?.filter((el) => {
							return el?.dubbedVideo !== 'null' || el?.status;
						})
						.reverse();

					setDubbingDetail(filterDetails);
					setLoading(false);
				})
				.catch((error) => {
					setLoading(false);
					console.log(error);
				});
		}
	}, [profileInformation]);

	// log

	return (
		<>
			{loading && <Loader />}
			<Box>
				<Container>
					<Box className="bg-white h-full rounded-lg rounded-br-lg shadow-lg">
						<div className="flex flex-col gap-5 p-8">
							<p className="mb-5 xm:text-2xl font-[600] xs:text-sm">
								Voice Dubbing History
							</p>
							<ThemeProvider theme={theme}>
								<TableContainer component={Paper}>
									<Table sx={{ minWidth: 650 }} aria-label="simple table">
										<TableHead>
											<TableRow>
												<TableCell align="left">Orignal Language</TableCell>
												<TableCell align="left">Dubbed Language</TableCell>
												<TableCell align="left">Dubbed at</TableCell>
												<TableCell align="left">Status</TableCell>
												<TableCell align="left">Download</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{dubbingDetail?.map((el) => (
												<TableRow
													key={el._id}
													sx={{
														'&:last-child td, &:last-child th': { border: 0 },
													}}
												>
													<TableCell
														component="th"
														scope="row"
														sx={{
															paddingLeft: { xs: 2, md: 5, lg: 7, xl: 10 },
														}}
													>
														{el?.sourceLanguage}
													</TableCell>
													<TableCell align="left">
														{el?.targetLanguage}
													</TableCell>
													<TableCell align="left">{el?.createdAt}</TableCell>
													<TableCell align="left">
														{el?.status === 'Progress' && 'In '}
														{el?.status}
													</TableCell>
													<TableCell align="left">
														{el?.status === 'Completed' ? (
															<a
																target="_blank"
																rel="noreferrer"
																href={el?.dubbedVideo}
															>
																<CloudDownloadIcon
																	style={{
																		cursor: 'pointer',
																		color: '#6db7f2',
																	}}
																/>
															</a>
														) : (
															<CloudDownloadIcon
																style={{
																	cursor: 'not-allowed',
																	color: '#cccccc',
																}}
															/>
														)}
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</ThemeProvider>
						</div>
						<div className="text-center pt-10">
							<p className="text-lg text-[#00000099]">
								Secure payment transctions are facilitated through
							</p>
							<div className="flex justify-center items-center">
								<img src={stripe} alt="stripe" width={150} />
							</div>
						</div>
					</Box>
				</Container>
			</Box>
		</>
	);
};

export default VoiceDubbingHistory;
