import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import user from './user';

const reducer = combineReducers({ user });

const store = configureStore({
	reducer,
	// eslint-disable-next-line no-undef
	devTools: process.env.REACT_APP_API_IS_DEV === 'false' ? false : true,
});

export default store;
