import {
	Box,
	Checkbox,
	Container,
	FormControlLabel,
	FormGroup,
} from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { set, useForm } from 'react-hook-form';
import stripe from 'assets/stripe.png';
import CustomSelect from 'component/CustomSelect';
import languages, {
	awsTranscribeLanguages,
	googleLiveVoiceToTextLanguages,
	languages as voiceCloneLanguages,
} from 'constants/languages';

import api from 'utils/axios';
import { createNotification } from 'utils/create-notification';
import Loader from 'component/loader';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import SimpleLoader from 'component/loader';
import Modal from 'component/Modal';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Visa from 'assets/credit-cards/visa.svg';
import Amex from 'assets/credit-cards/amex.svg';
import Mastercard from 'assets/credit-cards/mastercard.svg';
import Unknown from 'assets/credit-cards/unknown.svg';

const options = [
	{ value: '1', label: '1' },
	{ value: '2', label: '2' },
	{ value: '3', label: '3' },
	{ value: '4', label: '4' },
	{ value: '5', label: '5' },
	{ value: '6', label: '6' },
	{ value: '7', label: '7' },
	{ value: '8', label: '8' },
	{ value: '9', label: '9' },
	{ value: '10', label: '10' },
];
const optionsGender = [
	{ value: 'MALE', label: 'Male' },
	{ value: 'FEMALE', label: 'Female' },
];
const CREDIT_CARD_LOGOS = {
	Visa: Visa,
	Amex: Amex,
	Mastercard: Mastercard,
};

function checkEmail(email) {
	const emailList = [
		'tiffany@managedhealth.com',
		'muriel@managedhealth.com',
		'ernie@managedhealth.com',
		'fatima@managedhealth.com',
		'moe@managedhealth.com',
	];
	return emailList.includes(email);
}

// eslint-disable-next-line react/prop-types
const CheckoutForm = ({ name, email, totalMinutes, handleDubbing }) => {
	const [loading, setLoading] = useState(false);
	const stripe = useStripe();
	const elements = useElements();
	const navigation = useNavigate();
	const { profileInformation } = useSelector((store) => store?.user);

	useEffect(() => {
		if (!profileInformation?.billingInfo?.paymentMethods[0]) {
			navigation(
				'/billing-information?error=Please update your billing Information'
			);
		}
	}, []);

	const logo = useMemo(() => {
		if (!profileInformation?.billingInfo?.paymentMethods[0]?.brand)
			return Unknown;
		return (
			CREDIT_CARD_LOGOS[
				profileInformation?.billingInfo?.paymentMethods[0]?.brand
			] || Unknown
		);
	}, [profileInformation]);

	const handleSubmit = async (event) => {
		setLoading(true);
		event.preventDefault();
		setLoading(false);
		handleDubbing({
			totalAmount: (totalMinutes * 14.99).toFixed(2),
		});
	};

	return (
		<>
			{loading && <SimpleLoader />}
			<form onSubmit={handleSubmit}>
				<div className="flex flex-row justify-between">
					<div className="flex flex-col">
						<div className="flex items-center">
							{profileInformation?.billingInfo?.paymentMethods[0]?.expYear && (
								<img src={logo} alt="Start Icon" className="w-6 h-6 mr-2" />
							)}
							<span className="mr-2">
								{profileInformation?.billingInfo?.paymentMethods[0]?.expYear &&
									'****'}
								{profileInformation?.billingInfo?.paymentMethods[0]?.last4}
							</span>
						</div>
					</div>
					<div className="flex flex-col justify-end">
						<span className="text-right">
							{profileInformation?.billingInfo?.paymentMethods[0]?.expMonth}
							{profileInformation?.billingInfo?.paymentMethods[0]?.expYear &&
								'/'}
							{profileInformation?.billingInfo?.paymentMethods[0]?.expYear}
						</span>
					</div>
				</div>
				<div className="flex justify-between items-center pt-4">
					<label htmlFor="name" className="block text-gray-700 font-bold pt-3">
						Name
					</label>
					<input
						type="text"
						id="name"
						className="bg-white pt-3 rounded-lg text-right"
						placeholder="Enter your name"
						disabled // Disable the input field
						value={name}
					/>
				</div>

				<div className="flex justify-between items-center pt-2">
					<label htmlFor="email" className="block text-gray-700 font-bold ">
						Email
					</label>
					<input
						type="email"
						id="email"
						className="bg-white text-right w-full rounded-lg"
						placeholder="Enter your email"
						disabled // Disable the input field
						value={email}
					/>
				</div>

				<div className="flex justify-between items-center pt-2">
					<label htmlFor="name" className="block text-gray-700 font-bold">
						Total Minutes
					</label>
					<input
						type="text"
						id="name"
						className="bg-white rounded-lg text-right"
						placeholder="1021"
						disabled // Disable the input field
						value={totalMinutes.toFixed(2)}
					/>
				</div>
				<div className="flex justify-between items-center pt-2">
					<label htmlFor="name" className="block text-gray-700 font-bold">
						Per Minute Cost
					</label>
					<input
						type="text"
						id="name"
						className="bg-white  rounded-lg text-right"
						placeholder="$14.99"
						disabled // Disable the input field
					/>
				</div>

				<div className="flex justify-between items-center pt-2">
					<label htmlFor="name" className="block text-gray-700 font-bold ">
						Total Cost
					</label>
					<input
						type="text"
						id="name"
						className="bg-white rounded-lg text-right"
						placeholder="$200"
						disabled // Disable the input field
						value={'$' + (totalMinutes * 14.99).toFixed(2)}
					/>
				</div>
				<div className="flex justify-center items-center mt-5">
					<button
						type="submit"
						disabled={!stripe}
						className="w-full mt-5 bg-[#6db7f2] hover:bg-blue-400 text-white font-bold py-4 rounded-lg   sm:ml-0"
					>
						Pay
					</button>
				</div>
			</form>
		</>
	);
};

const VoiceDubbing = () => {
	const [selectedSrtName, setSelectedSrtName] = useState(null);
	const [selectedSrt, setSelectedSrt] = useState(null);
	const [selectedVideoName, setSelectedVideoName] = useState(null);
	const [selectedVideo, setSelectedVideo] = useState(null);
	const [myFormData, setMyFormData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [youtubeLink, setYoutubeLink] = useState('');
	const [isYoutubeValidLink, setIsYoutubeValidLink] = useState(true);
	const [driveLink, setDriveLink] = useState('');
	const [isDriveValidLink, setIsDriveValidLink] = useState(true);
	const [minutesCount, setMinutesCount] = useState(0);
	const [open, setOpen] = useState(false);

	const hiddenFileInputSrt = useRef(null);
	const hiddenFileInputVideo = useRef(null);

	const { profileInformation } = useSelector((state) => state.user);

	const {
		control,
		handleSubmit,
		register,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
		mode: 'all',
	});

	const handleClick = () => {
		hiddenFileInputSrt.current.click();
	};

	const handleImageUpload = (e) => {
		if (e.target.files && e.target.files[0]) {
			let imgFile = e.target.files[0];
			const truncatedFileName = imgFile.name.substring(0, 10);
			setSelectedSrt(imgFile);
			setSelectedSrtName(truncatedFileName);
		}
	};

	const handleClickVideo = () => {
		hiddenFileInputVideo.current.click();
	};

	const handleVideoUpload = (e) => {
		if (e.target.files && e.target.files[0]) {
			const videoFile = e.target.files[0];
			const truncatedFileName = videoFile.name.substring(0, 10);
			setSelectedVideo(videoFile);
			setSelectedVideoName(truncatedFileName);
		}
	};

	const handleLanguageChange = () => {
		console.log('kkkk');
	};

	const onSubmit = async (data) => {
		console.log(data);
		if (checkEmail(profileInformation?.email)) {
			setMyFormData(data);
			handleDubbing('charge_with_one_saved_card');
		} else if (
			profileInformation?.servicesPayment?.find((el) => el === 'voice-dubbing')
		) {
			setMyFormData(data);
			handleDubbing('not_charge_with_access');
		} else {
			setMyFormData(data);
			console.log(data);
			const sourceLanguage = data?.sourceLanguage?.value;
			const targetLanguage = data?.targetLanguage?.value;
			console.log(sourceLanguage, targetLanguage, data);
			if (sourceLanguage && targetLanguage) {
				setLoading(true);
				try {
					const formData = new FormData();
					formData.append('video', selectedVideo);
					formData.append('youtubeOrVimeoLink', data?.youtubeOrVimioLink);
					formData.append('googleDriveLink', data?.googleDriveLink);
					const response = await api.post(
						'/voice-dubbing/calculate-duration-in-ms',
						formData,
						{
							headers: {
								'Content-Type': 'multipart/form-data',
							},
							responseType: 'json',
						}
					);
					console.log(response.data);
					setMinutesCount(response.data?.duration / 60);
					setOpen(true);
					setLoading(false);
				} catch (error) {
					setLoading(false);
					console.error('Error uploading file:', error);
					createNotification(
						'error',
						'Error',
						'Error Please Check your video or problem in input fields'
					);
				}
			} else {
				createNotification(
					'error',
					'Error',
					'Please Select Source and Target Languages.'
				);
			}
		}
	};

	const handleDubbing = async (paymentId) => {
		setOpen(false);
		const sourceLanguage = myFormData?.sourceLanguage?.value;
		const targetLanguage = myFormData?.targetLanguage?.value;
		console.log(sourceLanguage, targetLanguage, myFormData);
		if (sourceLanguage && targetLanguage) {
			setLoading(true);
			try {
				const formData = new FormData();
				formData.append('video', selectedVideo);
				formData.append('srt', selectedSrt);
				formData.append('sourceLanguage', sourceLanguage);
				formData.append('targetLanguage', targetLanguage);
				formData.append(
					'numberOfSpeakers',
					myFormData?.numberOfSpeakers?.value
				);
				formData.append('youtubeOrVimeoLink', myFormData?.youtubeOrVimioLink);
				formData.append('googleDriveLink', myFormData?.googleDriveLink);
				formData.append('paymentMethodId', paymentId);

				if (myFormData?.isBurn) {
					formData.append('isBurn', myFormData?.isBurn);
				}
				const response = await api.post('/voice-dubbing', formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
					responseType: 'blob',
				});
				const blob = new Blob([response.data], {
					type: response.headers['content-type'],
				});

				// Create a temporary URL to download the file
				const url = window.URL.createObjectURL(blob);

				// Create an anchor element and programmatically click it to trigger the download
				const link = document.createElement('a');
				link.href = url;
				link.download = 'cloned-video'; // Set the desired file name and extension
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);

				// Release the temporary URL
				window.URL.revokeObjectURL(url);
				setLoading(false);
			} catch (error) {
				setLoading(false);
				// Error handling
				if (error.response && error.response.data instanceof Blob) {
					// Use FileReader to read the Blob as text
					const reader = new FileReader();
					reader.onload = function () {
						try {
							// Attempt to parse the text as JSON
							const errorJson = JSON.parse(reader.result);
							// Log or handle the parsed JSON error message
							createNotification('error', 'Error', errorJson.error);
							console.error('Error:', errorJson);
						} catch (e) {
							// If JSON parsing fails, log the error
							console.error('Error reading error response:', e);
						}
					};
					reader.onerror = function (e) {
						// Handle FileReader errors
						console.error('FileReader error:', e);
					};
					// Read the Blob as text
					reader.readAsText(error.response.data);
				} else {
					// If the response is not a Blob or another error occurs
					console.error('Unexpected error:', error);
				}
				console.error('Error uploading file:', error);
			}
		} else {
			setLoading(false);
			createNotification(
				'error',
				'Error',
				'Please Select Source and Target Languages.'
			);
		}
	};

	return (
		<>
			{loading && <Loader loading={loading} />}
			<Box className="bg-[#f8f8f8] text-trans">
				<form onSubmit={handleSubmit(onSubmit)}>
					<Container className="mt-5">
						<div className="bg-white flex flex-col">
							<div className="flex border-b-2">
								<div className="sm:hidden xs:block">
									<CustomSelect
										name="sourceLanguage"
										control={control}
										options={awsTranscribeLanguages.map((country) => ({
											label: country.name,
											value: country.code,
										}))}
										placeholder={'Source Language'}
										handleChange={handleLanguageChange}
										className="border-0 rounded-0 z-30"
									/>
								</div>
								<div className="sm:flex xs:hidden">
									<CustomSelect
										name="sourceLanguage"
										control={control}
										options={awsTranscribeLanguages.map((country) => ({
											label: country.name,
											value: country.code,
										}))}
										placeholder={'Select Source Language'}
										handleChange={handleLanguageChange}
										className="border-0 rounded-0 z-30"
									/>
								</div>
								<div className="sm:hidden xs:block">
									<CustomSelect
										name="targetLanguage"
										control={control}
										options={googleLiveVoiceToTextLanguages.map((country) => ({
											label: country.name,
											value: country.code,
										}))}
										placeholder={'Target Language'}
										handleChange={handleLanguageChange}
										className="border-0 rounded-0 z-30"
									/>
								</div>
								<div className="sm:flex xs:hidden">
									<CustomSelect
										name="targetLanguage"
										control={control}
										options={googleLiveVoiceToTextLanguages.map((country) => ({
											label: country.name,
											value: country.code,
										}))}
										placeholder={'Select Target Language'}
										handleChange={handleLanguageChange}
										className="border-0 rounded-0 z-30"
									/>
								</div>
							</div>

							<div className="rounded-lg shadow-lg flex flex-col justify-center items-center pb-10 z-10 gap-3">
								<div className="p-5 bg-white">
									<div className="text-3xl text-gray-600 ">Voice Dubbing</div>
								</div>
								<div className="flex lgx:flex-row xs:flex-col gap-5 mx-12">
									<div className="flex flex-col justify-center items-center">
										<p className="border-2 border-custom-blue xm:w-96 xs:w-60 xm:h-32 xs:h-28 rounded-lg border-dashed flex items-center justify-center">
											Upload srt file: {selectedSrtName}
										</p>
										<button
											className="bg-custom-blue text-white rounded-lg p-4 mt-5"
											onClick={handleClick}
											type="button"
										>
											Browse your computer
										</button>
										<input
											type="file"
											ref={hiddenFileInputSrt}
											onChange={handleImageUpload}
											style={{ display: 'none' }}
											accept=".srt"
										/>
									</div>
									<div className="flex flex-col justify-center items-center">
										<p className="border-2 border-custom-blue xm:w-96 xs:w-60 xm:h-32 xs:h-28 rounded-lg border-dashed flex items-center justify-center">
											Upload video file: {selectedVideoName}
										</p>
										<button
											className="bg-custom-blue text-white rounded-lg p-4 mt-5"
											onClick={handleClickVideo}
											type="button"
										>
											Browse your computer
										</button>
										<input
											type="file"
											ref={hiddenFileInputVideo}
											onChange={handleVideoUpload}
											style={{ display: 'none' }}
											accept="video/mp4"
										/>
									</div>
									<div className="">
										<div
											className={`flex flex-col justify-between ${
												errors.youtubeOrVimioLink || errors.googleDriveLink
													? 'gap-1'
													: 'gap-5'
											}`}
										>
											<input
												className={`bg-white text-left text-custom-blue px-2 py-3 border-2 ${
													errors.youtubeOrVimioLink
														? 'border-red-400'
														: 'border-grey-100'
												} rounded-lg`}
												type="text"
												placeholder="Input a youtube or vimeo link"
												{...register('youtubeOrVimioLink')}
											/>
											{errors.youtubeOrVimioLink && (
												<p className="text-red-600">
													{errors.youtubeOrVimioLink.message}
												</p>
											)}
											<input
												className={`bg-white text-left text-custom-blue px-2 py-3 border-2 ${
													errors.googleDriveLink
														? 'border-red-400'
														: 'border-grey-100'
												} rounded-lg`}
												type="text"
												placeholder="Input Google drive link"
												{...register('googleDriveLink')}
											/>
											{errors.googleDriveLink && (
												<p className="text-red-600">
													{errors.googleDriveLink.message}
												</p>
											)}
										</div>
										<div>
											<button type="button" className="hidden">
												hidden
											</button>
										</div>
									</div>
								</div>
								<div>
									<FormGroup>
										<FormControlLabel
											control={<Checkbox />}
											label="Burn in Subtitles"
											{...register('isBurn')}
										/>
									</FormGroup>
								</div>
								<div className="flex md:flex-row xs:flex-col justify-center items-center gap-5">
									<CustomSelect
										control={control}
										name="numberOfSpeakers"
										options={options}
										className={`border-2 ${
											errors.numberOfSpeakers
												? 'border-red-400'
												: 'border-grey-100'
										}  rounded-lg xs:w-60 xm:w-full`}
										placeholder="Number of speakers in video"
										errors={errors}
									/>
									{/* <CustomSelect
										control={control}
										name="gender"
										options={optionsGender}
										className={`border-2 ${
											errors.gender ? 'border-red-400' : 'border-grey-100'
										}  rounded-lg`}
										placeholder="Select gender"
										errors={errors}
									/> */}
									<button
										type="submit"
										className="bg-custom-blue text-white rounded-lg w-32 h-12"
									>
										Submit
									</button>
								</div>
							</div>
							<Box>
								<div className="text-center pt-10">
									<p className="text-lg text-[#00000099]">
										Secure payment transactions are facilitated through
									</p>
									<div className="flex justify-center items-center">
										<img src={stripe} alt="stripe" width={150} />
									</div>
								</div>
							</Box>
						</div>
					</Container>
				</form>
			</Box>
			{minutesCount && (
				<Modal
					title={'Payment'}
					open={open}
					handleClose={() => setOpen(false)}
					bodyTopMargin={1}
				>
					<CheckoutForm
						name={profileInformation?.firstName}
						email={profileInformation?.email}
						totalMinutes={minutesCount}
						handleDubbing={handleDubbing}
					/>
				</Modal>
			)}
		</>
	);
};

export default VoiceDubbing;

const schema = Yup.object().shape({
	numberOfSpeakers: Yup.object().shape({
		value: Yup.string().required('Number of Speakers in video is required'),
		label: Yup.string().required('Number of Speakers in video is required'),
	}),

	// youtubeOrVimioLink: Yup.string().matches(
	// 	/^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be|vimeo\.com)\/.+/,
	// 	'Please enter a valid YouTube or Vimeo link'
	// ),
	// googleDriveLink: Yup.string().matches(
	// 	/^(https?:\/\/)?(www\.)?(drive\.google\.com\/file\/d\/[\w-]+\/?)/,
	// 	'Please enter a valid Google Drive link'
	// ),
});
