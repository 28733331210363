import { Modal } from '@mui/material';
import React, { useState, useEffect } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSelector } from 'react-redux';
import { createNotification } from 'utils/create-notification';
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import Paper from '@mui/material/Paper';

import stripe from 'assets/stripe.png';
import Translation from 'pages/Translation';
import { useParams } from 'react-router-dom';
import api from 'utils/axios';
import Loader from 'component/loader';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 320,
			md: 600,
			lg: 960,
			xl: 1280,
		},
	},
});

const Refferel = () => {
	const { profileInformation } = useSelector((state) => state.user);
	const [url, seturl] = useState(
		`https://www.translation.letz.chat/login?utm_source=${profileInformation._id}`
	);
	const [loading, setLoading] = useState(true);
	const [userDetail, setUserDetail] = useState([]);

	function copyToClipboard() {
		navigator.clipboard
			.writeText(url)
			.then(() => {
				createNotification('success', 'Success', 'Text copied');
			})
			.catch((err) => {});
	}

	useEffect(() => {
		api
			.get(`/users/agents/getAllUsersByLinkedAgent/${profileInformation._id}`)
			.then((res) => {
				setLoading(true);
				setUserDetail(res?.data.users);
				res?.data.users?.map((userDetail) => {
					console.log({
						x:
							userDetail?.imageSubscriptionPayment?.customerId ||
							userDetail?.billingInfo?.subscriptions?.find(
								(sub) => sub.type === 'image'
							)?.status === 'active',
						y: userDetail?.imageSubscriptionPayment?.customerId,
						z:
							userDetail?.billingInfo?.subscriptions?.find(
								(sub) => sub.type === 'image'
							)?.status === 'active',
						xy: userDetail,
					});
				});

				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
			});
	}, []);

	return (
		<>
			<div className="mx-16 pb-0  flex lm:flex-row xs:flex-col justify-between items-center">
				<p className="">Referral URL</p>
				<p className="text-custom-blue text-center flex justify-center">
					{url}
				</p>
				<p onClick={copyToClipboard}>
					<ContentCopyIcon />
				</p>
			</div>
			<div className="mt-10 mx-10">
				<p className="mb-5 text-custom-blue xm:text-lg xs:text-sm text-center">
					Referred Users
				</p>
				{userDetail?.length !== 0 ? (
					<ThemeProvider theme={theme}>
						<TableContainer component={Paper}>
							<Table sx={{ minWidth: 650 }} aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell
											sx={{ paddingLeft: { xs: 2, md: 5, lg: 7, xl: 10 } }}
										>
											First Name
										</TableCell>
										<TableCell align="left">Last Name</TableCell>
										<TableCell align="left">Email</TableCell>
										<TableCell align="left">Image Subscription</TableCell>
										<TableCell align="left">Website Subscription</TableCell>
										<TableCell align="left">Podcast Subscription</TableCell>
										<TableCell align="left">Zoom Subscription</TableCell>
										<TableCell align="left">Unite Subscription</TableCell>
										<TableCell align="left">Voice Dubbing</TableCell>
										<TableCell align="left">Document Translation</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{userDetail?.map((userDetail) => (
										<TableRow
											key={userDetail._id}
											sx={{
												'&:last-child td, &:last-child th': { border: 0 },
											}}
										>
											<TableCell
												component="th"
												scope="row"
												sx={{ paddingLeft: 10 }}
											>
												{userDetail?.firstName}
											</TableCell>
											<TableCell align="left">{userDetail?.lastName}</TableCell>
											<TableCell align="left">{userDetail?.email}</TableCell>
											<TableCell align="left">
												{userDetail?.imageSubscriptionPayment?.customerId ||
												userDetail?.billingInfo?.subscriptions?.find(
													(sub) => sub.itemName === 'image'
												)?.status === 'active'
													? 'Yes'
													: 'No'}
											</TableCell>
											<TableCell align="left">
												{userDetail?.webpageSubscriptionPayment?.customerId ||
												userDetail?.billingInfo?.subscriptions?.find(
													(sub) => sub.itemName === 'webpage'
												)?.status === 'active'
													? 'Yes'
													: 'No'}
											</TableCell>
											<TableCell align="left">
												{userDetail?.billingInfo?.subscriptions?.find(
													(sub) => sub.itemName === 'podcast-translation'
												)?.status === 'active'
													? 'Yes'
													: 'No'}
											</TableCell>
											<TableCell align="left">
												{userDetail?.billingInfo?.subscriptions?.find(
													(sub) => sub.itemName === 'zoom-subtitles'
												)?.status === 'active'
													? 'Yes'
													: 'No'}
											</TableCell>
											<TableCell align="left">
												{userDetail?.billingInfo?.subscriptions?.find(
													(sub) => sub.itemName === 'unite'
												)?.status === 'active'
													? 'Yes'
													: 'No'}
											</TableCell>
											<TableCell align="left">
												{userDetail?.dubbing?.length}
											</TableCell>
											<TableCell align="left">
												{userDetail?.translations?.length}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</ThemeProvider>
				) : (
					<div className="py-2 pl-4">No Record Found</div>
				)}
			</div>
		</>
	);
};

export default Refferel;
