import './App.css';
import Routes from './routes';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import SocketManager from './services/SocketManager';

const App = () => {
	useEffect(() => {
		return () => {
			SocketManager.disconnect();
		};
	}, []);

	const [loader, setLoader] = useState(false);
	const { token } = useSelector((state) => state.user);
	return (
		<>
			<Router>
				<Routes token={token} loader={loader} />
			</Router>
		</>
	);
};
//only for commit
export default App;
