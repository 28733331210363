import React from 'react';
import styles from './minimal-loader.module.scss';

// eslint-disable-next-line react/prop-types
const MinimalLoader = ({ color, className }) => {
	return (
		<div
			className={`${styles.loader} ${className}`}
			style={{
				borderTop: `3px solid ${color}`,
			}}
		></div>
	);
};

export default MinimalLoader;
