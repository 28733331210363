import React, { useEffect, useRef, useState } from 'react';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import * as io from 'socket.io-client';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import CustomSelect from 'component/CustomSelect';
import { useForm } from 'react-hook-form';
import { uniteLanguages } from 'constants/languages';
import api from 'utils/axios';
import Loader from 'component/loader';
import MinimalLoader from 'component/MinimalLoader';
import { createNotification } from 'utils/create-notification';
import PublicHeader from 'component/PublicHeader';
import ReactSwitch from 'react-switch';
import autoPlayAudio from 'assets/autoplay.mp3';
import style from './scrollbar.module.scss';
import CircleLoader from 'component/NewLoader/NewLoader';

const getMediaStream = () =>
	navigator.mediaDevices.getUserMedia({
		audio: {
			deviceId: 'default',
			sampleRate: 16000,
			sampleSize: 16,
			channelCount: 1,
		},
		video: false,
	});

let deviceLanguage = navigator.language || navigator.userLanguage;
deviceLanguage = uniteLanguages.find((el) => el.code === deviceLanguage);

if (!deviceLanguage || deviceLanguage === 'undefined') {
	deviceLanguage = { name: 'English (United States)', code: 'en-US' };
}

const UnitePage = () => {
	const socketRef = useRef();
	let { room } = useParams();
	const [messages, setMessages] = useState([]);
	const [connection, setConnection] = useState();
	const [socketConnection, setSocketConnection] = useState();
	const [currentRecognition, setCurrentRecognition] = useState();
	const [recognitionHistory, setRecognitionHistory] = useState([]);
	const [autoPlay, setAutoPlay] = useState(false);
	const [isRecording, setIsRecording] = useState(false);
	const [recorder, setRecorder] = useState();
	const [inputFieldState, setInputFieldState] = useState('');
	const [isStreamLoading, setIsStreamLoading] = useState(false);
	const [receivedQuestions, setReceivedQuestions] = useState([]);
	const [uniqueLanguages, setUniqueLanguages] = useState(1);
	const [totalLanguages, setTotalLanguages] = useState({});
	const [loading, setLoading] = useState(1);
	const [dbMessages, setDbMessages] = useState([]);
	const [dbQuestions, setDbQuestions] = useState([]);
	const [audioQueue, setAudioQueue] = useState([]);
	const [messagePage, setMessagePage] = useState(1);
	const [getMessagesLoading, setGetMessagesLoading] = useState(false);
	const [questionPage, setQuestionPage] = useState(1);
	const [getQuestionsLoading, setGetQuestionsLoading] = useState(false);
	const [hasMoreQuestions, setHasMoreQuestions] = useState(true);
	const [hasMoreMessages, setHasMoreMessages] = useState(true);

	const processorRef = useRef();
	const audioContextRef = useRef();
	const audioInputRef = useRef();
	const inputRef = useRef();
	const autoPlayRef = useRef(autoPlay);
	const audioRef = useRef(new Audio());

	useEffect(() => {
		autoPlayRef.current = autoPlay;
	}, [autoPlay]);

	const { control, handleSubmit, getValues, watch } = useForm({
		defaultValues: {
			sourceLanguage: {
				value: deviceLanguage?.code,
				label: deviceLanguage?.name,
			},
		},
	});

	const messageRef = useRef(null);
	const questionRef = useRef(null);

	const handleMessageScroll = () => {
		if (messageRef.current && hasMoreMessages) {
			const { scrollTop, scrollHeight, clientHeight } = messageRef.current;
			if (scrollTop + clientHeight >= scrollHeight - 10) {
				setGetMessagesLoading(true);
				getMessages(messagePage + 1);
			}
		}
	};

	useEffect(() => {
		const transcriptElement = messageRef.current;
		if (transcriptElement) {
			transcriptElement.addEventListener('scroll', handleMessageScroll);
		}
		return () => {
			if (transcriptElement) {
				transcriptElement.removeEventListener('scroll', handleMessageScroll);
			}
		};
	}, [messagePage, getMessagesLoading]);

	const handleQuestionScroll = () => {
		if (questionRef.current && hasMoreQuestions) {
			const { scrollTop, scrollHeight, clientHeight } = questionRef.current;
			if (scrollTop + clientHeight >= scrollHeight - 10) {
				setGetQuestionsLoading(true);
				getQuestions(questionPage + 1);
			}
		}
	};

	useEffect(() => {
		const questionElement = questionRef.current;
		if (questionElement) {
			questionElement.addEventListener('scroll', handleQuestionScroll);
		}
		return () => {
			if (questionElement) {
				questionElement.removeEventListener('scroll', handleQuestionScroll);
			}
		};
	}, [questionPage, getQuestionsLoading]);

	useEffect(() => {
		if (watch('sourceLanguage')?.value !== deviceLanguage?.code) {
			setLoading(true);
			getMessages();
			getQuestions();
			setMessages([]);
			setDbQuestions([]); // Reset dbQuestions
			setQuestionPage(1); // Reset question page
			setHasMoreQuestions(true); // Reset hasMoreQuestions
			// ... rest of the existing code ...
		}
	}, [watch('sourceLanguage')?.value]);

	const getMessages = async (pageNum = 1, limit = 5) => {
		if (getMessagesLoading) return;
		try {
			setGetMessagesLoading(true);
			api
				.get(
					`unite/get-messages/${room}/${
						watch('sourceLanguage')?.value
					}?page=${pageNum}&limit=${limit}`
				)
				.then((res) => {
					setGetMessagesLoading(false);
					if (pageNum === 1) {
						setDbMessages(res.data.messages || {});
					} else {
						setDbMessages((prev) => [...prev, ...(res.data.messages || {})]);
					}
					setMessagePage(pageNum);
					if (res.data.messages.length < limit) {
						setHasMoreMessages(false);
					}
				})
				.catch((error) => {
					setLoading(false);
					console.log('Error in getting Db Messages', error);
				});
		} catch (error) {
			setLoading(false);
			console.log('Error in getting Db Messages');
		}
	};

	const getQuestions = async (pageNum = 1, limit = 5) => {
		if (getQuestionsLoading || !hasMoreQuestions) return;
		try {
			setGetQuestionsLoading(true);
			api
				.get(
					`unite/get-questions/${room}/${
						watch('sourceLanguage')?.value
					}?page=${pageNum}&limit=${limit}`
				)
				.then((res) => {
					setGetQuestionsLoading(false);
					if (pageNum === 1) {
						setDbQuestions(res.data.questions || []);
					} else {
						setDbQuestions((prev) => [...prev, ...(res.data.questions || [])]);
					}
					setQuestionPage(pageNum);

					// Check if we've reached the end of the questions
					if (res.data.questions.length < limit) {
						setHasMoreQuestions(false);
					}
				})
				.catch((error) => {
					setGetQuestionsLoading(false);
					console.log('Error in getting Db Questions', error);
				});
		} catch (error) {
			setGetQuestionsLoading(false);
			console.log('Error in getting Db Questions');
		}
	};
	useEffect(() => {
		// eslint-disable-next-line no-undef
		socketRef.current = io.connect('https://api.unite.letz.chat', {
			reconnection: true,
		});

		socketRef.current.on('connect', () => {
			console.log('connected', socketRef.current.id);
			console.log('Room:', room, watch('sourceLanguage'));
			socketRef.current.emit('join_room_unite', room, watch('sourceLanguage'));
			setSocketConnection(socketRef.current);
		});

		socketRef.current.on('reconnect', (attemptNumber) => {
			socketRef.current.emit('join_room_unite', room, watch('sourceLanguage'));
			console.log('Socket reconnected on attempt:', attemptNumber);
		});

		socketRef.current.on('update_languages', (languages) => {
			// Create a map to count occurrences of each language label
			const languageCountMap = languages.reduce((acc, curr) => {
				const key = curr.label;
				acc[key] = (acc[key] || 0) + 1;
				return acc;
			}, {});

			// Set the count map as the new state
			setTotalLanguages(languageCountMap);
			const uniqueArray = languages.filter(
				(item, index, self) =>
					index === self.findIndex((t) => t.value === item.value)
			);

			setUniqueLanguages(uniqueArray);
		});
		socketRef.current.on('receive_message_unite', (incomingMessage) => {
			//here why autoplay is false when i do it true
			if (autoPlayRef.current) {
				const audioData = incomingMessage?.audio; // Base64 encoded audio data
				const audioByteArray = Uint8Array.from(atob(audioData), (c) =>
					c.charCodeAt(0)
				);
				const audioBlob = new Blob([audioByteArray], {
					type: 'audio/mp3',
				});
				const audioUrl = URL.createObjectURL(audioBlob);
				setAudioQueue((prevQueue) => [...prevQueue, audioUrl]);
				// const audio = new Audio(audioUrl);
				// audio.play();
			}
			setMessages((prev) => [incomingMessage, ...prev]);
		});
		setLoading(true);
		getMessages();
		getQuestions();
		return () => {
			socketRef.current.disconnect();
		};
	}, []);

	useEffect(() => {
		const playNextAudio = () => {
			if (audioQueue.length === 0) return;
			audioRef.current.src = audioQueue[0];
			audioRef.current.play();
			audioRef.current.onended = () => {
				setAudioQueue((prevQueue) => prevQueue.slice(1));
			};
		};

		console.log(audioRef.current.paused);

		if (audioRef.current.paused) {
			playNextAudio();
		}
	}, [audioQueue]);

	useEffect(() => {
		//Auto play
		audioRef.current.src = autoPlayAudio;
		audioRef.current.play();
	}, [autoPlay]);

	const questionDownloadHandler = async () => {
		try {
			setLoading(true);
			const response = await api.get(
				`unite/download-questions/${room}/${watch('sourceLanguage').value}`
			);

			const blob = new Blob([response.data], {
				type: response.headers['content-type'],
			});

			// Create a temporary URL to download the file
			const url = window.URL.createObjectURL(blob);

			// Create an anchor element and programmatically click it to trigger the download
			const link = document.createElement('a');
			link.href = url;
			link.download = `question-${room}`; // Set the desired file name and extension
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);

			// Release the temporary URL
			window.URL.revokeObjectURL(url);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.error('An error occurred in downloadTranscriptHandler:', error);
		}
	};

	useEffect(() => {
		(async () => {
			if (connection) {
				if (isRecording) {
					return;
				}

				const stream = await getMediaStream();

				audioContextRef.current = new window.AudioContext();

				await audioContextRef.current.audioWorklet.addModule(
					'/src/worklets/recorderWorkletProcessor.js'
				);

				audioContextRef.current.resume();

				audioInputRef.current =
					audioContextRef.current.createMediaStreamSource(stream);

				processorRef.current = new AudioWorkletNode(
					audioContextRef.current,
					'recorder.worklet'
				);

				processorRef.current.connect(audioContextRef.current.destination);
				audioContextRef.current.resume();

				audioInputRef.current.connect(processorRef.current);

				processorRef.current.port.onmessage = () => {
					const audioData = event.data;
					connection.emit('send_audio_data', { audio: audioData });
				};
				setIsRecording(true);
			} else {
				console.error('No connection');
			}
		})();
		return () => {
			if (isRecording) {
				processorRef.current?.disconnect();
				audioInputRef.current?.disconnect();
				if (audioContextRef.current?.state !== 'closed') {
					audioContextRef.current?.close();
				}
			}
		};
	}, [connection, isRecording, recorder]);

	useEffect(() => {
		if (watch('sourceLanguage')?.value !== deviceLanguage?.code) {
			setLoading(true);
			getMessages();
			getQuestions();
			setMessages([]);
			if (socketRef.current) {
				socketRef.current.emit(
					'join_room_unite',
					room,
					watch('sourceLanguage')
				); // Update language when it changes
			}
			if (isRecording) {
				disconnect();
				connect();
			}
		}
	}, [watch('sourceLanguage')?.value]);

	useEffect(() => {
		if (
			`${recognitionHistory
				?.map((el) => el.text)
				?.reverse()
				.join(' ')} ${currentRecognition}` !== ' undefined'
		) {
			setInputFieldState(
				`${recognitionHistory
					?.map((el) => el.text)
					?.reverse()
					.join(' ')} ${currentRecognition}`
			);
		}

		if (inputRef.current) {
			inputRef.current.scrollLeft = inputRef.current.scrollWidth;
		}
	}, [recognitionHistory, currentRecognition]);

	useEffect(() => {
		socketRef.current.on('receive_question_unite', (translatedQuestion) => {
			setReceivedQuestions((prev) => [translatedQuestion, ...prev]);
		});
	}, []);

	const speechRecognized = (data) => {
		if (data.isFinal) {
			setCurrentRecognition('');
			if (data.text) {
				setRecognitionHistory((old) => [
					{ text: data.text, time: new Date() },
					...old,
				]);
			}
		} else setCurrentRecognition(data.text);
	};

	const connect = () => {
		setIsStreamLoading(true);
		setCurrentRecognition('');
		setRecognitionHistory([]);
		setTimeout(() => {
			setIsStreamLoading(false);
		}, 2000);

		setConnection(socketConnection);

		socketRef.current.emit(
			'startGoogleCloudStream',
			watch('sourceLanguage')?.value
		);

		socketRef.current.on('receive_audio_text', (data) => {
			speechRecognized(data);
		});
	};

	const disconnect = () => {
		if (!connection) return;
		connection?.emit('endGoogleCloudStream');
		// connection?.disconnect();
		processorRef.current?.disconnect();
		audioInputRef.current?.disconnect();
		audioContextRef.current?.close();
		setConnection(undefined);
		setRecorder(undefined);
		setIsRecording(false);
		socketRef.current.off('receive_audio_text');
	};

	const onSubmit = (e) => {
		e.preventDefault();
		if (inputFieldState.trim()) {
			socketRef.current.emit(
				'send_question_unite',
				{ text: inputFieldState, time: new Date() },
				room,
				watch('sourceLanguage')
			);
			disconnect();
			setInputFieldState('');
			setRecognitionHistory([]);
			setCurrentRecognition('');
		} else {
			createNotification('error', 'Error', 'Please Talk or Write');
		}
	};

	const inputChangeHandler = async (e) => {
		setInputFieldState(e.target.value);
	};

	const playAllHandler = async () => {
		if (dbMessages?.length > 0) {
			for (let i = dbMessages?.length - 1; i >= 0; i--) {
				const el = dbMessages[i];
				await new Promise((resolve) => {
					const audioData = el?.audio; // Base64 encoded audio data
					const audioByteArray = Uint8Array.from(atob(audioData), (c) =>
						c.charCodeAt(0)
					);
					const audioBlob = new Blob([audioByteArray], { type: 'audio/mp3' });
					const audioUrl = URL.createObjectURL(audioBlob);
					const audio = new Audio(audioUrl);
					audio.addEventListener('ended', resolve); // Resolve the promise when the audio ends
					audio.play();
				});
			}
		}
		if (messages?.length > 0) {
			for (let i = messages?.length - 1; i >= 0; i--) {
				const el = messages[i];
				await new Promise((resolve) => {
					const audioData = el?.audio; // Base64 encoded audio data
					const audioByteArray = Uint8Array.from(atob(audioData), (c) =>
						c.charCodeAt(0)
					);
					const audioBlob = new Blob([audioByteArray], { type: 'audio/mp3' });
					const audioUrl = URL.createObjectURL(audioBlob);
					const audio = new Audio(audioUrl);
					audio.addEventListener('ended', resolve); // Resolve the promise when the audio ends
					audio.play();
				});
			}
		}
	};

	return (
		<>
			{/* {loading && <Loader />} */}
			<PublicHeader />
			<div className="flex flex-col w-full justify-center">
				<h1 className="text-4xl text-gray-600 font-[600] flex items-center justify-center my-12">
					Unite
				</h1>
				<div className="bg-white rounded-3xl md:mx-20 xs:mx-8 z-10 shadow-lg">
					<CustomSelect
						control={control}
						name="sourceLanguage"
						options={uniteLanguages.map((country) => ({
							label: country.name,
							value: country.code,
						}))}
						defaultValue={{
							value: deviceLanguage?.code,
							label: deviceLanguage?.name,
						}}
						placeholder="Select source language"
						className="border-b-2 border-grey-300 xs:w-[60%] md:w-[20%]"
					/>
					<div
						className="flex items-center justify-between  gap-5 relative w-full"
						style={{ textAlign: 'center' }}
					>
						<div></div>
						<div className="flex items-center gap-5 px-8">
							<span className="text-custom-blue font-bold">Auto Play</span>
							<ReactSwitch
								className=""
								// disabled={isRecording && isStreamLoading}
								checked={autoPlay}
								onChange={() => setAutoPlay(!autoPlay)}
							/>
						</div>
					</div>
					<div className="bg-[#E5F3FF] md:mx-20 my-4 xs:mx-2 flex flex-col justify-center items-center gap-6 py-8 rounded-2xl">
						<p className="text-xl">Live Transcript</p>
						<p className="md:text-2xl xs:text-lg text-center">
							{uniqueLanguages?.length} Different Languages in Room
						</p>
						<button
							onClick={playAllHandler}
							className="w-32 bg-sky-300 ml-2 border rounded-lg text-lg"
						>
							Play All
						</button>
						<div
							className="text-xl gap-4 flex flex-col max-h-48"
							id={style.scrollbar}
							ref={messageRef}
						>
							<div className="flex flex-col gap-5">
								{messages?.map((el, index) => {
									return (
										<p
											key={index}
											className="md:text-2xl xs:text-lg flex lg:flex-row xs:flex-col justify-between items-center gap-2"
										>
											<div className="flex flex-row justify-between gap-12 w-full">
												<p className="text-center">{el?.text}</p>
												<p className="flex gap-4">
													{moment(el?.time).format('M/D/YY h:mm:ssA')}
												</p>
											</div>
											<button
												className="w-32 bg-sky-300 ml-2 border rounded-lg text-lg"
												onClick={() => {
													const audioData = el?.audio; // Base64 encoded audio data
													const audioByteArray = Uint8Array.from(
														atob(audioData),
														(c) => c.charCodeAt(0)
													);
													const audioBlob = new Blob([audioByteArray], {
														type: 'audio/mp3',
													});
													const audioUrl = URL.createObjectURL(audioBlob);
													const audio = new Audio(audioUrl);
													audio.play();
												}}
											>
												Play
											</button>
										</p>
									);
								})}
								{dbMessages?.map((el, index) => {
									return (
										<>
											<p
												key={index}
												className="md:text-2xl xs:text-lg flex lg:flex-row xs:flex-col justify-between items-center gap-2"
											>
												<div className="flex flex-row justify-between gap-12 w-full">
													<p className="text-center">{el?.text}</p>
													<p className="flex gap-4 justify-end">
														{moment(el?.time).format('M/D/YY h:mm:ssA')}
													</p>
												</div>
												<button
													className="w-32 bg-sky-300 ml-2 border rounded-lg text-lg"
													onClick={() => {
														const audioData = el?.audio; // Base64 encoded audio data
														const audioByteArray = Uint8Array.from(
															atob(audioData),
															(c) => c.charCodeAt(0)
														);
														const audioBlob = new Blob([audioByteArray], {
															type: 'audio/mp3',
														});
														const audioUrl = URL.createObjectURL(audioBlob);
														const audio = new Audio(audioUrl);
														audio.play();
													}}
												>
													Play
												</button>
											</p>
										</>
									);
								})}
							</div>
							<div className="flex justify-center mt-24">
								{getMessagesLoading && <CircleLoader />}
							</div>
						</div>
					</div>
				</div>
				<div className="flex lgmm:flex-row xs:flex-col justify-between lgmm:items-start xs:items-center md:mx-40 xs:mx-8 gap-2 mt-12">
					<div className="min-h-[300px] w-full">
						<form
							onSubmit={onSubmit}
							className="flex lm:flex-row xs:flex-col justify-center gap-5 items-center bg-white border-2 rounded-xl border-custom-blue p-12 min-h-[300px]"
						>
							<input
								value={inputFieldState}
								onChange={inputChangeHandler}
								type="text"
								name="ask a question"
								placeholder="Ask a question"
								className="p-4 pr-10 border border-custom-blue rounded-lg sm:w-96 xs:w-60 relative"
								ref={inputRef}
							/>

							{isStreamLoading ? (
								<MinimalLoader
									className={
										'absolute lm:ml-44 lm:mb-0 sm:ml-80 sm:mb-20 xs:mb-20 xs:ml-48 '
									}
									color={'grey'}
								/> // Replace with your actual loader component
							) : (
								<KeyboardVoiceIcon
									onClick={isRecording ? disconnect : connect}
									sx={{
										color: `${isRecording ? 'red' : 'grey'}`,
										fontSize: '35px',
									}}
									className="absolute lm:ml-44 lm:mb-0 sm:ml-80 sm:mb-20 xs:mb-20 xs:ml-48 cursor-pointer"
								/>
							)}

							<button
								type="submit"
								className="bg-custom-blue text-white rounded-lg py-4 px-12"
							>
								Submit
							</button>
						</form>
					</div>
					<div className="flex flex-col items-center gap-2 bg-white rounded-xl border-2 border-custom-blue p-8 min-h-[300px] w-full">
						<p
							onClick={questionDownloadHandler}
							className="text-xl flex text-center items-center justify-center cursor-pointer hover:text-custom-blue"
						>
							Download Audience Questions
						</p>
						<p className="text-md mb-2 flex items-center justify-center">
							Audience Questions
						</p>
						<div
							className="flex flex-col gap-5 max-h-48"
							id={style.scrollbar}
							ref={questionRef}
						>
							{receivedQuestions?.map((el) => {
								return (
									<>
										<div className="text-md flex xxl:flex-row xs:flex-col justify-between items-center text-custom-blue mb-2">
											<div className="mr-4 text-center break-words w-[40%]">
												{el?.text}
											</div>{' '}
											<div className="text-center break-words w-[30%]">
												{moment(el?.time).format('M/D/YY h:mm:ssA')}
											</div>
											<div className="text-center text-black break-words w-[30%]">
												Orignal language - {el?.orignalLanguage}
											</div>
										</div>
									</>
								);
							})}
							{dbQuestions?.map((el) => {
								return (
									<>
										<div className="text-md flex xxl:flex-row xs:flex-col justify-between items-center text-custom-blue mb-2">
											<div className="mr-4 text-center break-words w-[40%]">
												{el?.text}
											</div>{' '}
											<div className="text-center break-words w-[30%] mr-4">
												{moment(el?.time).format('M/D/YY h:mm:ssA')}
											</div>
											<div className="text-center text-black break-words w-[30%]">
												Orignal language - {el?.orignalLanguage}
											</div>
										</div>
									</>
								);
							})}
							<div className="flex justify-center mt-4">
								{getQuestionsLoading && <CircleLoader />}
							</div>
						</div>
					</div>
					<div className="flex flex-col gap-2 items-center bg-white rounded-xl border-2  border-custom-blue py-6 px-12 min-h-[300px] w-full">
						<p
							onClick={questionDownloadHandler}
							className="text-xl flex text-center items-center justify-center cursor-pointer hover:text-custom-blue"
						>
							Languages in Room
						</p>

						{Object.entries(totalLanguages)?.map(([key, value]) => (
							<div className="text-center" key={key}>
								<span>{key}</span> <span>{value}</span>
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	);
};

export default UnitePage;
