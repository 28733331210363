import React, { useEffect, useState } from 'react';
import {
	Box,
	Container,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import Paper from '@mui/material/Paper';

import stripe from 'assets/stripe.png';
import Translation from 'pages/Translation';
import { useParams } from 'react-router-dom';
import api from 'utils/axios';
import Loader from 'component/loader';

function createData(name, calories, fat, carbs, protein) {
	return { name, calories, fat, carbs, protein };
}
const rows = [
	createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
	createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
	createData('Eclair', 262, 16.0, 24, 6.0),
	createData('Cupcake', 305, 3.7, 67, 4.3),
	createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const DocumentTranslationHistory = () => {
	const [loading, setLoading] = useState(true);
	const [userDetail, setUserDetail] = useState([]);
	const { userId } = useParams();
	useEffect(() => {
		api
			.get(`/history/document-translation/${userId}`)
			.then((res) => {
				setLoading(true);
				setUserDetail(res?.data);
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
			});
	}, []);
	console.log(userDetail);
	return (
		<>
			{loading && <Loader />}
			<Translation />
			<Box>
				<Container>
					<Box className="relative bg-white h-full rounded-lg rounded-br-lg shadow-lg">
						<Grid className="" md={12}>
							<div className="border-b-2 border-grey-100 border-t-0 border-r-0 border-l-0">
								<h2 className="pl-3 py-4 text-[#6B6B6B] text-lg font-medium">
									Document Translation History
								</h2>
							</div>
						</Grid>
						<div>
							{userDetail.length !== 0 ? (
								<TableContainer component={Paper}>
									<Table sx={{ minWidth: 650 }} aria-label="simple table">
										<TableHead>
											<TableRow>
												<TableCell sx={{ paddingLeft: 5 }}>Id</TableCell>
												<TableCell align="left">Words Count</TableCell>
												<TableCell align="left">Translated At</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{userDetail?.map((userDetail) => (
												<TableRow
													key={userDetail._id}
													sx={{
														'&:last-child td, &:last-child th': { border: 0 },
													}}
												>
													<TableCell
														component="th"
														scope="row"
														sx={{ paddingLeft: 5 }}
													>
														{userDetail?._id}
													</TableCell>
													<TableCell align="left">
														{userDetail?.translatedWords}
													</TableCell>
													<TableCell align="left">
														{userDetail?.createdAt}
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							) : (
								<div className="py-2 pl-4">No Record Found</div>
							)}
						</div>
					</Box>

					<Box>
						<div className="text-center pt-10">
							<p className="text-lg text-[#00000099]">
								Secure payment transctions are facilitated through
							</p>
							<div className="flex justify-center items-center">
								<img src={stripe} alt="stripe" width={150} />
							</div>
						</div>
					</Box>
				</Container>
			</Box>
		</>
	);
};

export default DocumentTranslationHistory;
