import React, { useRef } from 'react';
import { Box, Button, Container, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { login, loginWithGoogle, loginWithMicrosoft, signup } from 'store/user';
import Loader from 'component/loader';
import rightCurve from 'assets/right-curve.png';
import googleIcon from 'assets/GOOGLE.png';
import leftCurve from 'assets/left-curve.png';
import leftImage from 'assets/left-img.png';
import logo from 'assets/logo.png';
import { GoogleLogin } from '@react-oauth/google';
import { createNotification } from 'utils/create-notification';

import { useMsalAuthentication, useMsal } from '@azure/msal-react';
import * as msal from '@azure/msal-browser';
import { SignInButton } from 'component/Microsoft-Login/SignInButton';
import { useNavigate } from 'react-router-dom';
import AuthService from 'services/auth-services';

const schema = yup.object().shape({
	firstName: yup.string().required('First Name is required'),
	lastName: yup.string().required('Last Name is required'),
	email: yup
		.string()
		.email('Invalid email format')
		.required('Email is required'),
	password: yup
		.string()
		.required('Password is required')
		.min(8, 'Minimum 8 Characters'),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref('password'), null], 'Passwords must match')
		.required('Confirm Password is required'),
});

const Signup = () => {
	//for testing
	const [loading, setLoading] = React.useState(false);
	const [signupSuccess, setSignupSuccess] = React.useState(false);
	const hiddenElementRef = useRef(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const onSubmit = async (data) => {
		setLoading(true);
		try {
			const response = await AuthService.signUp(data);
			if (response?.data) {
				setSignupSuccess(true);
				await dispatch(login({ email: data.email, password: data.password }));
			}
			setLoading(false);
		} catch (e) {
			console.log(e);
			setLoading(false);
		}
	};

	return (
		<>
			{loading && <Loader />}
			<Box className="h-screen">
				<Box>
					<img
						src={rightCurve}
						className="fixed top-0 right-0"
						alt="right-curve"
					/>
				</Box>
				<Box>
					<img
						src={leftCurve}
						className="fixed bottom-0 left-0 z-0"
						alt="left-curve"
						width={500}
					/>
				</Box>
				<Container>
					<div className="flex items-center justify-center h-screen">
						<div className="grid md:grid-cols-2 sm:grid-cols-1">
							<div className="left-wrapper">
								{' '}
								<img src={leftImage} className="relative" alt="left-img" />{' '}
							</div>

							<div className="right-wrapper flex flex-col justify-center items-center">
								<div className="mb-5 logo-img cursor-pointer">
									<img
										src={logo}
										className="mb-5 relative"
										width={250}
										alt="logo-img"
										onClick={() => navigate('/')}
									/>
								</div>
								<form onSubmit={handleSubmit(onSubmit)} className="form-signin">
									<div className="mb-5">
										<p className="mb-5 text-md">Sign up </p>
										<TextField
											{...register('firstName')}
											id="firstName"
											className="style-input"
											name="firstName"
											label="First Name"
											variant="outlined"
											size="medium"
											error={!!errors.firstName}
											helperText={errors.firstName?.message}
											sx={{
												'& .MuiOutlinedInput-root': {
													'& fieldset': {
														borderColor: 'rgba(126, 139, 182, 0.3);',
														borderRadius: '10px',
														'&:hover fieldset': {
															borderColor: '#6DB7F2',
														},
														'&.Mui-focused fieldset': {
															borderColor: '#6DB7F2',
														},
													},
												},
											}}
										/>
									</div>
									<div className="mb-5">
										<TextField
											{...register('lastName')}
											id="lastName"
											className="style-input"
											name="lastName"
											label="Last Name"
											variant="outlined"
											size="medium"
											error={!!errors.lastName}
											helperText={errors.lastName?.message}
											sx={{
												'& .MuiOutlinedInput-root': {
													'& fieldset': {
														borderColor: 'rgba(126, 139, 182, 0.3);',
														borderRadius: '10px',
														'&:hover fieldset': {
															borderColor: '#6DB7F2',
														},
														'&.Mui-focused fieldset': {
															borderColor: '#6DB7F2',
														},
													},
												},
											}}
										/>
									</div>
									<div className="mb-5">
										<TextField
											{...register('email')}
											id="email"
											className="style-input"
											name="email"
											label="Email"
											variant="outlined"
											size="medium"
											error={!!errors.email}
											helperText={errors.email?.message}
											sx={{
												'& .MuiOutlinedInput-root': {
													'& fieldset': {
														borderColor: 'rgba(126, 139, 182, 0.3);',
														borderRadius: '10px',
														'&:hover fieldset': {
															borderColor: '#6DB7F2',
														},
														'&.Mui-focused fieldset': {
															borderColor: '#6DB7F2',
														},
													},
												},
											}}
										/>
									</div>
									<div className="mb-5">
										<TextField
											{...register('password')}
											id="password"
											className="style-input"
											name="password"
											label="Password"
											type="password"
											size="medium"
											error={!!errors.password}
											helperText={errors.password?.message}
											sx={{
												'& .MuiOutlinedInput-root': {
													'& fieldset': {
														borderColor: 'rgba(126, 139, 182, 0.3);',
														borderRadius: '10px',
														'&:hover fieldset': {
															borderColor: 'green',
														},
														'&.Mui-focused fieldset': {
															borderColor: 'green',
														},
													},
												},
											}}
										/>
									</div>
									<div className="mb-8">
										<TextField
											{...register('confirmPassword')}
											id="confirmPassword"
											className="style-input"
											name="confirmPassword"
											label="Confirm Password"
											variant="outlined"
											type="password"
											size="medium"
											error={!!errors.confirmPassword}
											helperText={errors.confirmPassword?.message}
											sx={{
												'& .MuiOutlinedInput-root': {
													'& fieldset': {
														borderColor: 'rgba(126, 139, 182, 0.3);',
														borderRadius: '10px',
														'&:hover fieldset': {
															borderColor: '#6DB7F2',
														},
														'&.Mui-focused fieldset': {
															borderColor: '#6DB7F2',
														},
													},
												},
											}}
										/>
									</div>
									<div className="mb-3">
										<Button
											variant="contained"
											type="submit"
											className="submit-button"
										>
											Register
										</Button>
									</div>
									<div className="mb-3">
										<Button
											variant="contained"
											type="button"
											className="submit-button"
											onClick={() => navigate('/login')}
										>
											Back to Login
										</Button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</Container>
			</Box>
		</>
	);
};

export default Signup;
