/* eslint-disable no-debugger */
/* eslint-disable react/prop-types */
import { TabContext } from '@mui/lab';
import SyncAltIcon from '@mui/icons-material/SyncAlt';

import { Box, Container, Grid, Tab } from '@mui/material';
import React, { useEffect, useState } from 'react';
import LanguageSelectorHeader from './LanguageSelectorHeader';
import CustomSelect from './CustomSelect';
import languages, { talkEasyLanguages } from '../constants/languages';
import { Repeat } from '@mui/icons-material';

const LanguageSelectorMain = ({
	handleChange,
	control,
	righthandleChange,
	value,
	setValue,
	rightvalue,
	setRightValue,
	selectedLang,
	setLanguageCode,
	getValues,
	isTalkEasy,
	handleLanguageSwitch,
}) => {
	const [languageSelectFor, setLanguageSelectFor] = useState({
		languageFor: '',
		languageToggle: false,
	});
	const [sourceLanguageToggle, setSourceLanguageToggle] = useState(false);
	const [targetLanguageToggle, setTargetLanguageToggle] = useState(false);

	const handleLanguageChange = (e) => {
		setLanguageSelectFor({ ...languageSelectFor, languageToggle: false });
	};

	const handleToggleAndLangSelect = (e) => {
		if (e === 'sourceLanguage') setSourceLanguageToggle(!sourceLanguageToggle);
		if (e === 'targetLanguage') setTargetLanguageToggle(!targetLanguageToggle);

		setLanguageSelectFor({
			languageFor: e,
			languageToggle: !languageSelectFor?.languageToggle,
		});
	};

	// useEffect(() => {
	// 	if (getValues('sourceLanguage')) {
	// 		handleToggleAndLangSelect('sourceLanguage');
	// 	}
	// }, [getValues('sourceLanguage')]);
	// useEffect(() => {
	// 	if (getValues('targetLanguage')) {
	// 		handleToggleAndLangSelect('targetLanguage');
	// 	}
	// }, []);

	return (
		<>
			<Grid
				container
				className="relative bg-white rounded-tl-lg rounded-tr-lg"
				md={12}
			>
				<Grid item md={6} xs={12}>
					<Box className="left-slector">
						<TabContext value={value}>
							<Box className="border-b-2 border-grey-100 border-t-0 border-r-0 border-l-0">
								<LanguageSelectorHeader
									handleChange={handleChange}
									languageSide="sourceLanguage"
									selectedLang={selectedLang?.sourceLang}
									setLanguageToggle={handleToggleAndLangSelect}
									languageToggle={languageSelectFor}
									setLanguageCode={setLanguageCode}
								/>
							</Box>
							{sourceLanguageToggle && (
								<Box>
									<CustomSelect
										name="sourceLanguage"
										control={control}
										options={
											!isTalkEasy
												? languages.map((country) => ({
														label: country.name,
														value: country.code,
												  }))
												: talkEasyLanguages.map((country) => ({
														label: country.name,
														value: country.code,
												  }))
										}
										value={getValues('sourceLanguage')}
										placeholder={'Select Source Language'}
										handleChange={handleLanguageChange}
										setLanguageToggle={handleToggleAndLangSelect}
										className="border-2 border-grey-100 border-t-0 border-l-0 rounded-0"
									/>
								</Box>
							)}
						</TabContext>
					</Box>
				</Grid>
				<Box className="relative ">
					<Tab
						sx={{ marginX: -1.4, fontFamily: 'Poppins', padding: 0 }}
						icon={<SyncAltIcon />}
						style={{
							position: 'absolute',
							top:
								sourceLanguageToggle || targetLanguageToggle ? '31.3%' : '50%',
							left: '49%',
							transform: 'translate(-50%, -50%)',
						}}
						onClick={() => handleLanguageSwitch()}
					/>
				</Box>

				<Grid item md={6} xs={12}>
					<Box className="right-slector">
						<TabContext value={rightvalue}>
							<Box className="border-b-2 border-grey-100 border-t-0 border-l-0 border-r-0 ">
								<LanguageSelectorHeader
									handleChange={righthandleChange}
									selectedLang={selectedLang?.targetLang}
									languageSide="targetLanguage"
									setLanguageToggle={handleToggleAndLangSelect}
									languageToggle={languageSelectFor}
									setLanguageCode={setLanguageCode}
								/>
							</Box>
							{targetLanguageToggle && (
								<Box>
									<CustomSelect
										name="targetLanguage"
										control={control}
										options={
											!isTalkEasy
												? languages.map((country) => ({
														label: country.name,
														value: country.code,
												  }))
												: talkEasyLanguages.map((country) => ({
														label: country.name,
														value: country.code,
												  }))
										}
										value={getValues('targetLanguage')}
										placeholder={'Select Target Language'}
										handleChange={handleLanguageChange}
										setLanguageToggle={handleToggleAndLangSelect}
										className="border-2 border-grey-100 border-t-0 border-r-0 rounded-0"
									/>
								</Box>
							)}
						</TabContext>
					</Box>
				</Grid>
			</Grid>
		</>
	);
};

export default LanguageSelectorMain;
