import React, { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Container,
	Grid,
	Menu,
	MenuItem,
	ThemeProvider,
	createTheme,
} from '@mui/material';
import stripe from 'assets/stripe.png';
import { Link, useNavigate } from 'react-router-dom';
import api from 'utils/axios';
import userProfile from 'assets/user-profile.jpeg';
import Loader from 'component/loader';
import SearchIcon from '@mui/icons-material/Search';

const theme = createTheme({
	components: {
		MuiMenu: {
			styleOverrides: {
				paper: {
					boxShadow: 'none',
					border: '1px solid #6db7f2',
				},
			},
		},
	},
});

const Users = () => {
	const [loading, setLoading] = useState(true);
	const [users, setUsers] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [anchorEl, setAnchorEl] = useState({});

	const navigate = useNavigate();

	const handleClick = (userId, event) => {
		console.log(userId, event.currentTarget);
		console.log(anchorEl);
		setAnchorEl((prevState) => ({
			...prevState,
			[userId]: event.currentTarget,
		}));
		console.log(anchorEl);
	};

	const handleClose = (userId) => {
		setAnchorEl((prevState) => ({
			...prevState,
			[userId]: null,
		}));
	};

	useEffect(() => {
		api
			.get('/auth/get-all-users')
			.then((res) => {
				setLoading(true);
				setUsers(res.data.users);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	}, []);

	useEffect(() => {
		api
			.get(`/auth/get-all-users?search=${searchValue}`)
			.then((res) => {
				setLoading(true);
				setUsers(res.data.users);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	}, [searchValue]);

	return (
		<>
			{loading && <Loader />}
			<Box>
				<Container>
					<Box className="relative bg-white h-full rounded-lg rounded-br-lg shadow-lg ">
						<Grid className="flex justify-between items-center pr-2" md={12}>
							<div className="border-b-2 border-grey-100 border-t-0 border-r-0 border-l-0">
								<h2 className="pt-2 pl-3 pb-3 text-[#6B6B6B] text-lg font-medium">
									Users
								</h2>
							</div>
							<div className="relative">
								<input
									className="border-b-2 border-custom-blue p-1 flex justify-center items-center xm:w-60 xs:w-32"
									type="text"
									placeholder="Search user"
									value={searchValue}
									onChange={(e) => setSearchValue(e.target.value)}
								/>
								<div className="absolute right-1 bottom-1">
									<SearchIcon sx={{ color: '#747474' }} />
								</div>
							</div>
						</Grid>
						<div className="scroll-container">
							<div className="flex justify-center items-center xm:mx-4 xs:mx-2 my-4 gap-4 grid lm:grid-cols-2 xs:grid-cols-1">
								{users.length !== 0 ? (
									users.map((user) => (
										<div
											key={user._id}
											className="user__card__wrapper flex justify-between items-center border-2 p-3 gap-20"
										>
											<div className="flex items-center">
												<img
													onClick={() => navigate(`/user-view/${user._id}`)}
													className="object-cover rounded-full"
													width={32}
													src={`${
														user?.profilePicture
															? user?.profilePicture
															: userProfile
													}`}
												/>
												<p
													className="user__profile__text pl-3"
													onClick={() => navigate(`/user-view/${user._id}`)}
												>
													{user.firstName} {user.lastName}
												</p>
											</div>
											<div>
												<ThemeProvider theme={theme}>
													<Button
														id={`basic-button-${user._id}`}
														aria-controls={`basic-menu-${user._id}`}
														aria-haspopup="true"
														aria-expanded={Boolean(anchorEl[user._id])}
														onClick={(e) => handleClick(user._id, e)}
														className="capitalize"
													>
														Manage
													</Button>
													<Menu
														anchorEl={anchorEl[user._id]}
														open={Boolean(anchorEl[user._id])}
														onClose={() => handleClose(user._id)}
														MenuListProps={{
															'aria-labelledby': `basic-button-${user._id}`,
														}}
													>
														<MenuItem
															onClick={() => {
																navigate(`/user-view/${user._id}`);
																handleClose(user._id);
															}}
														>
															Permissions
														</MenuItem>
														<MenuItem
															onClick={() => {
																navigate(`/document-history/${user._id}`);
																handleClose(user._id);
															}}
														>
															Document Translation History
														</MenuItem>
													</Menu>
												</ThemeProvider>
											</div>
										</div>
									))
								) : (
									<div>No users found</div>
								)}
							</div>
						</div>
					</Box>
					<Box>
						<div className="text-center pt-10">
							<p className="text-lg text-[#00000099]">
								Secure payment transactions are facilitated through
							</p>
							<div className="flex justify-center items-center">
								<img src={stripe} alt="stripe" width={150} />
							</div>
						</div>
					</Box>
				</Container>
			</Box>
		</>
	);
};

export default Users;
