/* eslint-disable react/prop-types */
import { ExpandMore } from '@mui/icons-material';
import { TabList } from '@mui/lab';
import { Container, Tab } from '@mui/material';
import React from 'react';
import languages from '../constants/languages';

const LanguageSelectorHeader = ({
	handleChange,
	languageSide,
	selectedLang = 'English',
	setLanguageToggle,
	languageToggle,
	setLanguageCode,
}) => {
	return (
		<Container>
			<TabList
				onChange={handleChange}
				aria-label="lab API tabs example"
				variant="scrollable"
				scrollButtons="auto"
			>
				{languageSide === 'sourceLanguage' && (
					<Tab
						sx={{ margin: 0.5, fontFamily: 'Poppins', color: 'red' }}
						className="languageHeader"
						style={{ marginRight: '10px' }}
						label="DETECT LANGUAGE"
						value="1"
						onClick={() => {
							setLanguageCode({ type: languageSide, code: 'Auto' });
						}}
					/>
				)}
				<Tab
					sx={{ margin: 0.5, fontFamily: 'Poppins' }}
					className="languageHeader"
					label={selectedLang}
					value="2"
					onClick={() => {
						console.log('h7', selectedLang);
						if (selectedLang === 'English' || selectedLang === 'ENGLISH') {
							setLanguageCode({ type: languageSide, code: 'en' });
						} else if (selectedLang) {
							console.log('h77');
							setLanguageCode({
								type: languageSide,
								code: languages.find((el) => el.name === selectedLang)?.code,
							});
						}
					}}
				/>
				<Tab
					sx={{ margin: 0.5, fontFamily: 'Poppins' }}
					className="languageHeader"
					label="Spanish"
					value="3"
					onClick={() => {
						setLanguageCode({ type: languageSide, code: 'es' });
					}}
				/>
				<Tab
					sx={{ margin: 0.5, fontFamily: 'Poppins' }}
					className="languageHeader"
					label="French"
					value="4"
					onClick={() => {
						setLanguageCode({ type: languageSide, code: 'fr' });
					}}
				/>
				<Tab
					sx={{ margin: 0.5, fontFamily: 'Poppins' }}
					icon={<ExpandMore />}
					style={{
						rotate:
							languageSide === languageToggle?.languageFor &&
							languageToggle?.languageToggle
								? '180deg'
								: '0deg',
					}}
					iconPosition="start"
					value="5"
					className="w-10"
					onClick={() => {
						setLanguageToggle(languageSide);
					}}
				/>
			</TabList>
		</Container>
	);
};

export default LanguageSelectorHeader;
