import React, { useEffect, useMemo, useState } from 'react';
import { Backdrop, Box, Button, Container, Fade, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { createNotification } from '../../../utils/create-notification';
import LanguageSelectorMain from '../../../component/LanguageSelectorMain';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../../component/InfiniteLoader/InfiniteLoader';
import SimpleLoader from '../../../component/loader';
import stripe from 'assets/stripe.png';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import Modal from 'component/Modal';
import { useSelector } from 'react-redux';
import api from 'utils/axios';
import Visa from 'assets/credit-cards/visa.svg';
import Amex from 'assets/credit-cards/amex.svg';
import Mastercard from 'assets/credit-cards/mastercard.svg';
import Unknown from 'assets/credit-cards/unknown.svg';
import { useNavigate } from 'react-router-dom';

const CREDIT_CARD_LOGOS = {
	Visa: Visa,
	Amex: Amex,
	Mastercard: Mastercard,
};

// eslint-disable-next-line react/prop-types
const CheckoutForm = ({ name, email, totalWords, handleTranslation }) => {
	const [checkoutLoading, setCheckoutLoading] = useState(false);
	const stripe = useStripe();
	const elements = useElements();
	const navigation = useNavigate();
	const { profileInformation } = useSelector((store) => store?.user);

	useEffect(() => {
		if (!profileInformation?.billingInfo?.paymentMethods[0]) {
			navigation(
				'/billing-information?error=Please update your billing Information'
			);
		}
	}, []);

	const logo = useMemo(() => {
		if (!profileInformation?.billingInfo?.paymentMethods[0]?.brand)
			return Unknown;
		return (
			CREDIT_CARD_LOGOS[
				profileInformation?.billingInfo?.paymentMethods[0]?.brand
			] || Unknown
		);
	}, [profileInformation]);

	const handleSubmit = async (event) => {
		setCheckoutLoading(true);
		event.preventDefault();
		setCheckoutLoading(false);
		handleTranslation();
	};

	return (
		<>
			{checkoutLoading && <SimpleLoader />}
			<form onSubmit={handleSubmit}>
				<div className="flex flex-row justify-between">
					<div className="flex flex-col">
						<div className="flex items-center">
							{profileInformation?.billingInfo?.paymentMethods[0]?.expYear && (
								<img src={logo} alt="Start Icon" className="w-6 h-6 mr-2" />
							)}
							<span className="mr-2">
								{profileInformation?.billingInfo?.paymentMethods[0]?.expYear &&
									'****'}
								{profileInformation?.billingInfo?.paymentMethods[0]?.last4}
							</span>
						</div>
					</div>
					<div className="flex flex-col justify-end">
						<span className="text-right">
							{profileInformation?.billingInfo?.paymentMethods[0]?.expMonth}
							{profileInformation?.billingInfo?.paymentMethods[0]?.expYear &&
								'/'}
							{profileInformation?.billingInfo?.paymentMethods[0]?.expYear}
						</span>
					</div>
				</div>

				<div className="flex justify-between items-center pt-1">
					<label htmlFor="name" className="block text-gray-700 font-bold pt-3">
						Name
					</label>
					<input
						type="text"
						id="name"
						className="bg-white pt-3 rounded-lg text-right"
						placeholder="Enter your name"
						disabled // Disable the input field
						value={name}
					/>
				</div>

				<div className="flex justify-between items-center pt-2">
					<label htmlFor="email" className="block text-gray-700 font-bold ">
						Email
					</label>
					<input
						type="email"
						id="email"
						className="bg-white text-right w-full rounded-lg"
						placeholder="Enter your email"
						disabled // Disable the input field
						value={email}
					/>
				</div>

				<div className="flex justify-between items-center pt-2">
					<label htmlFor="name" className="block text-gray-700 font-bold">
						Total Words
					</label>
					<input
						type="text"
						id="name"
						className="bg-white rounded-lg text-right"
						placeholder="1021"
						disabled // Disable the input field
						value={totalWords}
					/>
				</div>
				<div className="flex justify-between items-center pt-2">
					<label htmlFor="name" className="block text-gray-700 font-bold">
						Per Words
					</label>
					<input
						type="text"
						id="name"
						className="bg-white  rounded-lg text-right"
						placeholder="$0.11"
						disabled // Disable the input field
					/>
				</div>

				<div className="flex justify-between items-center pt-2">
					<label htmlFor="name" className="block text-gray-700 font-bold ">
						Total Cost
					</label>
					<input
						type="text"
						id="name"
						className="bg-white rounded-lg text-right"
						placeholder="$200"
						disabled // Disable the input field
						value={'$' + (totalWords * 0.11).toFixed(2)}
					/>
				</div>
				<div className="flex justify-center items-center mt-5">
					<button
						type="submit"
						disabled={!stripe}
						className="w-full mt-5 bg-[#6db7f2] hover:bg-blue-400 text-white font-bold py-4 rounded-lg   sm:ml-0"
					>
						Pay
					</button>
				</div>
			</form>
		</>
	);
};

const DocumentTranslation = () => {
	const [loading, setLoading] = useState(false);
	const [simpleLoading, setSimpleLoading] = useState(false);
	const [selectedFile, setSelectedFile] = useState(false);
	const [selectedFileIgnorWords, setSelectedFileIgnoreWords] = useState(false);
	const [value, setValue] = React.useState('1');
	const [rightvalue, setRightValue] = React.useState('2');
	const [wordsCount, setWordsCount] = React.useState('...');
	const [open, setOpen] = useState(false);

	const [selectedLang, seSelectedLang] = React.useState({
		sourceLang: 'English',
		targetLang: 'English',
	});
	const [languageCode, setLanguageCode] = React.useState({
		sourceLangCode: 'auto',
		targetLangCode: 'en',
	});
	const { control, watch, getValues, setValue: RfSetValue } = useForm();
	const { profileInformation } = useSelector((store) => store?.user);

	useEffect(() => {
		if (selectedFile) {
			fetchWords();
		}
	}, [selectedFile]);

	const fetchWords = async () => {
		try {
			setSimpleLoading(true);
			const formData = new FormData();
			formData.append('document', selectedFile || selectedFileIgnorWords);
			const { data } = await api.post(
				'translation/document/count-words',
				formData
			);
			setWordsCount(data?.words);
			console.log(data, 'hamza');
			setSimpleLoading(false);
		} catch (error) {
			setSimpleLoading(false);
		}
	};

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const righthandleChange = (event, newValue) => {
		setRightValue(newValue);
	};

	const handleFileInputChange = async (event) => {
		setSelectedFile(event.target.files[0]);
		event.target.value = '';
	};
	const handleFileInputChangeIgnoreWords = async (event) => {
		setSelectedFileIgnoreWords(event.target.files[0]);
		console.log(selectedFileIgnorWords);
		event.target.value = '';
	};

	const handleTranslation = async (paymentId) => {
		setOpen(false);
		const sourceLanguage = languageCode?.sourceLangCode;
		const targetLanguage = languageCode?.targetLangCode;
		if (sourceLanguage && targetLanguage) {
			setLoading(true);
			try {
				const formData = new FormData();
				formData.append('document', selectedFile);
				formData.append('sourceLanguage', sourceLanguage);
				formData.append('targetLanguage', targetLanguage);
				formData.append('paymentMethodId', paymentId);
				formData.append('ignoreWordsFile', selectedFileIgnorWords);

				const response = await api.post('translation/document', formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
					responseType: 'blob',
				});
				console.log(response.data);
				// Create a Blob from the response data
				const blob = new Blob([response.data], {
					type: response.headers['content-type'],
				});

				// Create a temporary URL to download the file
				const url = window.URL.createObjectURL(blob);

				// Create an anchor element and programmatically click it to trigger the download
				const link = document.createElement('a');
				link.href = url;
				link.download = 'translated-doc'; // Set the desired file name and extension
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);

				// Release the temporary URL
				window.URL.revokeObjectURL(url);
				setSelectedFile(null);
				setLoading(false);
			} catch (error) {
				setSelectedFile(null);
				setLoading(false);
				console.error('Error uploading file:', error);
				createNotification(
					'error',
					'Error',
					'Error Please Check your card or problem in file format'
				);
			}
		} else {
			setSelectedFile(null);
			setLoading(false);
			createNotification(
				'error',
				'Error',
				'Please Select Source and Target Languages.'
			);
			setSelectedFile(null);
		}
	};

	const goToPayment = async () => {
		function checkEmail(email) {
			const emailList = [
				'tiffany@managedhealth.com',
				'muriel@managedhealth.com',
				'ernie@managedhealth.com',
				'fatima@managedhealth.com',
				'moe@managedhealth.com',
			];
			return emailList.includes(email);
		}

		if (checkEmail(profileInformation?.email)) {
			handleTranslation('charge_with_one_saved_card');
		} else if (
			profileInformation?.servicesPayment?.find((el) => el === 'document')
		) {
			handleTranslation('not_charge_with_access');
		} else {
			setOpen(true);
		}
	};

	const handleLangCode = (val) => {
		val?.type === 'sourceLanguage' &&
			setLanguageCode({ ...languageCode, sourceLangCode: val?.code });
		val?.type === 'targetLanguage' &&
			setLanguageCode({ ...languageCode, targetLangCode: val?.code });
	};

	useEffect(() => {
		let targetLang = getValues('targetLanguage');
		let sourceLang = getValues('sourceLanguage');
		if (targetLang?.value || sourceLang?.value) {
			seSelectedLang({
				sourceLang: sourceLang?.label,
				targetLang: targetLang?.label,
			});
			setLanguageCode({
				sourceLangCode: sourceLang ? sourceLang?.value : 'auto',
				targetLangCode: targetLang ? targetLang?.value : 'en',
			});
			if (targetLang?.label && targetLang?.label != selectedLang?.targetLang)
				setRightValue('2');
			if (sourceLang?.label && sourceLang?.label != selectedLang?.sourceLang)
				setValue('2');
		}
	}, [watch()?.sourceLanguage, watch()?.targetLanguage]);

	const handleLanguageSwitch = () => {
		let targetLang = getValues('targetLanguage');
		let sourceLang = getValues('sourceLanguage');

		targetLang &&
			sourceLang &&
			RfSetValue(
				'targetLanguage',
				sourceLang ?? { label: 'English', value: 'en' }
			);
		setLanguageCode({
			sourceLangCode: languageCode?.targetLangCode,
			targetLangCode: languageCode?.sourceLangCode,
		});
		targetLang &&
			sourceLang &&
			RfSetValue(
				'sourceLanguage',
				targetLang ?? { label: 'English', value: 'en' }
			);
		if (!targetLang && !sourceLang) {
			setLanguageCode({
				sourceLangCode: languageCode?.targetLangCode,
				targetLangCode: languageCode?.sourceLangCode,
			});
			languageCode?.targetLangCode === 'es' && setValue('3');
			languageCode?.targetLangCode === 'en' && setValue('2');
			languageCode?.targetLangCode === 'fr' && setValue('4');

			languageCode?.sourceLangCode === 'es' && setRightValue('3');
			languageCode?.sourceLangCode === 'en' && setRightValue('2');
			languageCode?.sourceLangCode === 'fr' && setRightValue('4');
		}
	};

	return (
		<>
			{loading && <Loader loading={loading} />}
			{simpleLoading && <SimpleLoader />}
			<Box>
				<Container className="mt-5">
					<LanguageSelectorMain
						handleChange={handleChange}
						control={control}
						righthandleChange={righthandleChange}
						value={value}
						setValue={setValue}
						rightvalue={rightvalue}
						setRightValue={setRightValue}
						selectedLang={selectedLang}
						setLanguageCode={handleLangCode}
						getValues={getValues}
						handleLanguageSwitch={handleLanguageSwitch}
					/>
					<Box className="relative bg-white rounded-lg shadow-lg pb-20">
						<div className="flex flex-col justify-center items-center">
							<div className="text-center pt-10">
								<p className="text-lg text-[#00000099]">
									Documents are required to be under 200MB in size and should
									not exceed 80 pages.
								</p>
							</div>
							<div className="flex flex-col justify-center items-center gap-5">
								<div className="flex flex-col justify-center items-center">
									<div className="pt-5">
										<h3 className="text-xl text-center mt-2 font-semibold font-sans tracking-tight text-[#00000099]">
											Choose a document{' '}
										</h3>
									</div>
									<div className="pt-5">
										<p className=" text-[#00000099] pb-10 text-center">
											Upload a .docx Document.
										</p>
									</div>

									<div className="">
										<div className="flex xm:flex-row xs:flex-col justify-center items-center xm:gap-5 xs:gap-3">
											{selectedFile && (
												<div className="">
													<div className="w-full  font-bold  ml-2 text-left wordcount-text">
														<p className="text-[#00000099]">
															{' '}
															Word Count : {wordsCount}
														</p>
														<p className="text-[#00000099]">
															{' '}
															Cost to Translate : $
															{(
																0.11 * (Number(wordsCount) ? wordsCount : 0)
															).toFixed(2)}
														</p>
													</div>
												</div>
											)}
											<div className="flex justify-center items-center">
												<div className="relative">
													<input
														type="file"
														className="absolute top-0 left-0 right-0 bottom-0 opacity-0 cursor-pointer "
														onChange={handleFileInputChange}
														accept=".docx,.pdf,.ppt,.pptx,.xlsx"
													></input>
													<button
														className="w-full h-full bg-[#6db7f2] text-white font-bold py-4 px-6 rounded-lg ml-2"
														// onClick={handleFileUpload}
													>
														{selectedFile
															? selectedFile.name
															: 'Browse your computer'}
													</button>
												</div>
												{selectedFile && (
													<div className="pt-2">
														{/* {showCloseButton && ( */}
														<button
															onClick={() => setSelectedFile(null)}
															className="w-10 h-10 bg-[#EC502F] hover:bg-blue-400 text-white font-bold py-2 px-4 rounded-full ml-5"
														>
															<FontAwesomeIcon
																icon={faClose}
																className="-ml-1 -mr-1 close-icon"
															/>
														</button>
														{/* )} */}
													</div>
												)}
											</div>
										</div>
									</div>
								</div>

								<div className="flex flex-col justify-center items-center">
									<div className="pt-5">
										<h3 className="text-xl text-center mt-2 font-semibold font-sans tracking-tight text-[#00000099]">
											Choose a document of Ignore Words{' '}
										</h3>
									</div>
									<div className="pt-5">
										<p className=" text-[#00000099] pb-10 text-center">
											Upload a .xlsx.
										</p>
									</div>
									<div className="">
										<div className="flex xm:flex-row xs:flex-col justify-center items-center xm:gap-5 xs:gap-3">
											{selectedFileIgnorWords && (
												<div className="">
													<div className="w-full  font-bold  ml-2 text-left wordcount-text">
														<p className="text-[#00000099]">
															{' '}
															Word Count : {wordsCount}
														</p>
														<p className="text-[#00000099]">
															{' '}
															Cost to Translate : $
															{(0.11 * (wordsCount ? wordsCount : 0)).toFixed(
																2
															)}
														</p>
													</div>
												</div>
											)}
											<div className="flex justify-center items-center">
												<div className="relative">
													<input
														type="file"
														className="absolute top-0 left-0 right-0 bottom-0 opacity-0 cursor-pointer "
														onChange={handleFileInputChangeIgnoreWords}
														accept=".xlsx"
													></input>
													<button
														className="w-full h-full bg-[#6db7f2] text-white font-bold py-4 px-6 rounded-lg ml-2"
														// onClick={handleFileUpload}
													>
														{selectedFileIgnorWords
															? selectedFileIgnorWords.name
															: 'Browse your computer'}
													</button>
												</div>
												{selectedFileIgnorWords && (
													<div className="">
														<button
															onClick={() => setSelectedFileIgnoreWords(null)}
															className="w-10 h-10 bg-[#EC502F] hover:bg-blue-400 text-white font-bold py-2 px-4 rounded-full ml-5"
														>
															<FontAwesomeIcon
																icon={faClose}
																className="-ml-1 -mr-1 close-icon"
															/>
														</button>
													</div>
												)}
											</div>
										</div>

										<div className="mt-12 flex justify-center items-center">
											{(selectedFileIgnorWords || selectedFile) && (
												<button
													className="bg-transparent border-2 text-white font-bold py-3 px-9 rounded-lg ml-2 translate-btn"
													onClick={goToPayment}
												>
													Translate
												</button>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</Box>
					<Box>
						<div className="text-center pt-10">
							<p className="text-lg text-[#00000099]">
								Upon completion of translations, it maybe necessary to format
								the translated documents accordingly.
								<br /> Secure payment transctions are facilitated through
							</p>
							<div className="flex justify-center items-center">
								<img src={stripe} alt="stripe" width={150} />
							</div>
						</div>
					</Box>
				</Container>
			</Box>
			{wordsCount && (
				<Modal
					title={'Payment'}
					open={open}
					handleClose={() => setOpen(false)}
					bodyTopMargin={1}
				>
					<CheckoutForm
						name={profileInformation?.firstName}
						email={profileInformation?.email}
						totalWords={wordsCount}
						handleTranslation={handleTranslation}
					/>
				</Modal>
			)}
		</>
	);
};

export default DocumentTranslation;
