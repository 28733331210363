import React, { useEffect, useState } from 'react';
import Stripeimg from 'assets/stripe_payments_vector.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileInfoAction } from 'store/user';
import CheckoutModal from './CheckoutModal';
import { useNavigate } from 'react-router-dom';
import api from 'utils/axios';
import { createNotification } from 'utils/create-notification';
import Modal from 'component/Modal';
import styles from './index.module.scss';
import Loader from 'component/loader';
import { set } from 'react-hook-form';
import moment from 'moment';

const UpgradePage = () => {
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [selectedSubscription, setSelectedSubscription] = useState(null);
	const [subscribedServices, setSubscribedServices] = useState([]);
	const [cancelSubscriptionModalOpen, setCancelSubscriptionModalOpen] =
		useState(false);

	const dispatch = useDispatch();
	const profileInformation = useSelector(
		(state) => state?.user?.profileInformation
	);
	const navigate = useNavigate();

	useEffect(() => {
		if (!profileInformation) {
			dispatch(getProfileInfoAction());
		}
		const subscribedServices = profileInformation?.billingInfo?.subscriptions;
		setSubscribedServices(subscribedServices);
	}, [profileInformation]);

	// Function to open modal and set the selected subscription
	const handleSubscribeClick = (subscription) => {
		if (!profileInformation?.billingInfo?.paymentMethods[0]?.brand) {
			navigate(
				'/billing-information?error=Please update your billing Information'
			);
		} else {
			setSelectedSubscription(subscription);
			setOpen(true);
		}
	};
	// Function to open modal and set the selected subscription
	const handleCancelClick = (subscription) => {
		setSelectedSubscription(subscription);
		setCancelSubscriptionModalOpen(true);
	};

	const subscribeToService = async (subscription) => {
		try {
			setLoading(true);
			console.log('Subscribing to:', subscription);
			const response = await api.post('subscriptions/create-subscription', {
				subscriptionName: subscription.id,
			});

			if (response.status === 200) {
				createNotification('success', 'Subscription successful');
				dispatch(getProfileInfoAction());
			}
			console.log('Response:', response);
			setLoading(false);
			// Here you would replace this console.log with your actual API call
			// For example: axios.post('/api/subscribe', { subscription })
		} catch (error) {
			setLoading(false);
			createNotification(
				'error',
				'Error',
				error?.response?.data?.error ||
					'Cannot subscribe at the moment. Please try again later.'
			);
			console.error('Error:', error);
		}
	};

	// Call this function when the modal submit button is clicked
	const handleModalSubmit = async (e) => {
		e.preventDefault();
		//Hi gpt why it will refresh page and does not show any logs in console when i add await there
		await subscribeToService(selectedSubscription);
		setOpen(false); // Close the modal after subscribing
	};

	const cancelSubscriptionHandler = async () => {
		setLoading(true);
		const response = await api.post('subscriptions/cancel-subscription', {
			subscriptionName: selectedSubscription.id,
		});

		if (response.status === 200) {
			createNotification('success', 'Subscription cancelled');
			dispatch(getProfileInfoAction());
		}
		setLoading(false);
		setCancelSubscriptionModalOpen(false);
	};

	return (
		<>
			<CheckoutModal
				subscriptionDetail={selectedSubscription?.description || ''}
				open={open}
				setOpen={setOpen}
				loading={loading}
				handleSubmit={handleModalSubmit}
				last4={profileInformation?.billingInfo?.paymentMethods[0]?.last4}
				expiryDate={`${profileInformation?.billingInfo?.paymentMethods[0]?.expMonth}/${profileInformation?.billingInfo?.paymentMethods[0]?.expYear}`}
				cvc={'123'}
				amount={selectedSubscription?.amount || ''}
				cardBrand={profileInformation?.billingInfo?.paymentMethods[0]?.brand}
			/>
			<Modal
				title={'Cancel Subscription'}
				open={cancelSubscriptionModalOpen}
				handleClose={() => setCancelSubscriptionModalOpen(false)}
				bodyTopMargin={1}
				className={styles.ModelContainer}
				customStyle={{ paddingTop: '26px' }}
			>
				{loading && <Loader />}
				<div className="w-96 bg-white rounded p-6 shadow-xl">
					<p> {selectedSubscription?.description}</p>
				</div>
				<div className="mt-8">
					<button
						onClick={cancelSubscriptionHandler}
						className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded mr-2"
					>
						Yes, Cancel
					</button>
					<button
						onClick={() => setCancelSubscriptionModalOpen(false)}
						className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded"
					>
						No, Go Back
					</button>
				</div>
			</Modal>
			<div className="max-w-[1880px] xs:mx-2 md:mx-4 lgzz:mx-auto my-16  ">
				<div className=" lg:max-2xl:max-w-[1092px] mx-auto xs:p-2   lg:p-6">
					<div className="flex xs:space-x-11 2xl:pl-4  md:space-x-32 lg:space-x-44   xs:mb-1 2xl:mb-6 xs:text-[8px] xm:text-[10px] md:text-2xl  lg:text-3xl mx-auto ">
						<button className="xs:px-4 xs:py-1 md:px-8 md:py-4 lg:px-[72px] lg:py-4 font-bold xs:rounded-[5px] md:rounded-[10px] text-white bg-[#6DB7F2] ">
							Products
						</button>
						<button className="xs:px-4 xs:py-1 md:px-8 md:py-4 lg:px-[72px] lg:py-4  font-bold xs:rounded-[5px] md:rounded-[10px] text-white bg-[#6DB7F2] ">
							Prices
						</button>
					</div>
					<div className="flex xs:flex-col  xm:max-md:flex-row 2xl:flex-row mt-16 justify-between ">
						<div className="xs:text-[8px] md:text-xl lg:text-2xl">
							<div className="group hover:bg-[#E2F2FF] hover:font-semibold  py-4 flex xs:space-x-11  md:space-x-32 lg:space-x-44 xs:mb-1 lg:mb-6 items-center    ">
								<span className="xs:w-[65px] md:w-40 lg:w-[290px] group-hover:text-[#6DB7F2] xs:pl-2 md:pl-8 lg:pl-12	 ">
									Text
								</span>
								<span className="group-hover:text-[#6DB7F2] xs:pl-2 md:pl-8 lg:pl-12">
									Free
								</span>
							</div>

							<div className=" group hover:bg-[#E2F2FF] hover:font-semibold py-4 flex xs:space-x-11  md:space-x-32 lg:space-x-44 xs:mb-1 2xl:mb-6    ">
								<span className="xs:w-[65px] md:w-40 lg:w-[290px] hover:text-[#6DB7F2] xs:pl-2 md:pl-8 lg:pl-12">
									Images
								</span>
								<span className="xs:w-[55px] md:w-40 lg:w-[170px] xs:pl-2 md:pl-8 lg:pl-12   hover:text-[#6DB7F2]">
									US$5.0
									<span className="text-[#B4B4B4] group-hover:text-[#B4B4B4]">
										/month
									</span>
								</span>

								{subscribedServices?.find((el) => el?.itemName === 'image')
									?.nextBilling &&
								moment(
									subscribedServices.find((el) => el?.itemName === 'image')
										.nextBilling
								).isValid() ? (
									<button
										onClick={() =>
											handleCancelClick({
												id: 'image',
												description: `Are you sure you want to cancel your Image Translation subscription?`,
											})
										}
										className="bg-gray-300 text-gray-800 font-semibold py-2 px-4 rounded hover:bg-gray-400"
									>
										Cancel Now
									</button>
								) : (
									<button
										onClick={() =>
											handleSubscribeClick({
												id: 'image',
												description: `Subscribe to our image translation service for only $5.00 per month. Unlimited translations, no hidden costs. Cancel anytime.`,
												amount: '5.00',
												type: 'subscribe', // Ensure this is 'subscribe' if that's the intended action
											})
										}
										className="xs:px-4 xs:py-1  md:px-7 md:py-2 text-white bg-[#6DB7F2] font-bold xs:rounded-[5px] md:rounded-[10px]"
									>
										Subscribe
									</button>
								)}
							</div>
							<div className="group hover:bg-[#E2F2FF] hover:font-semibold py-4 flex xs:space-x-11  md:space-x-32 lg:space-x-44 xs:mb-1 2xl:mb-6  items-center   ">
								<span className="group-hover:text-[#6DB7F2]  xs:w-[65px] md:w-40 lg:w-[290px] xs:pl-2 md:pl-8 lg:pl-12">
									Documents
								</span>
								<span className="group-hover:text-[#6DB7F2] xs:w-[55px] md:w-40 lg:w-[170px] xs:pl-2 md:pl-8 lg:pl-12">
									US$0.11
									<span className="text-[#B4B4B4] group-hover:text-[#B4B4B4]">
										/word
									</span>
								</span>
							</div>
							<div className="group hover:bg-[#E2F2FF] hover:font-semibold py-4 flex xs:space-x-11  md:space-x-32 lg:space-x-44 xs:mb-1 2xl:mb-6  items-center   ">
								<span className="group-hover:text-[#6DB7F2]  xs:w-[65px] md:w-40 lg:w-[290px] xs:pl-2 md:pl-8 lg:pl-12">
									Websites
								</span>
								<span className="group-hover:text-[#6DB7F2] xs:w-[55px] md:w-40 lg:w-[170px] xs:pl-2 md:pl-8 lg:pl-12">
									US$299
									<span className="text-[#B4B4B4] group-hover:text-[#B4B4B4]">
										/month
									</span>
								</span>

								{subscribedServices?.find((el) => el?.itemName === 'webpage')
									?.status === 'active' ? (
									<button
										onClick={() =>
											handleCancelClick({
												id: 'webpage',
												description: `Are you sure you want to cancel your Website Translation subscription?`,
											})
										}
										className="bg-gray-300 text-gray-800 font-semibold py-2 px-4 rounded hover:bg-gray-400 "
									>
										Cancel Now
									</button>
								) : (
									<button
										onClick={() =>
											handleSubscribeClick({
												id: 'webpage',
												description: `Subscribe to our Website translation service for only $299.00 per month.
								Unlimited translations, no hidden costs. Cancel anytime.`,
												amount: '299.00',
											})
										}
										className="xs:px-4 xs:py-1  md:px-7 md:py-2    text-white bg-[#6DB7F2] font-bold xs: rounded-[5px] md:rounded-[10px]  "
									>
										Subscribe
									</button>
								)}
							</div>
							<div className="group hover:bg-[#E2F2FF] hover:font-semibold py-4 flex xs:space-x-11  md:space-x-32 lg:space-x-44 xs:mb-1 2xl:mb-6  items-center   ">
								<span className="group-hover:text-[#6DB7F2]  xs:w-[65px] md:w-40 lg:w-[290px] xs:pl-2 md:pl-8 lg:pl-12">
									Voice Dubbing
								</span>
								<span className="group-hover:text-[#6DB7F2] xs:w-[55px] md:w-40 lg:w-[170px] xs:pl-2 md:pl-8 lg:pl-12">
									US$14.99
									<span className="text-[#B4B4B4] group-hover:text-[#B4B4B4]">
										/min
									</span>
								</span>
							</div>
							<div className="group hover:bg-[#E2F2FF] hover:font-semibold py-4 flex xs:space-x-11  md:space-x-32 lg:space-x-44 xs:mb-1 2xl:mb-6  items-center   ">
								<span className="group-hover:text-[#6DB7F2]  xs:w-[65px] md:w-40 lg:w-[290px] xs:pl-2 md:pl-8 lg:pl-12">
									Unite
								</span>
								<span className="group-hover:text-[#6DB7F2] xs:w-[55px] md:w-40 lg:w-[170px] xs:pl-2 md:pl-8 lg:pl-12">
									US$97.99
									<span className="text-[#B4B4B4] group-hover:text-[#B4B4B4]">
										/month
									</span>
								</span>

								{subscribedServices?.find((el) => el?.itemName === 'unite')
									?.status === 'active' ? (
									<button
										onClick={() =>
											handleCancelClick({
												id: 'unite',
												description: `Are you sure you want to cancel your Unite subscription?`,
											})
										}
										className="bg-gray-300 text-gray-800 font-semibold py-2 px-4 rounded hover:bg-gray-400 "
									>
										Cancel Now
									</button>
								) : (
									<button
										onClick={() =>
											handleSubscribeClick({
												id: 'unite',
												description: `Subscribe to our LetzChat Unite service for only $97.99 per month.
								Unlimited Usage, no hidden costs. Cancel anytime.`,
												amount: '97.99',
											})
										}
										className="xs:px-4 xs:py-1  md:px-7 md:py-2    text-white bg-[#6DB7F2] font-bold xs: rounded-[5px] md:rounded-[10px]  "
									>
										Subscribe
									</button>
								)}
							</div>
							<div className="group hover:bg-[#E2F2FF] hover:font-semibold py-4 flex xs:space-x-11  md:space-x-32 lg:space-x-44 xs:mb-1 2xl:mb-6  items-center   ">
								<span className="group-hover:text-[#6DB7F2]  xs:w-[65px] md:w-40 lg:w-[290px] xs:pl-2 md:pl-8 lg:pl-12">
									Zoom Subtitling
								</span>
								<span className="group-hover:text-[#6DB7F2] xs:w-[55px] md:w-40 lg:w-[170px] xs:pl-2 md:pl-8 lg:pl-12">
									US$97.99
									<span className="text-[#B4B4B4] group-hover:text-[#B4B4B4]">
										/month
									</span>
								</span>

								{subscribedServices?.find(
									(el) => el?.itemName === 'zoom-subtitles'
								)?.status === 'active' ? (
									<button
										onClick={() =>
											handleCancelClick({
												id: 'zoom-subtitles',
												description: `Are you sure you want to cancel your Zoom Subtitling subscription?`,
											})
										}
										className="bg-gray-300 text-gray-800 font-semibold py-2 px-4 rounded hover:bg-gray-400 "
									>
										Cancel Now
									</button>
								) : (
									<button
										onClick={() =>
											handleSubscribeClick({
												id: 'zoom-subtitles',
												description: `Subscribe to our Zoom Live Caption Translation service for only $97.99 per month.
							Unlimited Usage, no hidden costs. Cancel anytime.`,
												amount: '97.99',
											})
										}
										className="xs:px-4 xs:py-1  md:px-7 md:py-2    text-white bg-[#6DB7F2] font-bold xs: rounded-[5px] md:rounded-[10px]  "
									>
										Subscribe
									</button>
								)}
							</div>

							<div className="group hover:bg-[#E2F2FF] hover:font-semibold py-4 flex xs:space-x-11  md:space-x-32 lg:space-x-44 xs:mb-1 2xl:mb-6  items-center   ">
								<span className="group-hover:text-[#6DB7F2]  xs:w-[65px] md:w-40 lg:w-[290px] xs:pl-2 md:pl-8 lg:pl-12">
									Podcast Translation
								</span>
								<span className="group-hover:text-[#6DB7F2] xs:w-[55px] md:w-40 lg:w-[170px] xs:pl-2 md:pl-8 lg:pl-12">
									US$97.99
									<span className="text-[#B4B4B4] group-hover:text-[#B4B4B4]">
										/month
									</span>
								</span>

								{subscribedServices?.find(
									(el) => el?.itemName === 'podcast-translation'
								)?.status === 'active' ? (
									<button
										onClick={() =>
											handleCancelClick({
												id: 'podcast-translation',
												description: `Are you sure you want to cancel your Podcast-Translation subscription?`,
											})
										}
										className="bg-gray-300 text-gray-800 font-semibold py-2 px-4 rounded hover:bg-gray-400 "
									>
										Cancel Now
									</button>
								) : (
									<button
										onClick={() =>
											handleSubscribeClick({
												id: 'podcast-translation',
												description: `Subscribe to our Podcast Translation service for only $97.99 per month.
						Unlimited Usage, no hidden costs. Cancel anytime.`,
												amount: '97.99',
											})
										}
										className="xs:px-4 xs:py-1  md:px-7 md:py-2    text-white bg-[#6DB7F2] font-bold xs: rounded-[5px] md:rounded-[10px]  "
									>
										Subscribe
									</button>
								)}
							</div>
						</div>
						<div className="self-center">
							<img
								src={Stripeimg}
								className=" xs:max-xm:w-56 xm:max-sm:w-32 sm:max-md:w-56   "
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default UpgradePage;
