import Loader from 'component/loader';
import React, { useMemo } from 'react';

import Visa from 'assets/credit-cards/visa.svg';
import Amex from 'assets/credit-cards/amex.svg';
import Mastercard from 'assets/credit-cards/mastercard.svg';
import Unknown from 'assets/credit-cards/unknown.svg';
import Modal from 'component/Modal';
import styles from './index.module.scss';

const CREDIT_CARD_LOGOS = {
	Visa: Visa,
	Amex: Amex,
	Mastercard: Mastercard,
};

const CheckoutModal = ({
	subscriptionDetail,
	open,
	setOpen,
	loading,
	handleSubmit,
	last4,
	expiryDate,
	amount,
	cardBrand,
}) => {
	const logo = useMemo(() => {
		if (!cardBrand) return Unknown;
		return CREDIT_CARD_LOGOS[cardBrand] || Unknown;
	}, [cardBrand]);

	return (
		<div>
			<Modal
				open={open}
				handleClose={() => setOpen(false)}
				bodyTopMargin={1}
				className={styles.ModelContainer}
				customStyle={{ paddingTop: '26px' }}
			>
				{loading && <Loader />}
				<div className="w-96 bg-white rounded p-6 shadow-xl">
					<h2 className="text-2xl font-bold mb-6 text-gray-800">Checkout</h2>
					<p className="mb-6 text-gray-600">{subscriptionDetail}</p>
					<>
						<form onSubmit={handleSubmit}>
							<div className="flex items-center space-x-2 xs:my-2 xm:my-4 md:my-6 xl:my-7 2xl:my-9">
								<img src={logo} alt="Start Icon" className="w-6 h-6" />
								<span>****{last4}</span>
								<span>
									<strong>{expiryDate}</strong>
								</span>
							</div>
							<button
								type="submit"
								className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
							>
								Subscribe for ${amount}/month
							</button>
						</form>
					</>
				</div>
			</Modal>
		</div>
	);
};

export default CheckoutModal;
