import React, { useEffect } from 'react';
import ReactLoading from 'react-loading';
import styles from './loader.module.scss';
// eslint-disable-next-line react/prop-types
const Loader = ({ type, color }) => {
	useEffect(() => {
		document.body.style.overflow = 'hidden';
		return () => (document.body.style.overflow = 'scroll');
	});
	return (
		<div
			className="flex justify-center items-center bg-[#DBDDE1] opacity-60 fixed top-0 right-0 left-0 bottom-0 z-20"
			// style={{ minHeight: '100%' }}
		>
			<ReactLoading
				type={type}
				color={color}
				height={100}
				width={100}
				className={styles.mainLoader}
			/>
		</div>
	);
};
//
export default Loader;
