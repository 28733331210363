import io from 'socket.io-client';

class SocketManager {
	static instance = null;
	socket = null;

	constructor() {
		console.log('SocketManager constructer');
		if (!SocketManager.instance) {
			console.log('SocketManager created');
			// eslint-disable-next-line no-undef
			this.socket = io(process.env.REACT_APP_BASE_URL, {
				withCredentials: true,
			}); // replace with your server URL

			console.log(this.socket);
			SocketManager.instance = this;
		}

		return SocketManager.instance;
	}

	emit(event, ...args) {
		if (this.socket) {
			this.socket.emit(event, ...args);
		}
	}

	on(event, func) {
		if (this.socket) {
			this.socket.on(event, func);
		}
	}

	joinRoom(event, room) {
		if (this.socket) {
			this.socket.emit(event, room);
		}
	}

	off(event, func) {
		if (this.socket) {
			this.socket.off(event, func);
		}
	}
	disconnect() {
		if (this.socket) {
			this.socket.disconnect();
		}
	}
}

const instance = new SocketManager();
Object.freeze(instance);

export default instance;
