import React from 'react';
import { useSelector } from 'react-redux';

function WebsiteAnalytics() {
	const user = useSelector((state) => state.user);

	const handleLogin = (event) => {
		event.preventDefault();
		const userId = user.id;
		window.location.href = `https://stats.letz.chat/login?token=${userId}`;
	};

	return (
		<main>
			<div className="relative bg-white rounded-lg shadow-lg pb-20">
				<div className="flex flex-col justify-center items-center py-20">
					<h2 className="text-2xl font-semibold">Login with your Email</h2>
					<p className="mt-4 text-xs text-gray-400">
						Use your work email to log in to your Stats Page
					</p>
					<div className="mt-12 w-96">
						<form onSubmit={handleLogin}>
							<p className="font-semibold mb-4">Email</p>
							<input
								type="email"
								id="email"
								className="border-2 px-4 py-2 w-full rounded-lg"
								disabled
								value={user.email}
							/>
							<button
								className="bg-custom-blue rounded-md w-full text-white py-2 mt-4 px-4"
								type="submit"
							>
								Login
							</button>
						</form>
					</div>
				</div>
			</div>
		</main>
	);
}

export default WebsiteAnalytics;
