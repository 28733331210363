import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faUnlockKeyhole } from '@fortawesome/free-solid-svg-icons';
import {
	Box,
	Container,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
} from '@mui/material';

// project imports
import LanguageSelectorMain from 'component/LanguageSelectorMain';
import Loader from 'component/InfiniteLoader/InfiniteLoader';
import Modal from 'component/Modal';
import CheckoutForm from 'component/Checkout';

// Utils and Services
import api from 'utils/axios';
import { ApiService } from 'services/api-services';

// Assets and styles
import stripe from 'assets/stripe.png';
import styles from './index.module.scss';

import { useNavigate } from 'react-router-dom';
import { createNotification } from 'utils/create-notification';

const overlayStyles = {
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	backgroundColor: 'rgba(0, 0, 0, 0.8)',
	zIndex: 1,
};

const ImageTranslation = () => {
	const navigate = useNavigate();

	const [showOverlay, setShowOverlay] = useState(true);
	const [loading, setLoading] = useState('');
	const [selectedValue, setSelectedValue] = useState('');
	const [value, setValue] = React.useState('1');
	const [rightvalue, setRightValue] = React.useState('2');
	const [selectedFile, setSelectedFile] = useState(null);
	const [open, setOpen] = useState(false);
	const [openCameraModal, setOpenCameraModal] = useState(false);
	const [image, setImage] = useState(null);
	const [selectedLang, seSelectedLang] = React.useState({
		sourceLang: 'English',
		targetLang: 'English',
	});
	const [languageCode, setLanguageCode] = React.useState({
		sourceLangCode: 'auto',
		targetLangCode: 'en',
	});

	const videoRef = useRef(null);

	const { profileInformation } = useSelector((state) => state?.user);

	const { control, watch, getValues, setValue: RfSetValue } = useForm();

	useEffect(() => {
		let targetLang = getValues('targetLanguage');
		let sourceLang = getValues('sourceLanguage');
		if (targetLang?.value || sourceLang?.value) {
			seSelectedLang({
				sourceLang: sourceLang?.label,
				targetLang: targetLang?.label,
			});
			setLanguageCode({
				sourceLangCode: sourceLang ? sourceLang?.value : 'auto',
				targetLangCode: targetLang ? targetLang?.value : 'en',
			});
			if (targetLang?.label && targetLang?.label != selectedLang?.targetLang)
				setRightValue('2');
			if (sourceLang?.label && sourceLang?.label != selectedLang?.sourceLang)
				setValue('2');
		}
	}, [watch()?.sourceLanguage, watch()?.targetLanguage]);

	useEffect(() => {
		if (profileInformation?.imageSubscriptionPayment) {
			setShowOverlay(false);
		}

		const isSubscriptionActive =
			profileInformation?.billingInfo?.subscriptions?.find((el) => {
				return el?.status === 'active' && el?.itemName === 'image';
			});
		if (isSubscriptionActive) {
			setShowOverlay(false);
		}
		if (profileInformation?.servicesPayment?.find((el) => el === 'image')) {
			setShowOverlay(false);
		}
	}, [profileInformation]);

	const handleChangeslect = (event) => {
		setSelectedValue(event.target.value);
	};

	const handleFileInputChange = async (event) => {
		setSelectedFile(event.target.files[0]);
		event.target.value = '';
	};

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const righthandleChange = (event, newValue) => {
		setRightValue(newValue);
	};

	const handleLangCode = (val) => {
		val?.type === 'sourceLanguage' &&
			setLanguageCode({ ...languageCode, sourceLangCode: val?.code });
		val?.type === 'targetLanguage' &&
			setLanguageCode({ ...languageCode, targetLangCode: val?.code });
	};

	const handleTranslation = async () => {
		try {
			const sourceLanguage = languageCode?.sourceLangCode;
			const targetLanguage = languageCode?.targetLangCode;
			if (sourceLanguage && targetLanguage) {
				setLoading(true);
				try {
					const formData = new FormData();
					formData.append('image', selectedFile);
					formData.append('sourceLanguage', sourceLanguage);
					formData.append('targetLanguage', targetLanguage);
					if (selectedValue === 'simple') {
						formData.append('isSimple', true);
					}
					const response = await api.post('/translation/image', formData, {
						headers: {
							'Content-Type': 'multipart/form-data',
						},
						responseType: 'blob',
					});
					const blob = new Blob([response.data], {
						type: response.headers['content-type'],
					});
					const url = window.URL.createObjectURL(blob);
					const link = document.createElement('a');
					link.href = url;
					link.download = 'translated-Image';
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					window.URL.revokeObjectURL(url);
					setLoading(false);
					setSelectedFile(null);
				} catch (error) {
					setLoading(false);
					// Error handling
					if (error.response && error.response.data instanceof Blob) {
						// Use FileReader to read the Blob as text
						const reader = new FileReader();
						reader.onload = function () {
							try {
								// Attempt to parse the text as JSON
								const errorJson = JSON.parse(reader.result);
								// Log or handle the parsed JSON error message
								console.log('hamza', errorJson);
								if (errorJson.error) {
									createNotification('error', 'Error', errorJson.error);
								} else {
									createNotification('error', 'Error', errorJson.msg);
								}

								console.error('Error:', errorJson);
							} catch (e) {
								// If JSON parsing fails, log the error
								console.error('Error reading error response:', e);
							}
						};
						reader.onerror = function (e) {
							// Handle FileReader errors
							console.error('FileReader error:', e);
						};
						// Read the Blob as text
						reader.readAsText(error.response.data);
					} else {
						// If the response is not a Blob or another error occurs
						console.error('Unexpected error:', error);
					}
					console.error('Error uploading file:', error);
					setSelectedFile(null);
				}
			} else {
				console.log('error in image translation');
				setSelectedFile(null);
			}
		} catch (error) {
			setLoading(false);
			// Error handling
			if (error.response && error.response.data instanceof Blob) {
				// Use FileReader to read the Blob as text
				const reader = new FileReader();
				reader.onload = function () {
					try {
						// Attempt to parse the text as JSON
						const errorJson = JSON.parse(reader.result);
						// Log or handle the parsed JSON error message
						createNotification('error', 'Error', errorJson.error);
						console.error('Error:', errorJson);
					} catch (e) {
						// If JSON parsing fails, log the error
						console.error('Error reading error response:', e);
					}
				};
				reader.onerror = function (e) {
					// Handle FileReader errors
					console.error('FileReader error:', e);
				};
				// Read the Blob as text
				reader.readAsText(error.response.data);
			} else {
				// If the response is not a Blob or another error occurs
				console.error('Unexpected error:', error);
			}
			console.error('Error uploading file:', error);
			console.log('error in handle translation', error);
			setSelectedFile(null);
		}
	};

	const handlePayment = async (paymentMethodId) => {
		try {
			const response = await ApiService.post('api/payment/image-subscription', {
				paymentMethod: paymentMethodId,
			});
			if (!response?.response?.data?.error) {
				setOpen(false);
				setShowOverlay(false);
			}
		} catch (error) {
			console.error('error in handlePayment in image translation', error);
		}
	};

	const openCamera = async () => {
		setImage(null);
		try {
			const stream = await navigator.mediaDevices.getUserMedia({
				video: { facingMode: 'environment' },
			});
			if (videoRef.current) {
				videoRef.current.srcObject = stream;
			} else {
				console.log('Video reference is null.');
			}
		} catch (err) {
			console.error('Failed to open camera:', err);
		}
	};

	const captureImage = () => {
		try {
			const canvas = document.createElement('canvas');
			canvas.width = videoRef.current.videoWidth;
			canvas.height = videoRef.current.videoHeight;
			const context = canvas.getContext('2d');
			context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
			const imageData = canvas.toDataURL('image/png');
			setImage(imageData);
			fetch(imageData)
				.then((res) => res.blob())
				.then((blob) => {
					const file = new File([blob], 'capturedImage.png', {
						type: 'image/png',
						lastModified: Date.now(),
					});
					setSelectedFile(file);
				});
			const stream = videoRef.current.srcObject;
			const tracks = stream.getTracks();
			tracks.forEach((track) => track.stop());
		} catch (error) {
			console.log('error in capturing image', error);
		}
	};

	const handleLanguageSwitch = () => {
		try {
			let targetLang = getValues('targetLanguage');
			let sourceLang = getValues('sourceLanguage');
			targetLang &&
				sourceLang &&
				RfSetValue(
					'targetLanguage',
					sourceLang ?? { label: 'English', value: 'en' }
				);
			setLanguageCode({
				sourceLangCode: languageCode?.targetLangCode,
				targetLangCode: languageCode?.sourceLangCode,
			});
			targetLang &&
				sourceLang &&
				RfSetValue(
					'sourceLanguage',
					targetLang ?? { label: 'English', value: 'en' }
				);
			if (!targetLang && !sourceLang) {
				setLanguageCode({
					sourceLangCode: languageCode?.targetLangCode,
					targetLangCode: languageCode?.sourceLangCode,
				});
				languageCode?.targetLangCode === 'es' && setValue('3');
				languageCode?.targetLangCode === 'en' && setValue('2');
				languageCode?.targetLangCode === 'fr' && setValue('4');
				languageCode?.sourceLangCode === 'es' && setRightValue('3');
				languageCode?.sourceLangCode === 'en' && setRightValue('2');
				languageCode?.sourceLangCode === 'fr' && setRightValue('4');
			}
		} catch (error) {
			console.log('error in language switch', error);
		}
	};

	return (
		<>
			{loading && <Loader loading={loading} />}
			<Modal
				open={openCameraModal}
				handleClose={() => setOpenCameraModal(false)}
				bodyTopMargin={2}
				className={styles.CameraModalContainer + ' ' + styles.modalHeight}
			>
				{!image ? (
					<video ref={videoRef} autoPlay />
				) : (
					<img src={image} alt="Captured content" />
				)}

				<div className="flex justify-center">
					<button
						className="w-1/4 h-full bg-[#6db7f2] text-white font-bold py-4 px-6 rounded-lg ml-2 mt-4 flex items-center justify-center"
						onClick={captureImage}
					>
						Capture
					</button>
					<button
						onClick={() => setOpenCameraModal(false)}
						className="w-1/4 h-full bg-[#6db7f2] text-white font-bold py-4 px-6 rounded-lg ml-2 mt-4 flex items-center justify-center"
					>
						Continue
					</button>
				</div>
			</Modal>
			<Box>
				<Container className={`mt-5 ${showOverlay ? 'overlay-box' : ''}`}>
					<LanguageSelectorMain
						handleChange={handleChange}
						control={control}
						righthandleChange={righthandleChange}
						value={value}
						setValue={setValue}
						rightvalue={rightvalue}
						setRightValue={setRightValue}
						selectedLang={selectedLang}
						setLanguageCode={handleLangCode}
						getValues={getValues}
						handleLanguageSwitch={handleLanguageSwitch}
					/>

					<Box className="relative bg-white rounded-bl-lg rounded-br-lg shadow-lg box-height ">
						{showOverlay && (
							<div
								style={overlayStyles}
								className="flex justify-center items-center flex-wrap lg:gap-10 md:gap-10 sm:gap-0 overlay-text "
							>
								<div className="secure-img">
									<FontAwesomeIcon
										icon={faUnlockKeyhole}
										className="secure-icon"
									/>
								</div>
								<div>
									<h2
										className="text-white font-bold mb-2 text-6xl subcribe-secure hover:text-custom-blue"
										onClick={() => navigate('/upgrade')}
										style={{ cursor: 'pointer' }}
									>
										Subscribe to gain access
									</h2>
									<p className="text-white text-4xl plans-secure">
										The Unlimited Plan is available at a money rate of $5.00.
									</p>
								</div>
							</div>
						)}

						<Grid className="" md={12}>
							<Box className="absolute top-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
								<h3 className="text-xl font-semibold font-sans tracking-tight text-[#00000099]">
									Choose An Image
								</h3>
							</Box>
							<Box className="pb-14 absolute top-[38%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 pb-3">
								<p className=" text-[#00000099] pb-10 text-center">
									Upload a .jpg , .jpeg , .png{' '}
								</p>
							</Box>
							<Box className="absolute top-[40%] left-1/2 transform -translate-x-1/2 -translate-y-1.5/2">
								<div className="flex flex-col">
									<div className="flex flex-row">
										<div className="flex flex-col pb-4 pt-4 relative">
											<input
												type="file"
												className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
												onChange={handleFileInputChange}
												accept="image/*"
											/>
											<button className="w-full h-full bg-[#6db7f2] text-white font-bold py-4 px-6 rounded-lg ml-2 ">
												{selectedFile
													? selectedFile.name
													: 'Browse your computer'}
											</button>
										</div>
										{selectedFile && (
											<div className="pt-6">
												<button
													onClick={() => setSelectedFile(null)}
													className="w-10 h-10 bg-[#EC502F] hover:bg-blue-400 text-white font-bold py-2 px-4 rounded-full ml-5 close-btn-img"
												>
													<FontAwesomeIcon
														icon={faClose}
														className="-ml-1 -mr-1 close-icon-img"
													/>
												</button>
											</div>
										)}
									</div>
								</div>
								{selectedFile && (
									<div className="flex flex-row justify-center items-center mt-2 -ml-10 pb-4 output-type-btn">
										<FormControl fullWidth variant="outlined">
											<InputLabel htmlFor="select-input">
												Output Type
											</InputLabel>
											<Select
												className="mt-2"
												label="Choose an option"
												value={selectedValue}
												onChange={handleChangeslect}
												inputProps={{
													name: 'select-input',
													id: 'select-input',
												}}
												size="small"
											>
												<MenuItem value="not-simple">Formated</MenuItem>
												<MenuItem value="simple">Simple Text Image</MenuItem>
											</Select>
										</FormControl>
										<button
											className="w-4/5 h-4/5  font-bold py-2 px-4 rounded-lg mt-2 ml-2 translate-btn"
											onClick={handleTranslation}
										>
											Translate
										</button>
									</div>
								)}
								{!selectedFile && (
									<div className="flex flex-col gap-6 relative">
										<button
											className="w-full h-full bg-[#6db7f2] text-white font-bold py-4 px-6 rounded-lg ml-2"
											onClick={() => {
												setOpenCameraModal(true);
												openCamera();
											}}
										>
											Open Camera
										</button>
									</div>
								)}
							</Box>
						</Grid>
					</Box>
					<Box>
						<div className="text-center pt-10">
							<p className="text-lg text-[#00000099]">
								Secure payment transctions are facilitated through
							</p>
							<div className="flex justify-center items-center">
								<img src={stripe} alt="stripe" width={150} />
							</div>
						</div>
					</Box>
					<div>
						<Modal
							open={open}
							handleClose={() => setOpen(false)}
							bodyTopMargin={1}
							className={styles.ModelContainer}
							customStyle={{ paddingTop: '26px' }}
						>
							<div className="w-96 bg-white rounded p-6 shadow-xl">
								<h2 className="text-2xl font-bold mb-6 text-gray-800">
									Checkout
								</h2>
								<p className="mb-6 text-gray-600">
									Subscribe to our image translation service for only $5.00 per
									month. Unlimited translations, no hidden costs.
								</p>
								<CheckoutForm amount={5.0} handlePayment={handlePayment} />
							</div>
						</Modal>
					</div>
				</Container>
			</Box>
		</>
	);
};

export default ImageTranslation;
