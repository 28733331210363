import React from 'react';
import './speaker.css'; // Import CSS file for styling

const MicRightWave = () => {
	return (
		<>
			<div className="flex flex-row justify-center items-center">
				<div className="myElement text-custom-blue text-[60px] mb-24 -ml-8">
					{'）'}
				</div>
				<div className="myElement text-custom-blue text-[50px] mb-24 -ml-20">
					{'）'}
				</div>
				<div className="myElement text-custom-blue text-[30px] mb-24 -ml-16">
					{'）'}
				</div>
			</div>
		</>
	);
};

export default MicRightWave;
