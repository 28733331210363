/* eslint-disable no-debugger */
import React, { useEffect, useRef, useState } from 'react';
import { Box, Container, Grid } from '@mui/material';
import Tab from '@mui/material/Tab';
import LanguageSelectorMain from '../../../component/LanguageSelectorMain';
import { useForm } from 'react-hook-form';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import api from '../../../utils/axios';
import LanguageSelectorMobile from 'component/LanguageSelectorMobile';
import stripe from 'assets/stripe.png';
import { createNotification } from 'utils/create-notification';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Link } from 'react-router-dom';
import { googleLiveVoiceToTextLanguages } from 'constants/languages';
import useDebouncedQueue from 'hooks/useDebouncedQueue';
import Socket from 'services/SocketManager';

let deviceLanguage = navigator.language || navigator.userLanguage;
deviceLanguage = googleLiveVoiceToTextLanguages.find(
	(el) => el.code === deviceLanguage
);

if (!deviceLanguage || deviceLanguage === 'undefined') {
	deviceLanguage = { name: 'English (United States)', code: 'en-US' };
}

const getMediaStream = () =>
	navigator.mediaDevices.getUserMedia({
		audio: {
			deviceId: 'default',
			sampleRate: 16000,
			sampleSize: 16,
			channelCount: 1,
		},
		video: false,
	});

const TextTranslation = () => {
	const [firstTime, setFirstTime] = React.useState(true);
	const [isLoading, setIsLoading] = React.useState(false);
	const [translation, setTranslation] = React.useState('');
	const [audio, setAudio] = React.useState(null);
	const [currentRecognition, setCurrentRecognition] = useState();
	const [recognitionHistory, setRecognitionHistory] = useState([]);
	const [textAreaValue, setTextAreaValue] = React.useState('');
	const [value, setValue] = React.useState('1');
	const [connection, setConnection] = useState();
	const [isRecording, setIsRecording] = useState(false);
	const [rightvalue, setRightValue] = React.useState('2');
	const [recorder, setRecorder] = useState();
	const [socketConnection, setSocketConnection] = useState();
	const processorRef = useRef();
	const audioContextRef = useRef();
	const audioInputRef = useRef();
	const [selectedLang, setSelectedLang] = React.useState({
		sourceLang: 'English',
		targetLang: 'English',
	});
	const [timerId, setTimerId] = React.useState(null);
	const [languageCode, setLanguageCode] = React.useState({
		sourceLangCode: 'Auto',
		targetLangCode: 'en',
	});
	const notTranslateInLangSwitch = useRef(false);

	const { control, watch, getValues, setValue: RfSetValue } = useForm();

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const righthandleChange = (event, newValue) => {
		setRightValue(newValue);
	};

	const handleInputChange = (e) => {
		const inputText = e.target.value;
		setTextAreaValue(inputText);
		if (timerId) {
			clearTimeout(timerId);
		}
		const newTimerId = setTimeout(() => {
			makeDebouncedTranslationRequest(inputText);
		}, 1000);

		setTimerId(newTimerId);
	};

	const handleSpeakerClick = () => {
		const audioElement = new Audio(`data:audio/mp3;base64,${audio}`);
		audioElement.play();
	};

	const handleLangCode = (val) => {
		val?.type === 'sourceLanguage' &&
			setLanguageCode((prev) => ({
				...prev,
				sourceLangCode: val?.code,
			}));
		val?.type === 'targetLanguage' &&
			setLanguageCode((prev) => ({ ...prev, targetLangCode: val?.code }));
	};

	useEffect(() => {
		let targetLang = watch()?.targetLanguage;
		let sourceLang = watch()?.sourceLanguage;

		if (targetLang?.value || sourceLang?.value) {
			setSelectedLang({
				sourceLang: sourceLang?.label,
				targetLang: targetLang?.label,
			});
			setLanguageCode({
				sourceLangCode: sourceLang
					? sourceLang?.value
					: languageCode?.sourceLangCode,
				targetLangCode: targetLang
					? targetLang?.value
					: languageCode?.targetLangCode,
			});
			if (targetLang?.label && targetLang?.label != selectedLang?.targetLang)
				setRightValue('2');
			if (sourceLang?.label && sourceLang?.label != selectedLang?.sourceLang)
				setValue('2');
		}
	}, [watch()?.sourceLanguage, watch()?.targetLanguage]);

	useEffect(() => {
		if (recognitionHistory.length > 0 || currentRecognition) {
			const recognitionHistoryString =
				recognitionHistory
					?.map((el) => el?.text)
					.reverse()
					.join(' ') +
				' ' +
				currentRecognition;

			setTextAreaValue(() => recognitionHistoryString);
			if (recognitionHistory.length > 0 || currentRecognition) {
				if (timerId) {
					clearTimeout(timerId);
				}
				const newTimerId = setTimeout(() => {
					makeDebouncedTranslationRequest(recognitionHistoryString);
				}, 1000);
				setTimerId(newTimerId);
			}
		}
	}, [recognitionHistory, currentRecognition]);

	const handleToggle = () => {
		if (connection) {
			handleStop();
		} else {
			handleStart();
			setTextAreaValue('');
			setTranslation('');
			setRecognitionHistory([]);
			setCurrentRecognition('');
		}
	};

	useEffect(() => {
		if (!notTranslateInLangSwitch.current) {
			if (
				textAreaValue.split('').length > 1 ||
				translation.split('').length > 1
			) {
				makeDebouncedTranslationRequest(textAreaValue);
			}
		} else {
			notTranslateInLangSwitch.current = false;
		}
	}, [languageCode]);

	const makeTranslationRequest = async (text) => {
		if (!text || text.trim() === '') return;
		const sourceLanguage = languageCode?.sourceLangCode;
		const targetLanguage = languageCode?.targetLangCode;
		setIsLoading(true);
		try {
			const response = await api.post('/translation/text', {
				sourceLanguage,
				targetLanguage,
				text,
			});
			setIsLoading(false);
			setTranslation(response?.data?.translatedText);
			setAudio(response?.data?.audioData);
		} catch (error) {
			setIsLoading(false);
			createNotification('error', 'Error', error?.response?.data?.error);
			console.log('Error in text Translation', error?.response?.data?.error);
		}
	};

	const makeDebouncedTranslationRequest = useDebouncedQueue(
		makeTranslationRequest,
		50
	);

	function copyToClipboard() {
		navigator.clipboard
			.writeText(translation)
			.then(() => {
				createNotification('success', 'Success', 'Text copied');
			})
			.catch((err) => {});
	}

	const handleLanguageSwitch = () => {
		notTranslateInLangSwitch.current = true;
		let targetLang = watch()?.targetLanguage;
		let sourceLang = watch()?.sourceLanguage;
		if (!targetLang && !sourceLang) {
			languageCode?.targetLangCode === 'es' && setValue('3');
			languageCode?.targetLangCode === 'en' && setValue('2');
			languageCode?.targetLangCode === 'fr' && setValue('4');

			languageCode?.sourceLangCode === 'es' && setRightValue('3');
			languageCode?.sourceLangCode === 'en' && setRightValue('2');
			languageCode?.sourceLangCode === 'fr' && setRightValue('4');
		}
		if (sourceLang && targetLang) {
			if (value === '2' && rightvalue == '2') {
				RfSetValue('sourceLanguage', targetLang, { shouldDirty: true });
				RfSetValue('targetLanguage', sourceLang, { shouldDirty: true });
			} else {
				if (value === '2') {
					let newTargetLang;
					switch (rightvalue) {
						case '2':
							newTargetLang = { label: 'English', value: 'en' };
							break;
						case '3':
							newTargetLang = { label: 'Spanish', value: 'es' };
							break;
						case '4':
							newTargetLang = { label: 'French', value: 'fr' };
							break;
						default:
							newTargetLang = null;
					}
					RfSetValue('targetLanguage', sourceLang, { shouldDirty: true });
					RfSetValue('sourceLanguage', newTargetLang, { shouldDirty: true });
				} else if (rightvalue === '2') {
					let newSourceLang;
					switch (value) {
						case '2':
							newSourceLang = { label: 'English', value: 'en' };
							break;
						case '3':
							newSourceLang = { label: 'Spanish', value: 'es' };
							break;
						case '4':
							newSourceLang = { label: 'French', value: 'fr' };
							break;
						default:
							newSourceLang = null;
					}

					RfSetValue('sourceLanguage', targetLang, { shouldDirty: true });
					RfSetValue('targetLanguage', newSourceLang, { shouldDirty: true });
				} else {
					languageCode?.targetLangCode === 'es' && setValue('3');
					languageCode?.targetLangCode === 'en' && setValue('2');
					languageCode?.targetLangCode === 'fr' && setValue('4');

					languageCode?.sourceLangCode === 'es' && setRightValue('3');
					languageCode?.sourceLangCode === 'en' && setRightValue('2');
					languageCode?.sourceLangCode === 'fr' && setRightValue('4');
				}
			}
		}

		if (sourceLang && !targetLang) {
			let newTargetLang;
			switch (rightvalue) {
				case '2':
					newTargetLang = { label: 'English', value: 'en' };
					break;
				case '3':
					newTargetLang = { label: 'Spanish', value: 'es' };
					break;
				case '4':
					newTargetLang = { label: 'French', value: 'fr' };
					break;
				default:
					newTargetLang = null;
			}
			RfSetValue('targetLanguage', sourceLang, { shouldDirty: true });
			RfSetValue('sourceLanguage', newTargetLang, { shouldDirty: true });
		}

		if (targetLang && !sourceLang) {
			let newSourceLang;
			switch (value) {
				case '2':
					newSourceLang = { label: 'English', value: 'en' };
					break;
				case '3':
					newSourceLang = { label: 'Spanish', value: 'es' };
					break;
				case '4':
					newSourceLang = { label: 'French', value: 'fr' };
					break;
				default:
					newSourceLang = null;
			}

			RfSetValue('sourceLanguage', targetLang, { shouldDirty: true });
			RfSetValue('targetLanguage', newSourceLang, { shouldDirty: true });
		}

		setLanguageCode((prev) => ({
			sourceLangCode: prev?.targetLangCode,
			targetLangCode:
				prev?.sourceLangCode === 'Auto' ? 'en' : prev?.sourceLangCode,
		}));

		translation && setTextAreaValue(translation);
		textAreaValue.trim() && setTranslation(textAreaValue);
	};

	const speechRecognized = (data) => {
		if (data.isFinal) {
			setCurrentRecognition('');
			if (data.text) {
				setRecognitionHistory((old) => [
					{ text: data.text, time: new Date() },
					...old,
				]);
			}
		} else setCurrentRecognition(data.text);
	};

	useEffect(() => {
		Socket.on('connect_error', (err) => {
			console.log(`connect_error due to ${err.message}`);
		});

		setSocketConnection(Socket);
	}, []);

	useEffect(() => {
		if (firstTime) {
			setFirstTime(false);
			return;
		}
		(async () => {
			if (connection) {
				if (isRecording) {
					return;
				}

				const stream = await getMediaStream();

				audioContextRef.current = new window.AudioContext();

				await audioContextRef.current.audioWorklet.addModule(
					'/src/worklets/recorderWorkletProcessor.js'
				);

				audioContextRef.current.resume();

				audioInputRef.current =
					audioContextRef.current.createMediaStreamSource(stream);

				processorRef.current = new AudioWorkletNode(
					audioContextRef.current,
					'recorder.worklet'
				);

				processorRef.current.connect(audioContextRef.current.destination);
				audioContextRef.current.resume();

				audioInputRef.current.connect(processorRef.current);

				processorRef.current.port.onmessage = () => {
					const audioData = event.data;
					connection.emit('send_audio_data', { audio: audioData });
				};
				setIsRecording(true);
			}
		})();
		return () => {
			if (isRecording) {
				processorRef.current?.disconnect();
				audioInputRef.current?.disconnect();
				if (audioContextRef.current?.state !== 'closed') {
					audioContextRef.current?.close();
				}
			}
		};
	}, [connection, isRecording, recorder]);

	const handleStart = async () => {
		setCurrentRecognition('');
		setRecognitionHistory([]);

		setConnection(socketConnection);

		if (languageCode?.sourceLangCode == 'Auto') {
			Socket.emit('startGoogleCloudStream', deviceLanguage?.code);
		} else {
			Socket.emit('startGoogleCloudStream', languageCode?.sourceLangCode);
		}

		Socket.on('receive_audio_text', (data) => {
			speechRecognized(data);
		});
	};

	const handleStop = () => {
		if (!connection) return;
		connection?.emit('endGoogleCloudStream');
		// connection?.disconnect();
		processorRef.current?.disconnect();
		audioInputRef.current?.disconnect();
		audioContextRef.current?.close();
		setConnection(undefined);
		setRecorder(undefined);
		setIsRecording(false);
		Socket.off('receive_audio_text');
	};

	return (
		<>
			<Box className="bg-[#f8f8f8] text-trans">
				<Container className="mt-5">
					<Grid
						container
						className="bg-white rounded-lg shadow-lg"
						lg={12}
						md={12}
					>
						<LanguageSelectorMain
							handleChange={handleChange}
							control={control}
							righthandleChange={righthandleChange}
							value={value}
							setValue={setValue}
							rightvalue={rightvalue}
							setRightValue={setRightValue}
							selectedLang={selectedLang}
							setLanguageCode={handleLangCode}
							getValues={getValues}
							handleLanguageSwitch={handleLanguageSwitch}
						/>

						<Grid item md={6} xs={12} className="border-r-2 border-grey-100	">
							<Box className="">
								<div className="relative rounded-md border-0">
									<textarea
										value={textAreaValue}
										onChange={handleInputChange}
										type="text"
										placeholder=""
										style={{ resize: 'none', fontSize: '20px' }}
										autoFocus
										className="bg-white block w-full pl-8 pt-8 border-0 outline-none  h-80 focus:outline-none  focus:ring-0 focus:ring-0 focus:border-transparent"
									/>
									<Tab
										icon={<KeyboardVoiceIcon />}
										iconPosition="start"
										value="4"
										className="w-10 absolute"
										onClick={handleToggle}
										style={{
											color: isRecording ? 'red' : 'inherit',
											//bottom:'40px'
										}}
										// style={{bottom:'40px'}}
									/>
								</div>
							</Box>
						</Grid>

						<LanguageSelectorMobile
							handleChange={handleChange}
							control={control}
							righthandleChange={righthandleChange}
							value={value}
							setValue={setValue}
							rightvalue={rightvalue}
							setRightValue={setRightValue}
							selectedLang={selectedLang}
							setLanguageCode={handleLangCode}
						/>

						<Grid item md={6} xs={12} className="">
							<Box>
								<div className="relative rounded-0 border-0">
									<textarea
										type="text"
										style={{ fontSize: '20px', resize: 'none', width: '100%' }}
										placeholder="Translation..."
										className="bg-white block w-full pl-8 pt-8 border-0 outline-none  h-80 focus:outline-none  focus:ring-0 focus:ring-0 focus:border-transparent"
										readOnly
										value={translation}
									/>
									<Tab
										icon={<VolumeUpIcon />}
										iconPosition="start"
										value="4"
										className="w-10 absolute"
										onClick={handleSpeakerClick}
										style={{ marginBottom: '10px' }}
									/>
									{isLoading && (
										<div className="absolute inset-0 flex items-center justify-center">
											<div className="text-loader"></div>
										</div>
									)}

									<Tab
										icon={<ContentCopyIcon />}
										iconPosition="end"
										value="4"
										className="w-10 absolute"
										onClick={copyToClipboard}
										style={{ marginBottom: '10px' }}
									/>

									{isLoading && (
										<div className="absolute inset-0 flex items-center justify-center">
											<div className="text-loader"></div>
										</div>
									)}
									<Link
										to={'/talk-easy'}
										className="absolute font-[600] xz:left-52 xs:left-40 bottom-6 text-gray-600 cursor-pointer hover:text-custom-blue"
									>
										Go to Speak Easy
									</Link>
								</div>
							</Box>
						</Grid>
					</Grid>
					<Box>
						<div className="text-center pt-10">
							<p className="text-lg text-[#00000099]">
								Secure payment transctions are facilitated through
							</p>
							<div className="flex justify-center items-center">
								<img src={stripe} alt="stripe" width={150} />
							</div>
						</div>
					</Box>
				</Container>
			</Box>
		</>
	);
};

export default TextTranslation;
