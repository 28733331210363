import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
	return (
		<div className="bg-custom-blue flex justify-center items-center text-white text-xs sm:text-sm text-center p-2">
			{/* It's important to use `text-xs` instead of `text-[8px]` because Tailwind doesn't support arbitrary values like `[8px]` without custom configuration */}
			©2024 Copyright: LetzChat, Inc. All Rights Reserved.
			<Link className="mx-1" to="/terms-of-service">
				Terms of Service
			</Link>
			|
			<Link className="ml-1" to="/privacy-policy">
				Privacy Policy
			</Link>
		</div>
	);
};

export default Footer;
