/* eslint-disable react/prop-types */
import Select from 'react-select';

import React from 'react';
import { useController } from 'react-hook-form';

const CustomSelect = (props) => {
	const {
		field: { onChange },
	} = useController({ control: props.control, name: props.name });

	return (
		<div>
			<Select
				{...props}
				onChange={(val) => {
					onChange(val);
					if (props?.handleChange) {
						props.handleChange({ changeFor: props?.name });
					}
					if (props?.setLanguageToggle) {
						props.setLanguageToggle(props?.name);
					}
				}}
				value={props?.value}
				placeholder={props.placeholder}
				styles={{
					// eslint-disable-next-line no-unused-vars
					control: (base, state) => ({
						// border: 0,
						// ...base,
						display: 'flex',
						padding: '5px',
						paddingLeft: '2em',
						// borderColor:
						// 	props.errors && props.errors[props.name]
						// 		? 'red'
						// 		: 'rgb(243 244 246)',
					}),
					valueContainer: () => ({
						// padding: '10px 0',
						width: '100%',
						// maxHeight: 40,
						display: 'flex',
						alignItems: 'center',
					}),
					multiValue: () => ({
						display: 'flex',
						width: 'fit-content',
						background: 'rgba(76 ,29, 149,0.2)',
						borderRadius: 0,
						// padding: '0 10px',
						alignItems: 'center',
						// margin: '0 3px',
					}),
					option: (provided, state) => ({
						...provided,
						backgroundColor: state.isSelected ? '#6db7f2' : '#fff',
						color: state.isSelected ? '6db7f2' : 'gray-800',
						width: '100%',
					}),
					// dropdownIndicator: () => ({ display: 'none' }),
					indicatorSeparator: () => ({ display: 'none' }),
				}}
			/>
			{props.errors && props.errors[props.name] && (
				<p className="text-red-600 mb-0 ml-1">
					{props?.errors[props.name]?.label?.message}
				</p>
			)}
		</div>
	);
};

export default CustomSelect;
