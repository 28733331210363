import {
	Avatar,
	Box,
	Container,
	IconButton,
	Menu,
	MenuItem,
	Tooltip,
} from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Loader from './loader';
import curveImg from 'assets/curve.png';
import logo from 'assets/logo.png';
import userProfile from 'assets/user-profile.jpeg';

import UpgradeIcon from 'assets/upgradeicon.svg';
import { logout } from 'store/user';
import FeedbackModule from './FeedbackModule';

// eslint-disable-next-line react/prop-types
const Header = ({ styles }) => {
	const [submitOpen, SetSubmitOpen] = useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	const profileInformation = useSelector(
		(state) => state?.user?.profileInformation
	);

	const handleLogout = () => {
		dispatch(logout());
		navigate('/');
		// console.log();
	};
	const handleSubmitFeedback = () => {
		SetSubmitOpen(true);
	};
	const handleCloseSubmitFeedback = () => {
		SetSubmitOpen(false);
	};
	return (
		<>
			{!profileInformation && <Loader />}
			<FeedbackModule
				open={submitOpen}
				handleClose={handleCloseSubmitFeedback}
			/>

			<Box className="bg-[#f8f8f8]">
				<Box className="bg-white pt-3 pb-3 z-30">
					<Container
						maxWidth="lg"
						className="d-flex justify-content-between align-items-center profiles-user-image"
					>
						<div>
							<Link to="/">
								<img
									alt="company logo"
									src={logo}
									width="180"
									height="180"
									className="xs:max-xm:w-20  xm:max-sm:w-28  d-inline-block align-top pt-3 pb-3"
								/>
							</Link>
						</div>
						<div className="flex  ">
							{' '}
							<div className="flex items-center mr-8">
								<button
									onClick={handleSubmitFeedback}
									className=" xs:max-xm:text-[8px] xm:max-sm:text-sm flex items-center border-2 border-[#6DB7F2] xs:max-xm:px-2 xs:max-xm:py-1 px-8 py-3 text-[#6DB7F2] "
								>
									Submit Feedback{' '}
								</button>
							</div>
							<div className="flex items-center">
								<Link to="/upgrade">
									<button className=" xs:max-xm:text-[8px] xm:max-sm:text-sm flex items-center border-2 border-[#6DB7F2] xs:max-xm:px-2 xs:max-xm:py-1 px-8 py-3 text-[#6DB7F2] ">
										<img
											className="xs:max-xm:w-2 xm:max-sm:w-4 mr-0.5 hover:fill-black	"
											src={UpgradeIcon}
										/>
										Upgrade to Pro{' '}
									</button>
								</Link>
							</div>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									textAlign: 'center',
								}}
								className="z-20"
							>
								<IconButton
									onClick={handleClick}
									size="small"
									sx={{ ml: 2 }}
									aria-controls={open ? 'account-menu' : undefined}
									aria-haspopup="true"
									aria-expanded={open ? 'true' : undefined}
								>
									<img
										alt="Profile"
										src={`${
											profileInformation?.profilePicture
												? profileInformation?.profilePicture
												: userProfile
										}`}
										width="50"
										height="50"
										style={{
											borderRadius: '50%',
											cursor: 'pointer',
											zIndex: '',
											width: '60px',
											height: '60px',
										}}
										// onClick={() => navigate('/user-portal')}
										className="relative "
									/>
								</IconButton>
							</Box>
						</div>

						<Menu
							anchorEl={anchorEl}
							id="account-menu"
							open={open}
							onClose={handleClose}
							onClick={handleClose}
							PaperProps={{
								elevation: 0,
								sx: {
									overflow: 'visible',
									filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
									mt: 1.5,
									'& .MuiAvatar-root': {
										width: 32,
										height: 32,
										ml: -0.5,
										mr: 1,
									},
									'&:before': {
										content: '""',
										display: 'block',
										position: 'absolute',
										top: 0,
										right: 14,
										width: 10,
										height: 10,
										bgcolor: 'background.paper',
										transform: 'translateY(-50%) rotate(45deg)',
										zIndex: 0,
									},
								},
							}}
							transformOrigin={{ horizontal: 'right', vertical: 'top' }}
							anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
						>
							<MenuItem
								onClick={() => navigate('/user-portal')}
								className="hell02"
							>
								Profile
							</MenuItem>

							{profileInformation?.role === 'Admin' && (
								<>
									<MenuItem
										onClick={() => {
											handleClose();
											navigate('/users-panel');
										}}
									>
										Users
									</MenuItem>
								</>
							)}
							{profileInformation?.role === 'Admin' && (
								<>
									<MenuItem
										onClick={() => {
											handleClose();
											navigate('/agents-panel');
										}}
									>
										Agents
									</MenuItem>
								</>
							)}
							{(profileInformation?.role === 'Admin' ||
								profileInformation?.role === 'Agent') && (
								<MenuItem
									onClick={() => {
										handleClose();
										navigate('/agent-portal');
									}}
								>
									Agent Portal
								</MenuItem>
							)}
							<MenuItem
								onClick={() => {
									handleClose();
									navigate('/document-translation-history');
								}}
							>
								Document Translation History
							</MenuItem>
							<MenuItem
								onClick={() => {
									handleClose();
									navigate('/voice-dubbing-history');
								}}
							>
								Voice Dubbing History
							</MenuItem>
							<MenuItem onClick={() => navigate('/billing-information')}>
								{' '}
								Billing Information{' '}
							</MenuItem>
							<MenuItem onClick={handleLogout}>Logout</MenuItem>
						</Menu>
					</Container>
				</Box>
				<div className="profile-menu">
					<Menu
						anchorEl={
							isMenuOpen ? document.getElementById('profile-menu') : null
						}
						open={isMenuOpen}
						onClose={toggleMenu}
						className="profile-menu-list"
					></Menu>
				</div>
				<Box>
					<img
						src={curveImg}
						className="absolute top-0 right-0 curve-img-mobile"
						style={{ zIndex: '-111' }}
					/>
				</Box>
			</Box>
		</>
	);
};

export default Header;
