import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import api from 'utils/axios';
import MinimalLoader from 'component/MinimalLoader';
import styles from './listener.module.scss';
import { create } from '@mui/material/styles/createTransitions';
import { createNotification } from 'utils/create-notification';

const Listener = () => {
	const [qrCode, setQrCode] = useState(null);
	const [url, setUrl] = useState(null);
	const [loading, setLoading] = useState(false);

	const profileInformation = useSelector(
		(state) => state?.user?.profileInformation
	);
	const updateQrCode = async () => {
		alert('updateQrCode');
		try {
			setLoading(true);
			const res = await api.put(
				'https://api.unite.letz.chat/api/unite/update-qr-room'
			);
			console.log(res.data, 'res in updateQrCode');
			const { qrCodeBase64, url } = res.data;
			setUrl(url);
			setQrCode(qrCodeBase64);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log(error, 'error in updateQrCode');
			createNotification('error', 'Error', 'Error in updating QR code');
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (profileInformation?.unite?.qr) {
			setQrCode(
				profileInformation?.unite?.qr?.replace('data:image/png;base64,', '')
			);
		} else if (profileInformation?.status === 'Active') {
			updateQrCode();
		}
	}, [profileInformation]);

	return (
		<div className="flex justify-center gap-8">
			<div className="px-3 py-16 bg-[#E5F3FF] xs:w-[100%] rounded-3xl flex flex-col items-center justify-center gap-3 z-10">
				<button
					onClick={updateQrCode}
					className="sm:text-xl xs:text-md text-center  bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded cursor-pointer"
					disabled={loading}
				>
					{loading ? <MinimalLoader color={'#8B8000'} /> : 'Update QR code'}
				</button>
				<img
					id="base64Image"
					src={`data:image/png;base64,${
						qrCode ? qrCode : profileInformation?.unite?.qr
					}`}
					alt="Base64"
				/>
				<a
					href={`data:image/png;base64,${
						qrCode ? qrCode : profileInformation?.unite?.qr
					}`}
					download="letzchat-unite-qr.png"
				>
					<p className="sm:text-xl xs:text-md text-center text-custom-blue">
						Download QR Code
					</p>
				</a>

				<a
					href={url ? url : profileInformation?.unite?.url}
					target="_blank"
					rel="noopener noreferrer"
				>
					<p
						style={{
							textAlign: 'center',
							overflowWrap: 'break-word',
							maxWidth: 'calc(100% - 2rem)', // adjust this as needed
							overflow: 'hidden',
						}}
						className={`sm:text-xl xs:text-md text-center text-custom-blue whitespace-normal ${styles.breakUrl}`}
					>
						{url ? url : profileInformation?.unite?.url}
					</p>
				</a>
				<p className="sm:text-xl xs:text-md text-center">
					Scan to access Listener Page
				</p>
			</div>
		</div>
	);
};

export default Listener;
