import Translation from 'pages/Translation';
import Login from 'pages/auth/Login';
import UserPortal from 'pages/profile/UserPortal';
import DocumentTranslation from 'pages/translation/document-translation/DocumentTranslation';
import ImageTranslation from 'pages/translation/image-translation/ImageTranslation';
import TextTranslation from 'pages/translation/text-translation/TextTranslation';
import Users from 'pages/translation/users/Users';
import UserView from 'pages/translation/users/UserView';
import WebsiteTranslation from 'pages/translation/website-translation/WebsiteTranslation';
import AgentPortal from 'pages/translation/agent-portal/AgentPortal';
import Signup from 'pages/auth/Signup';
import ForgotPassword from 'pages/auth/ForgotPassword';
import ResetPassword from 'pages/auth/ResetPassword';
import DocumentHistory from 'pages/translation/users/DocumentHistory';
import Agents from 'pages/translation/agents/Agents';
import RefferedUser from 'pages/translation/agents/RefferedUser';
import TalkEasy from 'pages/talkeasy/TalkEasy';
import VoiceDubbing from 'pages/translation/voice-dubbing/VoiceDubbing';
import Unite from 'pages/translation/unite/Unite';
import UnitePage from 'pages/translation/unite/UnitePage';
import ZoomSubtitling from 'pages/translation/zoom-subtitling/ZoomSubtitling';
import ZoomConfigInfo from 'pages/translation/zoom-subtitling/ZoomConfigInfo';
import DocumentTranslationHistory from 'pages/translation/document-transltion-history/DocumentTranslationHistory';
import VoiceDubbingHistory from 'pages/translation/voice-dubbing-history/VoiceDubbingHistory';
import PodcastTranslation from 'pages/translation/podcast-translation';
import PodcastPage from 'pages/translation/podcast-translation/PodcastPage';
import PresenterPage from 'pages/translation/podcast-translation/presenter-page';
import IncommingCall from 'pages/translation/twilio/IncommingCall';
import UpgradePage from 'pages/translation/upgrade/Upgrade';
import PublicHeader from 'component/PublicHeader';
import Header from 'component/Header';
import BillingInformation from 'pages/translation/billing-information/BillingInformation';
import WebsiteAnalytics from 'pages/website-analytics/websiteAnalytics';

export const privateRoutes = [
	{
		path: '/',
		component: (
			<div>
				<Translation />
				<TextTranslation />
			</div>
		),
	},
	{
		path: '/image-translation',
		component: (
			<div>
				<Translation />
				<ImageTranslation />
			</div>
		),
	},
	{
		path: '/doc-translation',
		component: (
			<div>
				<Translation />
				<DocumentTranslation />
			</div>
		),
	},
	{
		path: '/web-translation',
		component: (
			<div>
				<Translation />
				<WebsiteTranslation />
			</div>
		),
	},
	{
		path: '/voice-dubbing',
		component: (
			<div>
				<Translation />
				<VoiceDubbing />,
			</div>
		),
	},
	{
		path: '/unite',
		component: (
			<div>
				<Translation />
				<Unite />,
			</div>
		),
	},
	{
		path: '/zoom-subtitling',
		component: (
			<div>
				<Translation />
				<ZoomSubtitling />,
			</div>
		),
	},
	{
		path: '/zoom-subtitling/config-info',
		component: (
			<div>
				<ZoomConfigInfo />,
			</div>
		),
	},
	{
		path: '/talk-easy',
		component: (
			<div>
				<Translation />
				<TalkEasy />,
			</div>
		),
	},
	{
		path: '/user-portal',
		component: <UserPortal />,
	},
	{
		path: '/unite/:name/:room',
		component: (
			<div>
				{/* <Translation /> */}
				<UnitePage />,
			</div>
		),
	},
	{
		path: '/document-translation-history',
		component: (
			<div>
				<Translation />
				<DocumentTranslationHistory />
			</div>
		),
	},
	{
		path: '/voice-dubbing-history',
		component: (
			<div>
				<Translation />
				<VoiceDubbingHistory />
			</div>
		),
	},
	{
		path: '/podcast-translation',
		component: (
			<div>
				<Translation />
				<PodcastTranslation />
			</div>
		),
	},
	{
		path: '/podcast-translation/:roomId',
		component: (
			<div>
				<PodcastPage />,
			</div>
		),
	},
	{
		path: '/podcast-translation/presenter/:presenterId',
		component: (
			<div>
				<PresenterPage />,
			</div>
		),
	},
	{
		path: '/twilio/incomming-call/:userId',
		component: (
			<div>
				<IncommingCall />,
			</div>
		),
	},
	{
		path: '/upgrade',
		component: (
			<div>
				{/* <PublicHeader />, */}
				<Header />,
				<UpgradePage />,
			</div>
		),
	},
	{
		path: '/billing-information',
		component: (
			<div>
				<BillingInformation />,
			</div>
		),
	},
	{
		path: '/website-analytics',
		component: (
			<div>
				<Translation />,
				<WebsiteAnalytics />
			</div>
		),
	},
];

export const publicRoute = [
	{
		path: '/login',
		component: <Login />,
	},
	{
		path: '/signup',
		component: <Signup />,
	},
	{
		path: '/forgot-password',
		component: <ForgotPassword />,
	},
	{
		path: '/reset-password/:userId',
		component: <ResetPassword />,
	},
	{
		path: '/unite/:name/:room',
		component: (
			<div>
				{/* <Translation /> */}
				<UnitePage />,
			</div>
		),
	},
	{
		path: '/zoom-subtitling/config-info',
		component: (
			<div>
				<ZoomConfigInfo /> ,
			</div>
		),
	},
	{
		path: '/podcast-translation/presenter/:presenterId',
		component: (
			<div>
				<PresenterPage />,
			</div>
		),
	},
	{
		path: '/podcast-translation/:roomId',
		component: (
			<div>
				<PodcastPage />,
			</div>
		),
	},
	{
		path: '/twilio/incomming-call/:userId',
		component: (
			<div>
				<IncommingCall />,
			</div>
		),
	},
];

export const adminRoute = [
	{
		path: '/',
		component: (
			<div>
				<Translation />
				<TextTranslation />
			</div>
		),
	},
	{
		path: '/talk-easy',
		component: (
			<div>
				<Translation />
				<TalkEasy />,
			</div>
		),
	},
	{
		path: '/image-translation',
		component: (
			<div>
				<Translation />
				<ImageTranslation />
			</div>
		),
	},
	{
		path: '/doc-translation',
		component: (
			<div>
				<Translation />
				<DocumentTranslation />
			</div>
		),
	},
	{
		path: '/web-translation',
		component: (
			<div>
				<Translation />
				<WebsiteTranslation />
			</div>
		),
	},
	{
		path: '/voice-dubbing',
		component: (
			<div>
				<Translation />
				<VoiceDubbing />,
			</div>
		),
	},
	{
		path: '/unite',
		component: (
			<div>
				<Translation />
				<Unite />,
			</div>
		),
	},
	{
		path: '/zoom-subtitling',
		component: (
			<div>
				<Translation />
				<ZoomSubtitling />,
			</div>
		),
	},
	{
		path: '/zoom-subtitling/config-info',
		component: (
			<div>
				<ZoomConfigInfo />
			</div>
		),
	},
	{
		path: '/user-portal',
		component: <UserPortal />,
	},
	{
		path: '/users-panel',
		component: (
			<div>
				<Translation />
				<Users />
			</div>
		),
	},
	{
		path: '/agents-panel',
		component: (
			<div>
				<Translation />
				<Agents />
			</div>
		),
	},
	{
		path: '/document-translation-history',
		component: (
			<div>
				<Translation />
				<DocumentTranslationHistory />
			</div>
		),
	},
	{
		path: '/user-view/:userId',
		component: <UserView />,
	},
	{
		path: '/document-history/:userId',
		component: <DocumentHistory />,
	},
	{
		path: '/reffered-user/:userId',
		component: <RefferedUser />,
	},
	{
		path: '/agent-portal',
		component: (
			<div>
				<Translation />
				<AgentPortal />
			</div>
		),
	},
	{
		path: '/unite/:name/:room',
		component: (
			<div>
				{/* <Translation /> */}
				<UnitePage />,
			</div>
		),
	},
	{
		path: '/voice-dubbing-history',
		component: (
			<div>
				<Translation />
				<VoiceDubbingHistory />
			</div>
		),
	},
	{
		path: '/podcast-translation',
		component: (
			<div>
				<Translation />
				<PodcastTranslation />
			</div>
		),
	},
	{
		path: '/podcast-translation/presenter/:presenterId',
		component: (
			<div>
				<PresenterPage />,
			</div>
		),
	},
	{
		path: '/podcast-translation/:roomId',
		component: (
			<div>
				<PodcastPage />,
			</div>
		),
	},
	{
		path: '/upgrade',
		component: (
			<div>
				{/* <PublicHeader /> */}
				<Header />,
				<UpgradePage />,
			</div>
		),
	},
	{
		path: '/billing-information',
		component: (
			<div>
				{/* <PublicHeader />, */}
				{/* <Header />, */}
				<BillingInformation />,
			</div>
		),
	},
	{
		path: '/website-analytics',
		component: (
			<div>
				<Translation />,
				<WebsiteAnalytics />
			</div>
		),
	},
];

export const agentRoute = [
	{
		path: '/agent-portal',
		component: (
			<div>
				<Translation />
				<AgentPortal />
			</div>
		),
	},
	{
		path: '/document-translation-history',
		component: (
			<div>
				<Translation />
				<DocumentTranslationHistory />
			</div>
		),
	},
	{
		path: '/voice-dubbing-history',
		component: (
			<div>
				<Translation />
				<VoiceDubbingHistory />
			</div>
		),
	},
	{
		path: '/podcast-translation/presenter/:presenterId',
		component: (
			<div>
				<PresenterPage />,
			</div>
		),
	},
	{
		path: '/podcast-translation/:roomId',
		component: (
			<div>
				<PodcastPage />,
			</div>
		),
	},
	{
		path: '/website-analytics',
		component: (
			<div>
				<Translation />,
				<WebsiteAnalytics />
			</div>
		),
	},
];
