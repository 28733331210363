import axios from 'axios';

const api = axios.create({
	// eslint-disable-next-line no-undef
	baseURL: `${process.env.REACT_APP_BASE_URL}/api/`,
});

api.interceptors.request.use(
	(config) => {
		const user = localStorage.getItem('user');
		if (user) {
			const token = JSON.parse(user)?.token;
			config.headers['Authorization'] = token;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

export default api;
