import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Container, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
	deleteLogoPictureFunc,
	deleteProfilePictureFunc,
	getProfileInfoAction,
	updateProfileInformationAction,
	updateProfileLogoFunc,
	updateProfilePictureFunc,
} from 'store/user';
import rightCurve from 'assets/right-curve.png';
import leftCurve from 'assets/left-curve.png';
import userProfile from 'assets/user-profile.jpeg';
import comapnyLogo from 'assets/company-logo.png';
import Loader from 'component/loader';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import MainHeader from 'component/MainHeader';
import Header from 'component/Header';

// Define schema for form validation
const schema = yup.object().shape({
	username: yup.string(),
	businessname: yup.string(),
	currentpass: yup
		.string()
		.test('currentpass', 'Current Password is required', function () {
			const { currentpass, newpass, confirmpass } = this.parent;
			if (!currentpass && !newpass && !confirmpass) {
				return true;
			}
			if (!currentpass || currentpass.length < 8) {
				return this.createError({
					message:
						'Current Password is required and must be at least 8 characters',
				});
			}
			return true;
		}),
	newpass: yup
		.string()
		.test('newpass', 'New Password is required', function () {
			const { currentpass, newpass, confirmpass } = this.parent;
			if (!currentpass && !newpass && !confirmpass) {
				return true;
			}
			if (!newpass || newpass.length < 8) {
				return this.createError({
					message: 'New Password is required and must be at least 8 characters',
				});
			}
			return true;
		}),
	confirmpass: yup
		.string()
		.oneOf([yup.ref('newpass'), null], 'Passwords must match')
		.test('confirmpass', 'Confirmed Password is required', function () {
			const { currentpass, newpass, confirmpass } = this.parent;
			if (!currentpass && !newpass && !confirmpass) {
				return true;
			}
			if (!confirmpass || confirmpass.length < 8) {
				return this.createError({
					message:
						'Confirmed Password is required and must be at least 8 characters',
				});
			}
			return true;
		}),
});

const UserPortal = () => {
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user);
	const profileInformation = useSelector(
		(state) => state?.user?.profileInformation
	);
	const fileInput = useRef();
	const logoInput = useRef();

	useEffect(() => {
		dispatch(getProfileInfoAction());
	}, []);

	const methods = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			username: '',
			businessname: '',
			currentpass: '',
			newpass: '',
			confirmpass: '',
		},
	});

	const {
		handleSubmit,
		control,
		setValue,
		formState: { errors },
	} = methods;

	useEffect(() => {
		if (profileInformation) {
			setValue('username', profileInformation.userName);
			setValue('businessname', profileInformation.businessName);
		}
	}, [profileInformation, setValue]);

	const onSubmit = async (data) => {
		// console.log(data);
		setLoading(true);
		const body = {
			businessName: data?.businessname,
			userName: data?.username,
		};
		if (data?.newpass) {
			body.oldPassword = data?.currentpass;
			body.password = data?.newpass;
		}
		console.log(body);
		await dispatch(updateProfileInformationAction(body));
		// await dispatch(getProfileInfoAction());
		setLoading(false);
	};

	const handleButtonClick = () => {
		fileInput.current.click();
	};

	const handleLogoButtonClick = () => {
		logoInput.current.click();
	};

	const handleFileChange = async (e) => {
		const file = e.target.files[0];

		// Validate file type
		if (!file.type.startsWith('image/')) {
			alert('Please select an image file!');
			return;
		}

		// Convert file to base64
		const reader = new FileReader();
		reader.onloadend = async () => {
			const base64 = reader.result;
			setLoading(true);
			await dispatch(updateProfilePictureFunc(base64));
			// console.log(base64);
			setLoading(false);
		};
		reader.readAsDataURL(file);
	};

	const handleLogoChange = async (e) => {
		const file = e.target.files[0];

		// Validate file type
		if (!file.type.startsWith('image/')) {
			alert('Please select an image file!');
			return;
		}

		// Convert file to base64
		const reader = new FileReader();
		reader.onloadend = async () => {
			const base64 = reader.result;
			setLoading(true);
			await dispatch(updateProfileLogoFunc(base64));
			// console.log(base64);
			setLoading(false);
		};
		reader.readAsDataURL(file);
	};

	const profilePictureDeleteHandler = async () => {
		setLoading(true);
		await dispatch(deleteProfilePictureFunc());
		setLoading(false);
	};

	const logoPictureDeleteHandler = async () => {
		setLoading(true);
		await dispatch(deleteLogoPictureFunc());
		setLoading(false);
	};

	return (
		<>
			{loading && <Loader />}
			{!profileInformation && <Loader />}
			<form onSubmit={handleSubmit(onSubmit)}>
				<Box className="h-auto">
					<Header />
					<Box>
						<img
							src={rightCurve}
							className="absolute top-0 right-0 "
							alt="right-curve"
						/>
					</Box>
					<Box>
						<img
							src={leftCurve}
							className="fixed bottom-0 left-0"
							alt="left-curve"
							width={500}
						/>
					</Box>

					<Container className="relative">
						<div className="flex flex-col justify-center">
							<div className="mb-5">
								<div className="">
									<div className="profile-user-text my-7">
										<h1 className="text-4xl font-bold text-[#6B6B6B]">
											User Portal Page
										</h1>
									</div>
									<div className="grid md:grid-cols-2 sm:grid-cols-1">
										<div className="">
											<div className="bg-[#f2f2f2] rounded-2xl p-9 pb-2 mb-5 grey-box overflow-auto">
												<div>
													<h2 className="text-md text-[#6B6B6B] mb-5">
														Edit Profile
													</h2>
												</div>
												<div className="mb-5 flex flex-row justify-between items-center">
													<div className="profile-img-wrapper">
														<img
															src={`${
																profileInformation?.profilePicture
																	? profileInformation?.profilePicture
																	: userProfile
															}`}
															width={120}
															height={80}
															className="rounded-2xl"
															alt="profile-img"
														/>
													</div>
													<div className="profile-btns-wrapper flex flex-col">
														<>
															<input
																type="file"
																ref={fileInput}
																style={{ display: 'none' }}
																accept="image/png, image/gif, image/jpeg"
																onChange={handleFileChange}
															/>
															<Button
																variant="contained"
																type="button"
																className="edit-button change-profile-btn"
																onClick={handleButtonClick}
																style={{ marginLeft: '10px' }}
															>
																Change Profile
															</Button>
														</>

														<Button
															variant="outlined"
															type="button"
															className="edit-button delete-profile-btn"
															onClick={profilePictureDeleteHandler}
															style={{ marginLeft: '10px' }}
														>
															Delete
														</Button>
													</div>
												</div>
												<div className="mb-5 mt-6 flex flex-row justify-between items-center">
													<div className="profile-img-wrapper">
														<img
															src={`${
																profileInformation?.companyLogo
																	? profileInformation?.companyLogo
																	: comapnyLogo
															}`}
															width={120}
															height={80}
															className="rounded-2xl"
															alt="company-logo"
														/>
													</div>
													<div className="profile-btns-wrapper flex flex-col">
														<>
															<input
																type="file"
																ref={logoInput}
																style={{ display: 'none' }}
																accept="image/png, image/gif, image/jpeg"
																onChange={handleLogoChange}
															/>
															<Button
																variant="contained"
																type="button"
																className="edit-button change-profile-btn"
																onClick={handleLogoButtonClick}
																style={{ marginLeft: '10px' }}
															>
																Change Logo
															</Button>
														</>

														<Button
															variant="outlined"
															type="button"
															className="edit-button delete-profile-btn"
															onClick={logoPictureDeleteHandler}
															style={{ marginLeft: '10px' }}
														>
															Delete
														</Button>
													</div>
												</div>
												<div className="mb-5">
													<label className="block text-sm font-medium text-gray-700 mb-2">
														Username
													</label>
													<Controller
														name="username"
														control={control}
														render={({ field }) => (
															<input
																{...field}
																id="username"
																className="style-input-user w-full"
																label="username"
																type="name"
																size="medium"
															/>
														)}
													/>
													{errors?.username && (
														<p className="text-red-500">
															{errors?.username?.message}
														</p>
													)}
												</div>
												<div className="mb-5 w-full">
													<label className="block text-sm font-medium text-gray-700 mb-2">
														Businessname
													</label>
													<Controller
														name="businessname"
														control={control}
														render={({ field }) => (
															<input
																{...field}
																id="businessname"
																className="style-input-user w-full"
																label="Businessname"
																size="medium"
															/>
														)}
													/>
													{errors?.businessname && (
														<p className="text-red-500">
															{errors?.businessname?.message}
														</p>
													)}
												</div>
											</div>
										</div>

										<div className="">
											<div className="bg-[#f2f2f2] rounded-2xl p-9 pb-2 mb-5 grey-box">
												<div className="mb-5 pl-5">
													<label className="block text-sm font-medium text-gray-700 mb-2">
														Current Password
													</label>
													<Controller
														name="currentpass"
														control={control}
														render={({ field }) => (
															<input
																{...field}
																id="currentpass"
																className="style-input-user w-full"
																label="******"
																type="password"
																size="medium"
															/>
														)}
													/>
													{errors?.currentpass && (
														<p className="text-red-500">
															{errors?.currentpass?.message}
														</p>
													)}
												</div>

												<div className="mb-5 pl-5">
													<label className="block text-sm font-medium text-gray-700 mb-2">
														New Password
													</label>
													<Controller
														name="newpass"
														control={control}
														render={({ field }) => (
															<input
																{...field}
																id="newpass"
																className="style-input-user w-full"
																label="******"
																type="password"
																size="medium"
															/>
														)}
													/>
													{errors?.newpass && (
														<p className="text-red-500">
															{errors?.newpass?.message}
														</p>
													)}
												</div>
												<div className="mb-5 pl-5">
													<label className="block text-sm font-medium text-gray-700 mb-2">
														Confirmed Password
													</label>
													<Controller
														name="confirmpass"
														control={control}
														render={({ field }) => (
															<input
																{...field}
																id="confirmpass"
																className="style-input-user w-full"
																label="******"
																type="password"
																size="medium"
															/>
														)}
													/>
													{errors?.confirmpass && (
														<p className="text-red-500">
															{errors?.confirmpass?.message}
														</p>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="flex justify-center items-center">
								<Button variant="contained" type="submit" className="save-btn">
									Save
								</Button>
							</div>
						</div>
					</Container>
				</Box>
			</form>
			<div className="mt-12"></div>
		</>
	);
};

export default UserPortal;
