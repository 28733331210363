import React, { useEffect, useRef, useState } from 'react';
import Mic from 'assets/mic.svg';
import Modal from 'component/Modal';
import styles from './presenter.module.scss';
import MicRed from 'assets/micRed.svg';
import * as io from 'socket.io-client';
import { createNotification } from 'utils/create-notification';
import MinimalLoader from 'component/MinimalLoader';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getRandomInt } from 'utils/helper';
import api from 'utils/axios';
import ReactSwitch from 'react-switch';
import CustomSelect from 'component/CustomSelect';
import { useForm } from 'react-hook-form';

import style from './scrollbar.module.scss';
import CircleLoader from 'component/NewLoader/NewLoader';

const options = [
	{ label: `0.2's`, value: 0.2 },
	{ label: `0.4's`, value: 0.4 },
	{ label: `0.6's`, value: 0.6 },
	{ label: `0.8's`, value: 0.8 },
	{ label: `1.0's`, value: 1.0 },
	{ label: `1.5's`, value: 1.5 },
	{ label: `2.0's`, value: 2.0 },
	{ label: `3.0's`, value: 3.0 },
	{ label: `4.0's`, value: 4.0 },
];

const getMediaStream = (deviceId) => {
	console.log('deviceId', { deviceId: deviceId || 'default' });
	return navigator.mediaDevices.getUserMedia({
		audio: {
			deviceId: deviceId || 'default', // Use selected device ID or 'default'
			sampleRate: 16000,
			sampleSize: 16,
			channelCount: 1,
		},
		video: false,
	});
};

const Presenter = ({ sourceLanguage, deviceLanguage }) => {
	const [modalTranscriptDelete, setModalTranscriptDelete] = useState(false);
	const [modalQuestionDelete, setModalQuestionDelete] = useState(false);
	const [open, setOpen] = useState(false);
	const [connection, setConnection] = useState(null);
	const [currentPhrase, setCurrentPhrase] = useState('');
	const [currentRecognition, setCurrentRecognition] = useState('');
	const [recognitionHistory, setRecognitionHistory] = useState([]);
	const [isRecording, setIsRecording] = useState(false);
	const [recorder, setRecorder] = useState();
	const [isStreamLoading, setIsStreamLoading] = useState(false);
	const [receivedQuestions, setReceivedQuestions] = useState([]);
	const [inputValues, setInputValues] = useState({});
	const [buttonStates, setButtonStates] = useState({});
	const [uniqueLanguages, setUniqueLanguages] = useState([]);
	const [totalLanguages, setTotalLanguages] = useState({});
	const [dbMessages, setDbMessages] = useState([]);
	const [dbQuestions, setDbQuestions] = useState([]);
	const [getTranscriptState, setGetTranscriptState] = useState(false);
	const [getQuestionsState, setGetQuestionsState] = useState(false);
	const [loading, setLoading] = useState(false);
	const [questionsPage, setQuestionsPage] = useState(1);
	const [getMessagesLoading, setGetMessagesLoading] = useState(false);
	const [getQuestionsLoading, setGetQuestionsLoading] = useState(false);
	const [hasMoreQuestions, setHasMoreQuestions] = useState(true);
	const [hasMoreMessage, setHasMoreMessage] = useState(true);
	const [autoSend, setAutoSend] = useState(false);
	const [page, setPage] = useState(1);
	const [inputDeviceOptions, setInputDeviceOptions] = useState([]);

	const socketConnection = useRef(null);
	const textareaRefs = useRef([]);
	const processorRef = useRef();
	const audioContextRef = useRef();
	const audioInputRef = useRef();
	const socketRef = useRef();
	const currentRecognitionRef = useRef();
	const autoSendRef = useRef();
	const transcriptRef = useRef(null);
	const questionsRef = useRef(null);

	const profileInformation = useSelector(
		(state) => state?.user?.profileInformation
	);

	const { control, watch } = useForm();

	useEffect(() => {
		if (watch('pauseOnSilentDuration')?.value) {
			socketRef?.current?.emit(
				'set_silence_duration',
				watch('pauseOnSilentDuration')?.value
			);
		}
	}, [watch('pauseOnSilentDuration')?.value]);

	useEffect(() => {
		const transcriptElement = transcriptRef.current;
		if (transcriptElement) {
			transcriptElement.addEventListener('scroll', handleTranscriptScroll);
		}
		return () => {
			if (transcriptElement) {
				transcriptElement.removeEventListener('scroll', handleTranscriptScroll);
			}
		};
	}, [page, getMessagesLoading]);

	useEffect(() => {
		const questionsElement = questionsRef.current;
		if (questionsElement) {
			questionsElement.addEventListener('scroll', handleQuestionsScroll);
		}
		return () => {
			if (questionsElement) {
				questionsElement.removeEventListener('scroll', handleQuestionsScroll);
			}
		};
	}, [questionsPage, getQuestionsLoading]);

	useEffect(() => {
		if (profileInformation?.unite?.url?.split('/')[5]) {
			getMessages(1, 5);
			getQuestions(1, 5);
		}
	}, [getTranscriptState, profileInformation, getQuestionsState]);

	useEffect(() => {
		const newInputValues = { ...inputValues };
		const newButtonStates = { ...buttonStates };

		recognitionHistory.forEach((tx) => {
			if (newInputValues[tx.id] === undefined) {
				newInputValues[tx.id] = { ...tx };
			}
			if (newButtonStates[tx.id] === undefined) {
				newButtonStates[tx.id] = false;
			}
		});

		setInputValues(newInputValues);
		setButtonStates(newButtonStates);
	}, [recognitionHistory]);

	useEffect(() => {
		textareaRefs.current.forEach((textareaRef) => {
			resizeTextarea(textareaRef);
		});
	}, [inputValues]);

	useEffect(() => {
		socketRef?.current?.on('connect', () => {
			console.log('connected socket manager');
		});
	}, []);

	useEffect(() => {
		currentRecognitionRef.current = currentRecognition;
		autoSendRef.current = autoSend;
	}, [currentRecognition, autoSend]);

	useEffect(() => {
		if (profileInformation?.unite?.url) {
			// eslint-disable-next-line no-undef
			socketRef.current = io.connect('https://api.unite.letz.chat', {
				reconnection: true,
			});

			socketRef.current.on('connect', () => {
				console.log('connected', socketRef.current.id);
				socketRef?.current?.emit(
					'join_room_unite',
					profileInformation?.unite?.url?.split('/')[5],
					sourceLanguage,
					true
				);
			});

			socketRef.current.on('reconnect', (attemptNumber) => {
				socketRef?.current?.emit(
					'join_room_unite',
					profileInformation?.unite?.url?.split('/')[5],
					sourceLanguage,
					true
				);
				console.log('Socket reconnected on attempt:', attemptNumber);
			});

			socketRef?.current?.on('update_languages', (languages) => {
				// Create a map to count occurrences of each language label
				const languageCountMap = languages.reduce((acc, curr) => {
					const key = curr.label;
					acc[key] = (acc[key] || 0) + 1;
					return acc;
				}, {});

				// Set the count map as the new state
				setTotalLanguages(languageCountMap);
				const uniqueArray = languages.filter(
					(item, index, self) =>
						index === self.findIndex((t) => t.value === item.value)
				);
				setUniqueLanguages(uniqueArray);
			});

			socketRef?.current?.on('silence_threshold_exceeded', () => {
				console.log('silence_threshold_exceeded', {
					x: currentRecognitionRef.current,
				});
				if (
					currentRecognitionRef.current &&
					currentRecognitionRef.current !== ' '
				) {
					const message = {
						text: currentRecognitionRef.current,
						time: new Date(),
						id: getRandomInt(999, 9999999999),
					};

					if (autoSendRef.current) {
						socketRef?.current?.emit(
							'send_message_unite',
							message,
							profileInformation?.unite?.url?.split('/')[5],
							sourceLanguage
						);
						currentRecognitionRef.current = null;
					}
					setRecognitionHistory((old) => [message, ...old]);
					setCurrentRecognition('');
					setCurrentPhrase('...');
				}
			});

			console.log('socket manager', socketRef.current);

			socketRef?.current?.on('connect', () => {
				console.log('connected', socketRef.current.id);
				socketConnection.current = socketRef.current;
			});

			socketRef.current.on('receive_question_unite', (translatedQuestion) => {
				setReceivedQuestions((prev) => [translatedQuestion, ...prev]);
			});
		}

		// Add event listener for window unload to handle cleanup
		const handleWindowUnload = (event) => {
			socketRef.current?.emit('endGoogleCloudStream');
			socketRef.current?.disconnect();

			// Optional: Add a message for confirmation before closing the tab/window
			event.returnValue = ''; // This line is necessary for some browsers to trigger the beforeunload event
		};

		window.addEventListener('beforeunload', handleWindowUnload);

		return () => {
			socketRef.current?.emit('endGoogleCloudStream');
			socketRef.current?.disconnect();
			disconnect();
			window.removeEventListener('beforeunload', handleWindowUnload);
		};
	}, [profileInformation]);

	useEffect(() => {
		(async () => {
			if (connection) {
				if (isRecording) {
					return;
				}
				const stream = await getMediaStream(watch('inputDevices')?.value);

				audioContextRef.current = new window.AudioContext();

				await audioContextRef.current.audioWorklet.addModule(
					'/src/worklets/recorderWorkletProcessor.js'
				);

				audioContextRef.current.resume();

				audioInputRef.current =
					audioContextRef.current.createMediaStreamSource(stream);

				processorRef.current = new AudioWorkletNode(
					audioContextRef.current,
					'recorder.worklet'
				);

				processorRef.current.connect(audioContextRef.current.destination);
				audioContextRef.current.resume();

				audioInputRef.current.connect(processorRef.current);

				processorRef.current.port.onmessage = () => {
					const audioData = event.data;
					if (typeof connection.emit === 'function')
						connection?.emit('send_audio_data', { audio: audioData });
				};
				setIsRecording(true);
			} else {
				console.error('No connection');
			}
		})();
		return () => {
			if (isRecording) {
				processorRef.current?.disconnect();
				audioInputRef.current?.disconnect();
				if (audioContextRef.current?.state !== 'closed') {
					audioContextRef.current?.close();
				}
			}
		};
	}, [connection, isRecording, recorder]);

	console.log(watch('inputDevices')?.value, 'hello world');

	useEffect(() => {
		if (sourceLanguage?.value !== deviceLanguage?.code) {
			setLoading(true);
			getMessages();
			getQuestions();
			if (socketRef.current) {
				if (profileInformation?.unite?.url) {
					socketRef.current.emit(
						'join_room_unite',
						profileInformation?.unite?.url?.split('/')[5],
						sourceLanguage,
						true
					); // Update language when it changes
				}
			}
			if (isRecording) {
				disconnect();
				connect();
			}
		}
	}, [sourceLanguage?.value]);

	useEffect(() => {
		navigator.mediaDevices
			.enumerateDevices()
			.then((devices) => {
				const microphones = devices.filter(
					(device) => device.kind === 'audioinput'
				);
				const microphoneOptions = microphones.map((device) => ({
					label: device.label || `Microphone ${device.deviceId}`,
					value: device.deviceId,
				}));
				setInputDeviceOptions(microphoneOptions);
			})
			.catch((error) => {
				console.error('Error accessing media devices', error);
			});
	}, []);

	useEffect(() => {
		if (watch('inputDevices')?.value && connection) {
			disconnect(); // Disconnect any existing connection
			connect(); // Reconnect with the new device
		}
	}, [watch('inputDevices')?.value]);

	const handleTranscriptScroll = () => {
		if (transcriptRef.current && hasMoreMessage) {
			const { scrollTop, scrollHeight, clientHeight } = transcriptRef.current;
			if (scrollTop + clientHeight >= scrollHeight - 10) {
				// User has scrolled to the bottom
				setGetMessagesLoading(true);
				getMessages(page + 1);
			}
		}
	};

	const handleQuestionsScroll = () => {
		if (questionsRef.current && hasMoreQuestions) {
			const { scrollTop, scrollHeight, clientHeight } = questionsRef.current;
			if (scrollTop + clientHeight >= scrollHeight - 10) {
				// User has scrolled to the bottom
				setGetQuestionsLoading(true);
				getQuestions(questionsPage + 1);
			}
		}
	};

	const getMessages = async (pageNum = 1, limit = 5) => {
		if (getMessagesLoading) return;
		try {
			setGetMessagesLoading(true);

			api
				.get(
					`https://api.unite.letz.chat/api/unite/get-messages/${
						profileInformation?.unite?.url?.split('/')[5]
					}/${sourceLanguage?.value}?page=${pageNum}&limit=${limit}`
				)
				.then((res) => {
					setGetMessagesLoading(false);
					if (pageNum === 1) {
						setDbMessages(res.data.messages || {});
					} else {
						setDbMessages((prev) => [...prev, ...(res.data.messages || {})]);
					}
					setPage(pageNum);
					if (res.data.messages.length < limit) {
						setHasMoreMessage(false);
					}
				})
				.catch((error) => {
					setGetMessagesLoading(false);
					console.log('Error in getting Db Messages', error);
				});
		} catch (error) {
			setGetMessagesLoading(false);
			console.log('Error in getting Db Messages');
		}
	};

	const getQuestions = async (pageNum = 1, limit = 5) => {
		if (getQuestionsLoading) return;
		try {
			setGetQuestionsLoading(true);
			api
				.get(
					`https://api.unite.letz.chat/api/unite/get-questions/${
						profileInformation?.unite?.url?.split('/')[5]
					}/${sourceLanguage?.value}?page=${pageNum}&limit=${limit}`
				)
				.then((res) => {
					setGetQuestionsLoading(false);
					if (pageNum === 1) {
						setDbQuestions(res.data.questions || []);
					} else {
						setDbQuestions((prev) => [...prev, ...(res.data.questions || [])]);
					}
					setQuestionsPage(pageNum);
					if (res.data.questions.length < limit) {
						setHasMoreQuestions(false);
					}
				})
				.catch((error) => {
					setGetQuestionsLoading(false);
					console.log('Error in getting Db Questions', error);
				});
		} catch (error) {
			setGetQuestionsLoading(false);
			console.log('Error in getting Db Questions');
		}
	};

	const handleInputChange = (e, id) => {
		setInputValues({
			...inputValues,
			[id]: { ...inputValues[id], text: e.target.value },
		});
	};

	const resizeTextarea = (textarea) => {
		if (textarea) {
			const style = window.getComputedStyle(textarea, null);
			const lineHeight = parseInt(style.lineHeight, 10);

			textarea.style.height = `${lineHeight}px`; // Initial height set to one line
			const numberOfLines = Math.floor(textarea.scrollHeight / lineHeight);
			textarea.style.height = `${Math.max(1, numberOfLines) * lineHeight}px`;
		}
	};

	const handleButtonClick = (id) => {
		if (inputValues[id]?.text?.trim()) {
			setButtonStates({
				...buttonStates,
				[id]: !buttonStates[id],
			});
			socketRef?.current?.emit(
				'send_message_unite',
				inputValues[id],
				profileInformation?.unite?.url?.split('/')[5],
				sourceLanguage
			);
		} else {
			createNotification('error', 'Error', 'Please Talk or Write');
		}
	};

	const handleTitleSubmit = (e) => {
		e.preventDefault();
		const title = e.target[0].value;
		if (!title) {
			return createNotification(
				'error',
				'Field Required.',
				'Please Enter the Title of Room.'
			);
		}
		setOpen(false);
		connect();
	};

	const speechRecognized = (data) => {
		if (data.isFinal) {
			setCurrentPhrase('...');
			setCurrentRecognition((old) => `${old ? `${old} ` : ''}${data.text}`);
		} else setCurrentPhrase(data.text + '...');
	};

	const connect = () => {
		try {
			setIsStreamLoading(true);
			setTimeout(() => {
				setIsStreamLoading(false);
			}, 2000);

			setConnection(socketRef.current);
			socketRef.current.emit('startGoogleCloudStream', sourceLanguage?.value);

			socketRef.current.on('receive_audio_text', (data) => {
				speechRecognized(data);
			});
		} catch (error) {
			createNotification(
				'info',
				'Info',
				'Please go to Listener Page and Click on update QR code button and refresh.'
			);
		}
	};

	const disconnect = () => {
		if (!connection) return;
		connection?.emit('endGoogleCloudStream');
		processorRef.current?.disconnect();
		audioInputRef.current?.disconnect();
		audioContextRef.current?.close();
		setConnection(undefined);
		setRecorder(undefined);
		setIsRecording(false);
		socketRef.current.off('receive_audio_text');
	};

	const downloadTranscriptHandler = async () => {
		try {
			setLoading(true);
			const response = await api.get(
				`https://api.unite.letz.chat/api/unite/download-messages/${
					profileInformation?.unite?.url?.split('/')[5]
				}/${sourceLanguage?.value}`
			);

			const blob = new Blob([response.data], {
				type: response.headers['content-type'],
			});

			// Create a temporary URL to download the file
			const url = window.URL.createObjectURL(blob);

			// Create an anchor element and programmatically click it to trigger the download
			const link = document.createElement('a');
			link.href = url;
			link.download = `transcript-${
				profileInformation?.unite?.url?.split('/')[5]
			}`; // Set the desired file name and extension
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);

			// Release the temporary URL
			window.URL.revokeObjectURL(url);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.error('An error occurred in downloadTranscriptHandler:', error);
		}
	};

	const downloadQuestionsHandler = async () => {
		try {
			setLoading(true);
			const response = await api.get(
				`https://api.unite.letz.chat/api/unite/download-questions/${
					profileInformation?.unite?.url?.split('/')[5]
				}/${sourceLanguage?.value}`
			);

			const blob = new Blob([response.data], {
				type: response.headers['content-type'],
			});

			// Create a temporary URL to download the file
			const url = window.URL.createObjectURL(blob);

			// Create an anchor element and programmatically click it to trigger the download
			const link = document.createElement('a');
			link.href = url;
			link.download = `question-${
				profileInformation?.unite?.url?.split('/')[5]
			}`; // Set the desired file name and extension
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);

			// Release the temporary URL
			window.URL.revokeObjectURL(url);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.error('An error occurred in downloadTranscriptHandler:', error);
		}
	};

	const deleteTranscriptFunctional = async () => {
		try {
			setLoading(true);
			setModalTranscriptDelete(false);
			await api.delete(
				`https://api.unite.letz.chat/api/unite/delete-messages/${
					profileInformation?.unite?.url?.split('/')[5]
				}`
			);
			setGetTranscriptState((prev) => !prev);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log('error in deleteTranscriptFunctional');
		}
	};

	const deleteQuestionFunctional = async () => {
		try {
			setLoading(true);
			setModalQuestionDelete(false);
			await api.delete(
				`https://api.unite.letz.chat/api/unite/delete-questions/${
					profileInformation?.unite?.url?.split('/')[5]
				}`
			);
			setGetQuestionsState((prev) => !prev);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log('error in deleteTranscriptFunctional');
		}
	};

	return (
		<>
			<Modal
				open={open}
				handleClose={() => {
					setOpen(false);
				}}
				className={styles.ModelContainer}
			>
				<form onSubmit={handleTitleSubmit} className="flex flex-col gap-4">
					<label htmlFor="">Enter Room Title</label>
					<div className="flex gap-3">
						<input
							type="text"
							placeholder=""
							className="border-2 rounded-lg h-10 p-2"
						/>
						<button
							type="submit"
							className="text-white bg-green-300 w-20 h-10 rounded-lg"
						>
							Save
						</button>
					</div>
				</form>
			</Modal>
			<Modal
				open={modalTranscriptDelete}
				handleClose={() => {
					setModalTranscriptDelete(false);
				}}
				title={'Delete Transcript'}
				className={styles.container}
			>
				<div className="flex gap-5 justify-center items-center">
					<div className="flex justify-center items-center">
						<p>Are you sure</p>
					</div>
					<div>
						<button
							onClick={deleteTranscriptFunctional}
							disabled={loading}
							className="my-5 mr-5 bg-sky-300 px-3 py-1 rounded-lg"
						>
							Yes
						</button>
						<button
							className=" bg-red-300 px-3 py-1 rounded-lg"
							onClick={() => setModalTranscriptDelete(false)}
						>
							No
						</button>
					</div>
				</div>
			</Modal>
			<Modal
				open={modalQuestionDelete}
				handleClose={() => {
					setModalQuestionDelete(false);
				}}
				title={'Delete Questions'}
				className={styles.container}
			>
				<div className="flex gap-5 justify-center items-center">
					<div className="flex justify-center items-center">
						<p>Are you sure</p>
					</div>
					<div>
						<button
							onClick={deleteQuestionFunctional}
							disabled={loading}
							className="my-5 mr-5 bg-sky-300 px-3 py-1 rounded-lg"
						>
							Yes
						</button>
						<button
							className="px-3 py-1 bg-red-300 rounded-lg"
							onClick={() => setModalQuestionDelete(false)}
						>
							No
						</button>
					</div>
				</div>
			</Modal>

			<div className="flex flex-col gap-8">
				<div className="w-full py-16 px-2 bg-[#E5F3FF] rounded-3xl flex flex-col gap-5 items-center z-10">
					<div
						className="flex items-center justify-between  gap-5 relative w-full mb-8"
						style={{ textAlign: 'center' }}
					>
						<div>
							<CustomSelect
								name="pauseOnSilentDuration"
								control={control}
								options={options}
								placeholder={'Pause on silent duration'}
								className="border-2 rounded-xl ml-4"
							/>
						</div>
						<div>
							<CustomSelect
								defaultValue={{
									value: 'default',
									label: 'Default',
								}}
								name="inputDevices"
								control={control}
								options={inputDeviceOptions}
								className="border-2 rounded-xl mr-4"
							/>
						</div>
						<div className="flex xm:flex-row xs:flex-col xs:gap-4 xm:gap-2 items-center justify-center">
							<div></div>
							<div className="flex items-center gap-5 px-8">
								<span className="text-custom-blue font-bold">Auto Send</span>
								<ReactSwitch
									className=""
									disabled={isRecording}
									checked={autoSend}
									onChange={() => setAutoSend(!autoSend)}
								/>
							</div>
						</div>
					</div>
					<img src={isRecording ? MicRed : Mic} alt="" />
					<button
						className={`${
							isRecording ? 'bg-red-400' : 'bg-green-400'
						} text-white rounded-3xl sm:px-6 xs:px-2 py-1 w-60 h-10`}
						// onClick={connect}
						onClick={isRecording ? disconnect : connect}
					>
						{isRecording ? (
							'Press to Stop recording'
						) : isStreamLoading ? (
							<MinimalLoader color={'#90EE90'} />
						) : (
							'Press to start recording'
						)}
					</button>

					<p className="text-xl">Live Transcript</p>
					<div className="max-h-48 flex flex-col gap-2" id={style.scrollbar}>
						{recognitionHistory.map((tx, index) => (
							<div
								key={tx.id}
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
								}}
							>
								<textarea
									ref={(el) => {
										textareaRefs.current[index] = el;
									}}
									style={{ resize: 'none', height: 'auto', minHeight: '18px' }}
									type="text"
									value={inputValues[tx.id]?.text || ''}
									onChange={(e) => handleInputChange(e, tx.id)}
									disabled={buttonStates[tx.id]}
								/>
								{!autoSend && (
									<button
										style={{
											backgroundColor: buttonStates[tx.id]
												? 'rgb(125 211 252)'
												: 'rgb(239 68 68)',
											color: 'white',
											padding: '0px 12px',
											borderRadius: '2px',
										}}
										onClick={() => handleButtonClick(tx.id)}
										disabled={buttonStates[tx.id]}
									>
										{buttonStates[tx.id] ? 'Sent' : 'Send'}
									</button>
								)}
							</div>
						))}
					</div>
					<p className="text-2xl ">
						{currentRecognition}
						{currentPhrase}
					</p>
				</div>
				<div className="flex lgz:items-start xs:items-center justify-between xxl:flex-row xs:flex-col gap-3 px-2 ">
					<div
						className={`flex flex-col border-2 rounded-lg border-custom-blue p-6 xxl:min-w-[39%] xs:w-[100%] min-h-[248px]`}
					>
						<p
							onClick={downloadTranscriptHandler}
							className="text-xl flex items-center justify-center text-center xs:mb-3 md:mb-0 cursor-pointer hover:text-custom-blue"
						>
							Download Transcript
						</p>
						<p className="text-lg 3 flex items-center justify-center mb-3 mt-1">
							Transcript
						</p>
						<div
							ref={transcriptRef}
							className="gap-5 flex flex-col max-h-48 relative"
							id={style.scrollbar}
						>
							{recognitionHistory?.map((el) => (
								<p
									key={el.time}
									className="text-md flex xxl:flex-row xs:flex-col justify-between items-center text-custom-blue mb-2 "
								>
									<span className="mr-4 text-center min-w-[50%]  ">
										{el?.text}
									</span>
									<span className="text-center min-w-[50%]">
										{moment(el?.time).format('M/D/YY h:mm:ssA')}
									</span>
								</p>
							))}
							{dbMessages?.map((el) => (
								<p
									key={el.time}
									className="text-md flex xxl:flex-row xs:flex-col justify-between items-center text-custom-blue mb-2 "
								>
									<span className="mr-4 text-center min-w-[50%] ">
										{el?.text}
									</span>
									<span className="text-center min-w-[50%] ">
										{moment(el?.time).format('M/D/YY h:mm:ssA')}
									</span>
								</p>
							))}
							<div className="flex justify-center mt-24">
								{getMessagesLoading && <CircleLoader />}
							</div>
						</div>
					</div>

					<div
						className={`flex flex-col  border-2 rounded-lg border-custom-blue p-8 xxl:min-w-[39%] xs:w-[100%] min-h-[248px]
						`}
					>
						<p
							onClick={downloadQuestionsHandler}
							className="text-xl flex items-center justify-center text-center xs:mb-3 md:mb-0 cursor-pointer hover:text-custom-blue"
						>
							Download Audience Questions
						</p>
						<p className="text-md mb-2 text-center mt-2">Audience Questions</p>
						<div
							className="flex flex-col gap-5 max-h-48"
							id={style.scrollbar}
							ref={questionsRef}
						>
							{receivedQuestions?.map((el) => {
								return (
									<>
										<div className="text-md flex xxl:flex-row xs:flex-col justify-between items-center text-custom-blue mb-2">
											<div className="mr-4 text-center break-words w-[40%]">
												{el?.text}
											</div>{' '}
											<div className="text-center break-words w-[30%]">
												{moment(el?.time).format('M/D/YY h:mm:ssA')}
											</div>
											<div className="text-center text-black break-words w-[30%]">
												Orignal language - {el?.orignalLanguage}
											</div>
										</div>
									</>
								);
							})}
							{dbQuestions?.map((el) => {
								return (
									<>
										<div className="text-md flex xxl:flex-row xs:flex-col justify-between items-center text-custom-blue mb-2">
											<div className="mr-4 text-center break-words w-[40%]">
												{el?.text}
											</div>{' '}
											<div className="text-center break-words w-[30%] mr-4">
												{moment(el?.time).format('M/D/YY h:mm:ssA')}
											</div>
											<div className="text-center text-black break-words w-[30%]">
												Orignal language - {el?.orignalLanguage}
											</div>
										</div>
									</>
								);
							})}
							<div className="flex justify-center mt-24">
								{getQuestionsLoading && <CircleLoader />}
							</div>
						</div>
					</div>
					<div className="flex flex-col gap-5 xxl:min-w-[22%] min-w-full">
						<div className="flex flex-col items-center border-2 rounded-lg border-custom-blue p-8 min-h-[248px]">
							<p className="text-xl flex items-center justify-center text-center xs:mb-3 md:mb-0">
								{uniqueLanguages?.length} Different Languages
							</p>
							<p className="text-lg flex items-center justify-center mb-3">
								In Room
							</p>
							<button
								onClick={() => setModalTranscriptDelete(true)}
								className="xm:w-60 xs:w-48 h-12 bg-custom-blue xm:text-lg xs:text-sm text-white rounded-lg mb-4"
							>
								Delete Transcript
							</button>
							<button
								onClick={() => setModalQuestionDelete(true)}
								className="xm:w-60 xs:w-48 h-12 bg-white text-custom-blue xm:text-lg xs:text-sm border border-custom-blue rounded-lg"
							>
								Delete Audience Questions
							</button>
						</div>
						<div
							className={`flex flex-col border-2 rounded-lg border-custom-blue p-8 min-h-[248px]`}
						>
							<p className="text-xl flex items-center justify-center text-center xs:mb-3 md:mb-0 ">
								Languages in Room
							</p>
							{Object.entries(totalLanguages)?.map(([key, value]) => (
								<div
									className="text-center mt-1 whitespace-normal break-words"
									key={key}
								>
									<span>{key}</span> <span>{value}</span>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Presenter;
