import { Box, Container, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Videos from 'component/AgentPortalTabs/Videos';
import MarketingMaterial from 'component/AgentPortalTabs/MarketingMaterial';
import PricingGuide from 'component/AgentPortalTabs/PricingGuide';
import CreateInvoice from 'component/AgentPortalTabs/CreateInvoice';
import Loader from 'component/loader';
import Refferel from 'component/AgentPortalTabs/Refferel';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

export default function AgentPortal() {
	const [value, setValue] = React.useState(0);
	const [loading, setLoading] = useState(true);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 500);
	}, []);

	return (
		<>
			{loading && <Loader />}
			<Box>
				<Container>
					<Box className="relative bg-white h-full rounded-lg rounded-br-lg shadow-lg">
						<Box
							sx={{
								borderBottom: 1,
								borderColor: 'divider',
							}}
						>
							<Tabs
								value={value}
								onChange={handleChange}
								aria-label="basic tabs example"
								variant="scrollable"
								scrollButtons
								allowScrollButtonsMobile
							>
								<Tab
									sx={{ margin: 0.5, fontFamily: 'Poppins' }}
									label="Videos"
									{...a11yProps(0)}
								/>
								<Tab
									sx={{ margin: 0.5, fontFamily: 'Poppins' }}
									label="Marketing Materials"
									{...a11yProps(1)}
								/>
								<Tab
									sx={{ margin: 0.5, fontFamily: 'Poppins' }}
									label="Pricing Guides"
									{...a11yProps(2)}
								/>
								<Tab
									sx={{ margin: 0.5, fontFamily: 'Poppins' }}
									label="Create Invoice"
									{...a11yProps(3)}
								/>
								<Tab
									sx={{ margin: 0.5, fontFamily: 'Poppins' }}
									label="Referral"
									{...a11yProps(4)}
								/>
							</Tabs>
						</Box>
						<TabPanel value={value} index={0}>
							<Videos />
						</TabPanel>
						<TabPanel value={value} index={1}>
							<MarketingMaterial />
						</TabPanel>
						<TabPanel value={value} index={2}>
							<PricingGuide />
						</TabPanel>
						<TabPanel value={value} index={3}>
							<CreateInvoice />
						</TabPanel>
						<TabPanel value={value} index={4}>
							<Refferel />
						</TabPanel>
					</Box>
				</Container>
			</Box>
		</>
	);
}
