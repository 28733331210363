import CheckoutForm from 'component/Checkout';
import Header from 'component/Header';
import Modal from 'component/Modal';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileInfoAction } from 'store/user';
import styles from './index.module.scss';
import {
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement,
	useStripe,
	useElements,
	CardElement,
} from '@stripe/react-stripe-js';
import Loader from 'component/loader';
import api from 'utils/axios';

import Visa from 'assets/credit-cards/visa.svg';
import Amex from 'assets/credit-cards/amex.svg';
import Mastercard from 'assets/credit-cards/mastercard.svg';
import Unknown from 'assets/credit-cards/unknown.svg';
import { useSearchParams } from 'react-router-dom';
import { createNotification } from 'utils/create-notification';
import moment from 'moment';

const subscriptionsFees = {
	image: { price: 5.0, name: 'Image Translation' },
	webpage: { price: 299.0, name: 'Webpage Translation' },
	unite: { price: 97.99, name: 'LetzChat Unite ' },
	'zoom-subtitles': { price: 97.99, name: 'Zoom Caption Translator' },
	'podcast-translation': { price: 97.99, name: 'Podcast Translation' },
	document: { price: 0.11, name: 'Document Translation', unit: 'per word' },
	'voice-dubbing': { price: 14.99, name: 'Voice Dubbing', unit: 'per minute' },
};

const SubscriptionsAndInvoices = ({ subscriptions, invoices }) => {
	return (
		<div className="mt-7 space-y-10">
			{/* Subscriptions Section */}
			<div>
				<div className="w-full text-3xl py-4 text-white font-semibold bg-[#6DB7F2] text-center rounded-lg">
					Your Subscriptions
				</div>
				<div className="mt-10 mx-4 xl:mx-16 overflow-x-auto">
					<table className="min-w-full text-sm lg:text-lg">
						<thead>
							<tr>
								<th className="text-left font-semibold px-4 py-3">
									Subscriptions
								</th>
								<th className="text-left font-semibold px-4 py-3">
									Subscriptions Fee
								</th>
								<th className="text-left font-semibold px-4 py-3">
									Start Date
								</th>
								<th className="text-left font-semibold px-4 py-3">End Date</th>
								<th className="text-left font-semibold px-4 py-3">State</th>
								<th className="text-left font-semibold px-4 py-3">
									Payment Status
								</th>
								<th className="text-left font-semibold px-4 py-3">
									Next Billing
								</th>
								<th className="text-left font-semibold px-4 py-3">Action</th>
							</tr>
						</thead>
						<tbody>
							{subscriptions.map((subscription, index) => (
								<tr key={index} className="border-b">
									<td className="px-4 py-3">{subscription.name}</td>
									<td className="px-4 py-3">US ${subscription.fee}/month</td>
									<td className="px-4 py-3">
										{moment(subscription.startDate).format('DD-MM-yyyy')}
									</td>
									<td className="px-4 py-3">
										{moment(subscription.endDate).format('DD-MM-yyyy')}
									</td>
									<td
										className={`px-4 py-3 ${
											subscription?.status?.toUpperCase() !== 'ACTIVE' &&
											'text-red-500'
										}`}
									>
										{subscription?.status?.toUpperCase()}
									</td>
									<td
										className={`px-4 py-3 ${
											subscription?.paymentStatus?.toUpperCase() !== 'PAID' &&
											'text-red-500'
										}`}
									>
										{subscription?.paymentStatus?.toUpperCase()}
									</td>
									<td
										className={`px-4 py-3  ${
											!moment(subscription.nextBilling)?.isValid() &&
											'text-red-500'
										}`}
									>
										{moment(subscription.nextBilling).isValid()
											? moment(subscription.nextBilling).format('DD-MM-yyyy')
											: subscription.nextBilling?.toUpperCase()}
									</td>
									<td className="px-4 py-3">
										<a
											href={subscription?.invoiceUrl}
											target="_blank"
											rel="noreferrer"
										>
											<button className="bg-[#6DB7F2] text-white rounded-lg px-6 py-2">
												Invoice
											</button>
										</a>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>

			{/* Invoices Section */}
			<div>
				<div className="w-full text-3xl py-4 text-white font-semibold bg-[#6DB7F2] text-center rounded-lg">
					Invoices
				</div>
				<div className="mt-10 mx-4 xl:mx-16 overflow-x-auto">
					<table className="min-w-full text-sm lg:text-lg">
						<thead>
							<tr>
								<th className="text-left font-semibold px-4 py-3">Product</th>
								<th className="text-left font-semibold px-4 py-3">
									Product Fee
								</th>
								<th className="text-left font-semibold px-4 py-3">Total Fee</th>
								<th className="text-left font-semibold px-4 py-3">Date</th>
								<th className="text-left font-semibold px-4 py-3">Action</th>
							</tr>
						</thead>
						<tbody>
							{invoices.map((invoice, index) => (
								<tr key={index} className="border-b">
									<td className="px-4 py-3">{invoice.product}</td>
									<td className="px-4 py-3">{invoice.productFee}</td>
									<td className="px-4 py-3">{invoice.totalFee}</td>
									<td className="px-4 py-3">
										{moment(invoice.date).format('DD-MM-yyyy')}
									</td>
									<td className="px-4 py-3">
										<a
											href={invoice?.invoiceUrl}
											target="_blank"
											rel="noreferrer"
										>
											<button className="bg-[#6DB7F2] text-white rounded-lg px-6 py-2">
												Invoice
											</button>
										</a>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

const CREDIT_CARD_LOGOS = {
	Visa: Visa,
	Amex: Amex,
	Mastercard: Mastercard,
};

const CARD_ELEMENT_OPTIONS = {
	style: {
		base: {
			color: '#333',
			fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
			fontSmoothing: 'antialiased',
			fontSize: '16px',
			'::placeholder': {
				color: '#aab7c4',
			},
		},
		invalid: {
			color: '#fa755a',
			iconColor: '#fa755a',
		},
	},
};

const BillingInformation = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [loading, setLoading] = useState(false);
	const [addCardModalOpen, setAddCardModalOpen] = useState(false);
	const [addCardLoading, setAddCardLoading] = useState(false);
	const [removeCardModalOpen, setRemoveCardModalOpen] = useState(false);
	const [updateCardModalOpen, setUpdateCardModalOpen] = useState(false);
	const [updateCardLoading, setUpdateCardLoading] = useState(false);
	const [subscriptionsData, setSubscriptionsData] = useState([]);
	const [invoicesData, setInvoicesData] = useState([]);

	const stripe = useStripe();
	const elements = useElements();

	const dispatch = useDispatch();

	const profileInformation = useSelector(
		(state) => state?.user?.profileInformation
	);

	useEffect(() => {
		dispatch(getProfileInfoAction());
	}, []);

	const logo = useMemo(() => {
		let subscriptionsInfo = profileInformation?.billingInfo?.subscriptions;
		let transactionsInfo = profileInformation?.billingInfo?.transactions;
		if (subscriptionsInfo) {
			subscriptionsInfo = subscriptionsInfo.map((subscription) => {
				return {
					name: subscriptionsFees[subscription?.itemName].name,
					fee: subscriptionsFees[subscription?.itemName].price,
					startDate: subscription?.startDate,
					endDate: subscription?.endDate,
					invoiceUrl: subscription?.invoiceUrl,
					status: subscription?.status,
					nextBilling: subscription?.nextBilling,
					paymentStatus: subscription?.paymentStatus,
				};
			});
			setSubscriptionsData(subscriptionsInfo);
		}
		if (transactionsInfo) {
			transactionsInfo = transactionsInfo.map((transaction) => {
				if (subscriptionsFees[transaction?.product].name) {
					return {
						product: subscriptionsFees[transaction?.product].name,
						productFee: `US $${
							subscriptionsFees[transaction?.product].price
						}/month`,
						totalFee: `US $${
							subscriptionsFees[transaction?.product]?.unit
								? transaction?.totalAmount
								: subscriptionsFees[transaction?.product].price
						}`,
						date: transaction?.createdAt,
						invoiceUrl: transaction?.invoiceUrl,
					};
				} else {
					return {
						// name: subscriptionsFees[transaction?.product].name,
						// fee: subscriptionsFees[transaction?.product].price,
						// startDate: transaction?.startDate,
						// endDate: transaction?.endDate,
						// invoiceUrl: transaction?.invoiceUrl,
						// status: transaction?.status,
					};
				}
			});
			setInvoicesData(transactionsInfo);
		}

		if (!profileInformation?.billingInfo?.paymentMethods[0]?.brand)
			return Unknown;
		return (
			CREDIT_CARD_LOGOS[
				profileInformation?.billingInfo?.paymentMethods[0]?.brand
			] || Unknown
		);
	}, [profileInformation]);

	useEffect(() => {
		if (searchParams.get('error')) {
			createNotification('error', 'Error', searchParams.get('error'));
		}
	}, []);

	const handleSubmitAddCard = async (event) => {
		try {
			event.preventDefault();
			setAddCardLoading(true);

			if (!stripe || !elements) {
				console.log('Stripe has not loaded');
				return;
			}

			const cardNumberElement = elements.getElement(CardNumberElement);

			if (!cardNumberElement) {
				console.log('Card number element not found');
				return;
			}

			const { error, token } = await stripe.createToken(cardNumberElement);

			if (error) {
				console.error(error);
				setAddCardLoading(false);
			} else {
				console.log('Received Stripe token:', token);
				const res = await addCardToCustomer(token?.id);
				console.log(res, 'res');
			}
			dispatch(getProfileInfoAction());
			setAddCardLoading(false);
			setAddCardModalOpen(false);
		} catch (error) {
			setAddCardModalOpen(false);
			setAddCardLoading(false);
			console.log('Error adding card', error);
		}
	};

	const addCardToCustomer = async (token) => {
		try {
			const response = await api.post('billing-information/add-card', {
				source: token,
			});
			return response.data;
		} catch (error) {
			createNotification('error', 'Error', error.response.data.error);
			console.log('Error adding card to customer', error);
		}
	};

	const deleteCardHandler = async () => {
		try {
			setLoading(true);
			const response = await api.post('billing-information/remove-card');
			console.log(response, 'response');
			dispatch(getProfileInfoAction());
			setRemoveCardModalOpen(false);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log('Error deleting card', error);
		}
	};

	const handleSubmitUpdateCard = async (event) => {
		try {
			event.preventDefault();
			setUpdateCardLoading(true);
			await deleteCardHandler();
			await handleSubmitAddCard(event);
			setUpdateCardLoading(false);
			setUpdateCardModalOpen(false);
		} catch (error) {
			setUpdateCardLoading(false);
			setUpdateCardModalOpen(false);
		}
	};

	return (
		<>
			{loading && <Loader />}
			<div className="">
				<Header />i{' '}
				<main className=" max-w-[1880px] mx-auto">
					<div className="xs:m-4 md:m-10">
						{' '}
						<h3 className="xs:text-lg  xm:text-xl md:text-2xl  lg:text-3xl  2xl:text-4xl font-bold">
							Billing Information
						</h3>{' '}
						<SubscriptionsAndInvoices
							subscriptions={subscriptionsData}
							invoices={invoicesData}
						/>
						<div className="mt-7">
							<div className="w-full text-[32px] xs:text-lg  xm:text-xl md:text-2xl  lg:text-3xl 2xl:text-[32px] xs:py-2 md:py-4 text-white font-semibold bg-[#6DB7F2] text-center rounded-[10px] ">
								Payment Methods
							</div>
							<div className="flex xs:text-[4px] xz:text-[6px] xm:text-[8px]  sm:text-xs  md:text-sm lg:text-base xl:text-lg 2xl:text-2xl  justify-between mt-10  md:mx-2  lg:mx-4  xl:mr-[699px] ">
								<div className="flex flex-col ">
									<h4 className="font-semibold  ">Payment Method</h4>
									<div className="flex items-center space-x-2 xs:my-2 xm:my-4 md:my-6 xl:my-7 2xl:my-9">
										{profileInformation?.billingInfo?.paymentMethods[0]
											?.expYear && (
											<img src={logo} alt="Start Icon" className="w-6 h-6" />
										)}

										<span>
											{profileInformation?.billingInfo?.paymentMethods[0]
												?.expYear && '****'}
											{
												profileInformation?.billingInfo?.paymentMethods[0]
													?.last4
											}
										</span>
									</div>
								</div>
								<div className="flex flex-col ">
									<h4 className="font-semibold  ">Expiration Date</h4>
									<span className="  xs:my-2 xm:my-4 md:my-6 xl:my-7 2xl:my-9">
										{
											profileInformation?.billingInfo?.paymentMethods[0]
												?.expMonth
										}
										{profileInformation?.billingInfo?.paymentMethods[0]
											?.expYear && '/'}
										{
											profileInformation?.billingInfo?.paymentMethods[0]
												?.expYear
										}
									</span>
								</div>
								<div className="flex flex-col ">
									<h4 className="font-semibold  ">Actions</h4>
									<div className="flex xs:gap-4 md:gap-6    xs:my-1 xm:my-4 md:my-6 xl:my-6 2xl:my-7">
										{profileInformation?.billingInfo?.paymentMethods[0]
											?.last4 ? (
											<>
												<button
													onClick={() => setUpdateCardModalOpen(true)}
													className="bg-white text-[#6DB7F2] hover:bg-[#6DB7F2] hover:text-white border-2 border-solid border-[#6DB7F2] xs:rounded-[5px] xl:rounded-[10px] md:py-0.5 md:px-4   xs:py-px xs:px-2 xl:py-1  2xl:py-2 2xl:px-6"
												>
													Update
												</button>
												<button
													onClick={() => setRemoveCardModalOpen(true)}
													className="bg-white text-[#6DB7F2] hover:bg-[#6DB7F2] hover:text-white border-2 border-solid border-[#6DB7F2] xs:rounded-[5px] xl:rounded-[10px] md:py-0.5 md:px-4   xs:py-px xs:px-2 xl:py-1  2xl:py-2 2xl:px-6"
												>
													Remove
												</button>
											</>
										) : (
											<button
												onClick={() => setAddCardModalOpen(true)}
												className="bg-white text-[#6DB7F2] hover:bg-[#6DB7F2] hover:text-white border-2 border-solid border-[#6DB7F2] xs:rounded-[5px] xl:rounded-[10px] md:py-0.5 md:px-4   xs:py-px xs:px-2 xl:py-1  2xl:py-2 2xl:px-6"
											>
												Add Card
											</button>
										)}
									</div>
								</div>
								<div>
									<Modal
										open={addCardModalOpen}
										handleClose={() => setAddCardModalOpen(false)}
										bodyTopMargin={1}
										className={styles.ModelContainer}
										customStyle={{ paddingTop: '26px' }}
									>
										<div className="w-96 bg-white rounded p-6 shadow-xl">
											<>
												{addCardLoading && <Loader />}
												<form onSubmit={handleSubmitAddCard}>
													<label>
														Card number
														<CardNumberElement options={CARD_ELEMENT_OPTIONS} />
													</label>
													<label>
														Expiration date
														<CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
													</label>
													<label>
														CVC
														<CardCvcElement options={CARD_ELEMENT_OPTIONS} />
													</label>
													<button
														type="submit"
														disabled={!stripe}
														className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
													>
														Add Card
													</button>
												</form>
											</>
										</div>
									</Modal>
								</div>
								<div>
									<Modal
										open={updateCardModalOpen}
										handleClose={() => setUpdateCardModalOpen(false)}
										bodyTopMargin={1}
										className={styles.ModelContainer}
										customStyle={{ paddingTop: '26px' }}
									>
										<div className="w-96 bg-white rounded p-6 shadow-xl">
											<>
												{updateCardLoading && <Loader />}
												<form onSubmit={handleSubmitUpdateCard}>
													<label>
														Card number
														<CardNumberElement options={CARD_ELEMENT_OPTIONS} />
													</label>
													<label>
														Expiration date
														<CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
													</label>
													<label>
														CVC
														<CardCvcElement options={CARD_ELEMENT_OPTIONS} />
													</label>
													<button
														type="submit"
														disabled={!stripe}
														className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
													>
														Update Card
													</button>
												</form>
											</>
										</div>
									</Modal>
								</div>
								<div>
									<Modal
										title={'Remove Card'}
										open={removeCardModalOpen}
										handleClose={() => setRemoveCardModalOpen(false)}
										bodyTopMargin={1}
										className={styles.ModelContainer}
										customStyle={{ paddingTop: '26px' }}
									>
										{loading && <Loader />}
										<div className="w-96 bg-white rounded p-6 shadow-xl">
											<p>Are you Sure</p>
										</div>
										<div className="mt-8">
											<button
												onClick={deleteCardHandler}
												className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded mr-2"
											>
												YES
											</button>
											<button
												onClick={() => setRemoveCardModalOpen(false)}
												className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded"
											>
												NO
											</button>
										</div>
									</Modal>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		</>
	);
};

export default BillingInformation;
