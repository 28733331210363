import React, { useRef, useState } from 'react';
import { createNotification } from 'utils/create-notification';
import api from 'utils/axios';
import Loader from 'component/loader';
import CloseIcon from '@mui/icons-material/Close';

const ListenTranscript = () => {
	const [transcript, setTranscript] = useState([]);
	const [selectedFile, setSelectedFile] = useState(null);
	const [loading, setLoading] = useState(false);

	const fileInputRef = useRef(null);

	const handleFileInputChange = (event) => {
		const file = event.target.files[0];
		setSelectedFile(file);
		if (file) {
			setLoading(true);
			const reader = new FileReader();
			reader.readAsText(file, 'UTF-8');
			reader.onload = async function (evt) {
				const text = evt.target.result;
				const base64String = btoa(text);
				try {
					const response = await api.post(
						`https://api.unite.letz.chat/api/unite/transcript-file-to-voice`,
						{ fileInBase64: base64String }
					);
					if (response?.data?.logArray?.length === 0) {
						createNotification(
							'error',
							'Error',
							'File is Empty or not in LetzChat Format.'
						);
					}
					setTranscript(response?.data?.logArray);
					setLoading(false);
				} catch (error) {
					setLoading(false);
					createNotification(
						'error',
						'Error',
						'File is not in LetzChat Format.'
					);
				}
			};
			reader.onerror = function (evt) {
				console.error('An error occurred while reading the file');
			};
		}
	};

	const openFileInput = () => {
		fileInputRef.current.click();
	};

	const playAllHandler = async () => {
		if (transcript?.length > 0) {
			for (let i = 0; i < transcript?.length; i++) {
				const el = transcript[i];
				await new Promise((resolve) => {
					const audioData = el?.audioBase64; // Base64 encoded audio data
					const audioByteArray = Uint8Array.from(atob(audioData), (c) =>
						c.charCodeAt(0)
					);
					const audioBlob = new Blob([audioByteArray], { type: 'audio/mp3' });
					const audioUrl = URL.createObjectURL(audioBlob);
					const audio = new Audio(audioUrl);
					audio.addEventListener('ended', resolve); // Resolve the promise when the audio ends
					audio.play();
				});
			}
		}
	};

	const clearFile = () => {
		setSelectedFile(null);
		setTranscript([]);
		fileInputRef.current.value = '';
	};

	return (
		<>
			{loading && <Loader />}
			<div className="flex justify-center gap-8">
				<div className="px-3 py-16 bg-[#E5F3FF] xs:w-[100%] rounded-3xl flex flex-col items-center justify-center gap-3 z-10">
					<div className="flex flex-col pb-4 pt-4 relative">
						{!selectedFile && (
							<label className="flex justify-center items-center mb-5">
								Upload a transcript document
							</label>
						)}
						<div className="relative flex">
							<input
								ref={fileInputRef}
								type="file"
								className="absolute top-0 left-0 w-0 h-0 opacity-0"
								onChange={handleFileInputChange}
								accept="txt/*"
							/>
							<button
								onClick={openFileInput}
								className="w-full h-full bg-[#6db7f2] text-white font-bold py-4 px-6 rounded-lg ml-2"
							>
								{selectedFile ? selectedFile.name : 'Browse your computer'}
							</button>
							{selectedFile && (
								<button
									onClick={clearFile}
									className="bg-red-400 rounded-lg py-4 px-6 text-white ml-2"
								>
									{/* <img src={cross} alt="Clear" />{' '} */}
									<CloseIcon sx={{ color: 'white' }} />
								</button>
							)}
						</div>
						<div className="flex flex-col justify-center items-center gap-5 mt-5">
							<div>
								<button
									onClick={playAllHandler}
									className="bg-green-400 rounded-lg py-2 px-8 text-white"
								>
									Play All
								</button>
							</div>
							<p className="text-xl">Live Transcript</p>
							{transcript?.map(({ id, text, audioBase64 }) => (
								<div
									key={id}
									className="flex justify-between items-center w-[300px]"
								>
									<p className="flex-shrink whitespace-normal break-words mr-1 text-center">
										{text}
									</p>
									<button
										onClick={() => {
											const audioData = audioBase64; // Base64 encoded audio data
											const audioByteArray = Uint8Array.from(
												atob(audioData),
												(c) => c.charCodeAt(0)
											);
											const audioBlob = new Blob([audioByteArray], {
												type: 'audio/mp3',
											});
											const audioUrl = URL.createObjectURL(audioBlob);
											const audio = new Audio(audioUrl);
											audio.play();
										}}
										className="bg-green-400 rounded-lg py-2 px-4 text-white"
									>
										Play
									</button>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
			'
		</>
	);
};

export default ListenTranscript;
