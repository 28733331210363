import React, { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Checkbox,
	Container,
	FormControlLabel,
	FormGroup,
	Grid,
} from '@mui/material';

import stripe from 'assets/stripe.png';
import Translation from 'pages/Translation';

import userProfile from 'assets/user-profile.jpeg';
import Loader from 'component/loader';
import api from 'utils/axios';
import { useNavigate, useParams } from 'react-router-dom';
import { createNotification } from 'utils/create-notification';
import { useSelector } from 'react-redux';

const Users = () => {
	const [user, setUser] = useState();
	const [loading, setLoading] = useState(true);

	const [textTranslation, setTextTranslation] = useState(false);
	const [imageTranslation, setImageTranslation] = useState(false);
	const [docxTranslation, setDocxTranslation] = useState(false);
	const [websiteTranslation, setWebsiteTranslation] = useState(false);
	const [voiceDubbing, setVoiceDubbing] = useState(false);
	const [unite, setUnite] = useState(false);
	const [zoomTranslation, setZoomTranslation] = useState(false);
	const [podcastTranslation, setPodcastTranslation] = useState(false);

	const [imageTranslationPayment, setImageTranslationPayment] = useState(false);
	const [docxTranslationPayment, setDocxTranslationPayment] = useState(false);
	const [websiteTranslationPayment, setWebsiteTranslationPayment] =
		useState(false);
	const [voiceDubbingPayment, setVoiceDubbingPayment] = useState(false);
	const [unitePayment, setUnitePayment] = useState(false);
	const [zoomTranslationPayment, setZoomTranslationPayment] = useState(false);
	const [podcastTranslationPayment, setPodcastTranslationPayment] =
		useState(false);

	const [agentPortalPermission, setAgentPortalPermission] = useState(false);

	const { userId } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		api
			.get(`/auth/get-user-by-id/${userId}`)
			.then((res) => {
				setLoading(true);
				setUser(res.data.user);
				setAgentPortalPermission(() => {
					return res?.data?.user?.role === 'Agent';
				});
				setTextTranslation(() => {
					return res.data.user?.services?.find((el) => el === 'text')
						? true
						: false;
				});
				setImageTranslation(() => {
					return res.data.user?.services?.find((el) => el === 'image')
						? true
						: false;
				});
				setDocxTranslation(() => {
					return res.data.user?.services?.find((el) => el === 'document')
						? true
						: false;
				});
				setWebsiteTranslation(() => {
					return res.data.user?.services?.find((el) => el === 'webpage')
						? true
						: false;
				});
				setVoiceDubbing(() => {
					return res.data.user?.services?.find((el) => el === 'voice-dubbing')
						? true
						: false;
				});
				setUnite(() => {
					return res.data.user?.services?.find((el) => el === 'unite')
						? true
						: false;
				});
				setZoomTranslation(() => {
					return res.data.user?.services?.find((el) => el === 'zoom-subtitles')
						? true
						: false;
				});
				setPodcastTranslation(() => {
					return res.data.user?.services?.find(
						(el) => el === 'podcast-translation'
					)
						? true
						: false;
				});

				setImageTranslationPayment(() => {
					return res.data.user?.servicesPayment?.find((el) => el === 'image')
						? true
						: false;
				});
				setDocxTranslationPayment(() => {
					return res.data.user?.servicesPayment?.find((el) => el === 'document')
						? true
						: false;
				});
				setWebsiteTranslationPayment(() => {
					return res.data.user?.servicesPayment?.find((el) => el === 'webpage')
						? true
						: false;
				});
				setVoiceDubbingPayment(() => {
					return res.data.user?.servicesPayment?.find(
						(el) => el === 'voice-dubbing'
					)
						? true
						: false;
				});
				setUnitePayment(() => {
					return res.data.user?.servicesPayment?.find((el) => el === 'unite')
						? true
						: false;
				});
				setZoomTranslationPayment(() => {
					return res.data.user?.servicesPayment?.find(
						(el) => el === 'zoom-subtitles'
					)
						? true
						: false;
				});
				setPodcastTranslationPayment(() => {
					return res.data.user?.servicesPayment?.find(
						(el) => el === 'podcast-translation'
					)
						? true
						: false;
				});
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	}, []);

	const handleUserPermission = async () => {
		const getServicesArray = () => {
			let services = [];
			if (textTranslation) services.push('text');
			if (imageTranslation) services.push('image');
			if (docxTranslation) services.push('document');
			if (websiteTranslation) services.push('webpage');
			if (voiceDubbing) services.push('voice-dubbing');
			if (unite) services.push('unite');
			if (zoomTranslation) services.push('zoom-subtitles');
			if (podcastTranslation) services.push('podcast-translation');
			return services;
		};
		const getServicesPaymentArray = () => {
			let services = [];
			if (imageTranslationPayment) services.push('image');
			if (docxTranslationPayment) services.push('document');
			if (websiteTranslationPayment) services.push('webpage');
			if (voiceDubbingPayment) services.push('voice-dubbing');
			if (unitePayment) services.push('unite');
			if (zoomTranslationPayment) services.push('zoom-subtitles');
			if (podcastTranslationPayment) services.push('podcast-translation');

			return services;
		};
		try {
			setLoading(true);
			const response = await api.put('services', {
				userId,
				services: getServicesArray(),
			});
			const responsePayment = await api.put('services/payment', {
				userId,
				services: getServicesPaymentArray(),
			});
			await api.post('services/agent-portal-permission', {
				userId,
				isAgent: agentPortalPermission,
			});
			createNotification('success', 'Done', response?.data?.message);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			createNotification('error', 'Error', error?.response?.data?.error);
		}
	};

	return (
		<>
			{loading && <Loader />}
			<Translation />
			<Box>
				<Container>
					<Box className="relative bg-white h-full rounded-lg rounded-br-lg shadow-lg pb-2 ">
						<Grid className="" md={12}>
							<div className="border-b-2 border-grey-100 border-t-0 border-r-0 border-l-0">
								<h2 className="pt-2 pl-3 pb-3 text-[#6B6B6B] text-lg font-medium">
									Users
								</h2>
							</div>
						</Grid>

						<div className="pt-10 ml-20 mr-20">
							<div className="p-2">
								<div className="flex items-center border-b-2 border-grey-100 border-t-0 border-r-0 border-l-0 pb-3">
									<img
										className="object-cover rounded-full"
										width={32}
										src={`${
											user?.profilePicture ? user?.profilePicture : userProfile
										}`}
									/>
									<p className="user__profile__text pl-3">
										{user?.firstName} {user?.lastName}
									</p>
								</div>
							</div>
						</div>
						<div className="pt-5 ml-20 mr-20">
							<div className="p-2">
								<div className="flex items-center">
									<p className="user__profile__text pl-3">
										Services Permissions
									</p>
								</div>
							</div>
						</div>
						<div className="pt-5">
							<FormGroup>
								<Grid
									container
									style={{ display: 'flex', justifyContent: 'center' }}
								>
									<Grid lg={2}>
										<div>
											<FormControlLabel
												style={{
													color: '#6B6B6B',
												}}
												control={
													<Checkbox
														checked={textTranslation}
														onChange={(e) =>
															setTextTranslation(e.target.checked)
														}
														style={{
															color: '#C7C7C7',
														}}
													/>
												}
												label="Text Translation "
											/>
										</div>
									</Grid>
									<Grid lg={2}>
										<div>
											<FormControlLabel
												style={{
													color: '#6B6B6B',
												}}
												required
												control={
													<Checkbox
														checked={imageTranslation}
														onChange={(e) =>
															setImageTranslation(e.target.checked)
														}
														style={{
															color: '#C7C7C7',
														}}
													/>
												}
												label="Image Translation"
											/>
										</div>
									</Grid>
									<Grid lg={2}>
										<div>
											<FormControlLabel
												style={{
													color: '#6B6B6B',
												}}
												required
												control={
													<Checkbox
														checked={docxTranslation}
														onChange={(e) =>
															setDocxTranslation(e.target.checked)
														}
														style={{
															color: '#C7C7C7',
														}}
													/>
												}
												label="Docx Translation"
											/>
										</div>
									</Grid>
									<Grid lg={2}>
										<div>
											<FormControlLabel
												style={{
													color: '#6B6B6B',
												}}
												required
												control={
													<Checkbox
														checked={websiteTranslation}
														onChange={(e) =>
															setWebsiteTranslation(e.target.checked)
														}
														style={{
															color: '#C7C7C7',
														}}
													/>
												}
												label="Website Translation"
											/>
										</div>
									</Grid>
									<Grid lg={2}>
										<div>
											<FormControlLabel
												style={{
													color: '#6B6B6B',
												}}
												required
												control={
													<Checkbox
														checked={voiceDubbing}
														onChange={(e) => setVoiceDubbing(e.target.checked)}
														style={{
															color: '#C7C7C7',
														}}
													/>
												}
												label="Voice Dubbing"
											/>
										</div>
									</Grid>
									<Grid>
										<div>
											<FormControlLabel
												style={{
													color: '#6B6B6B',
												}}
												required
												control={
													<Checkbox
														checked={unite}
														onChange={(e) => setUnite(e.target.checked)}
														style={{
															color: '#C7C7C7',
														}}
													/>
												}
												label="Unite"
											/>
										</div>
									</Grid>
									<Grid>
										<div>
											<FormControlLabel
												style={{
													color: '#6B6B6B',
												}}
												required
												control={
													<Checkbox
														checked={zoomTranslation}
														onChange={(e) =>
															setZoomTranslation(e.target.checked)
														}
														style={{
															color: '#C7C7C7',
														}}
													/>
												}
												label="Zoom Subtitling"
											/>
										</div>
									</Grid>
									<Grid>
										<div>
											<FormControlLabel
												style={{
													color: '#6B6B6B',
												}}
												required
												control={
													<Checkbox
														checked={podcastTranslation}
														onChange={(e) =>
															setPodcastTranslation(e.target.checked)
														}
														style={{
															color: '#C7C7C7',
														}}
													/>
												}
												label="Podcast Translation"
											/>
										</div>
									</Grid>
								</Grid>
							</FormGroup>
						</div>

						<div className="pt-5 ml-20 mr-20">
							<div className="p-2">
								<div className="flex items-center">
									<p className="user__profile__text pl-3">
										Skip Payment System Permissions
									</p>
								</div>
							</div>
						</div>
						<div className="pt-5">
							<FormGroup>
								<Grid
									container
									style={{ display: 'flex', justifyContent: 'center' }}
								>
									<Grid lg={2}>
										<div>
											<FormControlLabel
												style={{
													color: '#6B6B6B',
												}}
												required
												control={
													<Checkbox
														checked={imageTranslationPayment}
														onChange={(e) =>
															setImageTranslationPayment(e.target.checked)
														}
														style={{
															color: '#C7C7C7',
														}}
													/>
												}
												label="Image Translation"
											/>
										</div>
									</Grid>
									<Grid lg={2}>
										<div>
											<FormControlLabel
												style={{
													color: '#6B6B6B',
												}}
												required
												control={
													<Checkbox
														checked={docxTranslationPayment}
														onChange={(e) =>
															setDocxTranslationPayment(e.target.checked)
														}
														style={{
															color: '#C7C7C7',
														}}
													/>
												}
												label="Docx Translation"
											/>
										</div>
									</Grid>
									<Grid lg={2}>
										<div>
											<FormControlLabel
												style={{
													color: '#6B6B6B',
												}}
												required
												control={
													<Checkbox
														checked={websiteTranslationPayment}
														onChange={(e) =>
															setWebsiteTranslationPayment(e.target.checked)
														}
														style={{
															color: '#C7C7C7',
														}}
													/>
												}
												label="Website Translation"
											/>
										</div>
									</Grid>
									<Grid lg={2}>
										<div>
											<FormControlLabel
												style={{
													color: '#6B6B6B',
												}}
												required
												control={
													<Checkbox
														checked={voiceDubbingPayment}
														onChange={(e) =>
															setVoiceDubbingPayment(e.target.checked)
														}
														style={{
															color: '#C7C7C7',
														}}
													/>
												}
												label="Voice Dubbing"
											/>
										</div>
									</Grid>
									<Grid lg={2}>
										<div>
											<FormControlLabel
												style={{
													color: '#6B6B6B',
												}}
												required
												control={
													<Checkbox
														checked={unitePayment}
														onChange={(e) => setUnitePayment(e.target.checked)}
														style={{
															color: '#C7C7C7',
														}}
													/>
												}
												label="Unite"
											/>
										</div>
									</Grid>
									<Grid lg={2}>
										<div>
											<FormControlLabel
												style={{
													color: '#6B6B6B',
												}}
												required
												control={
													<Checkbox
														checked={zoomTranslationPayment}
														onChange={(e) =>
															setZoomTranslationPayment(e.target.checked)
														}
														style={{
															color: '#C7C7C7',
														}}
													/>
												}
												label="Zoom Subtitling"
											/>
										</div>
									</Grid>
									<Grid lg={2}>
										<div>
											<FormControlLabel
												style={{
													color: '#6B6B6B',
												}}
												required
												control={
													<Checkbox
														checked={podcastTranslationPayment}
														onChange={(e) =>
															setPodcastTranslationPayment(e.target.checked)
														}
														style={{
															color: '#C7C7C7',
														}}
													/>
												}
												label="Podcast Translation"
											/>
										</div>
									</Grid>
								</Grid>
								<div className="pt-5 ml-20 mr-20">
									<div className="p-2">
										<div className="flex items-center">
											<p className="user__profile__text pl-3">
												Agents Permissions
											</p>
										</div>
									</div>
								</div>
								<div className="pt-5">
									<FormGroup>
										<Grid
											container
											style={{
												display: 'flex',
												justifyContent: 'center',
											}}
										>
											<Grid lg={2}>
												<div>
													<FormControlLabel
														style={{
															color: '#6B6B6B',
														}}
														required
														control={
															<Checkbox
																checked={agentPortalPermission}
																onChange={(e) =>
																	setAgentPortalPermission(e.target.checked)
																}
																style={{
																	color: '#C7C7C7',
																}}
															/>
														}
														label="Agent Portal"
													/>
												</div>
											</Grid>
										</Grid>
									</FormGroup>
								</div>
								<div className="flex justify-center items-center pt-20 gap-2 pr-3 pb-6">
									<Button
										onClick={handleUserPermission}
										variant="contained"
										className="saveuser-btn"
									>
										Save
									</Button>
									<Button
										variant="outlined"
										className="back-btn"
										onClick={() => navigate('/users-panel')}
									>
										Back
									</Button>
								</div>
							</FormGroup>
						</div>
					</Box>

					<Box>
						<div className="text-center pt-10">
							<p className="text-lg text-[#00000099]">
								Secure payment transctions are facilitated through
							</p>
							<div className="flex justify-center items-center">
								<img src={stripe} alt="stripe" width={150} />
							</div>
						</div>
					</Box>
				</Container>
			</Box>
		</>
	);
};

export default Users;
