import React, { useEffect, useRef, useState } from 'react';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';

import { useParams } from 'react-router-dom';
import moment from 'moment';
import CustomSelect from 'component/CustomSelect';
import { useForm } from 'react-hook-form';
import { googleLiveVoiceToTextLanguages } from 'constants/languages';
import api from 'utils/axios';
import Loader from 'component/loader';
import MinimalLoader from 'component/MinimalLoader';
import { createNotification } from 'utils/create-notification';
import PublicHeader from 'component/PublicHeader';
import ReactSwitch from 'react-switch';
import Socket from '../../../services/SocketManager';
import style from './scrollbar.module.scss';
import { ApiService } from 'services/api-services';

import autoPlayAudio from 'assets/autoplay.mp3';

let deviceLanguage = navigator.language || navigator.userLanguage;
deviceLanguage = googleLiveVoiceToTextLanguages.find(
	(el) => el.code === deviceLanguage
);

if (!deviceLanguage || deviceLanguage === 'undefined') {
	deviceLanguage = { name: 'English (United States)', code: 'en-US' };
}

const PodcastPage = () => {
	let { roomId } = useParams();
	const [messages, setMessages] = useState([]);
	const [autoPlay, setAutoPlay] = useState(false);
	const [loading, setLoading] = useState(false);
	const [dbMessages, setDbMessages] = useState([]);
	const [audioQueue, setAudioQueue] = useState([]);

	const autoPlayRef = useRef(autoPlay);
	const audioRef = useRef(new Audio());

	const { control, handleSubmit, getValues, watch } = useForm({
		defaultValues: {
			sourceLanguage: {
				value: deviceLanguage?.code,
				label: deviceLanguage?.name,
			},
		},
	});

	useEffect(() => {
		const playNextAudio = () => {
			if (audioQueue.length === 0) return;
			audioRef.current.src = audioQueue[0];
			audioRef.current.play();
			audioRef.current.onended = () => {
				setAudioQueue((prevQueue) => prevQueue.slice(1));
			};
		};

		if (audioRef.current.paused) {
			playNextAudio();
		}
	}, [audioQueue]);

	useEffect(() => {
		//Auto play
		autoPlayRef.current = autoPlay;
		audioRef.current.src = autoPlayAudio;
		audioRef.current.play();
	}, [autoPlay]);

	useEffect(() => {
		Socket.on('receive_message_podcast', (data) => {
			if (autoPlayRef.current) {
				const audioData = data?.audio; // Base64 encoded audio data
				const audioByteArray = Uint8Array.from(atob(audioData), (c) =>
					c.charCodeAt(0)
				);
				const audioBlob = new Blob([audioByteArray], {
					type: 'audio/mp3',
				});
				console.log(audioData, 'audioBlob');
				const audioUrl = URL.createObjectURL(audioBlob);
				setAudioQueue((prevQueue) => [...prevQueue, audioUrl]);
				// const audio = new Audio(audioUrl);
				// audio.play();
			}
			setMessages((prev) => [data, ...prev]);
		});
	}, []);

	useEffect(() => {
		setLoading(true);
		ApiService.get(
			`api/podcast-translation/get-podcast-messages/${roomId}/${
				watch('sourceLanguage')?.value
			}`
		)
			.then((res) => {
				const messages = res?.data?.messages;
				console.log(messages, 'messages');
				setDbMessages(messages?.reverse());
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				console.log(err, 'error in api call of is-correct-presenter-url');
			});
		Socket.emit(
			'join_room_podcast_public',
			roomId,
			watch('sourceLanguage').value
		);
	}, [watch('sourceLanguage').value]);

	const playAllHandler = async () => {
		if (dbMessages?.length > 0) {
			for (let i = dbMessages?.length - 1; i >= 0; i--) {
				const el = dbMessages[i];
				await new Promise((resolve) => {
					const audioData = el?.audio; // Base64 encoded audio data
					const audioByteArray = Uint8Array.from(atob(audioData), (c) =>
						c.charCodeAt(0)
					);
					const audioBlob = new Blob([audioByteArray], { type: 'audio/mp3' });
					const audioUrl = URL.createObjectURL(audioBlob);
					const audio = new Audio(audioUrl);
					audio.addEventListener('ended', resolve); // Resolve the promise when the audio ends
					audio.play();
				});
			}
		}
		if (messages?.length > 0) {
			for (let i = messages?.length - 1; i >= 0; i--) {
				const el = messages[i];
				await new Promise((resolve) => {
					const audioData = el?.audio; // Base64 encoded audio data
					const audioByteArray = Uint8Array.from(atob(audioData), (c) =>
						c.charCodeAt(0)
					);
					const audioBlob = new Blob([audioByteArray], { type: 'audio/mp3' });
					const audioUrl = URL.createObjectURL(audioBlob);
					const audio = new Audio(audioUrl);
					audio.addEventListener('ended', resolve); // Resolve the promise when the audio ends
					audio.play();
				});
			}
		}
	};

	return (
		<>
			{loading && <Loader />}
			<PublicHeader />
			<div className="flex flex-col w-full justify-center">
				<h1 className="text-4xl text-gray-600 font-[600] flex items-center justify-center my-12">
					Podcast
				</h1>
				<div className="bg-white rounded-3xl md:mx-40 xs:mx-8 z-10 shadow-lg">
					<CustomSelect
						control={control}
						name="sourceLanguage"
						options={googleLiveVoiceToTextLanguages.map((country) => ({
							label: country.name,
							value: country.code,
						}))}
						defaultValue={{
							value: deviceLanguage?.code,
							label: deviceLanguage?.name,
						}}
						placeholder="Select source language"
						className="border-b-2 border-grey-300 xs:w-[60%] md:w-[20%]"
					/>
					<div
						className="flex items-center justify-between  gap-5 relative w-full"
						style={{ textAlign: 'center' }}
					>
						<div></div>
						<div className="flex items-center gap-5 px-8">
							<span className="text-custom-blue font-bold">Auto Play</span>
							<ReactSwitch
								className=""
								// disabled={isRecording && isStreamLoading}
								checked={autoPlay}
								onChange={() => setAutoPlay(!autoPlay)}
							/>
						</div>
					</div>
					<div className="bg-[#E5F3FF] md:mx-11 my-4 xs:mx-2 flex flex-col justify-center items-center gap-6 py-8 rounded-2xl">
						<p className="text-xl">Live Transcript</p>

						<button
							onClick={playAllHandler}
							className="w-32 bg-sky-300 ml-2 border rounded-lg text-lg"
						>
							Play All
						</button>
						<div className="text-xl gap-4 flex flex-col">
							<div
								className="flex flex-col gap-5 max-h-48 "
								id={style.scrollbar}
							>
								{messages?.map((el, index) => {
									return (
										<p
											key={index}
											className="md:text-2xl xs:text-lg flex lg:flex-row xs:flex-col justify-between items-center gap-2"
										>
											<div className="flex flex-row justify-between gap-12 w-full">
												<p className="text-center">
													<b>{el?.name}</b>
												</p>
												<p className="text-center text-sm	">{el?.text}</p>
												<p className="flex gap-4">
													{moment(el?.time).format('M/D/YY h:mm:ssA')}
												</p>
											</div>
											<button
												className="w-32 bg-sky-300 ml-2 border rounded-lg text-lg"
												onClick={() => {
													const audioData = el?.audio; // Base64 encoded audio data
													const audioByteArray = Uint8Array.from(
														atob(audioData),
														(c) => c.charCodeAt(0)
													);
													const audioBlob = new Blob([audioByteArray], {
														type: 'audio/mp3',
													});
													const audioUrl = URL.createObjectURL(audioBlob);
													const audio = new Audio(audioUrl);
													audio.play();
												}}
											>
												Play
											</button>
										</p>
									);
								})}
								{dbMessages?.map((el, index) => {
									return (
										<>
											<p
												key={index}
												className="md:text-2xl xs:text-lg flex lg:flex-row xs:flex-col justify-between items-center gap-2"
											>
												<div className="flex flex-row justify-between ">
													<p className="text-center">
														<b>{el?.sender}</b>
													</p>
													<p className="text-center text-sm	">{el?.text}</p>
													<p className="flex gap-4 justify-end">
														{moment(el?.time).format('M/D/YY h:mm:ssA')}
													</p>
												</div>
												<button
													className="w-32 bg-sky-300 ml-2 border rounded-lg text-lg"
													onClick={() => {
														const audioData = el?.audio; // Base64 encoded audio data
														const audioByteArray = Uint8Array.from(
															atob(audioData),
															(c) => c.charCodeAt(0)
														);
														const audioBlob = new Blob([audioByteArray], {
															type: 'audio/mp3',
														});
														const audioUrl = URL.createObjectURL(audioBlob);
														const audio = new Audio(audioUrl);
														audio.play();
													}}
												>
													Play
												</button>
											</p>
										</>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default PodcastPage;
