import { Box, Button, Container, Grid } from '@mui/material';
import Modal from 'component/Modal';
import Loader from 'component/loader';
import { saveAs } from 'file-saver';
import React, { useState } from 'react';
import styles from './videos.module.scss';

const items = [
	{
		id: 1,
		title: 'Introduction',
		thumbnailPath:
			'https://letz-chat-agent-material.s3.us-east-2.amazonaws.com/videos/thumbnail_LetzChat+Intro.png',
		Vpath:
			'https://letz-chat-agent-material.s3.us-east-2.amazonaws.com/videos/LetzChat+Intro.mp4',
	},
	{
		id: 2,
		title: 'Comcast Presentation',
		thumbnailPath:
			'https://letz-chat-agent-material.s3.us-east-2.amazonaws.com/videos/thumbnail_letzchat___comcast_presentation.png',
		Vpath:
			'https://letz-chat-agent-material.s3.us-east-2.amazonaws.com/videos/letzchat___comcast_presentation.mp4',
	},
	{
		id: 3,
		title: 'Document Portal',
		thumbnailPath:
			'https://letz-chat-agent-material.s3.us-east-2.amazonaws.com/videos/thumbnail_+letzchat_document_portal.png',
		Vpath:
			'https://letz-chat-agent-material.s3.us-east-2.amazonaws.com/videos/letzchat_document_portal.mp4',
	},
	{
		id: 4,
		title: 'LetzChat Unite',
		thumbnailPath:
			'https://letz-chat-agent-material.s3.us-east-2.amazonaws.com/videos/thumbnail_+letzchat_unite.png',
		Vpath:
			'https://letz-chat-agent-material.s3.us-east-2.amazonaws.com/videos/letzchat_unite.mp4',
	},
	{
		id: 5,
		title: 'Video Subtitling',
		thumbnailPath:
			'https://letz-chat-agent-material.s3.us-east-2.amazonaws.com/videos/thumbnail_+letzchat_video_subtitling.png',
		Vpath:
			'https://letz-chat-agent-material.s3.us-east-2.amazonaws.com/videos/letzchat_video_subtitling.mp4',
	},
	{
		id: 6,
		title: 'Voice Debugging',
		thumbnailPath:
			'https://letz-chat-agent-material.s3.us-east-2.amazonaws.com/videos/thumbnail_+letzchat_voice_dubbing.png',
		Vpath:
			'https://letz-chat-agent-material.s3.us-east-2.amazonaws.com/videos/letzchat_voice_dubbing.mp4',
	},
	{
		id: 7,
		title: 'Website Plugin',
		thumbnailPath:
			'https://letz-chat-agent-material.s3.us-east-2.amazonaws.com/videos/thumbnail_+letzchat_website_plugin.png',
		Vpath:
			'https://letz-chat-agent-material.s3.us-east-2.amazonaws.com/videos/letzchat_website_plugin.mp4',
	},
	{
		id: 8,
		title: 'Translating Deep Fake Subtitling Video',
		thumbnailPath:
			'https://letz-chat-agent-material.s3.us-east-2.amazonaws.com/videos/thumbnail_translating_deep_fake_%2B_subtitling_video.png',
		Vpath:
			'https://letz-chat-agent-material.s3.us-east-2.amazonaws.com/videos/translating_deep_fake_%2B_subtitling_video.mp4',
	},
];
export default function Videos() {
	const [activeVideo, setActiveVideo] = useState(null);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	const downloadVideo = async (url, filename) => {
		setLoading(true);
		const response = await fetch(url);
		const blob = await response.blob();
		setLoading(false);
		saveAs(blob, filename);
	};

	console.log(styles);
	return (
		<>
			{loading && <Loader />}
			<Box className="scroll-container">
				<Container className="video_con">
					<div className="relative mx-2">
						<Grid
							container
							className="gap-5 pt-10"
							lg="12"
							sx={{ display: 'flex', justifyContent: 'center' }}
						>
							{items.map((item) => (
								<Grid lg="3" key={item.id} className="mb-5">
									<div className="border-[#6DB7F2] border-2 p-3 rounded-xl">
										<img
											src={item.thumbnailPath}
											className="rounded-xl cursor-pointer"
											onClick={() => {
												setOpen(true);
												setActiveVideo(item.Vpath);
											}}
										/>
									</div>
									<div className="pt-3">
										<div className="flex justify-center">
											<h2 className="text-[#6B6B6B] text-center">
												{item.title}
											</h2>
										</div>
										<div className="flex justify-center">
											<Button
												variant="contained"
												className="skyblue__button cursor-pointer "
												onClick={() => downloadVideo(item.Vpath, item.title)}
											>
												Download
											</Button>
										</div>
									</div>
								</Grid>
							))}

							{activeVideo && (
								<Modal
									open={open}
									handleClose={() => {
										setOpen(false);
										setActiveVideo(null);
									}}
									className={styles.mainDivClasses}
								>
									<video src={activeVideo} controls autoPlay />
								</Modal>
							)}
						</Grid>
					</div>
				</Container>
			</Box>
		</>
	);
}
