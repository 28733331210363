import React, { useEffect, useRef, useState } from 'react';
import { Box, Container, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import VolumeMuteIcon from '@mui/icons-material/VolumeMute';
import api from 'utils/axios';
import stripe from 'assets/stripe.png';
import { createNotification } from 'utils/create-notification';
import MicIcon from '@mui/icons-material/Mic';
import style from './talkeasy.module.scss';
import Speaker from './Speaker';
import MicRightWave from './MicRightWave';
import MicLeftWave from './MicLeftWave';
import { Link } from 'react-router-dom';
import { googleLiveVoiceToTextLanguages } from 'constants/languages';
import CustomSelect from 'component/CustomSelect';
import useGoogleTranscription from 'hooks/useGoogleTranscription';

let deviceLanguage = navigator.language || navigator.userLanguage;
deviceLanguage = googleLiveVoiceToTextLanguages.find(
	(el) => el.code === deviceLanguage
);
if (!deviceLanguage || deviceLanguage === 'undefined') {
	console.log('device language is not getting setting to en-US');
	deviceLanguage = { name: 'English (United States)', code: 'en-US' };
}

const silenceOptions = [
	{ label: `1's`, value: 1 },
	{ label: `2's`, value: 2 },
	{ label: `3's`, value: 3 },
	{ label: `4's`, value: 4 },
	{ label: `5's`, value: 5 },
	{ label: `6's`, value: 6 },
	{ label: `7's`, value: 7 },
	{ label: `8's`, value: 8 },
	{ label: `9's`, value: 9 },
	{ label: `10's`, value: 10 },
];

const TalkEasy = () => {
	const [isLoading, setIsLoading] = React.useState(false);
	const [translation, setTranslation] = React.useState('');
	const [audio, setAudio] = React.useState(null);
	const [textAreaValue, setTextAreaValue] = React.useState('');
	const [textAreaValue1, setTextAreaValue1] = React.useState('');
	const [speaker, setSpeaker] = React.useState(true);
	const [speechConfidenceInfo, setSpeechConfidenceInfo] = React.useState({
		sourceLanguage: [],
		targetLanguage: [],
	});

	const { control, watch } = useForm();

	const sourceLanguage = watch('sourceLanguage')?.value
		? watch('sourceLanguage')?.value
		: deviceLanguage?.code;

	const targetLanguage = watch('targetLanguage')?.value;

	const {
		isStreamLoading,
		connect: connect0,
		disconnect: disconnect0,
		currentPhrase,
		currentRecognition,
		recognitionHistory,
		isRecording,
		setSilenceDuration,
		dataSpeech,
		stopOnSilenceRef,
		currentRecognitionRef,
	} = useGoogleTranscription(sourceLanguage, 3);

	const {
		isStreamLoading: isStreamLoading1,
		connect: connect1,
		disconnect: disconnect1,
		currentPhrase: currentPhrase1,
		currentRecognition: currentRecognition1,
		recognitionHistory: recognitionHistory1,
		isRecording: isRecording1,
		setSilenceDuration: setSilenceDuration1,
		dataSpeech: dataSpeech1,
		stopOnSilenceRef: stopOnSilenceRef1,
		currentRecognitionRef: currentRecognitionRef1,
	} = useGoogleTranscription(targetLanguage, 3);

	function compareAverageConfidence(scores1, scores2) {
		const calculateAverage = (scores) => {
			const nonZeroScores = scores.filter((score) => score > 0);
			const count = nonZeroScores.length;
			if (count === 0) return 0; // Return 0 if there are no non-zero scores
			const sum = nonZeroScores.reduce((acc, score) => acc + score, 0);
			return sum / count;
		};
		const average1 = calculateAverage(scores1);
		const average2 = calculateAverage(scores2);
		if (average1 > average2) {
			return 0;
		} else if (average1 < average2) {
			return 1;
		} else if (average1 === 0 && average2 === 0) {
			return -1;
		} else {
			return -1;
		}
	}

	useEffect(() => {
		if (dataSpeech?.isFinal) {
			setSpeechConfidenceInfo((prev) => {
				return {
					...prev,
					sourceLanguage: [
						...prev.sourceLanguage,
						dataSpeech?.info?.results[0]?.alternatives[0]?.confidence,
					],
				};
			});
		}
	}, [dataSpeech]);

	useEffect(() => {
		if (dataSpeech1?.isFinal) {
			setSpeechConfidenceInfo((prev) => {
				return {
					...prev,
					targetLanguage: [
						...prev.targetLanguage,
						dataSpeech1?.info?.results[0]?.alternatives[0]?.confidence,
					],
				};
			});
		}
	}, [dataSpeech1]);

	console.log(
		compareAverageConfidence(
			speechConfidenceInfo.sourceLanguage,
			speechConfidenceInfo.targetLanguage
		)
	);

	useEffect(() => {
		if (stopOnSilenceRef.current) {
			stopOnSilenceRef.current = false;
			disconnect();
			let languagesToTranslate = {
				sourceLangCode: 'auto',
				targetLangCode: 'en-US',
			};
			const speechConfidenceInfoAvg = compareAverageConfidence(
				speechConfidenceInfo.sourceLanguage,
				speechConfidenceInfo.targetLanguage
			);
			if (speechConfidenceInfoAvg === 0 || speechConfidenceInfoAvg === -1) {
				languagesToTranslate.sourceLangCode = sourceLanguage;
				languagesToTranslate.targetLangCode = targetLanguage;
				setTimeout(() => {
					if (!recognitionHistory.map((el) => el.text).join(' ')) {
						makeTranslationRequest(
							currentRecognitionRef.current,
							languagesToTranslate
						);
					} else {
						makeTranslationRequest(
							`${recognitionHistory.map((el) => el.text).join(' ')} ${
								currentRecognitionRef.current
							}`,
							languagesToTranslate
						);
					}
				}, 100);
			} else if (speechConfidenceInfoAvg === 1) {
				languagesToTranslate.sourceLangCode = targetLanguage;
				languagesToTranslate.targetLangCode = sourceLanguage;
				setTimeout(() => {
					if (!recognitionHistory1.map((el) => el.text).join(' ')) {
						makeTranslationRequest(
							currentRecognitionRef1.current,
							languagesToTranslate
						);
					} else {
						makeTranslationRequest(
							`${recognitionHistory1.map((el) => el.text).join(' ')} ${
								currentRecognitionRef1.current
							}`,
							languagesToTranslate
						);
					}
				}, 100);
			}

			stopOnSilenceRef.current = false;
		}
	}, [stopOnSilenceRef.current, stopOnSilenceRef1.current]);

	const makeTranslationRequest = async (
		text,
		{ sourceLangCode, targetLangCode }
	) => {
		if (!text || text.trim() === '') return;
		setIsLoading(true);
		try {
			const response = await api.post('/translation/text', {
				sourceLanguage: sourceLangCode,
				targetLanguage: targetLangCode,
				text,
			});
			setIsLoading(false);
			setTranslation(response?.data?.translatedText);
			setAudio(response?.data?.audioData);
			if (!response?.data?.audioData) {
				connect();
				return;
			}
			const audioElement = new Audio(
				`data:audio/mp3;base64,${response?.data?.audioData}`
			);
			audioElement.addEventListener('play', () => {
				setSpeaker(false);
			});
			audioElement.addEventListener('ended', () => {
				setSpeaker(true);
				connect();
			});
			audioElement.play();
		} catch (error) {
			setIsLoading(false);
			createNotification('error', 'Error', error?.response?.data?.error);
			console.log('Error in text Translation', error?.response?.data?.error);
		}
	};

	const playAudio = async () => {
		console.log(audio, 'hamza123');
		if (!audio) {
			connect();
			return;
		}
		const audioElement = new Audio(`data:audio/mp3;base64,${audio}`);
		audioElement.addEventListener('play', () => {
			setSpeaker(false);
		});
		audioElement.addEventListener('ended', () => {
			setSpeaker(true);
		});
		await audioElement.play();
		connect();
	};

	const handleInputChange = (e) => {
		const inputText = e.target.value;
		setTextAreaValue(inputText);
	};

	const handleInputChange1 = (e) => {
		const inputText = e.target.value;
		setTextAreaValue1(inputText);
	};

	useEffect(() => {
		if (watch('silenceDuration')?.value) {
			setSilenceDuration(watch('silenceDuration')?.value);
			setSilenceDuration1(watch('silenceDuration')?.value);
		}
	}, [watch('silenceDuration')?.value]);

	useEffect(() => {
		setTextAreaValue(
			() =>
				`${recognitionHistory
					.map((el) => el.text)
					.join(' ')} ${currentRecognition} ${currentPhrase}`
		);
	}, [currentRecognition, currentPhrase]);

	useEffect(() => {
		setTextAreaValue1(
			() =>
				`${recognitionHistory1
					.map((el) => el.text)
					.join(' ')} ${currentRecognition1} ${currentPhrase1}`
		);
	}, [currentRecognition1, currentPhrase1]);

	const connect = () => {
		if (!sourceLanguage || !targetLanguage) {
			return createNotification(
				'error',
				'Error',
				'Please Select Both Languages.'
			);
		}
		setSpeechConfidenceInfo({
			sourceLanguage: [],
			targetLanguage: [],
		});
		connect0();
		connect1();
	};

	const disconnect = () => {
		disconnect0();
		disconnect1();
	};

	return (
		<>
			<Box className="bg-[#f8f8f8] text-trans shadow-none">
				<Container className="mt-5">
					<div className="flex bg-white  border-b-2">
						<CustomSelect
							name="sourceLanguage"
							control={control}
							options={googleLiveVoiceToTextLanguages.map((country) => ({
								label: country.name,
								value: country.code,
							}))}
							placeholder={'First Language'}
							defaultValue={{
								label: deviceLanguage?.name,
								value: deviceLanguage?.code,
							}}
							// handleChange={handleLanguageChange}
							className="border-0 rounded-0 z-30"
						/>
						<CustomSelect
							name="targetLanguage"
							control={control}
							options={googleLiveVoiceToTextLanguages.map((country) => ({
								label: country.name,
								value: country.code,
							}))}
							placeholder={'Second Language'}
							// handleChange={handleLanguageChange}
							className="border-0 rounded-0 z-30"
						/>
						<CustomSelect
							name="silenceDuration"
							control={control}
							options={silenceOptions}
							placeholder={'Silence Duration'}
							// handleChange={handleLanguageChange}
							className="border-0 rounded-0 z-30"
						/>
					</div>
					<Grid
						container
						className="bg-white rounded-lg shadow-lg"
						lg={12}
						md={12}
					>
						<Grid item md={6} xs={12} className="border-r-2 border-grey-100">
							<Box className="">
								<div className="flex rounded-md border-0 relative">
									<textarea
										disabled
										value={textAreaValue}
										onChange={handleInputChange}
										type="text"
										placeholder=""
										style={{ resize: 'none', fontSize: '20px' }}
										autoFocus
										className={`${
											compareAverageConfidence(
												speechConfidenceInfo.sourceLanguage,
												speechConfidenceInfo.targetLanguage
											) === 0 && 'text-custom-blue'
										} bg-white w-1/2 pl-8 pt-8 border-0 outline-none h-80 focus:outline-none  focus:ring-0 focus:ring-0 focus:border-transparent `}
									/>
									<textarea
										disabled
										value={textAreaValue1}
										onChange={handleInputChange1}
										type="text"
										placeholder=""
										style={{ resize: 'none', fontSize: '20px' }}
										autoFocus
										className={`${
											compareAverageConfidence(
												speechConfidenceInfo.sourceLanguage,
												speechConfidenceInfo.targetLanguage
											) === 1 && 'text-custom-blue'
										} bg-white w-1/2 pl-8 pt-8 border-0 outline-none h-80 focus:outline-none  focus:ring-0 focus:ring-0 focus:border-transparent `}
									/>

									<div className="w-1/2 flex flex-col items-center justify-center bg-white pl-8 pt-8 border-0 outline-none h-80 ">
										<div className="flex  items-center justify-center">
											<MicIcon
												onClick={isRecording ? disconnect : connect}
												sx={{
													color: '#6db7f2',
													fontSize: '180px',
												}}
											/>
											{isRecording && (
												<>
													<MicRightWave />
													<MicLeftWave />
												</>
											)}
										</div>
									</div>
									<p className="px-4 absolute bottom-5 right-6 text-center font-[600] text-custom-blue">
										Click the microphone to start/stop listening
									</p>
								</div>
							</Box>
						</Grid>
						<Grid item md={6} xs={12} className="">
							<Box>
								<div className="flex rounded-0 border-0 relative">
									<textarea
										type="text"
										style={{ fontSize: '20px', resize: 'none', width: '100%' }}
										placeholder="Translation..."
										className="bg-white w-1/2 pl-8 pt-8 border-0 outline-none h-80 focus:outline-none focus:ring-0 focus:ring-0 focus:border-transparent"
										readOnly
										value={translation}
									/>

									<div className=" bg-white w-1/2 bg-whit border-0 outline-none h-80 focus:outline-none focus:ring-0 focus:ring-0 focus:border-transparent pr-16">
										{!isLoading && (
											<div className="flex justify-center items-center mr-20 xm:mr-0 relative mt-20 ">
												<div
													className="relative"
													onClick={playAudio}
													style={{ cursor: 'pointer' }}
												>
													<VolumeMuteIcon
														className={`${style.speakericon} `}
														sx={{ color: '#6DB7F2', fontSize: '180px' }}
													/>
													{!speaker && (
														<div className="absolute -bottom-2 left-4">
															<Speaker />
														</div>
													)}
												</div>
											</div>
										)}
										{isLoading && (
											<div className="absolute inset-0 flex items-center justify-center">
												<div className="text-loader w-80 "></div>
											</div>
										)}
									</div>
									<Link
										to={'/'}
										className="px-4 cursor-pointer text-gray-600 absolute bottom-5 font-[600] hover:text-custom-blue"
									>
										Return to Text Translation
									</Link>
								</div>
							</Box>
						</Grid>
					</Grid>
					<Box>
						<div className="text-center pt-10">
							<p className="text-lg text-[#00000099]">
								Secure payment transctions are facilitated through
							</p>
							<div className="flex justify-center items-center">
								<img src={stripe} alt="stripe" width={150} />
							</div>
						</div>
					</Box>
				</Container>
			</Box>
		</>
	);
};

export default TalkEasy;
