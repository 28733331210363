import { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';

const getMediaStream = () =>
	navigator.mediaDevices.getUserMedia({
		audio: {
			deviceId: 'default',
			sampleRate: 16000,
			sampleSize: 16,
			channelCount: 1,
		},
		video: false,
	});

const useGoogleTranscription = (language, silenceDuration) => {
	const [isStreamLoading, setIsStreamLoading] = useState(false);
	const [currentPhrase, setCurrentPhrase] = useState('');
	const [currentRecognition, setCurrentRecognition] = useState('');
	const [recognitionHistory, setRecognitionHistory] = useState([]);
	const [connection, setConnection] = useState();
	const [isRecording, setIsRecording] = useState(false);
	const [recorder, setRecorder] = useState();
	const [dataSpeech, setDataSpeech] = useState();

	const socketRef = useRef();
	const processorRef = useRef();
	const audioContextRef = useRef();
	const audioInputRef = useRef();
	const stopOnSilenceRef = useRef(false);
	const currentRecognitionRef = useRef();
	const socketConnection = useRef();

	const speechRecognized = (data) => {
		setDataSpeech(data);
		if (data.isFinal) {
			setCurrentPhrase('');
			setCurrentRecognition((old) => `${old ? `${old} ` : ''}${data.text}`);
		} else setCurrentPhrase(data.text);
	};

	useEffect(() => {
		currentRecognitionRef.current = `${currentRecognition} ${currentPhrase}`;
	}, [currentRecognition, currentPhrase]);

	useEffect(() => {
		// eslint-disable-next-line no-undef
		socketRef.current = io.connect(process.env.REACT_APP_BASE_URL);

		socketRef.current.emit('set_silence_duration', silenceDuration);

		socketRef.current.on('silence_threshold_exceeded', () => {
			if (
				currentRecognitionRef.current &&
				currentRecognitionRef.current !== ' '
			) {
				const message = {
					text: currentRecognitionRef.current,
					time: new Date(),
				};

				setRecognitionHistory((old) => {
					return [message, ...old];
				});

				setCurrentRecognition('');
				setCurrentPhrase('');
				currentRecognitionRef.current = '';
			}
			stopOnSilenceRef.current = true;
		});

		socketRef.current.on('connect', () => {
			socketConnection.current = socketRef.current;
		});

		return () => {
			socketRef?.current?.disconnect();
		};
	}, []);

	const setSilenceDuration = (duration) => {
		socketRef.current.emit('set_silence_duration', duration);
	};

	const connect = () => {
		setCurrentPhrase('');
		setCurrentRecognition('');
		setRecognitionHistory([]);

		setIsStreamLoading(true);
		setTimeout(() => {
			setIsStreamLoading(false);
		}, 2000);

		setConnection(socketConnection.current);

		socketRef.current.emit('startGoogleCloudStream', language);

		socketRef.current.on('receive_audio_text', (data) => {
			speechRecognized(data);
		});

		socketRef.current.on('disconnect', () => {
			console.log('disconnected', socketRef.current.id);
		});
	};

	const disconnect = () => {
		if (!connection) return;
		connection?.emit('endGoogleCloudStream');
		processorRef.current?.disconnect();
		audioInputRef.current?.disconnect();
		audioContextRef.current?.close();
		setConnection(undefined);
		setRecorder(undefined);
		setIsRecording(false);
		socketRef.current.off('receive_audio_text');
	};

	useEffect(() => {
		(async () => {
			if (connection) {
				if (isRecording) {
					return;
				}

				const stream = await getMediaStream();

				audioContextRef.current = new window.AudioContext();

				await audioContextRef.current.audioWorklet.addModule(
					'/src/worklets/recorderWorkletProcessor.js'
				);

				audioContextRef.current.resume();

				audioInputRef.current =
					audioContextRef.current.createMediaStreamSource(stream);

				processorRef.current = new AudioWorkletNode(
					audioContextRef.current,
					'recorder.worklet'
				);

				processorRef.current.connect(audioContextRef.current.destination);
				audioContextRef.current.resume();

				audioInputRef.current.connect(processorRef.current);

				processorRef.current.port.onmessage = () => {
					const audioData = event.data;
					if (typeof connection.emit === 'function')
						connection?.emit('send_audio_data', { audio: audioData });
				};
				setIsRecording(true);
			} else {
				console.log('No connection');
			}
		})();
		return () => {
			if (isRecording) {
				processorRef.current?.disconnect();
				audioInputRef.current?.disconnect();
				if (audioContextRef.current?.state !== 'closed') {
					audioContextRef.current?.close();
				}
			}
		};
	}, [connection, isRecording, recorder]);

	return {
		isStreamLoading,
		connect,
		disconnect,
		currentPhrase,
		currentRecognition,
		recognitionHistory,
		isRecording,
		setSilenceDuration,
		dataSpeech,
		stopOnSilenceRef,
		currentRecognitionRef,
	};
};

export default useGoogleTranscription;
