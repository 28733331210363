import { memo, useEffect, useState } from 'react';
import {
	BrowserRouter as Router,
	Routes as Switch,
	Route,
	Navigate,
} from 'react-router-dom';

import { adminRoute, privateRoutes, publicRoute, agentRoute } from './helper';
import Loader from 'component/loader';
import { useSelector } from 'react-redux';
import Footer from 'component/footer';

// eslint-disable-next-line react/prop-types
const Routes = ({ token, loader }) => {
	const { profileInformation } = useSelector((state) => state.user);

	useEffect(() => {
		// Handle token logic here
	}, [token]);

	if (loader) {
		return <Loader />;
	}

	return (
		<div className="flex flex-col min-h-screen ">
			<div
				className="flex-1"
				style={{
					minHeight: 'calc(100vh - 30px)',
				}}
			>
				<Switch>
					{!token &&
						publicRoute?.map(({ path, component }, index) => {
							return <Route key={index} path={path} element={component} />;
						})}
					{token &&
						profileInformation?.role === 'Admin' &&
						adminRoute?.map(({ path, component }, index) => {
							return <Route key={index} path={path} element={component} />;
						})}
					{token &&
						profileInformation?.role === 'Agent' &&
						agentRoute?.map(({ path, component }, index) => {
							return <Route key={index} path={path} element={component} />;
						})}
					{token &&
						profileInformation?.role !== 'Admin' &&
						privateRoutes?.map(({ path, component }, index) => {
							return <Route key={index} path={path} element={component} />;
						})}
					<Route path="*" element={<Navigate to={token ? '/' : '/login'} />} />
				</Switch>
			</div>
			<Footer />
		</div>
	);
};

export default memo(Routes);
