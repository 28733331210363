import React from 'react';
import './speaker.css'; // Import CSS file for styling

const MicLeftWave = () => {
	return (
		<>
			<div className="flex flex-row justify-center items-center">
				<div className="myElement text-custom-blue text-[30px] mb-24 -ml-[360px]">
					{'（'}
				</div>
				<div className="myElement text-custom-blue text-[40px] mb-24 -ml-[60px]">
					{'（'}
				</div>
				<div className="myElement text-custom-blue text-[50px] mb-24 -ml-[70px]">
					{'（'}
				</div>
			</div>
		</>
	);
};

export default MicLeftWave;
