import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import CustomSelect from 'component/CustomSelect';
import { useForm } from 'react-hook-form';
import Mic from 'assets/mic.svg';
import MicRed from 'assets/micRed.svg';
import { googleLiveVoiceToTextLanguages } from 'constants/languages';
import api from 'utils/axios';
import Loader from 'component/loader';
import MinimalLoader from 'component/MinimalLoader';
import PublicHeader from 'component/PublicHeader';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import useGoogleTranscriptionWithSingleConnection from 'hooks/useGoogleTranscriptionWithSingleConnection';
import Socket from '../../../services/SocketManager';
import moment from 'moment';
import autoPlayAudio from 'assets/autoplay.mp3';
import { createNotification } from 'utils/create-notification';
import style from './scrollbar.module.scss';
import useSkipFirstRender from 'hooks/useSkipFirstRender';
import { ApiService } from 'services/api-services';
import Modal from 'component/Modal';

const silenceOptions = [
	{ label: `0.2's`, value: 0.2 },
	{ label: `0.3's`, value: 0.3 },
	{ label: `0.4's`, value: 0.4 },
	{ label: `0.5's`, value: 0.5 },
	{ label: `0.6's`, value: 0.6 },
	{ label: `0.7's`, value: 0.7 },
	{ label: `0.8's`, value: 0.8 },
	{ label: `1.0's`, value: 1.0 },
	{ label: `1.5's`, value: 1.5 },
	{ label: `2.0's`, value: 2.0 },
	{ label: `3.0's`, value: 3.0 },
	{ label: `4.0's`, value: 4.0 },
];

const genderOptions = [
	{ label: `MALE`, value: 'MALE' },
	{ label: `FEMALE`, value: 'FEMALE' },
];

let deviceLanguage = navigator.language || navigator.userLanguage;
deviceLanguage = googleLiveVoiceToTextLanguages.find(
	(el) => el.code === deviceLanguage
);

if (!deviceLanguage || deviceLanguage === 'undefined') {
	deviceLanguage = { name: 'English (United States)', code: 'en-US' };
}

const getCallDetails = async (userId) => {
	try {
		const response = await api.get(`twilio-call/incomming-call/${userId}`);
		return response?.data;
	} catch (error) {
		console.log('An error occurred in getCallDetails:', error);
		throw error;
	}
};

const updateCallDetails = async (userId, data) => {
	try {
		if (data) {
			const response = await api.patch(
				`twilio-call/incomming-call/${userId}`,
				data
			);
			console.log(response, 'response hamza');
			return response?.data;
		} else {
			const response = await api.patch(`twilio-call/incomming-call/${userId}`, {
				callerLanguage: deviceLanguage?.code === 'en-US' ? 'en-CA' : 'en-US',
				agentLanguage: deviceLanguage?.code,
				agentGender: 'MALE',
				callStatus: 'completed',
			});
			return response?.data;
		}
	} catch (error) {
		console.log('An error occurred in updateCallDetails:', error);
		throw error;
	}
};

const IncommingCall = () => {
	let { userId } = useParams();

	const [transcriptReceived, setTranscriptReceived] = useState([]);
	const [liveTranscription, setLiveTranscription] = useState('');
	const [presenter, setPresenter] = useState();
	const [loading, setLoading] = useState(false);
	const [autoPlay, setAutoPlay] = useState(false);
	const [dbMessages, setDbMessages] = useState([]);
	const [inputDeviceOptions, setInputDeviceOptions] = useState([]);
	const [audioQueue, setAudioQueue] = useState([]);
	const [isCallConnected, setIsCallConnected] = useState(false);
	const [deletePresenterOpen, setDeletePresenterOpen] = useState(false);

	const RecognitionHistoryScroling = useRef(null);
	const autoPlayRef = useRef(autoPlay);
	const audioRef = useRef(new Audio());

	const { control, watch, setValue } = useForm({
		defaultValues: {
			callerPauseOnSilentDuration: {
				value: 0.7,
				label: `0.7's`,
			},
			agentLanguage: {
				value: deviceLanguage?.code,
				label: deviceLanguage?.name,
			},
			gender: {
				value: 'MALE',
				label: 'MALE',
			},
		},
	});

	const {
		isStreamLoading,
		connect,
		disconnect,
		currentPhrase,
		currentRecognition,
		recognitionHistory,
		isRecording,
		setSilenceDuration,
		dataSpeech,
		stopOnSilenceRef,
		currentRecognitionRef,
		updatedMessage,
	} = useGoogleTranscriptionWithSingleConnection(
		watch('agentLanguage')?.value,
		1,
		watch('inputDevices')?.value
	);

	console.log({
		agentLanguage: watch('agentLanguage')?.value,
	});

	const sortedRecognitionHistory = [...recognitionHistory.current]
		.sort((a, b) => new Date(b.time) - new Date(a.time))
		.reverse();

	// useEffect(() => {
	// 	setLoading(true);
	// 	ApiService.post(
	// 		`api/podcast-translation/is-correct-presenter-url/${presenterId}`
	// 	)
	// 		.then((res) => {
	// 			setPresenterName(res?300.podcastTranslation?.name);
	// 			setPresenter(res?.data?.podcastTranslation);
	// 			Socket.emit(
	// 				'join_room_podcast',
	// 				res?.data?.podcastTranslation?.roomId,
	// 				watch('sourceLanguage')?.value,
	// 				res?.data?.podcastTranslation?._id,
	// 				watch('gender')
	// 			);
	// 			Socket.emit('language_to_moderator', {
	// 				presenter: res?.data?.podcastTranslation,
	// 				sourceLanguage: watch('sourceLanguage')?.value,
	// 			});
	// 			ApiService.get(
	// 				`api/podcast-translation/get-podcast-messages/${
	// 					res?.data?.podcastTranslation?.roomId
	// 				}/${watch('sourceLanguage')?.value}`
	// 			).then((res) => {
	// 				const messages = res?.data?.messages;
	// 				setDbMessages(messages?.reverse());
	// 				setLoading(false);
	// 			});

	// 			// res.status === 200 && res.data.isCorrect ? null : navigate('/');
	// 		})
	// 		.catch((err) => {
	// 			setLoading(false);
	// 			console.log(err, 'error in api call of is-correct-presenter-url');
	// 		});

	// 	Socket.on('receive_message_podcast', (data) => {
	// 		if (autoPlayRef.current) {
	// 			if (data?.presenter !== presenterId) {
	// 				const audioData = data?.audio; // Base64 encoded audio data
	// 				const audioByteArray = Uint8Array.from(atob(audioData), (c) =>
	// 					c.charCodeAt(0)
	// 				);
	// 				const audioBlob = new Blob([audioByteArray], {
	// 					type: 'audio/mp3',
	// 				});
	// 				console.log(audioData, 'audioBlob');
	// 				const audioUrl = URL.createObjectURL(audioBlob);
	// 				setAudioQueue((prevQueue) => [...prevQueue, audioUrl]);
	// 				// const audio = new Audio(audioUrl);
	// 				// audio.play();
	// 			}
	// 		}
	// 		setTranscriptReceived((prev) => {
	// 			return [data, ...prev];
	// 		});
	// 	});

	// 	Socket.on('moderator_not_live', () => {
	// 		createNotification('error', 'Error', 'Your Moderator is not live');
	// 	});
	// }, []);

	//New Work From Here
	useSkipFirstRender(() => {
		console.log('render');
		if (isRecording) {
			disconnect();
			connect();
		}
		Socket.emit('change_language_inbound_call_twilio', {
			callerLanguage: watch('callerLanguage')?.value,
			agentLanguage: watch('agentLanguage')?.value,
			userId,
		});
		setLoading(true);
		updateCallDetails(userId, {
			callerLanguage: watch('callerLanguage')?.value,
			agentLanguage: watch('agentLanguage')?.value,
		})
			.then(() => {
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
			});
	}, [watch('callerLanguage')?.value, watch('agentLanguage')?.value]);

	useSkipFirstRender(() => {
		if (watch('callerPauseOnSilentDuration')?.value) {
			Socket.emit('change_caller_silence_duration_inbound_call_twilio', {
				silenceDuration: watch('callerPauseOnSilentDuration')?.value,
				userId,
			});
		}
	}, [watch('callerPauseOnSilentDuration')?.value]);

	useSkipFirstRender(() => {
		Socket.emit('change_agent_gender_inbound_call_twilio', {
			agentGender: watch('gender')?.value,
			userId,
		});
		setLoading(true);
		updateCallDetails(userId, {
			agentGender: watch('gender')?.value,
		})
			.then(() => {
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
			});
	}, [watch('gender')?.value]);

	useSkipFirstRender(() => {
		if (RecognitionHistoryScroling.current) {
			RecognitionHistoryScroling.current.scrollTop =
				RecognitionHistoryScroling.current.scrollHeight;
		}
	}, [sortedRecognitionHistory]);

	useSkipFirstRender(() => {
		if (watch('pauseOnSilentDuration')?.value) {
			setSilenceDuration(watch('pauseOnSilentDuration')?.value);
		}
	}, [watch('pauseOnSilentDuration')?.value]);

	useSkipFirstRender(() => {
		if (updatedMessage) {
			Socket.emit('send_message_from_agent_inbound_call_twilio', {
				message: updatedMessage,
				sourceLanguage: watch('agentLanguage')?.value,
				time: new Date(),
				userId,
			});
		}
	}, [updatedMessage]);

	useEffect(() => {
		const playNextAudio = () => {
			if (audioQueue.length === 0) return;
			audioRef.current.src = audioQueue[0];
			audioRef.current.play();
			audioRef.current.onended = () => {
				setAudioQueue((prevQueue) => prevQueue.slice(1));
			};
		};
		if (audioRef.current.paused) {
			playNextAudio();
		}
	}, [audioQueue]);

	useEffect(() => {
		autoPlayRef.current = autoPlay;
		audioRef.current.src = autoPlayAudio;
		audioRef.current.play();
	}, [autoPlay]);

	useEffect(() => {
		// Get the list of available input devices
		navigator.mediaDevices
			.enumerateDevices()
			.then((devices) => {
				const microphones = devices.filter(
					(device) => device.kind === 'audioinput'
				);
				const microphoneOptions = microphones.map((device) => ({
					label: device.label || `Microphone ${device.deviceId}`,
					value: device.deviceId,
				}));
				setInputDeviceOptions(microphoneOptions);
			})
			.catch((error) => {
				console.error('Error accessing media devices', error);
			});

		const handleTranscription = (data) => {
			console.log(data, 'data');
			if (data?.audio) {
				console.log('This is run 1');
				const audioData = data?.audio;
				const audioByteArray = Uint8Array.from(atob(audioData), (c) =>
					c.charCodeAt(0)
				);
				const audioBlob = new Blob([audioByteArray], { type: 'audio/mp3' });
				console.log(audioData, 'audioBlob');
				const audioUrl = URL.createObjectURL(audioBlob);
				setAudioQueue((prevQueue) => [...prevQueue, audioUrl]);
			}
			if (data.text) {
				setLiveTranscription(data?.text);
			}
		};

		const handleCallConnected = () => {
			setIsCallConnected(true);
		};

		const handleCallEnded = () => {
			setIsCallConnected(false);
		};

		Socket.emit('join_inbound_caller_room_twilio', userId);
		Socket.on('twilio-realtime-transcription', handleTranscription);
		Socket.on('twilio-connected-incomming-call', handleCallConnected);
		Socket.on('twilio-end-incomming-call', handleCallEnded);

		getCallDetails(userId)
			.then((res) => {
				setLoading(true);
				setIsCallConnected(res?.callStatus === 'ongoing' ? true : false);
				console.log({
					value: res?.callerLanguage,
					label: googleLiveVoiceToTextLanguages.find(
						(el) => el.code === res?.callerLanguage
					)?.name,
				});
				setValue('callerLanguage', {
					value: res?.callerLanguage,
					label: googleLiveVoiceToTextLanguages.find(
						(el) => el.code === res?.callerLanguage
					)?.name,
				});
				setValue('agentLanguage', {
					value: res?.agentLanguage,
					label: googleLiveVoiceToTextLanguages.find(
						(el) => el.code === res?.agentLanguage
					)?.name,
				});
				setValue('gender', {
					value: res?.agentGender,
					label: res?.agentGender?.toUpperCase(),
				});
				setDbMessages(res?.transcript?.reverse());
				setTimeout(() => {
					setLoading(false);
				}, 500);
			})
			.catch(() => {
				setLoading(true);
				updateCallDetails(userId);
				setIsCallConnected(false);

				if (deviceLanguage?.code === 'en-US') {
					setValue('callerLanguage', {
						value: 'en-CA',
						label: 'English (Canada)',
					});
				} else {
					setValue('callerLanguage', {
						value: 'en-US',
						label: 'English (United States)',
					});
				}
				setValue('agentLanguage', {
					value: deviceLanguage?.code,
					label: deviceLanguage?.name,
				});
				setValue('gender', {
					value: 'MALE',
					label: 'MALE',
				});
				setTimeout(() => {
					setLoading(false);
				}, 500);
			});

		return () => {
			Socket.off('twilio-realtime-transcription', handleTranscription);
			Socket.off('twilio-connected-incomming-call', handleCallConnected);
			Socket.off('twilio-end-incomming-call', handleCallEnded);
			Socket.disconnect();
		};
	}, []);

	const downloadTranscriptHandler = async () => {
		try {
			setLoading(true);

			const response = await api.get(
				`twilio-call/incomming-call/download-transcript/${userId}`
			);

			const blob = new Blob([response.data], {
				type: response.headers['content-type'],
			});

			// Create a temporary URL to download the file
			const url = window.URL.createObjectURL(blob);

			// Create an anchor element and programmatically click it to trigger the download
			const link = document.createElement('a');
			link.href = url;
			link.download = `transcript-${watch('sourceLanguage')?.value}`; // Set the desired file name and extension
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);

			// Release the temporary URL
			window.URL.revokeObjectURL(url);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.error('An error occurred in downloadTranscriptHandler:', error);
		}
	};

	const endCallHandler = async () => {
		setLoading(true);
		Socket.emit('End_inbound_call_twilio', userId);
		setTimeout(() => {
			setIsCallConnected(false);
			setLoading(false);
		}, 500);
	};

	console.log({
		callerLanguage: watch('callerLanguage')?.value,
		agentLanguage: watch('agentLanguage')?.value,
	});

	const deleteTranscriptHandler = async () => {
		setLoading(true);
		setDeletePresenterOpen(false);
		await api.get(`twilio-call/incomming-call/delete-transcript/${userId}`);
		setDbMessages([]);
		setLoading(false);
	};

	return (
		<>
			<Modal
				open={deletePresenterOpen}
				handleClose={() => {
					setDeletePresenterOpen(false);
				}}
				title="Are you sure you want to delete Transcript?"
			>
				<div className="flex justify-center ">
					<button
						onClick={deleteTranscriptHandler}
						className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded mr-2"
					>
						YES
					</button>
					<button
						onClick={() => setDeletePresenterOpen(false)}
						className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded"
					>
						NO
					</button>
				</div>
			</Modal>
			{loading && <Loader />}
			<PublicHeader />
			<div className="flex flex-col w-full justify-center">
				<h1 className="text-4xl text-gray-600 font-[600] flex items-center justify-center my-12">
					<span>Call Translator</span>
					<span> - </span>
					<span
						className={`${isCallConnected ? 'text-green-500' : 'text-red-500'}`}
					>
						{isCallConnected ? 'Call Connected' : 'No Call Connected'}
					</span>
					{isCallConnected && (
						<button
							onClick={endCallHandler}
							className="bg-red-500 text-sm ml-8 rounded-md text-white p-2"
						>
							End Call
						</button>
					)}
				</h1>
				<div className="grid grid-cols-3 md:mx-40 p-4 justify-between ">
					<div className="flex items-center">
						<span className="font-bold"> Your's Language </span>
						<CustomSelect
							name="agentLanguage"
							control={control}
							options={googleLiveVoiceToTextLanguages.map((country) => ({
								label: country.name,
								value: country.code,
							}))}
							placeholder={'Select Language'}
							defaultValue={{
								value: watch('agentLanguage')?.value,
								label: watch('agentLanguage')?.label,
							}}
							value={{
								value: watch('agentLanguage')?.value,
								label: watch('agentLanguage')?.label,
							}}
							className="border-b-0 rounded-0 xs:w-[60%] md:w-[100%] z-30   px-2"
						/>
					</div>
					<div className="flex items-center ">
						<span className="font-bold">Caller Silence Duration</span>
						<CustomSelect
							name="callerPauseOnSilentDuration"
							control={control}
							options={silenceOptions}
							placeholder={'Pause on silent duration'}
							className="border-2 rounded-xl ml-4 z-30"
							defaultValue={{
								label: `0.7's`,
								value: 0.7,
							}}
						/>
					</div>
					<div className="flex justify-between  items-center  w-full">
						<span className="font-bold "> Caller Language </span>
						<div className="">
							<CustomSelect
								name="callerLanguage"
								control={control}
								options={googleLiveVoiceToTextLanguages.map((country) => ({
									label: country.name,
									value: country.code,
								}))}
								placeholder={'Select Language'}
								// defaultValue={{
								// 	value: 'en-US',
								// 	label: 'English (United States)',
								// }}
								value={{
									value: watch('callerLanguage')?.value,
									label: watch('callerLanguage')?.label,
								}}
								className="border-b-0 rounded-0 xs:w-[60%] md:w-[100%] z-30   px-2"
							/>
						</div>
					</div>
				</div>
				<div className="bg-[#E5F3FF] py-10 px-2 flex flex-col gap-5 items-center rounded-3xl md:mx-40 xs:mx-8 z-10 shadow-lg">
					<div
						className="flex items-center justify-between  gap-5 relative w-full"
						style={{ textAlign: 'center' }}
					>
						<div>
							<CustomSelect
								name="pauseOnSilentDuration"
								control={control}
								options={silenceOptions}
								placeholder={'Pause on silent duration'}
								className="border-2 rounded-xl ml-4"
								defaultValue={{
									label: `1.0's`,
									value: 1.0,
								}}
							/>
						</div>
						<div>
							<CustomSelect
								name="gender"
								control={control}
								options={genderOptions}
								value={{
									value: watch('gender')?.value,
									label: watch('gender')?.label,
								}}
								placeholder={'Gender'}
								className="border-2 rounded-xl ml-4"
								defaultValue={{
									label: `MALE`,
									value: 'MALE',
								}}
							/>
						</div>
						<div>
							<CustomSelect
								defaultValue={{
									value: 'default',
									label: 'Default',
								}}
								name="inputDevices"
								control={control}
								options={inputDeviceOptions}
								placeholder={'Pause on silent duration'}
								className="border-2 rounded-xl mr-4 mb-8"
							/>
						</div>
					</div>
					<img src={isRecording ? MicRed : Mic} alt="" />
					<button
						className={`${
							isRecording ? 'bg-red-400' : 'bg-green-400'
						} text-white rounded-3xl sm:px-6 xs:px-2 py-1 w-60 h-10`}
						onClick={isRecording ? disconnect : connect}
					>
						{isRecording ? (
							'Press to Stop recording'
						) : isStreamLoading ? (
							<MinimalLoader color={'#90EE90'} />
						) : (
							'Press to start recording'
						)}
					</button>

					<p className="text-xl">Live Transcript</p>
					<div
						className="max-h-24"
						id={style.scrollbar}
						ref={RecognitionHistoryScroling}
					>
						{sortedRecognitionHistory?.map((tx, index) => (
							<div
								key={tx.id}
								style={{
									display: 'flex',
									flexDirection: 'row',
									gap: '10px',
								}}
							>
								<p className="text-medium">{tx.text}</p>
							</div>
						))}
					</div>

					<p className="text-2xl">
						{(currentRecognition.current || currentPhrase.current) && (
							<b>You: </b>
						)}
						<span>
							{currentRecognition.current}
							{currentPhrase.current}
						</span>
					</p>

					<p className="text-2xl">
						{liveTranscription && <b>Caller: </b>}
						<span>{liveTranscription && liveTranscription}</span>
					</p>
				</div>
				<div className="  md:mx-40 xs:mx-8 gap-2 mt-12">
					<div
						className="flex lg:items-start xs:items-center justify-between xxl:flex-row xs:flex-col gap-3 w-full px-12
				 "
					>
						<div
							className={`flex flex-col md:flex-row  border-2 rounded-lg border-custom-blue p-6 xxl:min-w-[39%] xs:w-[100%] min-h-[248px]`}
						>
							{/* play btn section  */}
							<div className="flex  items-start mr-10 mt-4">
								<div className="flex items-center">
									<div
										onClick={() => setAutoPlay(!autoPlay)}
										className="w-10 h-10 bg-black rounded-full cursor-pointer flex justify-center items-center mr-4	"
									>
										{!autoPlay ? (
											<PlayArrowIcon
												fontSize="large"
												style={{ color: '#fff' }}
											/>
										) : (
											<PauseIcon fontSize="large" style={{ color: '#fff' }} />
										)}
									</div>
									<span className="text-xs">Play audio as it arrives</span>
								</div>
							</div>
							<div className="mt-3 md:mt-0">
								<p
									onClick={downloadTranscriptHandler}
									className="text-xl font-semibold flex items-center justify-center text-center xs:mb-3 md:mb-0 cursor-pointer hover:text-custom-blue"
								>
									Download Transcript
								</p>

								<p className="text-lg font-semibold 3 flex items-center justify-center mb-3 mt-1">
									Transcript
								</p>
								<div className="h-48 w-80" id={style.scrollbar}>
									{/* {transcriptReceived?.map((el, i) => {
										return (
											<p key={i} className="mb-4 border-b-2">
												<strong>{el?.name}: </strong>
												<p>
													<span>{el?.text} </span>
												</p>
												<strong>
													{' '}
													{moment(el?.time).format('M/D/YY h:mm:ssA')}
												</strong>
											</p>
										);
									})} */}
									{dbMessages?.map((el, i) => {
										return (
											<p key={i} className="mb-4 border-b-2">
												<strong>{el?.sender?.toUpperCase()}: </strong>
												<p>
													<span>{el?.text} </span>
												</p>
												<strong>
													{' '}
													{moment(el?.time).format('M/D/YY h:mm:ssA')}
												</strong>
											</p>
										);
									})}
								</div>
							</div>
							<div>
								<p
									onClick={() => setDeletePresenterOpen(true)}
									className="ml-4 text-red-800 hover:text-red-500 cursor-pointer font-bold bg-red-300 rounded-md p-2"
								>
									Delete Transcript
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default IncommingCall;
