import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CustomSelect from 'component/CustomSelect';
import { useForm } from 'react-hook-form';
import Mic from 'assets/mic.svg';
import MicRed from 'assets/micRed.svg';
import { googleLiveVoiceToTextLanguages } from 'constants/languages';
import api from 'utils/axios';
import Loader from 'component/loader';
import MinimalLoader from 'component/MinimalLoader';
import PublicHeader from 'component/PublicHeader';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { useSelector } from 'react-redux';
import { ApiService } from 'services/api-services';
import useGoogleTranscriptionWithSingleConnection from 'hooks/useGoogleTranscriptionWithSingleConnection';
import Socket from '../../../services/SocketManager';
import moment from 'moment';
import autoPlayAudio from 'assets/autoplay.mp3';
import { create } from '@mui/material/styles/createTransitions';
import { createNotification } from 'utils/create-notification';
import style from './scrollbar.module.scss';

const updateLanguageToDb = async (language, presenterId) => {
	await api.post('podcast-translation/update-presenter-language', {
		language,
		podcastTranslationId: presenterId,
	});
};

const silenceOptions = [
	{ label: `0.2's`, value: 0.2 },
	{ label: `0.4's`, value: 0.4 },
	{ label: `0.6's`, value: 0.6 },
	{ label: `0.8's`, value: 0.8 },
	{ label: `1.0's`, value: 1.0 },
	{ label: `1.5's`, value: 1.5 },
	{ label: `2.0's`, value: 2.0 },
	{ label: `3.0's`, value: 3.0 },
	{ label: `4.0's`, value: 4.0 },
];

const genderOptions = [
	{ label: `Male`, value: 'male' },
	{ label: `Female`, value: 'female' },
];

let deviceLanguage = navigator.language || navigator.userLanguage;
deviceLanguage = googleLiveVoiceToTextLanguages.find(
	(el) => el.code === deviceLanguage
);

if (!deviceLanguage || deviceLanguage === 'undefined') {
	deviceLanguage = { name: 'English (United States)', code: 'en-US' };
}

const PresenterPage = ({ sourceLanguage }) => {
	const navigate = useNavigate();

	let { presenterId } = useParams();

	const [presenterName, setPresenterName] = useState(null);
	const [transcriptReceived, setTranscriptReceived] = useState([]);
	const [presenter, setPresenter] = useState();
	const [loading, setLoading] = useState(false);
	const [autoPlay, setAutoPlay] = useState(false);
	const [dbMessages, setDbMessages] = useState([]);
	const RecognitionHistoryScroling = useRef(null);
	const [inputDeviceOptions, setInputDeviceOptions] = useState([]);
	const [audioQueue, setAudioQueue] = useState([]);

	const autoPlayRef = useRef(autoPlay);
	const audioRef = useRef(new Audio());

	const { control, watch } = useForm({
		defaultValues: {
			sourceLanguage: {
				value: deviceLanguage?.code,
				label: deviceLanguage?.name,
			},
			gender: {
				value: 'male',
				label: 'Male',
			},
		},
	});

	const {
		isStreamLoading,
		connect,
		disconnect,
		currentPhrase,
		currentRecognition,
		recognitionHistory,
		isRecording,
		setSilenceDuration,
		dataSpeech,
		stopOnSilenceRef,
		currentRecognitionRef,
		updatedMessage,
	} = useGoogleTranscriptionWithSingleConnection(
		watch('sourceLanguage').value,
		1,
		watch('inputDevices')?.value
	);

	const sortedRecognitionHistory = [...recognitionHistory.current]
		.sort((a, b) => new Date(b.time) - new Date(a.time))
		.reverse();

	useEffect(() => {
		if (RecognitionHistoryScroling.current) {
			RecognitionHistoryScroling.current.scrollTop =
				RecognitionHistoryScroling.current.scrollHeight;
		}
	}, [sortedRecognitionHistory]);

	useEffect(() => {
		if (watch('pauseOnSilentDuration')?.value) {
			console.log(
				'pauseOnSilentDuration',
				watch('pauseOnSilentDuration')?.value
			);
			setSilenceDuration(watch('pauseOnSilentDuration')?.value);
		}
	}, [watch('pauseOnSilentDuration')?.value]);

	useEffect(() => {
		navigator.mediaDevices
			.enumerateDevices()
			.then((devices) => {
				const microphones = devices.filter(
					(device) => device.kind === 'audioinput'
				);
				const microphoneOptions = microphones.map((device) => ({
					label: device.label || `Microphone ${device.deviceId}`,
					value: device.deviceId,
				}));
				setInputDeviceOptions(microphoneOptions);
			})
			.catch((error) => {
				console.error('Error accessing media devices', error);
			});
	}, []);

	useEffect(() => {
		const playNextAudio = () => {
			if (audioQueue.length === 0) return;
			audioRef.current.src = audioQueue[0];
			audioRef.current.play();
			audioRef.current.onended = () => {
				setAudioQueue((prevQueue) => prevQueue.slice(1));
			};
		};

		if (audioRef.current.paused) {
			playNextAudio();
		}
	}, [audioQueue]);

	useEffect(() => {
		//Auto play
		autoPlayRef.current = autoPlay;
		audioRef.current.src = autoPlayAudio;
		audioRef.current.play();
	}, [autoPlay]);

	useEffect(() => {
		setLoading(true);
		ApiService.post(
			`api/podcast-translation/is-correct-presenter-url/${presenterId}`
		)
			.then((res) => {
				setPresenterName(res?.data?.podcastTranslation?.name);
				setPresenter(res?.data?.podcastTranslation);
				Socket.emit(
					'join_room_podcast',
					res?.data?.podcastTranslation?.roomId,
					watch('sourceLanguage')?.value,
					res?.data?.podcastTranslation?._id,
					watch('gender')
				);
				Socket.emit('language_to_moderator', {
					presenter: res?.data?.podcastTranslation,
					sourceLanguage: watch('sourceLanguage')?.value,
				});
				ApiService.get(
					`api/podcast-translation/get-podcast-messages/${
						res?.data?.podcastTranslation?.roomId
					}/${watch('sourceLanguage')?.value}`
				).then((res) => {
					const messages = res?.data?.messages;
					setDbMessages(messages?.reverse());
					setLoading(false);
				});

				res.status === 200 && res.data.isCorrect ? null : navigate('/');
			})
			.catch((err) => {
				setLoading(false);
				console.log(err, 'error in api call of is-correct-presenter-url');
			});

		Socket.on('receive_message_podcast', (data) => {
			if (autoPlayRef.current) {
				if (data?.presenter !== presenterId) {
					const audioData = data?.audio; // Base64 encoded audio data
					const audioByteArray = Uint8Array.from(atob(audioData), (c) =>
						c.charCodeAt(0)
					);
					const audioBlob = new Blob([audioByteArray], {
						type: 'audio/mp3',
					});
					console.log(audioData, 'audioBlob');
					const audioUrl = URL.createObjectURL(audioBlob);
					setAudioQueue((prevQueue) => [...prevQueue, audioUrl]);
					// const audio = new Audio(audioUrl);
					// audio.play();
				}
			}
			setTranscriptReceived((prev) => {
				return [data, ...prev];
			});
		});

		Socket.on('moderator_not_live', () => {
			createNotification('error', 'Error', 'Your Moderator is not live');
		});
	}, []);

	useEffect(() => {
		if (presenter) {
			Socket.emit('language_to_moderator', {
				presenter,
				sourceLanguage: watch('sourceLanguage')?.value,
			});
		}
		if (watch('sourceLanguage').value !== deviceLanguage?.code) {
			if (Socket) {
				Socket.emit(
					'join_room_podcast',
					presenter?.roomId,
					watch('sourceLanguage')?.value,
					presenter?._id
				); // Update language when it changes
			}
		}
		if (watch('sourceLanguage')?.value && presenterId) {
			updateLanguageToDb(watch('sourceLanguage')?.value, presenterId);
			if (isRecording) {
				disconnect();
				connect();
			}
		}
		if (presenter?.roomId) {
			setLoading(true);
			ApiService.get(
				`api/podcast-translation/get-podcast-messages/${presenter?.roomId}/${
					watch('sourceLanguage')?.value
				}`
			)
				.then((res) => {
					const messages = res?.data?.messages;
					setDbMessages(messages?.reverse());
					setLoading(false);
				})
				.catch((err) => {
					setLoading(false);
					console.log(err, 'error in api call of is-correct-presenter-url');
				});
		}
	}, [watch('sourceLanguage').value]);

	useEffect(() => {
		if (updatedMessage) {
			Socket.emit('send_message_podcast_moderator', {
				presenter,
				updatedMessage,
				sourceLanguage: watch('sourceLanguage')?.value,
				time: new Date(),
			});
		}
	}, [updatedMessage]);

	useEffect(() => {
		Socket.emit(
			'update_presenter_gender',
			presenter?._id,
			watch('gender.value'),
			presenter?.roomId
		);
	}, [watch('gender.value')]);

	const downloadTranscriptHandler = async () => {
		try {
			setLoading(true);
			if (!presenter?.roomId)
				return createNotification(
					'error',
					'Error',
					'Your Moderator is not live'
				);
			const response = await api.get(
				`podcast-translation/download-podcast-messages/${presenter?.roomId}/${
					watch('sourceLanguage')?.value
				}`
			);

			const blob = new Blob([response.data], {
				type: response.headers['content-type'],
			});

			// Create a temporary URL to download the file
			const url = window.URL.createObjectURL(blob);

			// Create an anchor element and programmatically click it to trigger the download
			const link = document.createElement('a');
			link.href = url;
			link.download = `transcript-${watch('sourceLanguage')?.value}`; // Set the desired file name and extension
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);

			// Release the temporary URL
			window.URL.revokeObjectURL(url);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.error('An error occurred in downloadTranscriptHandler:', error);
		}
	};

	return (
		<>
			{loading && <Loader />}
			<PublicHeader />
			<div className="flex flex-col w-full justify-center">
				<h1 className="text-4xl text-gray-600 font-[600] flex items-center justify-center my-12">
					Presenter - {presenterName}
				</h1>
				<CustomSelect
					name="sourceLanguage"
					control={control}
					options={googleLiveVoiceToTextLanguages.map((country) => ({
						label: country.name,
						value: country.code,
					}))}
					placeholder={'Select Language'}
					defaultValue={{
						value: deviceLanguage?.code,
						label: deviceLanguage?.name,
					}}
					className="border-b-0 rounded-0 xs:w-[60%] md:w-[20%] z-30 md:mx-40  px-2"
				/>
				<div className="bg-[#E5F3FF] py-10 px-2 flex flex-col gap-5 items-center rounded-3xl md:mx-40 xs:mx-8 z-10 shadow-lg">
					<div
						className="flex items-center justify-between  gap-5 relative w-full"
						style={{ textAlign: 'center' }}
					>
						<div>
							<CustomSelect
								name="pauseOnSilentDuration"
								control={control}
								options={silenceOptions}
								placeholder={'Pause on silent duration'}
								className="border-2 rounded-xl ml-4"
								defaultValue={{
									label: `1.0's`,
									value: 1.0,
								}}
							/>
						</div>
						<div>
							<CustomSelect
								name="gender"
								control={control}
								options={genderOptions}
								placeholder={'Gender'}
								className="border-2 rounded-xl ml-4"
								defaultValue={{
									label: `Male`,
									value: 'male',
								}}
							/>
						</div>
						<div>
							<CustomSelect
								defaultValue={{
									value: 'default',
									label: 'Default',
								}}
								name="inputDevices"
								control={control}
								options={inputDeviceOptions}
								placeholder={'Pause on silent duration'}
								className="border-2 rounded-xl mr-4 mb-8"
							/>
						</div>
					</div>
					<img src={isRecording ? MicRed : Mic} alt="" />
					<button
						className={`${
							isRecording ? 'bg-red-400' : 'bg-green-400'
						} text-white rounded-3xl sm:px-6 xs:px-2 py-1 w-60 h-10`}
						onClick={isRecording ? disconnect : connect}
					>
						{isRecording ? (
							'Press to Stop recording'
						) : isStreamLoading ? (
							<MinimalLoader color={'#90EE90'} />
						) : (
							'Press to start recording'
						)}
					</button>

					<p className="text-xl">Live Transcript</p>
					<div
						className="max-h-24"
						id={style.scrollbar}
						ref={RecognitionHistoryScroling}
					>
						{sortedRecognitionHistory?.map((tx, index) => (
							<div
								key={tx.id}
								style={{
									display: 'flex',
									flexDirection: 'row',
									gap: '10px',
								}}
							>
								<p className="text-medium">{tx.text}</p>
							</div>
						))}
					</div>

					<p className="text-2xl">
						{currentRecognition.current}
						{currentPhrase.current}
					</p>
				</div>
				<div className="  md:mx-40 xs:mx-8 gap-2 mt-12">
					<div
						className="flex lg:items-start xs:items-center justify-between xxl:flex-row xs:flex-col gap-3 w-full px-12
				 "
					>
						<div
							className={`flex flex-col md:flex-row  border-2 rounded-lg border-custom-blue p-6 xxl:min-w-[39%] xs:w-[100%] min-h-[248px]`}
						>
							{/* play btn section  */}
							<div className="flex  items-start mr-10 mt-4">
								<div className="flex items-center">
									<div
										onClick={() => setAutoPlay(!autoPlay)}
										className="w-10 h-10 bg-black rounded-full cursor-pointer flex justify-center items-center mr-4	"
									>
										{!autoPlay ? (
											<PlayArrowIcon
												fontSize="large"
												style={{ color: '#fff' }}
											/>
										) : (
											<PauseIcon fontSize="large" style={{ color: '#fff' }} />
										)}
									</div>
									<span className="text-xs">Play audio as it arrives</span>
								</div>
							</div>
							<div className="mt-3 md:mt-0">
								<p
									onClick={downloadTranscriptHandler}
									className="text-xl font-semibold flex items-center justify-center text-center xs:mb-3 md:mb-0 cursor-pointer hover:text-custom-blue"
								>
									Download Transcript
								</p>{' '}
								<p className="text-lg font-semibold 3 flex items-center justify-center mb-3 mt-1">
									Transcript
								</p>
								<div className="h-48" id={style.scrollbar}>
									{transcriptReceived?.map((el, i) => {
										return (
											<p key={i} className="mb-4 border-b-2">
												<strong>{el?.name}: </strong>
												<p>
													<span>{el?.text} </span>
												</p>
												<strong>
													{' '}
													{moment(el?.time).format('M/D/YY h:mm:ssA')}
												</strong>
											</p>
										);
									})}
									{dbMessages?.map((el, i) => {
										return (
											<p key={i} className="mb-4 border-b-2">
												<strong>{el?.sender}: </strong>
												<p>
													<span>{el?.text} </span>
												</p>
												<strong>
													{' '}
													{moment(el?.time).format('M/D/YY h:mm:ssA')}
												</strong>
											</p>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default PresenterPage;
